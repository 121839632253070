import React from 'react';
import styled from "styled-components";
import {Menu} from "antd";

const Wrapper = styled.svg`
    fill: #494D5B;
     stroke: #494D5B;
    
    &:hover {
      fill: #2E2C9A;
     stroke: #2E2C9A;
    }
`

export default ({ style }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.4004 5.2002H3.20039L2.40039 12.8002L2.80039 14.4002L3.60039 14.8002L12.4004 15.0002L13.2004 14.0002L12.4004 5.2002Z" fill="#FF8E95" stroke="#FF8E95"/>
        <g clipPath="url(#clip0)">
            <path d="M12.9147 5.0739C12.9021 4.92579 12.7761 4.8123 12.626 4.81375H10.4319V3.99018C10.4319 2.64776 9.33307 1.55957 7.97783 1.55957C6.62248 1.55957 5.52381 2.64776 5.52381 3.99018V4.81375H3.32963C3.1802 4.81431 3.05547 4.92668 3.04092 5.0739L2.28453 13.6869C2.25013 14.0863 2.38637 14.4817 2.6603 14.7769C2.93412 15.072 3.32038 15.2399 3.72514 15.2396H12.2333C12.6381 15.2399 13.0244 15.072 13.2982 14.7769C13.5721 14.4817 13.7085 14.0863 13.6741 13.6869L12.9147 5.0739ZM6.10123 3.99018C6.10123 2.96365 6.94142 2.13148 7.97783 2.13148C9.01425 2.13148 9.85443 2.96365 9.85443 3.99018V4.81375H6.10123V3.99018ZM12.8714 14.3903C12.7081 14.5684 12.4762 14.6692 12.2333 14.6677H3.72221C3.4794 14.6678 3.24776 14.5669 3.08355 14.3897C2.91946 14.2125 2.83793 13.9751 2.85902 13.7355L3.59229 5.38566H5.52381V7.16997C5.05951 7.31149 4.76956 7.76801 4.84185 8.24386C4.91403 8.71959 5.32679 9.07145 5.81252 9.07145C6.29825 9.07145 6.7109 8.71959 6.78319 8.24386C6.85537 7.76801 6.56542 7.31149 6.10123 7.16997V5.38275H9.85443V7.16997C9.39013 7.31149 9.10018 7.76801 9.17247 8.24386C9.24465 8.71959 9.65741 9.07145 10.1431 9.07145C10.6289 9.07145 11.0415 8.71959 11.1138 8.24386C11.186 7.76801 10.896 7.31149 10.4319 7.16997V5.38275H12.3604L13.0937 13.7326C13.1174 13.9732 13.0365 14.2124 12.8714 14.3903ZM5.76922 7.70189C5.79797 7.70568 5.82707 7.70568 5.85583 7.70189C6.06931 7.72467 6.22765 7.90898 6.21615 8.12132C6.20453 8.33378 6.02725 8.50021 5.81252 8.50021C5.59768 8.50021 5.4204 8.33378 5.40889 8.12132C5.39739 7.90898 5.55562 7.72467 5.76922 7.70189ZM10.0998 7.70189C10.1286 7.70568 10.1577 7.70568 10.1864 7.70189C10.3999 7.72467 10.5583 7.90898 10.5468 8.12132C10.5352 8.33378 10.3579 8.50021 10.1431 8.50021C9.9283 8.50021 9.75102 8.33378 9.73952 8.12132C9.72801 7.90898 9.88624 7.72467 10.0998 7.70189Z" fill="#FF8E95" stroke="#FF8E95" strokeWidth="0.2"/>
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="15.2" height="15.2" fill="white" transform="translate(0 0.799805)"/>
            </clipPath>
        </defs>
    </svg>
);
