import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";

import {
  Field,
  FieldArray,
  reduxForm,
  FormSection,
  formValueSelector,
  initialize,
  change,
  reset,
} from "redux-form";
import { Form, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import cancel from "../../../assets/icons/cancelbtn.svg";
import { FormComponents, InTypography, InButton } from "../../";
import { InputPhone } from "../../FormComponents/MobileNumberInput";
import { AddDates, AddCities, AddGuests } from "../../../components";
import { events } from "../../../helpers/firestore";

const FormItem = Form.Item;

const Wrapper = styled.div``;
const FormWrapper = styled.form`
  max-width: 670px;
  margin: 6.75em auto 0 auto;

  .ant-form-item-label {
    text-align: left;
  }

  .ant-form-item-label label::after {
    content: "";
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  h1 {
    font-size: 2em;
    line-height: 140%;
    color: #101b43;
  }

  button {
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
  }
`;
const Section = styled.div`
  width: 100%;
  height: 2.75em;
  margin: 27px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1em;
  background: #fafafc;
  border-radius: 4px;

  h5 {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 100%;
    text-transform: uppercase;
    color: #2e2c9a;
    padding: 0;
    margin: 0;
  }
`;

const Bride = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const CancelBtn = styled.button`
  width: 44px;
  height: 44px;
  z-index: 12;
`;

const PlainBtn = styled(Button)`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 80%;
  margin-left: 35px;
  margin-top: 0.75em;
  /* identical to box height, or 12px */

  text-transform: uppercase;

  color: #2e2c9a;

  svg {
    font-size: 1em;
    font-weight: bold;
  }
`;

const InputWrapper = styled.div`
  margin: 1em 0;
  display: flex;
  flex-direction: column;

  label {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #101b43;
  }

  span {
    text-align: right;
    color: red;
    width: 100%;
  }
`;

const renderBrides = ({ fields, countryCode }) => {
  const data = fields.length ? fields : ["brides[0]"];
  return (
    <Bride>
      {data.map((member, index) => {
        return (
          <li key={index}>
            <Section>
              <h5>BRIDE'S DETAILS</h5>
              {!!index && (
                <button
                  type="button"
                  title="Remove Member"
                  onClick={() => fields.remove(index)}
                >
                  <img src={cancel} alt="remove" />
                </button>
              )}
            </Section>
            <Field
              name={`${member}.fullname`}
              type="text"
              component={FormComponents.InInput}
              label="Bride's Name"
              placeholder="Full Name"
            />
            <Field
              label="Bride's Email"
              name={`${member}.email`}
              type="email"
              component={FormComponents.InInput}
              placeholder="Email"
              hasFeedback
            />
            <InputWrapper>
              <label htmlFor="mobileNumber">Bride’s Mobile Number</label>
              <Field
                label="Mobile Number"
                name={`${member}.mobile`}
                component={InputPhone}
                countryCode={countryCode}
                defaultCountry="RU"
                valueInput=""
              />
            </InputWrapper>
          </li>
        );
      })}
      {/* <li>
        <PlainBtn
          type="text"
          onClick={() => fields.push({})}
          icon={<PlusOutlined />}
        >
          Add Another Bride
        </PlainBtn>
      </li> */}
    </Bride>
  );
};

const CreateEventForm = ({
  handleSubmit,
  formState,
  formInit,
  resetFields,
  onClose,
  countryCode,
  uid,
  displayName,
  eventName,
  brides,
  dates,
  cities,
  guestList,
}) => {
  const [loading, setLoader] = useState(false);
  const [destinations, setDestinations] = useState([]);
  function initForm(data) {
    formInit({ ...formState.values, ...data });
  }

  const resetForm = () => {
    formState.values = {};
    formInit({});
    setDestinations([]);
  };
  const submitFunction = (data) => {
    setLoader(true);
    events.create(uid, displayName, data, setLoader, onClose, resetForm);
  };

  return (
    <Wrapper>
      <FormWrapper onSubmit={handleSubmit((values) => submitFunction(values))}>
        <TitleWrapper>
          <InTypography.InH1>New Event</InTypography.InH1>
          <CancelBtn type="button" onClick={() => onClose()}>
            <img src={cancel} alt="cancel" />
          </CancelBtn>
        </TitleWrapper>
        <FieldArray
          name="brides"
          component={renderBrides}
          countryCode={countryCode}
        />
        <Section>
          <h5>EVENT DETAILS</h5>
        </Section>
        <FormItem>
          <Field
            label="Event Name"
            name="eventName"
            type="text"
            component={FormComponents.InInput}
            placeholder="Event Name"
          />
        </FormItem>
        <FormSection name="dates">
          <FormItem>
            <AddDates
              dates={dates}
              id="dates"
              resetFields={resetFields}
              initForm={initForm}
            />
          </FormItem>
        </FormSection>
        <FormItem>
          <AddCities
            initForm={initForm}
            cities={cities}
            destinations={destinations}
            setDestinations={setDestinations}
          />
        </FormItem>
        <FormItem>
          <AddGuests
            initForm={initForm}
            countryCode={countryCode}
            guestList={guestList}
          />
        </FormItem>
        <FormItem style={{ marginTop: 60 }}>
          <InButton
            onClick={() => null}
            text="CREATE EVENT"
            htmlType="submit"
            primary="true"
            disabled={!eventName || !brides || !cities || !guestList || !dates}
            loading={loading}
          />
        </FormItem>
      </FormWrapper>
    </Wrapper>
  );
};

CreateEventForm.defaultProps = {};

CreateEventForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const CreateEventFormForm = reduxForm({
  form: `createEvent`,
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(CreateEventForm);

const selector = formValueSelector("createEvent");

export function mapStateToProps(state) {
  return {
    uid: state.auth.user.user.uid,
    displayName: state.auth.user.user.displayName,
    countryCode: state.location.data.country.code,
    formState: state.form.createEvent,
    eventName: selector(state, "eventName"),
    brides: selector(state, "brides"),
    dates: selector(state, "dates"),
    cities: selector(state, "cities"),
    guestList: selector(state, "guestList"),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    formInit: (data) => {
      dispatch(initialize("createEvent", data));
    },
    resetFields: (fields) => {
      dispatch(change("createEvent", "dates.", ""));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEventFormForm);
