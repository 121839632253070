import React, { useState } from 'react'
import styled from 'styled-components'
import { Button } from 'antd';

export const DefaultBtn = styled(Button)`
  background: ${props => props.primary ? '#EC6E5B' : '#ffffff'};
  border-color: #EC6E5B;
  width: ${props => props.width ? props.width : '172px'};
  height: 44px;
  border-radius: 4px;
  color: ${props => props.primary ? '#ffffff' : '#EC6E5B'};
  text-transform: uppercase;
  
  &:hover {
    background: ${props => props.primary ? '#EC6E5B' : '#ffffff'};
    border-color: #EC6E5B;
    color: ${props => props.primary ? '#ffffff' : '#EC6E5B'};
  }
  
  &:focus {
    background: ${props => props.primary ? '#EC6E5B' : '#ffffff'};
    border-color: #EC6E5B;
    color: ${props => props.primary ? '#ffffff' : '#EC6E5B'};
  }

`

const Wrapper = styled(Button)`
  background: ${props => props.primary ? '#4F4DB8' : '#ffffff'};
  border-color: #4F4DB8;
  width: ${props => props.width ? props.width : '172px'};
  height: 44px;
  border-radius: 4px;
  color: ${props => props.primary ? '#ffffff' : '#2E2C9A'};
  text-transform: uppercase;
  
  &:hover {
    background: ${props => props.primary ? '#2E2C9A' : '#ffffff'};
    border-color: #2E2C9A;
    color: ${props => props.primary ? '#ffffff' : '#2E2C9A'};
  }
  
  &:focus {
    background: ${props => props.primary ? '#2E2C9A' : '#ffffff'};
    border-color: #2E2C9A;
    color: ${props => props.primary ? '#ffffff' : '#2E2C9A'};
  }

`

export const InButton = ({ text, onClick, icon, primary, disabled, width, htmlType, loading, style }) => {

	return (
		<Wrapper
			type="default"
			icon={icon}
			disabled={disabled}
			onClick={() => onClick ? onClick() : null}
			primary={primary ? primary.toString() : null}
			width={width}
			htmlType={htmlType}
			loading={loading}
			style={style}
		>
			{text}
		</Wrapper>
	);
};

InButton.defaultProps = {

};

InButton.propTypes = {

};

