import React from 'react'
import styled from 'styled-components'
import ReactSelect from 'react-select';
import { Form, Input, Radio, Select, Checkbox, DatePicker } from "antd";

const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const { TextArea } = Input;

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 24 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 24 }
	}
};


const CheckboxField = styled(FormItem)`

	padding-top: 10px;
	label {
		display: flex;
		align-items: center;
	}
	label + span {
		color: #101B43;
		padding-left: 23px;
	}

	label {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      color: #101B43;
    }
    
    &:focus {
       border: 1px solid #4F4DB8;
    }
      
    input, textarea, select {
      height: 44px;
      outline: none;
      border-radius: 4px;
      
      &:hover {
        border: 1px solid #4F4DB8;
      }
      
      &:focus {
        border: 1px solid #4F4DB8;
      }
    }


    .css-1okebmr-indicatorSeparator {
        display: none
    }
	
`

const InputField = styled(Input)`

.ant-form-vertical .ant-form-item-label > label {
  color: red;
}

.ant-form-item-label {
    position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    height: 32px;
    color: red;
    font-size: 14px;
}

label { 
  font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    display: none;
    /* identical to box height, or 24px */
    
    
    /* Text/Midnight Blue */
    
    color: #101B43;
    }
`

const SelectFieldItem = styled(FormItem)`
    max-width: 31.5em;
    
    label {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      color: #101B43;
    }
    
    &:focus {
       border: 1px solid #4F4DB8;
    }
      
    input, select {
      height: 44px;
      outline: none;
      
      &:hover {
        border: 1px solid #4F4DB8;
      }
      
      &:focus {
        border: 1px solid #4F4DB8;
      }
    }
`

const FieldItem = styled(FormItem)`
     
    label {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      color: #101B43;
    }
    
    &:focus {
       border: 1px solid #4F4DB8;
    }
      
    input, textarea, select {
      height: 44px;
      outline: none;
      border-radius: 4px;
      
      &:hover {
        border: 1px solid #4F4DB8;
      }
      
      &:focus {
        border: 1px solid #4F4DB8;
      }
    }


    .css-1okebmr-indicatorSeparator {
        display: none
    }
`

const FieldItemSelect = styled(FormItem)`
     
    label {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      color: #101B43;
    }
    
    &:focus {
       border: 1px solid #4F4DB8;
    }
      
    select {
      outline: none;
      border-radius: 4px;
      
      &:hover {
        border: 1px solid #4F4DB8;
      }
      
      &:focus {
        border: 1px solid #4F4DB8;
      }
    }


    .css-1okebmr-indicatorSeparator {
        display: none
    }
`

const makeField = Component => ({ input, meta, children, hasFeedback, label, ...rest }) => {
	const hasError = meta.touched && meta.invalid;
	return (
		<FieldItem
			{...formItemLayout}
			label={label}
			validateStatus={hasError ? "error" : "success"}
			hasFeedback={hasFeedback && hasError}
		// help={hasError && meta.error}
		>
			<Component {...input} {...rest} children={children} size="large" rows={4} />
		</FieldItem>
	);
};

const makeFieldCheckbox = Component => ({ input, meta, children, hasFeedback, label, additionText, ...rest }) => {
	const hasError = meta.touched && meta.invalid;
	return (
		<CheckboxField
			validateStatus={hasError ? "error" : "success"}
			hasFeedback={hasFeedback && hasError}
		>
			<Component {...input} {...rest} children={children} size="large" rows={4} >{label}</Component>
			{additionText && <span>{additionText}</span>}
		</CheckboxField>
	);
};

const makeReactSelect = Component => ({ input, meta, children, options, defaultValue, hasFeedback, label, ...rest }) => {
	const hasError = meta.touched && meta.invalid;
	const handleChange = (e, input) => {
		if (e && e.find(item => item.label === 'Everyone')) {
			const all = e.find(item => item.label === 'Everyone')
			return input.onChange(all.everyone.filter(item => item.label !== 'Everyone'))
		}
		if (e && e.find(item => item.label === 'Everyone but the Bride')) {
			const all = e.find(item => item.label === 'Everyone but the Bride')
			return input.onChange(all.everyone.filter(item => item.label !== 'Everyone but the Bride'))
		}
		return input.onChange(e)
	}

	return (
		<FieldItemSelect
			{...formItemLayout}
			label={label}
			validateStatus={hasError ? "error" : "success"}
			hasFeedback={hasFeedback && hasError}
		// help={hasError && meta.error}
		>
			<Component
				{...input}
				{...rest}
				closeMenuOnSelect={false}
				isMulti
				children={children}
				onChange={(e) => handleChange(e, input)}
				onFocus={e => e.preventDefault()}
				onBlur={e => e.preventDefault()}
				options={options}
				defaultValue={options}
			/>
		</FieldItemSelect>
	);
};

const makeDatePicker = Component => ({ input, meta, children, options, hasFeedback, label, ...rest }) => {
	const hasError = meta.touched && meta.invalid;

	const handleChange = (e, input) => {
		return input.onChange(e)
	}
	return (
		<FormItem
			{...formItemLayout}
			label={label}
			validateStatus={hasError ? "error" : "success"}
			hasFeedback={hasFeedback && hasError}
		// help={hasError && meta.error}
		>
			<Component
				{...input}
				{...rest}
				children={children}
				onChange={(e) => handleChange(e, input)}
				onFocus={e => e.preventDefault()}
				onBlur={e => e.preventDefault()}
				size='large'
			/>
		</FormItem>
	);
};

const makeMultiSelect = Component => ({ input, options, meta, children, hasFeedback, label, ...rest }) => {
	const hasError = meta.touched && meta.invalid;
	const handleChange = (e, input) => {
		return input.onChange(e)
	}

	return (
		<SelectFieldItem
			{...formItemLayout}
			label={label}
			validateStatus={hasError ? "error" : "success"}
			hasFeedback={hasFeedback && hasError}
			// help={hasError && meta.error}
			size='large'
		>
			<Component
				{...input}
				{...rest}
				children={children}
				onChange={(e) => handleChange(e, input)}
				onFocus={e => e.preventDefault()}
				onBlur={e => e.preventDefault()}
				size='large'
			/>
		</SelectFieldItem>
	);
};

export const InInput = makeField(InputField);
export const InRadioGroup = makeField(RadioGroup);
export const InSelect = makeField(Select);
export const InCheckbox = makeFieldCheckbox(Checkbox);
export const InCheckboxGroup = makeField(Checkbox);
export const InTextarea = makeField(TextArea);
export const InDatePicker = makeDatePicker(DatePicker);
export const InMultiSelect = makeMultiSelect(Select);
export const InReactSelect = makeReactSelect(ReactSelect);


const Wrapper = styled.div`

`

const FormComponents = props => {
	return (
		<Wrapper>
			FormComponents
		</Wrapper>
	);
};

FormComponents.defaultProps = {

};

FormComponents.propTypes = {

};

export default FormComponents;
