import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Field, reduxForm, initialize, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import styled from "styled-components";
import { Row, Col } from "antd";
import { FormComponents, InButton } from "../../components";
import { emailProvider, auth } from "../../config/firebase";
import { toast } from "../../redux/actions";
import { Section, Header } from "./Settings";

const InputWrapper = styled.div`
  margin: 1em 0;
  display: flex;
  flex-direction: column;

  label {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #101b43;
  }

  span {
    text-align: right;
  }

  .ant-row {
    margin: 0;
  }

  .ant-form-item {
    margin: 0;
  }
`;

const Error = styled.span`
  color: #ee4238;
`;
const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 128px;
`;

const PasswordUser = ({ passwords, dispatch }) => {
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  useEffect(() => {
    if (
      passwords.currentPassword &&
      passwords.newPassword &&
      passwords.confirmPassword
    ) {
      setSubmit(true);
    }
  }, [passwords]);

  const changePassword = async (passwordGroup) => {
    setLoading(true);
    if (
      !passwordGroup.currentPassword &&
      !passwordGroup.newPassword &&
      !passwordGroup.confirmPassword
    ) {
      setErrors({
        currentPassword: true,
        newPassword: true,
        confirmPassword: true,
      });
      setLoading(false);
      return;
    }
    if (!passwordGroup.currentPassword) {
      setErrors({
        currentPassword: true,
      });
      setLoading(false);
      return;
    }

    if (!passwordGroup.newPassword) {
      setErrors({
        newPassword: true,
      });
      setLoading(false);
      return;
    }

    if (!passwordGroup.confirmPassword) {
      setErrors({
        confirmPassword: true,
      });
      setLoading(false);
      return;
    }

    if (
      passwordGroup.currentPassword &&
      passwordGroup.newPassword &&
      passwordGroup.confirmPassword
    ) {
      if (passwordGroup.newPassword === passwordGroup.confirmPassword) {
        const user = auth.currentUser;

        const credential = await emailProvider.credential(
          user.email,
          passwordGroup.currentPassword
        );

        // Prompt the user to re-provide their sign-in credentials
        user
          .reauthenticateWithCredential(credential)
          .then((res) => {
            user
              .updatePassword(passwordGroup.newPassword)
              .then(() => {
                setLoading(false);
                dispatch(toast("Password updated", "success"));
              })
              .catch((error) => {
                setErrors({
                  currentPassword: true,
                });
                setLoading(false);
              });
          })
          .catch(function (error) {
            setLoading(false);
            setErrors({
              currentPassword: true,
            });
          });
      } else {
        setErrors({
          confirmPassword: true,
        });
        setLoading(false);
      }
    }
  };

  const onChangeCurrent = (e) => {
    if (e.target.value) {
      setErrors({
        currentPassword: false,
      });
    }
  };

  const onChangeNew = (e) => {
    if (e.target.value) {
      setErrors({
        newPassword: false,
      });
    }
  };

  const onChangeConfirm = (e) => {
    if (e.target.value) {
      setErrors({
        confirmPassword: false,
      });
    }
  };
  return (
    <Row>
      <Header xs={24}>
        <h5>PASSWORD</h5>
      </Header>
      <Section xs={24}>
        <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
          <Col xs={24} md={6}>
            <InputWrapper>
              <Field
                label="Current Password"
                name="currentPassword"
                type="password"
                component={FormComponents.InInputPassword}
                placeholder="Current Password"
                onChange={(e) => onChangeCurrent(e)}
              />
              <Error>
                {errors.currentPassword ? "Enter a valid password" : ""}
              </Error>
            </InputWrapper>
          </Col>
          <Col xs={24} md={6}>
            <InputWrapper>
              <Field
                label="New Password"
                name="newPassword"
                type="password"
                component={FormComponents.InInputPassword}
                placeholder="Password"
                onChange={(e) => onChangeNew(e)}
              />
              <Error>{errors.newPassword ? "Add a new password" : ""}</Error>
            </InputWrapper>
          </Col>
          <Col xs={24} md={6}>
            <InputWrapper>
              <Field
                label="Confirm New Password"
                name="confirmPassword"
                type="password"
                component={FormComponents.InInputPassword}
                placeholder="Password"
                onChange={(e) => onChangeConfirm(e)}
              />
              <Error>
                {errors.confirmPassword ? "Passwords must match" : ""}
              </Error>
            </InputWrapper>
          </Col>
          <Col xs={24} md={6}>
            <BtnWrapper>
              <InButton
                htmlType="button"
                onClick={() => changePassword(passwords)}
                text="Save password"
                width="264px"
                disabled={!submit}
                loading={loading}
                primary
              />
            </BtnWrapper>
          </Col>
        </Row>
      </Section>
    </Row>
  );
};
const validate = (values) => {
  const errors = {};

  return errors;
};

const PasswordUserForm = reduxForm({
  form: `passwordUser`,
  validate,
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(PasswordUser);

const selector = formValueSelector("passwordUser");

export function mapStateToProps(state) {
  return {
    passwords: {
      currentPassword: selector(state, "currentPassword"),
      newPassword: selector(state, "newPassword"),
      confirmPassword: selector(state, "confirmPassword"),
    },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    formInit: (data) => {
      dispatch(initialize("passwordUser", data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordUserForm);
