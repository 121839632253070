import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Space } from "antd";
import { scroller } from "react-scroll/modules";
import NavbarProvider, { NavbarContext } from "./NavbarProvider";
import { Login } from "../";
import { MaxWidthContainer, ProfilePhoto, Inbox } from "../../components";
import MobileNav from "./MobileNav";
import EventMobileNav from "./EventMobileNav";
import invigoLogo from "../../assets/logo/logo.svg";
import Hamburger from "../../assets/icons/hamburger";

const NavWrapper = styled.nav`
  position: fixed;
  top: -1px;
  left: 0;
  width: 100%;
  height: 56px;
  border-bottom: 1px solid #ccc;
  background: #fff;
  z-index: 999;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Nav = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Menu = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const ListItem = styled.li`
  margin: 0 15px;

  a {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: ${(props) => (props.active ? "#EC6E5B" : "#101B43")};
  }
`;

const AuthSpace = styled(Space)`
  ${(props) => (props.disabled === true ? "display: none;" : "")};

  @media (max-width: 1024px) {
    display: none;
  }
`;

const MobileMenu = styled.button`
  display: none;
  border: none;
  margin: 0;
  background: none;

  ${(props) => props.theme.breakpoints.maxTablet} {
    display: block;
  }
`;

const scrollOnPage = (name, e) => {
  scroller.scrollTo(name, {
    duration: 800,
    delay: 0,
    smooth: "easeInOutQuart",
  });

  e.preventDefault();
};

const Navbar = ({ location, scrollTo }) => {
  const [openMobile, setMobileMenu] = useState(false);
  const [activeTab, setActiveTab] = useState("");

  const toggleMobileMenu = () => {
    setMobileMenu(!openMobile);
  };

  const toogleActiveTab = (name) => {
    setActiveTab(name);
  };

  return (
    <NavbarProvider>
      <NavbarContext.Consumer>
        {({ isAuthenticated, user, logout, submitUserDetails }) => {
          const disabled = !!location.pathname.includes("/rsvp/");
          const isEvent = !!location.pathname.includes("/event/") || !!location.pathname.includes("/event-guest-view/");
          const isGuest = !!location.pathname.includes("/event-guest-view/");
          return (
            <NavWrapper>
              <MaxWidthContainer isAuthenticated={isAuthenticated}>
                <Nav>
                  <a href="https://goinvigo.com/" target="_blank" rel="noopener noreferrer">
                    <img src={invigoLogo} alt="" />
                  </a>
                  {false && !isAuthenticated && !disabled && (
                    <Menu>
                      <ListItem active={location.pathname.includes("/about")}>
                        <Link to="/about" onClick={(e) => toogleActiveTab("about")}>
                          About
                        </Link>
                      </ListItem>
                      <ListItem active={activeTab === "features"}>
                        <Link
                          to={{
                            pathname: "/",
                            state: {
                              scrollToSection: "info",
                            },
                          }}
                          onClick={(e) => toogleActiveTab("features")}
                        >
                          Features
                        </Link>
                      </ListItem>
                      <ListItem active={location.pathname.includes("/planning-tools")}>
                        <Link to="/" onClick={(e) => toogleActiveTab("planning")}>
                          Planning Tools
                        </Link>
                      </ListItem>

                      <ListItem active={location.pathname.includes("/city-guides")}>
                        <Link to="/city-guides" onClick={(e) => toogleActiveTab("city-guides")}>
                          City Guides
                        </Link>
                      </ListItem>
                      <ListItem active={location.pathname.includes("/ideas-and-advice")}>
                        <Link to="/" onClick={(e) => toogleActiveTab("ideas")}>
                          {`Ideas & Advice`}
                        </Link>
                      </ListItem>
                      <ListItem>
                        <a href="https://www.goinvigo.com/city-guides/" target="_blank">
                          City Guides
                        </a>
                      </ListItem>
                    </Menu>
                  )}
                  {!isAuthenticated && !disabled && (
                    <AuthSpace>
                      <Space>
                        <Login name="login" title="sign in" />
                      </Space>
                      <Space>
                        <Login name="register" title="sign up" submitUserDetails={submitUserDetails} primary />
                      </Space>
                    </AuthSpace>
                  )}
                  {isAuthenticated && !disabled && (
                    <AuthSpace size="large" disabled={disabled}>
                      <Inbox />
                      <ProfilePhoto user={user} logout={logout} />
                    </AuthSpace>
                  )}
                </Nav>
              </MaxWidthContainer>
              <MobileMenu onClick={() => toggleMobileMenu()}>
                <Hamburger />
              </MobileMenu>
              {isAuthenticated && !disabled && isEvent ? (
                <EventMobileNav
                  location={location}
                  visible={openMobile}
                  onClose={toggleMobileMenu}
                  handleLogout={logout}
                  isGuest={isGuest}
                />
              ) : (
                <MobileNav
                  location={location}
                  visible={openMobile}
                  onClose={toggleMobileMenu}
                  isAuthenticated={isAuthenticated}
                  submitUserDetails={submitUserDetails}
                  logout={logout}
                />
              )}
            </NavWrapper>
          );
        }}
      </NavbarContext.Consumer>
    </NavbarProvider>
  );
};

Navbar.defaultProps = {};

Navbar.propTypes = {};

export default Navbar;
