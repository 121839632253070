export default {
  primary: '#f05154',
  secondary: '#282f72',
  lightPink: '#fac5c6',
  lightgrey: '#c4ccdf',
  darkgrey: '#a4adbe',
  deepDark: '#707070',
  white: '#FFFFFF',
  black: '#000000',
  crimsonRed: '#990000',
  altBlue: '#002C3E',
  tarawera: '#083D55',
  tradewind: '#5DA9AE',
}
