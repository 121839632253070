import React, { useState } from "react";
import "./styles.css";
import styled from "styled-components";
import { connect } from 'react-redux';

import { Row, Col, Rate, Button, Checkbox } from "antd";
import AddCircle from "../../../../assets/icons/addCircle";
import HeartIcon from "../../../../assets/icons/heartButton";
import { PlusOutlined, CheckOutlined } from "@ant-design/icons";
import { InButton } from "../../../../components";

const TodoContainer = styled.div`
  width: 100%;
  height: 357px;
  background: #FFFFFF;
  border-radius: 8px;
  margin-bottom: 1.3125em;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05);
  
  
`


export const Text = styled.div`
   background: #FAFAFC;
   padding: 0.5em  1.25em 0.5em  1.25em;
   border-radius: 8px;
   min-height: 110px;
   
   h4 {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: #101B43;
    display: flex;
    justify-content: space-between;
    margin: 0.5em 0;
    padding: 0;
    span {
      color: #2E2C9A;
    }
   }
   
   p {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;    
    color: #101B43;
    margin: 0 0 0.5em 0;
    padding: 0;
   }
  
`;
export const Image = styled.img`
    width: 100%;
`;

export const Wrapper = styled.div`
    width: 100%;
    height: 188px;
    background: transparent url(${props => props.src}) no-repeat center;
    background-size: cover;
    border-radius: 8px 8px 0 0;
`;

export const AddButton = styled.div`
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    height: 357px;
    margin-bottom: 1.3125em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    
    h4 {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 80%;
        padding: 0;
        margin: 2.09375em 0 0 0;
        text-transform: uppercase;
        color: #2E2C9A;
    }
`;

const Ratings = styled.div`
  display: flex;
  align-items: center;
  
  h5 {
    font-family: 'Proxima Nova Semibold';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 100%;
    color: #2E2C9A;
    padding: 0;
    margin: 0 0.5em 0 0;
  }
  
    
  .ant-rate {
    font-size: 6px;
  }
  
  .ant-rate-star {
    margin: 0;
  }
  
  ul {
    margin: 0;
    padding: 0;
  }
  svg {
    width: 15px;
    height: 15px;
  }
 
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.25em;
`

const HeartButton = styled.button`
  background: none;
  
  svg {
  fill: ${props => props.favourite ? '#FF8E95' : '#cccccc'};
    rect {
      fill: #ffffff;
      stroke: #EEEEEF;
    }
  }
`

export const LowerContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const IconButton = styled(Button)`
    background: ${props => props.primary ? '#4F4DB8' : '#ffffff'};
    border-color: #4F4DB8;
    height: 44px;
    border-radius: 4px;
    color: ${props => props.primary ? '#ffffff' : '#2E2C9A'};
    text-transform: uppercase;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 80%;
    display: flex;
    align-items: center;
  
  &:hover {
    background: ${props => props.primary ? '#2E2C9A' : '#ffffff'};
    border-color: #2E2C9A;
    color: ${props => props.primary ? '#ffffff' : '#2E2C9A'};
  }
  
  &:focus {
    background: ${props => props.primary ? '#2E2C9A' : '#ffffff'};
    border-color: #2E2C9A;
    color: ${props => props.primary ? '#ffffff' : '#2E2C9A'};
  }

`

export const BottomContainer = styled.div`
  display: ${props => props.display ? 'flex' : 'none'};
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 76px;
  padding: 0 1.5em;
  
    background: #FFFFFF;
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
`

export const Label = styled.label`
  display: flex;
  align-items: center;
  .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #4F4DB8;
      border-color: #4F4DB8;
    }
  h4 {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    color: #4F4DB8;
    margin: 0 0.5em;
    padding: 0;
    
    span {
       color: #2E2C9A;
    }
  }
`

const CheckItem = ({ name, checked, count }) => {
	return (
		<Label htmlFor={name}>
			<Checkbox
				name={name}
				size="large"
				checked={checked}
			/>
			<h4><span>{count} / 3</span> ADDED TO POLL</h4>
		</Label>
	);
}

function PollItem({ option, index, addFavourite, addToPoll, removeFromPoll, limit, isSelected }) {
	return (
		<TodoContainer>
			<a href={option.url} target='_blank' rel="noopener noreferrer">
				<Wrapper src={option.image} />
				<Text>
					<h4>
						{option.name}
					</h4>
					<p>
						{option.note}
					</p>
					<Ratings><h5>{option.price}</h5></Ratings>
				</Text>
			</a>

			<ButtonContainer>
				{/* <HeartButton favourite={option.isCompleted} type='button' onClick={() => addFavourite(index)}>
                    <HeartIcon />
                </HeartButton> */}
				<InButton
					primary={isSelected}
					disabled={!limit && !isSelected}
					text={isSelected ? 'ADDED' : 'ADD TO POLL'}
					icon={isSelected ? <CheckOutlined /> : <PlusOutlined />}
					onClick={() => isSelected ? removeFromPoll(index) : addToPoll(index, option)}
				/>
			</ButtonContainer>
		</TodoContainer>
	);
}

function PollList({ options, openPollOption, customPollData, initForm }) {
	const [pollOptions, setPollOptions] = useState([]);
	const [limit, setPollLimit] = useState(true);
	const [count, setCount] = useState(0);
	const [selectedOptions, setSelectedOption] = React.useState([]);

	const countSelected = (newOptions) => newOptions.filter(newOption => newOption.isSelected)

	const addToPoll = (position, option) => {
		const newPolls = [...selectedOptions, option];
		setSelectedOption(newPolls);

		const newOptions = pollOptions.map((option, index) => {
			if (position === index) {
				return {
					...option,
					isSelected: true
				}
			}
			return {
				...option,
			}
		})

		const selectedOptionsCount = countSelected(newOptions)
		initForm(selectedOptionsCount)
		setPollOptions(newOptions);

		setCount(count + 1);
		if (selectedOptionsCount.length >= 3) {
			setCount(3);
			setPollLimit(false);
		}

	};

	const addFavourite = position => {
		const newPollOptions = [...pollOptions];
		// console.log('newPollOptions:: ', newPollOptions)
		// newTodos[position].isCompleted = true;
		// setPollOptions(newTodos);
	};

	const removeFromPoll = position => {
		const newTodos = selectedOptions;
		newTodos.splice(position, 1);

		setSelectedOption(newTodos);

		const newOptions = pollOptions.map((option, index) => {
			if (position === index) {
				return {
					...option,
					isSelected: false
				}
			}
			return {
				...option,
			}
		})

		setPollOptions(newOptions);
		const selectedOptionsCount = countSelected(newOptions)
		initForm(selectedOptionsCount)
		setCount(count - 1);
		if (newTodos.length < 3) {
			setPollLimit(true);
		}
	};

	React.useEffect(() => {
		if ((!pollOptions.length && options.length) || pollOptions.length !== options.length) {
			setPollOptions(options);
		}

	}, [options, pollOptions]);

	return (
		<Row gutter={16} style={{ margin: '1.5em' }}>
			<Col xs={24} md={6}>
				<AddButton type='button' onClick={() => openPollOption(customPollData)}>
					<AddCircle />
					<h4>
						Add New Option
					</h4>
				</AddButton>
			</Col>
			{pollOptions.map((option, index) => {
				const isSelected = selectedOptions.some(item => item.name === option.name)
				console.log('isSelected:: ', isSelected, option.name)
				return (
					<Col xs={24} md={6}>
						<PollItem
							key={index}
							index={index}
							option={option}
							addFavourite={addFavourite}
							addToPoll={addToPoll}
							removeFromPoll={removeFromPoll}
							limit={limit}
							isSelected={isSelected}
						/>
					</Col>
				)
			})}
			<Col xs={24}>
				<LowerContainer display={!!count}>
					<CheckItem name='polls' checked={!!count & count === 3} count={count} />
					<IconButton
						primary
						htmlType="submit"
					>
						OPEN VOTING
					</IconButton>
				</LowerContainer>
			</Col>
		</Row>
	);
}

export default connect()(PollList);