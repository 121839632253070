import moment from "moment";
// import firebase from 'firebase/app'
import { db } from "../../config/firebase";
import sendToList from "./sendToList";

const createItinerary = (eventId, eventName) => {
  const timestamp = moment().unix();
  const eventRef = db.collection("events").doc(eventId);
  const itineraryRef = eventRef.collection(`itinerary`).doc(`${eventId}-${timestamp}`);
  const countdown = moment().add(7, "d").format("YYYY-MM-DD");

  itineraryRef
    .set({
      id: itineraryRef.id,
      start: false,
      eventId,
      eventName,
      city: {
        countdown,
        name: null,
        imageUrl: null,
      },
      dates: {
        countdown,
        start: null,
        end: null,
      },
      timestamp,
    })
    .then(async (result) => {
      return true;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};

const startItinerary = async (eventId) => {
  const eventRef = db.collection("events").doc(eventId);
  await eventRef
    .update({
      "itinerary.start": true,
    })
    .then(async () => {
      return true;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};

async function resetPolls(eventId, name, request = fetch) {
  if (!name) return;

  const requestObject = {
    data: {
      eventId,
    },
  };

  const postBody = JSON.stringify(requestObject);

  let url;
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    url = `${process.env.REACT_APP_RESET_POLLS_DEV}`;
  } else {
    url = `${process.env.REACT_APP__RESET_POLLS_PROD}`;
  }

  try {
    const response = await request(url, { method: "POST", body: postBody });
    if (response.status === 200) {
      const body = await response;
      return body.body;
    }
  } catch (error) {
    console.log("errors: ", error);
  }
}

const addRecommendations = async (eventId) => {
  const eventRef = db.collection("events").doc(eventId);

  const batch = db.batch();

  const recommendationsRef = eventRef.collection(`recommendations`);

  const timestamp = moment().unix();
  const accommodationRec = recommendationsRef.doc("accommodation");
  const accommodationRecInit = {
    timestamp,
    key: 1,
    id: accommodationRec.id,
    name: "accommodation",
    title: "Choose options on where to stay",
    recommendations: [],
  };

  batch.set(accommodationRec, accommodationRecInit);

  const diningRec = recommendationsRef.doc("dining");

  const diningRecInit = {
    timestamp,
    key: 2,
    id: diningRec.id,
    name: "dining",
    title: "Choose options on where to eat",
    recommendations: [],
  };

  batch.set(diningRec, diningRecInit);

  const activitiesRec = recommendationsRef.doc("activities");

  const activitiesRecInit = {
    timestamp,
    key: 3,
    id: activitiesRec.id,
    name: "activities",
    title: "Choose options on what to do",
    recommendations: [],
  };

  batch.set(activitiesRec, activitiesRecInit);

  const customRec = recommendationsRef.doc("custom");

  const customRecInit = {
    timestamp,
    key: 4,
    id: customRec.id,
    name: "custom",
    title: "Ask the guests anything else you want to know",
    recommendations: [],
  };

  batch.set(customRec, customRecInit);
  // Commit the batch
  return await batch
    .commit()
    .then(async (res) => {
      return true;
    })
    .catch((err) => console.log("err: ", err));
};
// const finalizeDetails = itineraryData && itineraryData.city.name
const addDestination = async (event, city, finalizeDetails, guests, setCity) => {
  const countdown = moment().add(7, "d").format("YYYY-MM-DD");
  const eventRef = db.collection("events").doc(event.id);

  const batch = db.batch();

  const itineraryDestination = {
    "itinerary.countdown": countdown,
    "itinerary.city.name": city.name,
    "itinerary.details": null,
    "itinerary.city.imageUrl": city.imageUrl,
    "timeline.finalize": !city.name,
    setCity,
  };

  console.log(itineraryDestination);

  batch.update(eventRef, itineraryDestination);

  await addRecommendations(event.id);

  // Commit the batch
  await batch
    .commit()
    .then(async (res) => {
      if (finalizeDetails) {
        await sendToList.guests(event, guests);
      }
      return await resetPolls(event.id, city.name);
    })
    .catch((err) => console.log("err: ", err));
};

const addDates = async (event, dates, finalizeDetails, guests, setDates) => {
  const countdown = moment().add(7, "d").format("YYYY-MM-DD");
  const eventRef = db.collection("events").doc(event.id);

  const batch = db.batch();

  const itineraryDates = {
    "itinerary.countdown": countdown,
    "itinerary.dates.start": dates.start,
    "itinerary.dates.end": dates.end,
    setDates: setDates,
  };

  batch.update(eventRef, itineraryDates);

  // Commit the batch
  return batch
    .commit()
    .then(async (res) => {
      if (finalizeDetails) {
        console.log("sendToGuestList:: ", finalizeDetails);
        await sendToList.guests(event, guests);
      }
      return res;
    })
    .catch((err) => console.log("err: ", err));
};

function getItineraryData(eventId, setItineraryData) {
  const eventRef = db.collection("events").doc(eventId);
  const sfDocRef = eventRef.collection(`itinerary`).where("eventId", "==", eventId).orderBy("timestamp", "desc");

  sfDocRef.onSnapshot((querySnapshot) => {
    let itineraryData = [];
    querySnapshot.forEach((doc) => {
      itineraryData.push(doc.data());
    });
    setItineraryData(itineraryData[0]);
  });
}

const itinerary = {
  createItinerary: createItinerary,
  startItinerary: startItinerary,
  getItineraryData: getItineraryData,
  addDestination: addDestination,
  addDates: addDates,
};

export default itinerary;
