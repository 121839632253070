import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Rate } from "antd";
import { HeartSvg } from '../../../../assets/icons/heart'

const Wrapper = styled.div`
  width: 100%;
  min-width: 332px;
  background: #FAFAFC;
  height: 100%;
	${(props) => props.theme.breakpoints.maxTablet} {
		background: #fff;
	}
`

const Title = styled.div`
font-family: 'Clearface Bold';
font-style: normal;
font-weight: bold;
font-size: 26px;
line-height: 34px;
color: #101B43;
margin-bottom: 24px;
${(props) => props.theme.breakpoints.maxTablet} {
	display: none;
}
`

const Item = styled.button` 
  min-height: 95px;
  border-top: 1px solid #EDEDED;
  border-bottom: 1px solid #EDEDED;
  padding: 44px 1.25em 1.25em 1.25em;
  background: none;
	width: 100%;
  
`

const Image = styled.div`
  width: 95px;
  height: 95px;
    background: transparent url(${props => props.src}) no-repeat center;
    background-size: cover;
    border-radius: 8px;
		/* cursor: pointer; */
  
`

const Ratings = styled.div`
	display: flex;
	justify-content: flex-end;
  
  .ant-rate {
    font-size: 6px;
  }
  
  .ant-rate-star {
    margin: 0;
  }
  
  ul {
    margin: 0;
    padding: 0;
  }
  svg {
    width: 15px;
    height: 15px;
  }
 
`

const Container = styled.div`
    display: flex;
    align-items: center;
  
`;

export const Price = styled.span`
	color: #4F4DB8;
	font-weight: bold;
	margin-right: 0.5em;
	
`;

export const Note = styled.span`
	font-size: 16px;
	color: #101B43;
`

export const Body = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	text-align: left;
	h5 {
    font-family: 'Proxima Nova Semibold';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 100%;
    color: #101B43;
    padding: 0;
    text-transform: capitalize;
		text-align: left;
		margin-bottom: 0;
  }
	margin-left: 1em;

`;

export const SvgContainer = styled.div`
  margin-top: 2em;
	display: flex;
	align-items: center;
  svg {
    font-size: 1em;
    margin: 0;
    padding: 0;
    width: 24px;
  }
	span {
		font-weight: 600;
		text-transform: uppercase;
		color: #000;
		margin-left: 5px;
		font-size: 16px;
	}
`;


const AccommodationMenu = ({ toggleMenu, accommodation, addDestination }) => {
	// const poll = accommodation
	const selectPlace = (destination) => {
		addDestination(destination.name)
	}
	const [finalized, setFinalize] = useState([]);

	useEffect(() => {
		if (!accommodation && !accommodation.length) return;
		const options = accommodation.filter(poll => poll.finalizedPoll && poll.finalizedPoll.length).map(poll => poll.finalizedPoll[0])
		setFinalize(options)

	}, [accommodation])


	return (
		<Wrapper>
			<Title>Accommodation</Title>
			{
				finalized.length ?
					finalized.map((item, index) => (
						<Item key={index} type='button' onClick={() => selectPlace(item)}>
							<Container>
								<Image src={item.image} onClick={() => item.url ? window.open(item.url.startsWith("http://") || item.url.startsWith("https://") ? item.url : `https://${item.url}`, "_blank") : {}} />
								<Body>
									<h5>{item.name}</h5>
									<div>
										<Price>{item.price}</Price>
										<Note>{item.note}</Note>
									</div>
									<Ratings>
										{/* <Rate disabled allowHalf defaultValue={3} /> */}
										<div></div>
										<SvgContainer>
											<HeartSvg />
											<span>
												{item && item.votes ? item.votes.length : 0} votes
											</span>
										</SvgContainer>
									</Ratings>

								</Body>


							</Container>
						</Item>
					)) : <span>no closed polls by accommodation</span>
			}
		</Wrapper>
	);
};

AccommodationMenu.defaultProps = {

};

AccommodationMenu.propTypes = {

};

export default AccommodationMenu;
