import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
	ItineraryPlan,
	AccommodationMenu,
	EventPlaceMenu,
	YourPlan,
} from "./Menus";
import { Skeleton } from "antd";
import { CustomModal, CustomModalLayout } from '../.';
import { useWindowSize } from '../../hooks'
const Wrapper = styled.div`
  max-height: 85vh;
  height: 100%;
  max-width: 372px;
  background: ${(props) =>
		props.activeMenu
			? "#4F4DB8"
			: props.accommodationMenu
				? "#FAFAFC"
				: "#2E2C9A"};
  border-radius: 8px;
  padding: 32px 20px;
  border: 1px solid rgba(0, 0, 0, 0.09);
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  ${(props) => props.theme.breakpoints.maxTablet} {
    width: 100%;
    max-height: 100%;
    max-width: 100%;
  }
`;

function renderMenu(
	{ activeMenu,
		toggleMenu,
		itineraryData,
		brides,
		accommodation,
		polls,
		addEvent,
		addPlace,
		addDestination,
		width,
		handleClose,
		visible }
) {
	switch (activeMenu.name) {
		case "itinerary-plan":
			return <ItineraryPlan itineraryData={itineraryData} brides={brides} />;
		case "accommodation":
			if (width < 768) {
				return <CustomModal visible={visible} onClose={handleClose}>
					<CustomModalLayout
						handleCancel={handleClose}
						title='Accommodation'
						id='accommodation'
						spaceBetween
					>
						<AccommodationMenu
							accommodation={accommodation}
							addDestination={addDestination}
							toggleMenu={toggleMenu}
							activeMenu={activeMenu}
							itineraryData={itineraryData}
							brides={brides}
						/>
					</CustomModalLayout>
				</CustomModal >
			} else {
				return <AccommodationMenu
					accommodation={accommodation}
					addDestination={addDestination}
					toggleMenu={toggleMenu}
					activeMenu={activeMenu}
					itineraryData={itineraryData}
					brides={brides}
				/>
			}

			;
		case "event":
			if (width < 768) {
				return <CustomModal visible={visible} onClose={handleClose}>
					<CustomModalLayout
						handleCancel={handleClose}
						title='Event'
						id='event'
						spaceBetween
					>
						<EventPlaceMenu
							activeMenu={activeMenu}
							itineraryData={itineraryData}
							brides={brides}
							polls={polls}
							addEvent={addEvent}
							addPlace={addPlace}
						/>
					</CustomModalLayout>
				</CustomModal>
			} else {
				return (
					<EventPlaceMenu
						activeMenu={activeMenu}
						itineraryData={itineraryData}
						brides={brides}
						polls={polls}
						addEvent={addEvent}
						addPlace={addPlace}
					/>
				)
			};
		default:
			return (
				<Wrapper>
					<Skeleton active />
				</Wrapper>
			);
	}
}

function isObject(objValue) {
	return (
		objValue && typeof objValue === "object" && objValue.constructor === Object
	);
}

const ScheduleSidebar = ({
	itineraryData,
	brides,
	toggleMenu,
	activeMenu,
	polls,
	initForm,
	registeredValues,
	completeSchedule,
}) => {
	const [eventType, setEvent] = useState(null);
	const [visible, setVisibility] = useState(false);

	useEffect(() => {
		if (!activeMenu?.name) return;

		setVisibility(true);

	}, [activeMenu.name])

	const { width } = useWindowSize();
	const handleClose = () => {
		setVisibility(false);
		toggleMenu("itinerary-plan", null);
	}
	if (!itineraryData)
		return (
			<Wrapper>
				<Skeleton active />
			</Wrapper>
		);

	const accommodation = polls.filter(
		(poll) => poll.recommendationName === "accommodation"
	);

	const addEvent = (eventName) => {
		setEvent(eventName);
	};

	const expand = (items, obj, value) => {
		const output = {}; // prepare an empty object, to fill later
		let ref = output; // keep a reference of the new object

		const splitItem = items[1].split("[0]");
		const newItem = splitItem[0];
		items[1] = newItem;

		const mainKey = items[1].split("[")[0];
		const start = items[1].indexOf('[');
		const end = items[1].indexOf(']');
		const index = items[1].substring(start + 1, end);

		//  loop through all nodes, except the last one
		for (let i = 0; i < items.length - 1; i++) {
			ref[items[i]] = {}; // create a new element inside the reference
			ref = ref[items[i]];
		}
		ref[items[items.length - 1]] = value; // apply the final value
		let key1, key2;
		Object.entries(output).forEach(([key, value]) => {
			key1 = key;
			Object.entries(value).forEach(([subkey, subvalue]) => {
				key2 = subkey;
			});
		});

		output[key1][key2] =
			obj.type === "accommodation"
				? obj.accommodation
				: [{ event: obj.event, place: obj.place, image: obj.image }];

		const previousValues = registeredValues ? registeredValues[key1] : {};
		const hasPreviousValues =
			previousValues &&
			Object.keys(previousValues).length === 0 &&
			obj.constructor === Object;
		const hasTimer =
			registeredValues &&
				registeredValues[key1] &&
				registeredValues[key1][key2] &&
				!hasPreviousValues && obj.type !== "accommodation"
				? registeredValues[key1][key2]
				: [];

		if (hasTimer.length) {
			output[key1][key2][0] = {
				...hasTimer[0],
				...output[key1][key2][0],
			};
		}
		if (index > 0) {
			const oldValue = previousValues[mainKey][index];
			let newValue;
			const hasTime = Object.keys(oldValue).includes('time');
			if (Object.keys(oldValue).length && hasTime) {
				newValue = {
					time: oldValue.time,
					event: obj.event, place: obj.place, image: obj.image
				}
			} else {
				newValue = { event: obj.event, place: obj.place, image: obj.image }
			}
			let arr = [...previousValues[mainKey]];
			arr.splice(index, 1, newValue);
			const newPreviusValue = { ...previousValues, [mainKey]: arr };
			output[key1] = { ...newPreviusValue, ...output[key1] };
		} else {
			output[key1] = { ...previousValues, ...output[key1] };
		}


		return output; // return the full object
	};

	const addPlace = ({ placeName, placeImage }) => {
		const activeMenuField = activeMenu.field.split("."); // split on dot notation
		activeMenuField.pop();
		const stateRef = expand(activeMenuField, {
			type: "place",
			event: eventType,
			place: placeName,
			image: placeImage
		});
		initForm(stateRef);
		toggleMenu("itinerary-plan", null);
	};

	const addDestination = (accommodation) => {
		const activeMenuField = activeMenu.field.split(".");
		const stateRef = expand(activeMenuField, {
			type: "accommodation",
			accommodation: accommodation,
		});
		initForm(stateRef);
		toggleMenu("itinerary-plan", null);
	};
	const whiteMenu = ["accommodation", "event"];
	return (
		<Wrapper
			activeMenu={activeMenu.name === "itinerary-plan" && !completeSchedule}
			accommodationMenu={
				whiteMenu.includes(activeMenu.name) && !completeSchedule
			}
		>
			{!completeSchedule ? (
				renderMenu(
					{
						activeMenu,
						toggleMenu,
						itineraryData,
						brides,
						accommodation,
						polls,
						addEvent,
						addPlace,
						addDestination,
						width,
						visible,
						handleClose
					}
				)
			) : (
				<YourPlan itineraryData={itineraryData} brides={brides} />
			)}
		</Wrapper>
	);
};

ScheduleSidebar.defaultProps = {};

ScheduleSidebar.propTypes = {};

export default ScheduleSidebar;
