import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
	Field,
	FieldArray,
	reduxForm,
	formValueSelector,
	initialize,
} from "redux-form";
import styled from "styled-components";
import { Button, Radio, Form, Row, Col } from "antd";
import { InInput, InReactSelect, InCheckbox } from "./FormComponents";
import PollList from "./PollList/PollList";

const FormItem = Form.Item;

const FormWrapper = styled.form`
  width: 100%;
  /* max-width: 670px; */
  padding: 0;
  margin: 0 auto;
  padding: 0 15px;
  .ant-form-item-label {
    text-align: left;
  }

  .ant-form-item-label label::after {
    content: "";
  }

  .ant-form-item {
    margin-bottom: 1em;
  }
`;

export const ButtonContainer = styled.div`
  margin: 1.5em 0 5em 0;
  display: flex;
  justify-content: flex-end;
`;

const IconButton = styled(Button)`
  background: ${(props) => (props.primary ? "#4F4DB8" : "#ffffff")};
  border-color: #4f4db8;
  height: 44px;
  border-radius: 4px;
  color: ${(props) => (props.primary ? "#ffffff" : "#2E2C9A")};
  text-transform: uppercase;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 80%;
  display: flex;
  align-items: center;

  &:hover {
    background: ${(props) => (props.primary ? "#2E2C9A" : "#ffffff")};
    border-color: #2e2c9a;
    color: ${(props) => (props.primary ? "#ffffff" : "#2E2C9A")};
  }

  &:focus {
    background: ${(props) => (props.primary ? "#2E2C9A" : "#ffffff")};
    border-color: #2e2c9a;
    color: ${(props) => (props.primary ? "#ffffff" : "#2E2C9A")};
  }
`;

export const RadioItem = styled(FormItem)`
  .ant-radio-button {
    color: #2e2c9a;
    border-radius: 4px;
    border: 1px solid #eeeeef;
  }

  .ant-radio-button-checked {
    color: #2e2c9a;
    border: 1px solid #eeeeef;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #2e2c9a;
    border: 1px solid #2e2c9a;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #2e2c9a;
  }

  .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
    border-right-color: #2e2c9a;
  }

  label {
    margin-right: 1em;
    color: #2e2c9a;
    font-family: "Proxima Nova Semibold";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 100%;
  }

  &:hover {
    label {
      color: #2e2c9a;
    }
  }
`;

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 24 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 24 },
	},
};

const options = [
	{ label: "$", value: "$" },
	{ label: "$$", value: "$$" },
	{ label: "$$$", value: "$$$" },
	{ label: "$$$$", value: "$$$$" },
];

const DollarSign = ({ input, meta, children, hasFeedback, label, ...rest }) => {
	const [rating, setRating] = useState(0);

	const handleRating = (value) => {
		setRating(value);
	};

	const handleChange = (e, input) => {
		return input.onChange(e);
	};
	const hasError = meta.touched && meta.invalid;
	return (
		<RadioItem
			{...formItemLayout}
			label={label}
			validateStatus={hasError ? "error" : "success"}
			hasFeedback={hasFeedback && hasError}
		// help={hasError && meta.error}
		>
			<Radio.Group
				options={options}
				onChange={(e) => handleChange(e, input)}
				value={rating}
				optionType="button"
				{...input}
				{...rest}
				children={children}
				size="large"
			/>
		</RadioItem>
	);
};

const CreateCustomPoll = ({
	handleSubmit,
	pristine,
	submitting,
	recommendationName,
	eventId,
	handleCustomData,
	guests,
	options = [],
}) => {
	const filterGuests = guests.filter((guest) => guest && !guest.isBride).filter(guest => guest && guest.state !== "declined");
	const [loading, setLoader] = useState(false);
	const allGuests = guests.filter(guest => guest && guest.state !== "declined").map((guest) => {
		return {
			id: guest.id,
			label: guest.name,
			value: guest.id,
			// isBride: guest && guest.isBride,
			// details: {
			// 	id: guest.id,
			// 	name: guest.name,
			// 	email: guest.email,
			// 	mobileNumber: guest.mobileNumber,
			// },
		};
	});

	const sortGuests = filterGuests.map((guest) => {
		return {
			id: guest.id,
			label: guest.name,
			value: guest.id,
			// isBride: guest && guest.isBride,
			// details: {
			// 	id: guest.id,
			// 	name: guest.name,
			// 	email: guest.email,
			// 	mobileNumber: guest.mobileNumber,
			// },
		};
	});

	const Guests = guests.filter(guest => guest && guest.state !== "declined").map((guest) => {
		return {
			id: guest.id,
			label: guest.name,
			value: guest.id,
			// isBride: guest && guest.isBride,
			// details: {
			// 	id: guest.id,
			// 	name: guest.name,
			// 	email: guest.email,
			// 	mobileNumber: guest.mobileNumber,
			// },
		};
	});

	const guestsList = [
		{
			label: "Everyone",
			value: "Everyone",
			// isBride: null,
			everyone: Guests,
		},
		{
			label: "Everyone but the Bride",
			value: "Everyone but the Bride",
			// isBride: null,
			everyone: sortGuests,
		},
		...allGuests]

	// sortGuests.unshift({
	//   label: "Everyone",
	//   value: "Everyone",
	//   isBride: null,
	//   everyone: sortGuests,
	// });

	const [optionIndex, setOptionIndex] = useState(null);
	const [isEdit, setIsEdit] = useState(false);

	const openOption = (index, type) => {
		setOptionIndex(index);
		if (type === "edit") {
			setIsEdit(true);
		}
	};

	const closeOption = () => {
		setOptionIndex(null);
		setIsEdit(false);
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	};

	return (
		<FormWrapper
			onSubmit={handleSubmit((values) => {
				setLoader(true);
				handleCustomData(eventId, values, recommendationName)
			}
			)}
		>
			<FormItem>
				<Field
					label="Poll Question"
					name="question"
					component={InInput}
					placeholder="Where should we stay?"
				/>
			</FormItem>
			<Row>
				<Col xs={24} lg={12}>
					<Field
						label="Allow guests to select multiple answers"
						name="multipleAnswers"
						component={InCheckbox}
						additionText="* All answers are anonymous"
					/>
				</Col>
				<Col xs={24} lg={12}>
					<FormItem>
						<Field
							label="Invited Guests"
							name="invitedGuests"
							options={guestsList}
							type="select"
							component={InReactSelect}
							placeholder="Select Guests"
						/>
					</FormItem>
				</Col>
			</Row>

			<FieldArray
				name="options"
				component={PollList}
				optionIndex={optionIndex}
				openOption={openOption}
				closeOption={closeOption}
				isEdit={isEdit}
			/>
			<ButtonContainer>
				<IconButton
					primary
					disabled={submitting || options?.length < 1}
					htmlType="submit"
					loading={loading}
				>
					OPEN VOTING
				</IconButton>
			</ButtonContainer>
		</FormWrapper>
	);
};

CreateCustomPoll.defaultProps = {};

CreateCustomPoll.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
};

const validate = (values) => {
	const errors = {};

	if (!values.question) {
		errors.question = "Required";
	}
	if (!values.invitedGuests) {
		errors.invitedGuests = "Required";
	}

	return errors;
};

const CustomPollForm = reduxForm({
	form: `customPoll`,
	validate,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
})(CreateCustomPoll);

const selector = formValueSelector("customPoll");

export function mapStateToProps(state) {
	return {
		options: selector(state, "options"),
		formState: state.form.customPoll,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		formInit: (data) => {
			dispatch(initialize("customPoll", data));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomPollForm);
