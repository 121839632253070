import React from "react";
import styled from "styled-components";
import { Modal, Space } from "antd";
import { InTypography } from "../index";
import CancelBtn from "../../assets/icons/cancelBtn";
import DeleteIcon from "../../assets/icons/bin";
import { guests } from "../../helpers/firestore";

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 2em;
`;

const CircleButton = styled.button`
  background: #fafafc;
  border-radius: 50%;
  width: 98px;
  height: 98px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
`;

export const Text = styled.div`
  margin: 32px 0 8px;
`;

export const Label = styled.label`
  color: #101b43;
  font-family: "Proxima Nova Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
`;

export const Buttons = styled.div`
  display: flex;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 210px;
  height: 44px;
  background: ${(props) => props.bg};
  border-radius: 4px;
  font-family: "Proxima Nova Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 80%;

  color: ${(props) => props.color};
`;

export const TextWrapper = styled.div`
  margin: 32px 0 32px;
  padding: 12px;
  border: 1px solid #eeeeef;
  border-radius: 4px;
  span:first-child {
    color: #2e2c9a;
    line-height: 21px;
    margin-right: 10px;
  }
  span:last-child {
    color: #8696ae;
  }
`;
const DeleteGuestPopup = ({ visible, onClose, user, eventId }) => {
  const handleSubmit = () => {
    guests.delete(eventId, user.id).then((response) => (response ? onClose() : null));
  };
  return (
    <Modal
      title=""
      visible={visible}
      onCancel={() => onClose()}
      closeIcon={<CancelBtn style={{ margin: 12 }} />}
      destroyOnClose={true}
      footer={null}
    >
      <Content>
        <CircleButton type="button" onClick={() => onClose()}>
          <DeleteIcon />
        </CircleButton>
        <Text>
          <InTypography.InH2>Woah!</InTypography.InH2>
        </Text>
        <InTypography.InParagraph>Are you sure you want to delete?</InTypography.InParagraph>
        <TextWrapper>
          <span>{user?.name}</span>
          <span>{user?.emailMobile?.[0]}</span>
        </TextWrapper>
        <Buttons>
          <Space size="large">
            <Button type="button" bg="#EDEDED" color="#8696AE" onClick={() => onClose()}>
              NO
            </Button>
            <Button onClick={() => handleSubmit()} bg="#EE4238" color="#FFFFFF">
              YES
            </Button>
          </Space>
        </Buttons>
      </Content>
    </Modal>
  );
};

DeleteGuestPopup.defaultProps = {};

DeleteGuestPopup.propTypes = {};

export default DeleteGuestPopup;
