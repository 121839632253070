import React from "react";
import styled from "styled-components";
import { Row, Col } from "antd";
import { EventConsole } from "../../../components";
import { ActivityTabs } from "./Activities";

const Container = styled(Row)`
  background: #ffffff;
  padding-right: 1em;
  overflow: hidden;

  ${(props) => props.theme.breakpoints.maxPhone} {
    padding: 1em;
  }
`;

const Main = styled(Col)`
  background: #ffffff;
  transition: all 0.2s linear;
  max-height: 90vh;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }

  ${(props) => props.theme.breakpoints.maxPhone} {
    max-height: 100%;
  }
`;

const SiderActivityContainer = styled(Col)`
  margin-top: 25px;
  background: #ffffff;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 0 0;
  height: 100%;

  ${(props) => props.theme.breakpoints.tablet} {
    //position: absolute;
    //margin-right: 1em;
  }
`;

export default class DashboardLayout extends React.Component {
  state = {
    collapsed: false,
    collapsedLeft: false,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    return (
      <Container gutter={24}>
        <Main xs={{ span: 24, order: 2 }} md={{ span: 16, order: 1 }}>
          <EventConsole />
        </Main>
        <SiderActivityContainer xs={0} md={{ span: 8, order: 2 }}>
          <ActivityTabs />
        </SiderActivityContainer>
      </Container>
    );
  }
}
