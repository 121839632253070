import React from 'react';
import styled from "styled-components";
import TimeAgo from 'javascript-time-ago';
import { EventGuestViewContext } from '../../EventGuestViewProvider';
import { timestampToDate, formatDates } from '../../../../helpers/normalizeDate';

// Load locale-specific relative date/time formatting rules.
import en from 'javascript-time-ago/locale/en'

// Add locale-specific relative date/time formatting rules.
TimeAgo.addLocale(en)

// Create relative date/time formatter.
const timeAgo = new TimeAgo('en-US')

const Wrapper = styled.ul`
list-style: none;
padding: 0;
margin: 1em;

li {
    margin: 1em 0;
}
`

const Paragraph = styled.p`
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: ${props => props.done ? '#101B43' : '#8696AE'};
    margin: 0;
    padding: 0;
`

const TimeParagraph = styled.p`
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: ${props => props.done ? '#101B43' : '#8696AE'};
    margin: 0;
    padding: 0;
`

const TimelineWrapper = styled.div`
        position: absolute;
        width: 100%;
        overflow-y: auto;
`
function diff_minutes(dt2, dt1) 
 {

  var diff =(dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
  
 }

export const ActivityTimeline = () => (
    <EventGuestViewContext.Consumer>
    {({ activities }) => {
        if(!activities.length) return null;
        return (
            <TimelineWrapper>
                <Wrapper>
                    {activities.map((activity) => {
                        const dt1 = new Date();
                        const dt2 = new Date(2014,10,3);
                        console.log(diff_minutes(dt1, dt2));
                        return (
                            <li>
                                <Paragraph>
                                    {activity.activity}
                                </Paragraph>
                                <TimeParagraph>
                                    {timeAgo.format(Date.now() - 60 * 1000)}
                                </TimeParagraph>
                            </li>
                        )
                    })}
                </Wrapper>
            </TimelineWrapper>
        )
    }}
</EventGuestViewContext.Consumer>

);