import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Row, Col, Modal } from "antd";
import { HeartSvg } from "../../../assets/icons/heart";
import { InButton } from "../../../components";
import { formatEnd, formatStart, timestampToDate } from "../../../helpers/normalizeDate";
import moment from "moment";

export const Header = styled(Col)`
  height: 60px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    font-family: "Clearface Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 34px;
    color: #101b43;
    padding: 0 1em;
    margin: 0;
  }

  button {
    margin-right: 1.25em;
  }
`;

export const CardContent = styled(Row)`
  padding: 1.25em;
`;

export const CityCard = styled.div`
  height: 254px;
  //background: #FAFAFC;
  background: #fafafc;
  border-radius: 8px;
`;

export const Content = styled.div`
  h5 {
    padding: 20px;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: #101b43;
  }

  p {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    padding: 0 20px;

    color: #101b43;
  }

  span {
    margin: 0 16px;

    svg {
      font-size: 1.5em;
      margin: 0;
      padding: 0;
    }
  }
`;

export const Container = styled.div``;

export const HeaderImage = styled.div`
  width: 100%;
  height: 150px;
  background: transparent url(${(props) => props.src}) no-repeat center;
  background-size: cover;
  border-radius: 8px 8px 0 0;
`;

export const Title = styled.div`
  width: 100%;

  h1 {
    font-family: "Clearface Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 140%;
    text-align: center;
    color: #101b43;
  }
`;

function formatDates(date) {
  const start = date.start.seconds;
  const end = date.end.seconds;

  const startTimestamp = timestampToDate(start);
  const startDateString = moment(startTimestamp);

  const endTimestamp = timestampToDate(end);
  const endDateString = moment(endTimestamp);
  const days = endDateString.diff(startDateString, "days");

  return days > 1 ? `${days} days` : `${days} day`;
}

const DatesModal = ({ event, visible, toggleModal, finalize }) => {
  return (
    <div>
      <Modal
        title={
          <Title>
            <h1>Choose the dates</h1>
          </Title>
        }
        visible={visible}
        onOk={() => toggleModal()}
        onCancel={() => toggleModal()}
        width="90%"
        footer={null}
      >
        <Row gutter={16}>
          {event.dates.map((date, index) => {
            return (
              <Col sm={8} key={index}>
                <CityCard>
                  <Content>
                    <h5>{`${formatStart(date.start.seconds)} - ${formatEnd(date.end.seconds)}`}</h5>
                    <p>{formatDates(date)}</p>
                    <span>
                      <HeartSvg />
                    </span>
                    <Container>
                      <InButton width="127px" type="button" onClick={() => finalize(date)} text="FINALIZE" primary />
                    </Container>
                  </Content>
                </CityCard>
              </Col>
            );
          })}
        </Row>
      </Modal>
    </div>
  );
};

DatesModal.defaultProps = {};

DatesModal.propTypes = {};

export default DatesModal;
