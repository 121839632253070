import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Field, initialize, reduxForm} from 'redux-form'
import styled from "styled-components";
import {Button, Checkbox, Form, List, Row, Col} from "antd";
import { FormComponents  } from "../../";
import {connect} from "react-redux";
import { chat } from "../../../helpers/firestore";


const FormItem = Form.Item;

const FormWrapper = styled.form`
  .ant-form-item-label {
    text-align: left;
  }
  
  .ant-form-item-label label::after {
    content: '';
}
`

export const NameContainer = styled.div`
  display: flex;
  width: 100%;
  background: #ffffff;
    padding: 1.5em;
    
    .ant-form-item {
      margin: 0;
    }
  .ant-row {
    width: 100%;
    max-width: 31.875em;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
`

const IconButton = styled(Button)`
    background: ${props => props.primary ? '#4F4DB8' : '#ffffff'};
    border-color: #4F4DB8;
    width: 100%;
    height: 44px;
    border-radius: 4px;
    color: ${props => props.primary ? '#ffffff' : '#2E2C9A'};
    text-transform: uppercase;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 80%;
  
  &:hover {
    background: ${props => props.primary ? '#2E2C9A' : '#ffffff'};
    border-color: #2E2C9A;
    color: ${props => props.primary ? '#ffffff' : '#2E2C9A'};
  }
  
  &:focus {
    background: ${props => props.primary ? '#2E2C9A' : '#ffffff'};
    border-color: #2E2C9A;
    color: ${props => props.primary ? '#ffffff' : '#2E2C9A'};
  }

`

export const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 76px;
  padding: 0 1.5em;
  
    background: #FFFFFF;
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
`

export const Label = styled.label`
  display: flex;
  align-items: center;
  .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #4F4DB8;
      border-color: #4F4DB8;
    }
  h4 {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    color: #4F4DB8;
    margin: 0 0.5em;
    padding: 0;
    
    span {
       color: #2E2C9A;
    }
  }
`
const CheckboxCard = ({ item, addToList }) => {
    const [checkValue, setCheck] = useState(null);
    const handleChange = (guest) => {
        setCheck(guest.title)
        addToList(item.index, guest)
    }
    return (
        <Checkbox
            name={item.name}
            size="large"
            onChange={(e) => handleChange(item)}
            checked={checkValue === item.title}
        />
    );
}

const CheckItem = ({ initForm, guests }) => {

    const [selectedOptions, setSelectedOption] = React.useState([]);

    const addToList = (position, option) => {
        const newPolls = [...selectedOptions, option];
        setSelectedOption(newPolls);
        initForm(newPolls)

    };

    // const removeFromPoll = position => {
    //     const newTodos = selectedOptions;
    //     newTodos.splice(position, 1);
    //     setSelectedOption(newTodos);
    // };

    const guestsData = guests.map((guest, index) => {
        return {
            index: index,
            title: guest.name,
            name: guest.name,
            id: guest.id,
        }
    })
    return (
        <List
            style={{ marginBottom: '16px' }}
            itemLayout="horizontal"
            dataSource={guestsData}
            renderItem={(item) => {
                return (
                    <List.Item key={item.index}>
                        <List.Item.Meta
                            avatar={<div />}
                            title={<p>{item.title}</p>}
                            // description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                        />
                        <CheckboxCard
                            addToList={addToList}
                            initForm={initForm}
                            item={item}
                        />

                    </List.Item>
                )
            }}
        />
    );
}

const CreateChatGroup = ({ handleSubmit, pristine, submitting, guests, formState, formInit, eventId, handleClose }) => {

    const initForm = (data) => {
        formInit({ ...formState.values, members: data  });
    };

    const submitData = (data) => {
        chat.addChatGroup(eventId, data, handleClose)
    };

  return (
      <FormWrapper onSubmit={handleSubmit(values => submitData(values))}>
          <NameContainer>
              <FormItem>
                  <Field label="Group Name" name="name" type='text' component={FormComponents.InInput} placeholder="Name" />
              </FormItem>
          </NameContainer>

          <CheckItem initForm={initForm} guests={guests}/>
          <Row gutter={16}>
              <Col xs={12}>
                  <IconButton

                      // disabled={pristine || submitting}
                      htmlType="button"
                      onClick={() => handleClose()}
                      width='100%'
                  >
                      CANCEL
                  </IconButton>
              </Col>
              <Col xs={12}>
                  <IconButton
                      // disabled={pristine || submitting}
                      htmlType="submit"
                      width='100%'
                      primary
                  >
                      CREATE
                  </IconButton>
              </Col>

          </Row>

      </FormWrapper>
  );
};

CreateChatGroup.defaultProps = {

};

CreateChatGroup.propTypes = {
    handleSubmit: PropTypes.func.isRequired
};

const validate = values => {
    const errors = {};
    if (!values.name) {
        errors.name = "Required";
    }

    return errors;
};

const CreateChatGroupForm = reduxForm({
    form: `createChat`,
    validate,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true
})(CreateChatGroup)

export function mapStateToProps (state) {
    return {
        formState: state.form.createChat,
    }
}

function mapDispatchToProps (dispatch) {
    return {
        formInit: (data) => {
            dispatch(initialize('createChat', data))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateChatGroupForm)