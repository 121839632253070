import React from "react";
import PropTypes from "prop-types";
import { Field, initialize, reduxForm } from "redux-form";
import { connect } from "react-redux";
import styled from "styled-components";
import { InButton } from "../..";
import { FormComponents } from "../..";

const FormWrapper = styled.form`
  max-width: 670px;
  width: 100%;
  margin: 27px 0 0 0;

  .ant-form-item-label {
    text-align: left;
  }

  .ant-form-item-label label::after {
    content: "";
  }
`;

export const ForgotPassword = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  a {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 100%;
    /* identical to box height, or 13px */

    display: flex;
    align-items: center;
    text-align: center;
    color: #8696ae;
  }
`;
export const SocialMedia = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;

  span {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 13px */

    display: flex;
    align-items: center;
    text-align: center;
    color: #8696ae;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${(props) => props.theme.breakpoints.maxPhone} {
    flex-direction: column;
  }
`;

const SocialPassword = ({ handleSubmit, handleSubmitPassword }) => {
  return (
    <FormWrapper onSubmit={handleSubmit((values) => handleSubmitPassword(values.password))}>
      <h3>To link your Invigo account and Facebook, enter your Invigo password</h3>
      <Field
        label="Please set your password"
        addonAfter="Minimum 8 characters"
        name="password"
        type="password"
        component={FormComponents.InInputPassword}
        placeholder="Password"
      />

      <InButton
        type="primary"
        // disabled={pristine || submitting}
        htmlType="submit"
        text="CONNECT AND LOGIN"
        primary
        width="100%"
        disabled={false}
      />
    </FormWrapper>
  );
};

SocialPassword.defaultProps = {};

SocialPassword.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const validate = (values) => {
  const errors = {};

  if (values && values.password && values.password.length < 8) {
    errors.password = "Your password is too short";
  }

  return errors;
};

const SocialPasswordForm = reduxForm({
  form: "socialPassword",
  validate,
})(SocialPassword);

function mapDispatchToProps(dispatch) {
  return {
    formInit: (data) => {
      dispatch(initialize("socialPassword", data));
    },
  };
}

export default connect(null, mapDispatchToProps)(SocialPasswordForm);
