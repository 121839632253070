import React from 'react'
import styled from 'styled-components'
import Evening from "../../../assets/itinerary/Evening.png";
import { Timeline, Row, Col, Image } from 'antd';
import moment from "moment";

const DateBar = styled.div`
    margin: 32px 0;
    height: 53px;
    background: #E9E8F0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0 20px;
  
  h5 {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 120%;
    color: #2E2C9A;
    margin: 0;
    padding: 0;
  }
`

const TimelineSection = styled.div`
  min-height: 100px;
  background: #ffffff;
  padding: 0.5em 1em;
  ${(props) => props.theme.breakpoints.maxPhone} {
    padding: 0.5em 0em;
  }
`

const TimelineItem = styled(Timeline.Item)`
  background: #ffffff;
  
  margin: 0;
  
  .ant-timeline-item-head-custom {
    padding: 0;
  }
`

const TimelineWrapper = styled(Timeline)`
  margin: 1em 32px;
`

const TimelineIcon = styled.div`
  background: #ffffff;
`



const Title = styled.h5`
    font-family: 'Proxima Nova Semibold';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 100%;
    text-transform: uppercase;
    color: #101B43;
`

const ActivityTitle = styled.h4`
    font-family: 'Proxima Nova Semibold';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 100%;
    text-transform: uppercase;
    color: #101B43;
    margin: 0;
    padding: 0;
		margin-top: 1rem;
`

const ActivityTime = styled.p`
    font-family: 'Proxima Nova';
    font-family: Proxima Nova;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 150%;
color: #2E2C9A;
margin-bottom: 0;
`

const ActivityRow = styled(Row)`
  min-height: 84px;
  background: #FAFAFC;
border-radius: 4px;
padding: 0.5em;
`

export const Thumbnail = styled(Image)`
width: 52px;
height: 52px;
border-radius: 50%;    


    background: #ccc url(${props => props.src}) no-repeat center;
    background-size: cover;
`;

export const formatDate = (value) => {
	return moment(value).format("MMM DD YYYY")
};

const TimelinePageTwo = ({ dateList, registeredValues }) => {

  const colors = [
		"https://res.cloudinary.com/pcgwa/image/upload/f_auto/w_640/v1610578555/reveleaze/colors/light-blue-image",
		"https://res.cloudinary.com/pcgwa/image/upload/f_auto/w_640/v1610578555/reveleaze/colors/pink-image",
		"https://res.cloudinary.com/pcgwa/image/upload/f_auto/w_640/v1610578555/reveleaze/colors/blue-image.jpg",
	];

	return (
		<>
			{Object.entries(registeredValues).sort().map(([day, value], i) => {
				return (
					<div key={i}>
						<DateBar><h5>{`Day ${i + 1}`}: {formatDate(dateList[i])}</h5></DateBar>
						<Row>
							<Col xs={24} md={12} >
								<TimelineSection>
									<Title>Morning</Title>
									<ActivityRow align='middle'>
										{value && value.mornings && value.mornings.map((item, index) => {
											return (
												<Col xs={24}>
													<Row align='middle'>
														<Col xs={6}>
															<Thumbnail src={!item.image ? colors[index % 3] : item.image} />
														</Col>
														<Col xs={12}>
															<ActivityTitle>{item.event} </ActivityTitle>
															<ActivityTime>{item.place} </ActivityTime>
															<ActivityTime>{item.time}</ActivityTime>
														</Col>
													</Row>
												</Col>
											)
										})}
									</ActivityRow>
								</TimelineSection>
							</Col>
							<Col xs={24} md={12}>
								<TimelineSection>
									<Title>Afternoon</Title>
									<ActivityRow align='middle'>
										{value && value.afternoon && value.afternoon.map((item, index) => {
											return (
												<Col xs={24}>
													<Row align='middle'>
														<Col xs={6}>
															<Thumbnail src={!item.image ? colors[index % 3] : item.image} />
														</Col>
														<Col xs={12}>
															<ActivityTitle>{item.event} </ActivityTitle>
															<ActivityTime>{item.place} </ActivityTime>
															<ActivityTime>{item.time}</ActivityTime>
														</Col>
													</Row>
												</Col>
											)
										})}
									</ActivityRow>
								</TimelineSection>
							</Col>
							<Col xs={24} md={12}>
								<TimelineSection>
									<Title>Evening</Title>
									<ActivityRow align='middle'>
										{value && value.evening && value.evening.map((item, index) => {
											return (
												<Col xs={24}>
													<Row align='middle'>
														<Col xs={6}>
															<Thumbnail src={!item.image ? colors[index % 3] : item.image} />
														</Col>
														<Col xs={12}>
															<ActivityTitle>{item.event} </ActivityTitle>
															<ActivityTime>{item.place} </ActivityTime>
															<ActivityTime>{item.time}</ActivityTime>
														</Col>
													</Row>
												</Col>
											)
										})}
									</ActivityRow>
								</TimelineSection>
							</Col>
						</Row>
					</div>
				)
			})}
			<div>

			</div>
		</>
	);
};

TimelinePageTwo.defaultProps = {

};

TimelinePageTwo.propTypes = {

};


export default TimelinePageTwo