import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Drawer, Button, Radio, Space } from 'antd';

const Wrapper = styled.div`

`

const DrawerDialog = ({ visible, onClose, children }) => {
  return (
      <Drawer
          title=""
          placement={`bottom`}
          closable={false}
        //   onClose={() => onClose()}
          visible={visible}
          key={`bottom`}
          height='100%'
          style={{ zIndex: 10 }}
          data-keyboard="false"
      >
          {children}
      </Drawer>
  );
};

DrawerDialog.defaultProps = {

};

DrawerDialog.propTypes = {

};

export default DrawerDialog;
