import React from "react";
import styled from "styled-components";
import { Field } from "redux-form";
import { Space } from "antd";
import { InputPhone } from "../../../FormComponents/MobileNumberInput";
import { InButton } from "../../../";

const Wrapper = styled.div`
  margin: 18px 0;
  ${props => props.theme.breakpoints.maxTablet}  {
    button {
      width: min-content !important;
    }
  }
`;

const BoxWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 1.125em 0 5em;
  display: flex;
  flex-direction: column;
`;

const InputWrapper = styled.div`
  margin: 1em 0;
  label {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #101b43;
  }
`;

const Input = styled.input`
  width: 100%;
  height: 44px;
  padding: 10px 12px;
  margin: 0;
  border: 1px solid #eeeeef;
  border-radius: 4px;
  outline: none;

  &:hover {
    border: 1px solid #eeeeef;
  }
`;

const GuestAddForm = (props) => {
	return (
		<Wrapper>
			<BoxWrapper>
				<InputWrapper>
					<label htmlFor="name">Name</label>
					<Input
						label="name"
						name="name"
						placeholder="Full Name"
						ref={props.inputElement}
						value={props.currentItem.name}
						onChange={props.handleName}
					/>
				</InputWrapper>
				<InputWrapper>
					<label htmlFor="email">Email</label>
					<Input
						name="email"
						placeholder="Email"
						ref={props.inputElement}
						value={props.currentItem.email}
						onChange={props.handleEmail}
					/>
				</InputWrapper>
				<InputWrapper>
					<label htmlFor="mobileNumber">Mobile Number</label>
					<Field
						name="mobileNumber"
						ref={props.inputElement}
						component={InputPhone}
						value={props.currentItem.mobileNumber}
						valueInput={props.currentItem.mobileNumber}
						// countryCode={countryCode}
						defaultCountry="US"
						onChange={(e) => props.handleMobileNumber(e)}
					/>
				</InputWrapper>
			</BoxWrapper>
			<Space size="large">
				<InButton
					width="253px"
					type="button"
					onClick={() => props.addItem(props.currentItem)}
					text="Save And add one more"
					disabled={props.disabled}
				>
					Save And add one more
				</InButton>
				<InButton
					type="button"
					onClick={() => props.done(props.currentItem)}
					text="Done"
					disabled={props.disabled}
					loading={props.loading}
					primary
				>
					Done
				</InButton>
			</Space>
		</Wrapper>
	);
};

export default GuestAddForm;
