import React, { useState } from "react";
import styled from "styled-components";
import ReactFileReader from "react-file-reader";
import uploadImage from "../../assets/icons/uploadImage.svg";

export const Container = styled.div`
  width: 325px !important;
  height: 188px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent url(${(props) => props.src}) no-repeat center;
  background-size: cover;
  border: 1px solid #ededed;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;

  input {
    &:hover {
      border: 1px solid #eee;
    }

    border: #eee;
    outline: #eee;

    &:focus {
      border: #eee;
      outline: #eee;
    }
  }

  h5 {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 80%;
    color: #2e2c9a;
    margin: 1em 0;
  }

  button {
    border: none;
    background: none;
    outline: none;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

function Previews({ input, initForm }) {
  const [preview, setPreview] = useState('');



  const handleFiles = (files) => {
    setPreview(files.base64);
    initForm({ image: files.base64 });
  };

  return (
    <ReactFileReader
      fileTypes={[".jpg", ".jpeg", ".png"]}
      base64={true}
      handleFiles={handleFiles}
    >
      <Container src={preview}>
        <button type="button">
          {preview ? (
            ""
          ) : (
            <Wrapper>
              <img src={uploadImage} alt="" />
              <h5>UPLOAD IMAGE</h5>
            </Wrapper>
          )}
        </button>
        <input {...input} type="hidden" name="image" value={preview || ''}  />
      </Container>
    </ReactFileReader>
  );
}

export default Previews;
