import React from 'react';
import styled from "styled-components";
import {Menu} from "antd";

const Wrapper = styled.svg`
    fill: #494D5B;
     stroke: #494D5B;
    
    &:hover {
      fill: #2E2C9A;
     stroke: #2E2C9A;
    }
`

export default ({ style }) => (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none">
        <path d="M17.5101 3.3062C17.3034 3.12973 17.0117 3.09254 16.7672 3.21155L13.0264 5.03265L9.51643 0.985703C9.38661 0.835996 9.19818 0.75 9.00001 0.75C8.80183 0.75 8.61343 0.835996 8.48359 0.985703L4.97361 5.03261L1.23284 3.21152C0.988357 3.09254 0.696668 3.12969 0.489881 3.30616C0.283093 3.48263 0.200515 3.76489 0.279573 4.025L2.87723 12.5699C2.96476 12.8579 3.23031 13.0547 3.53126 13.0547H14.4688C14.7697 13.0547 15.0353 12.8579 15.1228 12.57L17.7204 4.02503C17.7995 3.76492 17.7169 3.48267 17.5101 3.3062Z" fill="#FFDD00"/>
    </svg>
);
