import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from "styled-components"
import { scroller } from "react-scroll/modules";
import { Image } from '../../components'
import DecorDivider from "./Sections/DecorDivider";
import Header from "./Sections/Header";
import Info from "./Sections/Info";
import Dashboard from "./Sections/Dashboard";
import DitchTheGroupThreads from "./Sections/JoinNow";
import SignUpNow from "./Sections/SignUpNow";
import PersonalizeTheParty from "./Sections/PlanNow";
import Guides from "./Sections/Guides";
import PlanningStar from "./Sections/PlanningStar";
import bgLineImage from "./bg_hero_line.png";
import { Element } from 'react-scroll';
import Login from '../../containers/Login';
import LoginProvider, { LoginContext } from '../../containers/LoginContainer'

const Wrapper = styled.div`
  padding-bottom: 1em;
  overflow: hidden;
  min-height: 100vh;
`;

const HeaderContent = styled.div`
    padding-top: 120px;
    background: #F7F7F7;
`

function scrollTo(name) {
	scroller.scrollTo(name, {
		duration: 800,
		delay: 0,
		smooth: 'easeInOutQuart'
	})
}

class LoginPage extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	componentDidMount() {
	}

	componentWillUnmount() {
	}

	render() {
		return (
			<LoginProvider>
				<LoginContext.Consumer>
					{({
						submitUserDetails
					}) => {
						return (
							<Wrapper>
								<Login name='login' title="Get Started It's Free!" submitUserDetails={submitUserDetails} width='227px' primary visible={true} returnBack={true} />
							</Wrapper>
						);
					}}
				</LoginContext.Consumer>
			</LoginProvider>
		)
	}

}

export default connect(null, null)(LoginPage)
