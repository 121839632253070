import React from "react";
import moment from "moment";
import { Field } from "redux-form";
import styled from "styled-components";
import { Button, Form, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { FormComponents } from "../../index";
import cancel from "../../../assets/icons/cancelbtn.svg";

const Wrapper = styled.div``;

const Container = styled.div`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const CancelBtn = styled.button`
  outline: none;
  background: none;
  border: none;
  margin-top: 30px;
`;

const FormItem = styled(Form.Item)``;

const PlainBtn = styled(Button)`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 80%;
  /* identical to box height, or 12px */

  text-transform: uppercase;

  color: #2e2c9a;

  svg {
    font-size: 1em;
    font-weight: bold;
  }
`;
export const renderMembers = ({ fields, toggleMenu, defaultFields }) => {
	const data = fields.length ? fields : [defaultFields];
	return (
		<Wrapper>
			<Container>
				{data.map((member, index) => {
					const timeOfDay = member.includes("evening")
						? moment("16:00:00", "h:mm a")
						: member.includes("afternoon")
							? moment("12:00:00", "h:mm a")
							: moment("06:00:00", "h:mm a");
					const timeOfDayPlaceholder = member.includes("evening")
						? "4:00 PM"
						: member.includes("afternoon")
							? "12:00 PM"
							: "06:00 AM";
					return (
						<Row>
							<Col xs={24} sm={7}>
								<FormItem>
									<Field
										name={`${member}.time`}
										type="text"
										component={FormComponents.InTimePicker}
										label="Time"
										use12Hours
										// format="h:mm a"
										placeholder={timeOfDayPlaceholder}
										defaultOpenValue={timeOfDay}
									/>
								</FormItem>
							</Col>
							<Col xs={24} sm={7}>
								<FormItem onClick={(e) => toggleMenu("event", e.target.name)}>
									<Field
										label="Event"
										name={`${member}.event`}
										component={FormComponents.InInput}
										placeholder="Event"
										hasFeedback
									/>
								</FormItem>
							</Col>
							<Col xs={24} sm={8}>
								<Field
									label="Place"
									name={`${member}.place`}
									component={FormComponents.InInput}
									placeholder="Place"
									hasFeedback
								/>
							</Col>
							<Col xs={24} sm={2}>
								{!!index && (
									<CancelBtn
										type="button"
										title="Remove Member"
										onClick={() => fields.remove(index)}
									>
										<img src={cancel} alt="close" />
									</CancelBtn>
								)}
							</Col>
						</Row>
					);
				})}
			</Container>
			<PlainBtn
				type="text"
				onClick={() => fields.push({})}
				icon={<PlusOutlined />}
			>
				Add
			</PlainBtn>
		</Wrapper>
	);
};
