import React from 'react'
import styled from "styled-components";
import {PlusOutlined} from "@ant-design/icons";
import {InButton, InTypography} from "../index";

const Container = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 80px;
`

const Image = styled.img`
  margin: 0 0 16px 0;
  width: ${props => props.width ? props.width : '150px'};
`

const Text = styled(InTypography.InH3)`
  margin: 16px 0;
`

const EmptyTable = ({ image, width }) => {
    return (
        <Container>
            <Image width={width} src={image} alt=""/>
        </Container>
    )
}

export default  EmptyTable;