import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, withRouter, Redirect } from "react-router-dom";
import { NavBar, Events, Rsvp } from "../containers";
import ScrollToTop from "./ScrollToTop";
import ProtectedRoute from "./ProtectedRoute";
import { ResetPassword, Login, Register, Unsubscribe, Users } from "../pages";
import { AppContainer } from "../components";
import { EventGuestViewRoutes } from "./EventGuestViewRoutes";
import EventRoutes from "./EventRoutes";
import GlobalFonts from "../fonts";
// import ReactGA from "react-ga";
function NestingExample({ isAuthenticated, isVerifying }) {
  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);
  return (
    <Router>
      <ScrollToTop>
        <Route
          render={({ location }) => {
            return (
              <AppContainer>
                <GlobalFonts />
                <NavBar location={location} />
                <Switch>
                  <Route exact path="/" render={() => <Redirect to="/events" />} />
                  {/* <ProtectedRoute
										exact
										path="/"
										location={location}
										render={() => <Redirect to="/events" />}
										isAuthenticated={!isAuthenticated}
										isVerifying={isVerifying}
										redirectTo="/login"
									/> */}
                  <ProtectedRoute
                    exact
                    path="/events"
                    component={Events}
                    isAuthenticated={isAuthenticated}
                    isVerifying={isVerifying}
                    redirectTo="/login"
                  />
                  <ProtectedRoute
                    exact
                    path="/users"
                    component={Users}
                    isAuthenticated={isAuthenticated}
                    isVerifying={isVerifying}
                    redirectTo="/login"
                  />
                  {/* <ProtectedRoute
										exact
										path="/settings"
										component={Settings}
										isAuthenticated={isAuthenticated}
										isVerifying={isVerifying}
										redirectTo="/"
									/> */}
                  <Route exact path="/rsvp/:userId/:eventId" component={Rsvp} />
                  <Route exact path="/rsvp/:userId/:eventId/:email/:key" component={Rsvp} />
                  <Route path="/event/:eventId">
                    <EventRoutes isAuthenticated={isAuthenticated} isVerifying={isVerifying} />
                  </Route>
                  <Route path="/event-guest-view/:eventId">
                    <EventGuestViewRoutes isAuthenticated={isAuthenticated} isVerifying={isVerifying} />
                  </Route>
                  <Route path="/reset-password">
                    <ResetPassword />
                  </Route>

                  <Route path="/unsubscribe/:userId">
                    <Unsubscribe />
                  </Route>
                  <ProtectedRoute
                    exact
                    path="/login"
                    location={location}
                    component={() => <Login />}
                    isAuthenticated={!isAuthenticated}
                    isVerifying={isVerifying}
                    // redirectTo="/events"
                  />
                  <ProtectedRoute
                    exact
                    path="/register"
                    location={location}
                    component={() => <Register />}
                    isAuthenticated={!isAuthenticated}
                    isVerifying={isVerifying}
                    // redirectTo="/events"
                  />
                  <ProtectedRoute
                    exact
                    path="*"
                    component={Events}
                    isAuthenticated={isAuthenticated}
                    isVerifying={isVerifying}
                    redirectTo="/login"
                  />
                </Switch>
              </AppContainer>
            );
          }}
        />
      </ScrollToTop>
    </Router>
  );
}

export default withRouter(NestingExample);
