import React, { Component } from 'react';
import styled from "styled-components"
import banner from '../../../../../assets/home/dashboard-image.png'
import splashSvg from '../../../../../assets/home/element.png'


const HeaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
  
     @media (max-width: 480px) {
        margin-top: 1em;
   }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 318px;
  z-index: 2;
  position: relative;
  
    ${props => props.theme.breakpoints.tablet}{
    width: 50vw;
    height: auto;
  }
 
  
  ${props => props.theme.breakpoints.desktop} {
    width: 470px;
    height: 537px;
  }
`;

const Image = styled.div`
    //width: 100%;
    //height: 100%;
    background: transparent url(${props => props.src}) no-repeat center;
    background-size: cover;
    transform-style: preserve-3d;
    transition: all 0.5s ease;
    border-radius: 80px 0;
    
    
      width: 100%;
  height: 318px;
  z-index: 2;
  position: relative;
  
    ${props => props.theme.breakpoints.tablet}{
    width: 470px;
    height: 537px;
  }
`;

const Rectangle = styled.div`
  width: 470px;
height: 318px;
  border: 1px solid ${props => props.theme.colors.tradewind};
  border-radius: 80px 0;
  position: absolute;
  z-index: 1;
  right: 20px;
  bottom: 20px;
  
  ${props => props.theme.breakpoints.maxTablet}{
    display: none;
  }
 
  
  ${props => props.theme.breakpoints.desktop} {
    width: 470px;
    height: 537px;
    display: block;
  }
`;

const BgSplash = styled.div`
  position: absolute;
  z-index: 0;
  top: 100px;
  left: -150px;
  
  img {
    position: relative;
    width: 100%;
    height: 100%;
  }
`;

class CarouselComponent extends Component {
  render() {
    return (
      <HeaderWrapper>
        <Rectangle />
            <ImageWrapper>
              <Image src={banner} />
            </ImageWrapper>
          <BgSplash>
            <img src={splashSvg} alt=""/>
          </BgSplash>
      </HeaderWrapper>
    );
  }
}

export default CarouselComponent
