import React, { useState, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Modal, Row, Col } from "antd";
import { InTypography } from "../";
import { RsvpLoginForm, PasswordForm, SocialPasswordForm } from "../Forms";
import { loginUser, loginWithGoogle, loginWithFacebook, updateGuestPassword } from "../../helpers/auth";
import landing from "../../assets/login/login.png";
import invigoLogo from "../../assets/logo/logo.svg";
import { useHistory } from "react-router-dom";

const GlobalStyle = createGlobalStyle`
		.login-modal .ant-modal-close {
			left: 15px;
			right: auto;
		}

		.loginform.login-modal .ant-modal,
    .loginform.login-modal .ant-modal-content {
			height: 100%;
		}
    .login-modal .ant-modal,
    .login-modal .ant-modal-content {
			height: auto;
      width: 100vw !important;
			max-width: 100vw;
      margin: 0;
      top: 0 !important;
			margin: 0;
			border-radius: 0;
			top: 0;
    }
	

		
		.loginform.login-modal .ant-modal-body {
			height: 100%;
		}
    
		.login-modal .ant-modal-body {
			height: auto;
			padding: 0;
    }
		@media all and (max-width: 767px) {
			.login-modal .ant-modal-body {
			height: auto;
			min-height: 100vh;
			padding: 15px;
    }
		}
    .login-modal .modal-dialog {
      max-width: 100%;
      margin: 0;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100vh;
      display: flex;
    }

		@media all and (min-width: 768px) {
			.login-modal .ant-modal,
    .login-modal .ant-modal-content,.login-modal .ant-modal-body {
			height: 100%;
		}
		}
`;

const ModalWrapper = styled(Modal)`
  padding: 0;
  .ant-modal-content {
    height: 100%;
    border-radius: 0px;
  }

  .ant-modal-close {
    top: 20px;
    right: 20px;
    display: none;
  }
  .ant-modal-close-x {
    display: none;
    border: 1px solid #eeeeef;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: #494d5b;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 2em;
  height: 100%;
  @media all and (max-width: 767px) {
    margin: 0;
  }
`;

export const Text = styled.div`
  margin: 32px 0 8px;
`;

export const Paragraph = styled(InTypography.InParagraph)`
  text-align: center;
  max-width: 320px;
  margin: 0 auto;
`;

export const Buttons = styled.div`
  display: flex;
`;

export const RsvpContent = styled.div`
  width: 100%;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 210px;
  height: 44px;
  background: ${(props) => props.bg};
  border-radius: 4px;
  font-family: "Proxima Nova Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 80%;

  color: ${(props) => props.color};
`;

export const RowLogin = styled(Row)`
  height: 100%;
`;

export const Image = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: column;
  background: transparent url(${(props) => props.src}) no-repeat 70% center;
  background-size: cover;
  //margin-top: 5vh;
`;
var promiseResolve;
const Login = ({ isAuthenticated, eventParams }) => {
  const [showModal, setModal] = useState(false);
  const [showPassword, setPassword] = useState(false);
  const [showLogin, setLogin] = useState(false);
  const [showSocialPassword, setSocialPassword] = useState(false);
  let history = useHistory();

  const handleClose = () => {
    setModal(false);
  };

  const handleLoginClose = () => {
    setLogin(false);
    setPassword(true);
  };

  const handlePasswordClose = () => {
    setPassword(false);
    setModal(false);
  };

  const handleSubmit = (email, password, dispatch) => {
    // const setClose
    if (!isAuthenticated && eventParams && eventParams.email) {
      loginUser(email, password)(dispatch, handleLoginClose);
    } else if (!isAuthenticated) {
      loginUser(email, password)(dispatch, handleClose);
    } else {
      updateGuestPassword(password, handlePasswordClose);
    }
  };

  const googleLogin = (dispatch) => {
    loginWithGoogle(dispatch, handleClose);
  };

  const socialPassword = () => {
    setSocialPassword(true);
    return new Promise((resolve, reject) => {
      promiseResolve = resolve;
    });
  };

  const facebookLogin = (dispatch) => {
    loginWithFacebook({
      dispatch,
      onClose: handleClose,
      eventParams,
      socialPassword,
    });
  };

  const handleSubmitPassword = (password) => {
    promiseResolve(password);
  };

  useEffect(() => {
    if (!isAuthenticated) {
      setLogin(true);
      setModal(true);
    }
  }, [isAuthenticated]);
  return (
    <>
      <GlobalStyle />
      <ModalWrapper
        title=""
        visible={showModal}
        transitionName=""
        destroyOnClose={true}
        footer={null}
        style={{ top: "16px" }}
        wrapClassName={"loginform login-modal"}
        onCancel={() => history.push("/")}
      >
        <RowLogin>
          <Col xs={0} sm={12}>
            <Image src={landing} />
          </Col>
          <Col xs={24} sm={{ span: 8, offset: 2 }}>
            <Content>
              <a href="https://goinvigo.com/" target="_blank" rel="noopener noreferrer">
                <img src={invigoLogo} alt="goinvigo" />
              </a>
              <Text>
                <InTypography.InH2>Welcome to Invigo!</InTypography.InH2>
              </Text>
              {showLogin && !showSocialPassword && (
                <RsvpContent>
                  <Paragraph>Create an account so the Event Organizer can see your choices.</Paragraph>
                  <RsvpLoginForm
                    eventParams={eventParams}
                    googleLogin={googleLogin}
                    facebookLogin={facebookLogin}
                    loginWithEmailAndPassword={handleSubmit}
                    isAuthenticated={isAuthenticated}
                  />
                </RsvpContent>
              )}
              {showPassword && !showSocialPassword && (
                <PasswordForm
                  eventParams={eventParams}
                  googleLogin={googleLogin}
                  handleSubmitPassword={handleSubmit}
                  isAuthenticated={isAuthenticated}
                />
              )}
              {showSocialPassword && <SocialPasswordForm handleSubmitPassword={handleSubmitPassword} />}
            </Content>
          </Col>
        </RowLogin>
      </ModalWrapper>
    </>
  );
};

Login.defaultProps = {};

Login.propTypes = {};

export default Login;
