import React from 'react';
import { Tabs } from 'antd';
import styled from "styled-components";
import { AllChats } from './allChats'
import { ChatContext } from "../../../containers/Chat/ChatProvider";

const { TabPane } = Tabs;

const Wrapper = styled(Tabs)`
    width: 100%;
    background: white;
    padding: 0;
    border-radius: 8px 8px 0 0;
  .ant-tabs-nav {
    margin: 0 0 .5em 0;
  }
  
  .ant-tabs-tab-btn {
    color: #8696AE;
  }
  
  .ant-tabs-tab {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 100%;
    margin: 6px 20px 12px;
    color: #8696AE;
}
  .ant-tabs-tab.ant-tabs-tab-active {
    color: #2E2C9A;
    font-weight: 600;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #2E2C9A;
    font-weight: 500;
}

.ant-tabs-ink-bar {
    background: #2E2C9A;
}

.ant-tabs-tab:hover {
    color: #2E2C9A;
}
`
function callback() {
    return null
}

export const ChatsTab = ({ setActiveGroupChat, activeGroup }) => (
    <ChatContext.Consumer>
        {({ guests, eventId, allGroupChats }) => {
            return (
                <Wrapper defaultActiveKey="1" onChange={callback}>
                    <TabPane tab="ALL CHATS" key="1">
                        <AllChats activeGroup={activeGroup} guests={guests} eventId={eventId} allGroupChats={allGroupChats} setActiveGroupChat={setActiveGroupChat} />
                    </TabPane>
                    {/*<TabPane tab="GUESTS" key="2">*/}
                    {/*    /!*Content of Tab Pane 2*!/*/}
                    {/*    /!*<TimelineActivity />*!/*/}
                    {/*</TabPane>*/}
                </Wrapper>
            )
        }}
    </ChatContext.Consumer>

);