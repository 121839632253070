import React, { useState } from "react";
import styled from "styled-components";
import { Space, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { GuestListTableForGuests, DeleteGuestPopup } from "../";
import { SearchInput, InTypography } from "../";
import AddGuests from "./AddGuests";
import GuestReminder from "./GuestReminder";
import EditGuest from './EditGuest';

const Wrapper = styled.div`
  margin: 2.5em 1em 1em 1em;
  background: #ffffff;
  min-height: 80vh;
`;

const IconButton = styled(Button)`
  background: ${(props) => (props.primary ? "#4F4DB8" : "#ffffff")};
  border-color: #4f4db8;
  width: ${(props) => (props.width ? props.width : "172px")};
  height: 44px;
  border-radius: 4px;
  color: ${(props) => (props.primary ? "#ffffff" : "#2E2C9A")};
  text-transform: uppercase;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 80%;
  display: flex;
  align-items: center;

  &:hover {
    background: ${(props) => (props.primary ? "#2E2C9A" : "#ffffff")};
    border-color: #2e2c9a;
    color: ${(props) => (props.primary ? "#ffffff" : "#2E2C9A")};
  }

  &:focus {
    background: ${(props) => (props.primary ? "#2E2C9A" : "#ffffff")};
    border-color: #2e2c9a;
    color: ${(props) => (props.primary ? "#ffffff" : "#2E2C9A")};
  }
`;
// Search
const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: #ffffff;
`;

const HeaderRight = styled.div`
  display: flex;
`;

const GuestList = ({ title, tableData, eventId, guests }) => {
	const [visible, setVisibility] = useState(false);
	const [showDelete, setShowDelete] = useState(false);
	const [showReminder, setShowReminder] = useState(false);
	const [showEdit, setShowEdit] = useState(false);
	// const [deleteUser, setDeleteUser] = useState(null);
	const [guest, setGuest] = useState(null);

	const showModal = () => {
		setVisibility(true);
	};

	const handleOk = () => {
		setVisibility(false);
		setShowEdit(false);
	};

	const handleCancel = () => {
		setVisibility(false);
		setShowDelete(false);
		setShowReminder(false);
		setShowEdit(false);
		setGuest(null);
	};

	const showDeleteModal = (user) => {
		setShowDelete(true);
		setGuest(user);
	};

	const showReminderModal = (user) => {
		setShowReminder(true);
		setGuest(user);
	};

	const showEditModal = (user) => {
		setShowEdit(true);
		setGuest(user);
	};

	return (
		<Wrapper>
			<TableHeader>
				<InTypography.InH1 dangerouslySetInnerHTML={title} />
				<HeaderRight>
					<Space size="large">
						<SearchInput width="242px" />
					</Space>
				</HeaderRight>
			</TableHeader>
			<GuestListTableForGuests tableData={tableData} showDeleteModal={showDeleteModal} showReminderModal={showReminderModal} showEditModal={showEditModal} />
			<AddGuests visible={visible} handleOk={handleOk} handleCancel={handleCancel} eventId={eventId} />
			<DeleteGuestPopup visible={showDelete} onClose={handleCancel} user={guest} eventId={eventId} />
			<GuestReminder visible={showReminder} onClose={handleCancel} guest={guest} eventId={eventId} />
			<EditGuest visible={showEdit} handleOk={handleOk} handleCancel={handleCancel} guest={guest} eventId={eventId} guests={guests} />
		</Wrapper>
	);
};

GuestList.defaultProps = {};

GuestList.propTypes = {};

export default GuestList;
