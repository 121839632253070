import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  useParams,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import {
  EventDashboard,
  Polls,
  Schedule,
  Settings,
  GuestLists,
  TaskLists,
  Chat,
  Budget,
} from "../containers";
import ProtectedRoute from "./ProtectedRoute";
import styled from "styled-components";
import { Layout } from "antd";
import { EventMenu, PageMotion } from "../components";
import ArrowSVG from "../assets/icons/arrow";

const { Sider } = Layout;
// Since routes are regular React components, they
// may be rendered anywhere in the app, including in
// child elements.
//
// This helps when it's time to code-split your app
// into multiple bundles because code-splitting a
// React Router app is the same as code-splitting
// any other React app.

const Container = styled(Layout)`
  margin-top: 60px;
  background: #ffffff;
  @media (max-width: 500px) {
    padding-bottom: 2rem;
  }
  //   overflow-x: hidden;
  width: 100vw;
  height: 90vh;
  ${(props) => props.theme.breakpoints.maxTablet} {
    height: auto;
  }
`;

const SiderContainer = styled(Sider)`
  ${(props) => props.theme.breakpoints.maxTablet} {
    display: none;
  }
  display: block;
  box-shadow: 1px 0 0 rgba(0, 0, 0, 0.05);
  position: fixed;
  height: 90vh;

  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border-right: none;
  }

  .ant-menu-item-selected {
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #ffffff;

    svg {
    }
  }

  .ant-menu-inline-collapsed > .ant-menu-item,
  .ant-menu-inline-collapsed
    > .ant-menu-item-group
    > .ant-menu-item-group-list
    > .ant-menu-item,
  .ant-menu-inline-collapsed
    > .ant-menu-item-group
    > .ant-menu-item-group-list
    > .ant-menu-submenu
    > .ant-menu-submenu-title,
  .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    left: 0;
    padding: 0 32px;
    text-overflow: clip;
  }
`;

const Button = styled.button`
  position: absolute;
  background: none;
  border: none;
  outline: none;
  right: -15px;
  top: 5px;
  z-index: 999;

  svg {
    transform: ${(props) =>
      props.collapsed ? "rotate(180deg)" : "rotate(0deg)"};
    transition: 0.3s All ease;
  }
`;

export default function EventRoutes({ isAuthenticated, isVerifying }) {
  const [collapsed, setCollapsed] = useState(false);
  const [activeRoute, setRoute] = useState(null);
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  let { path, url } = useRouteMatch();

  const toggle = () => {
    setCollapsed(!collapsed);
  };
  return (
    <PageMotion>
      <Container>
        <SiderContainer
          width={200}
          style={{ background: "#FFFFFF", maxHeight: "90vh" }}
          trigger={null}
          collapsible
          collapsed={collapsed}
        >
          <Button collapsed={collapsed} onClick={() => toggle()}>
            <ArrowSVG />
          </Button>
          <EventMenu url={url} collapsed={collapsed} routeId={activeRoute} />
        </SiderContainer>
        <Switch>
          <ProtectedRoute
            exact
            path={`${path}/:routeId`}
            // component={() => <Event collapsed={collapsed} setRoute={setRoute} />}
            component={Event}
            isAuthenticated={isAuthenticated}
            isVerifying={isVerifying}
            redirectTo="/"
            collapsed={collapsed}
            setRoute={setRoute}
            url={url}
          />
        </Switch>
      </Container>
    </PageMotion>
  );
}

const Main = styled(Layout)`
  background: none;
  padding-left: ${(props) => (props.collapsed ? "115px" : "215px")};
  transition: all 0.3s ease;
  @media (max-width: 500px) {
    max-height: fit-content;
    overflow: auto;
  }
  max-height: 90vh;
  overflow: hidden;
  ::-webkit-scrollbar {
    display: none;
  }

  ${(props) => props.theme.breakpoints.maxTablet} {
    padding-left: 0;
  }
`;

function Event(props) {
  // The <Route> that rendered this component has a
  // path of `/topics/:topicId`. The `:topicId` portion
  // of the URL indicates a placeholder that we can
  // get from `useParams()`.
  let { routeId } = useParams();
  props.setRoute(routeId);
  return (
    <Main collapsed={props.collapsed}>
      <ActiveComponent {...props} routeId={routeId} />
    </Main>
  );
}

const Test = styled.div`
  //border: 1px solid red;
  @media (max-width: 500px) {
    height: fit-content;
    overflow-y: auto;
  }
  height: 90vh;
  overflow-y: scroll;
  ${(props) => props.theme.breakpoints.maxTablet} {
    height: auto;
    overflow-y: auto;
  }
`;

function ActiveComponent(props) {
  const { routeId } = props;
  switch (routeId) {
    case "dashboard":
      return (
        <Test>
          <EventDashboard {...props} />
        </Test>
      );
    case "polls":
      return (
        <Test>
          <Polls {...props} />
        </Test>
      );
    case "schedule":
      return (
        <Test>
          <Schedule {...props} />
        </Test>
      );
    case "guest-lists":
      return (
        <Test>
          <GuestLists {...props} />
        </Test>
      );
    case "task-lists":
      return (
        <Test>
          <TaskLists {...props} />
        </Test>
      );
    case "chat":
      return (
        <Test>
          <Chat {...props} />
        </Test>
      );
    case "budget":
      return (
        <Test>
          <Budget {...props} />
        </Test>
      );
    case "settings":
      return (
        <Test>
          <Settings {...props} />
        </Test>
      );
    default:
      return (
        <Test>
          <EventDashboard {...props} />
        </Test>
      );
  }
}
