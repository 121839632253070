import React from 'react';
import styled from "styled-components";
import {Menu} from "antd";

const Wrapper = styled.svg`
    fill: #494D5B;
     stroke: #494D5B;
    
    &:hover {
      fill: #2E2C9A;
     stroke: #2E2C9A;
    }
`

export default () => (
    <svg width="28px" height="25px" fill="#FF8E95" viewBox="0 0 1024 1024">
        <path d="M923 283.6c-13.4-31.1-32.6-58.9-56.9-82.8-24.3-23.8-52.5-42.4-84-55.5-32.5-13.5-66.9-20.3-102.4-20.3-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5-24.4 23.9-43.5 51.7-56.9 82.8-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3 0.1-35.3-7-69.6-20.9-101.9z" />
    </svg>
);

export const HeartSvg = () => (
    <svg width="28px" height="25px" fill="#FF8E95" viewBox="0 0 1024 1024">
        <path d="M923 283.6c-13.4-31.1-32.6-58.9-56.9-82.8-24.3-23.8-52.5-42.4-84-55.5-32.5-13.5-66.9-20.3-102.4-20.3-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5-24.4 23.9-43.5 51.7-56.9 82.8-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3 0.1-35.3-7-69.6-20.9-101.9z" />
    </svg>
);

export const HeartOutlinedSvg = () => (
    <svg  viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-inside-1" fill="white">
            <path d="M20.3 0.555664C17.864 0.555664 15.526 1.6543 14 3.37686C12.474 1.6543 10.136 0.555664 7.7 0.555664C3.388 0.555664 0 3.82445 0 8.01555C0 13.129 4.76 17.3201 11.97 23.6542L14 25.4446L16.03 23.6542C23.24 17.3201 28 13.129 28 8.01555C28 3.82445 24.612 0.555664 20.3 0.555664Z"/>
        </mask>
        <path d="M14 3.37686L12.8772 4.37152L14 5.63892L15.1228 4.37152L14 3.37686ZM11.97 23.6542L12.9622 22.5292L12.96 22.5273L11.97 23.6542ZM14 25.4446L13.0078 26.5695L14 27.4446L14.9922 26.5695L14 25.4446ZM16.03 23.6542L15.04 22.5273L15.0378 22.5292L16.03 23.6542ZM20.3 -0.944336C17.4279 -0.944336 14.6834 0.343354 12.8772 2.38219L15.1228 4.37152C16.3686 2.96525 18.3001 2.05566 20.3 2.05566V-0.944336ZM15.1228 2.38219C13.3166 0.343354 10.5721 -0.944336 7.7 -0.944336V2.05566C9.69992 2.05566 11.6314 2.96525 12.8772 4.37152L15.1228 2.38219ZM7.7 -0.944336C2.60653 -0.944336 -1.5 2.94983 -1.5 8.01555H1.5C1.5 4.69907 4.16946 2.05566 7.7 2.05566V-0.944336ZM-1.5 8.01555C-1.5 11.0811 -0.0596772 13.7433 2.13477 16.3444C4.31438 18.9278 7.40522 21.6406 10.98 24.7811L12.96 22.5273C9.32478 19.3337 6.43062 16.7838 4.42773 14.4098C2.43968 12.0534 1.5 10.0634 1.5 8.01555H-1.5ZM10.9778 24.7792L13.0078 26.5695L14.9922 24.3196L12.9622 22.5292L10.9778 24.7792ZM14.9922 26.5695L17.0222 24.7792L15.0378 22.5292L13.0078 24.3196L14.9922 26.5695ZM17.02 24.7811C20.5948 21.6406 23.6856 18.9278 25.8652 16.3444C28.0597 13.7433 29.5 11.0811 29.5 8.01555H26.5C26.5 10.0634 25.5603 12.0534 23.5723 14.4098C21.5694 16.7838 18.6752 19.3337 15.04 22.5273L17.02 24.7811ZM29.5 8.01555C29.5 2.94983 25.3935 -0.944336 20.3 -0.944336V2.05566C23.8305 2.05566 26.5 4.69907 26.5 8.01555H29.5Z" fill="#C7CBCF" mask="url(#path-1-inside-1)"/>
    </svg>
);