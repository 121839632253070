// /* global google */
import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import Geosuggest from 'react-geosuggest';

export const GeoSuggestion = styled(Geosuggest)`
  width: 100%;
      input {
        border: 1px solid #ccc;
        height: 49px;
        border-radius: 4px;
        box-sizing: border-box;
        border-radius: 4px;
        display: flex;
        outline: none;
        padding: 10px 12px;
        width: 100%;
        -webkit-appearance: none;
  }
  
  .geosuggest__suggests-wrapper {
  
        ${props => props.dropDownOpen ? 'display: block;' : 'display: none;'}
        ul {
            ${props => props.dropDownOpen ? 'display: block;' : 'display: none;'}
            margin: 0;
            padding: 0;
            position: absolute;
            background: #FFFFFF;
            
            ${props => props.dropDownOpen ? 'border: 1px solid #EEEEEF;' : ''};
            box-sizing: border-box;
            border-radius: 4px;
            
            max-width: 670px;
            width: 100%;
            list-style: none;
            z-index: 1;
      }
  }

  

   li {
     padding: 10px 12px;
     margin: 0;
     border-bottom: 1px solid #EDEDED;
   }

   li:hover {
     cursor: pointer;
     background: #4F4DB8;
     color: white;
   }
`;


function handleOnChange(value, open, close) {
    if (value) return open(true);
    close(false);
}

export const SelectLocation = (props) => {
    const textInput = useRef();
    const [open, setOpen] = useState(true);
    const { onSelect, placeholder } = props;
    return (
        <GeoSuggestion
            ref={textInput}
            placeholder={placeholder}
            types={[ '(cities)' ]}
            onSuggestSelect={(suggest) => {
                if (suggest === undefined) return;
                if(suggest && suggest.description){
                    setOpen(false)
                    onSelect(suggest.description, suggest.gmaps.photos[0].getUrl());
                    // textInput.current.blur();
                }
                // textInput.current.clear();

            }}
            autoComplete='on'
            dropDownOpen={open}
            onChange={value => handleOnChange(value, setOpen, setOpen)}
        />
    );
};
