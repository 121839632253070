import React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { Row, Col } from 'antd';
import { Field } from "redux-form";
import { FormComponents, InButton } from "../";

const Wrapper = styled.div`
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  
`

const DateCard = styled.div`
    //display: flex;
    background: #FAFAFC;
    border-radius: 8px;
    height: 5.375em;
    padding: 1em;
    
    span {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: normal;
        font-size: 1em;
        line-height: 150%;
        color: #101B43;
    }
`
const Dash = styled.div`
    display: flex;
    background: ${props => props.color};
    border-radius: 8px;
    width: 3.3125em;
    height: 0.1875em;
    margin: 0.4375em 0;

`

const Caption = styled.p`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #8696AE;
  margin-top: 1.25em;
`

const Container = styled.div`
  height: 100px;
`

const SelectedDates = ({ selectedDates }) => {
	const colors = [
		'#FF8E95',
		'#A9C5F4',
		'#47B0B7'
	]
	return (
		<Row gutter={16}>
			{selectedDates.map((selectedDate, index) => {
				return (
					<Col key={index} className="gutter-row" xs={24} md={8}>
						<DateCard>
							<Dash color={colors[index]} />
							<span>
								{`${selectedDate.start} - ${selectedDate.end}`} <br />
								{selectedDate.days === 1 ? `${selectedDate.days} day` : `${selectedDate.days} days`}
							</span>
						</DateCard>
					</Col>
				)
			})}

		</Row>
	)

}

function disabledDate(current) {
	// Can not select days before today and today
	return current && current < moment().endOf('day');
}

const InDatepicker = ({ checkValue, disabled, onClick, selectedDates }) => {
	return (
		<Wrapper>
			<Field size='large' label={checkValue ? 'Dates Option 1' : 'Selected Date'} disabledDate={disabledDate} name="datesOptionOne" type='text' component={FormComponents.InRangePicker} placeholder={['Start Date', 'End Date']} />
			{checkValue && <Field size='large' label="Dates Option 2" disabledDate={disabledDate} name="datesOptionTwo" type='text' component={FormComponents.InRangePicker} placeholder={['Start Date', 'End Date']} />}
			{checkValue && <Field size='large' label="Dates Option 3" disabledDate={disabledDate} name="datesOptionThree" type='text' component={FormComponents.InRangePicker} placeholder={['Start Date', 'End Date']} />}

			<Caption>{checkValue ? 'Add up to 3 potential dates for the event' : 'Add a potential date for the event'}</Caption>
			<Container>
				<SelectedDates selectedDates={selectedDates} />
			</Container>

			<InButton
				type='button'
				htmlType='button'
				onClick={() => onClick()}
				text='ADD DATES'
				disabled={disabled}
				primary
			/>
		</Wrapper>
	);
};

InDatepicker.defaultProps = {

};

InDatepicker.propTypes = {

};

export default InDatepicker;
