import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Row, Col, Skeleton } from "antd";

import { HeartSvg, HeartOutlinedSvg } from "../../../assets/icons/heart";
import RatingIcon from "../../../assets/icons/rating";
import { PollTimer, InButton } from "../../../components";
import Modal from "./modal";
import { itinerary } from "../../../helpers/firestore";

export const Container = styled(Row)`
  padding: 0 1.25em;
`;

export const Header = styled(Col)`
  height: 60px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    font-family: "Clearface Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 34px;
    color: #101b43;
    padding: 0 1em;
    margin: 0;
  }

  button {
    margin-right: 1.25em;
  }
`;

export const CardContent = styled(Row)`
  padding: 1.25em;
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;

  h5 {
    padding: 0;
    margin: 0;
    font-family: "Proxima Nova Semibold";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 100%;
    color: #101b43;
  }
`;

export const CityCard = styled.div`
  height: 254px;
  background: #fafafc;
  border-radius: 8px;
  cursor: pointer;
`;

export const Content = styled.div`
  padding: 0 20px;
  h5 {
    margin: 20px 0;
    padding: 0;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: #101b43;

    span {
      margin: 0 6px;
    }
  }
`;

export const SvgContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;

  svg {
    fill: #ff8e95;
    font-size: 1.5em;
  }

  span {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 100%;
    text-transform: uppercase;
    color: #000000;
    margin-right: 6px;
  }
`;

export const HeaderImage = styled.div`
  width: 100%;
  height: 150px;
  background: transparent url(${(props) => props.src}) no-repeat center;
  background-size: cover;
  border-radius: 8px 8px 0 0;
`;

const PollsPage = ({ event, itineraryData, guest }) => {
	const [visible, setVisibility] = useState(false);

	const toggleModal = () => {
		setVisibility(!visible);
	};

	const finalize = (city) => {
		const finalizeDetails = itineraryData && itineraryData.dates.start;
		itinerary.addDestination(event, city, finalizeDetails, [], true).then((result) => setVisibility(false));
	};

	if (!event)
		return (
			<Container>
				<Skeleton active />
			</Container>
		);

	const highestValue = Math.max.apply(
		Math,
		event.cities.map(function (o) {
			return o && o.votes ? o.votes : 0;
		})
	);

	return (
		<div>
			<Row>
				<Header xs={24}>
					<h2>Destinations</h2>
					<ActionContainer>
						<h5>POLL CLOSES:</h5>
						<PollTimer closeDate={itineraryData.city.countdown} />
						{!guest && (
							<InButton width="170px" type="button" onClick={() => toggleModal()} text="CLOSE VOTING" primary />
						)}
					</ActionContainer>
				</Header>
			</Row>
			<CardContent gutter={16}>
				{event.cities.map((city, index) => {
					return (
						<Col sm={8} key={index}>
							<CityCard>
								<HeaderImage src={city.imageUrl} />
								<Content>
									<h5>
										{city.name}
										<span>{highestValue === city.votes && highestValue > 0 ? <RatingIcon /> : ""}</span>
									</h5>

									<SvgContainer>
										<HeartSvg />
										<span>
											{city && city.votes ? city.votes : 0} {city && city.votes && city.votes === 1 ? "vote" : "votes"}
										</span>
									</SvgContainer>
								</Content>
							</CityCard>
						</Col>
					);
				})}
			</CardContent>
			<Modal visible={visible} toggleModal={toggleModal} event={event} finalize={finalize} />
		</div>
	);
};

PollsPage.defaultProps = {};

PollsPage.propTypes = {};

export default PollsPage;
