import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ToastContainer, toast } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import publicIp from "../publicIp";
import { addLocation } from "../publicIp/actions";

export const AppContext = React.createContext()

function displayMessage(toastMessage){
  switch (toastMessage.type) {
    case 'success':
      return toast.success(toastMessage.message);
    case 'error':
      return toast.error(toastMessage.message);
    case 'warning':
      return toast.warning(toastMessage.message);
    case 'info':
      return toast.info(toastMessage.message);
    default:
      return null;
  }
}

function AppProvider({ children, dispatch, toastMessage, user: { uid }  }) {
  // const [openSnackbar, closeSnackbar] = useSnackbar();
  // const [openSnackbar, closeSnackbar] = useState()

  useEffect(() => {
    publicIp().then((res) => {
      dispatch(addLocation(res))
    })

    if(toastMessage){
      
      displayMessage(toastMessage)
    }
    
  }, [ dispatch, toastMessage ]);
 
  return (
    <AppContext.Provider value={{ uid }}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
        {children}
      </AppContext.Provider>
  )
} 

AppProvider.propTypes = {
  children: PropTypes.object,
}

AppProvider.defaultProps = {
  children: {}
}

function mapStateToProps (state) {
  return {
    toastMessage: state.toast.message
  }
}

export default connect(mapStateToProps, null)(AppProvider)