import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding: 0 1em;
  width: 100vw;
  margin: 0 auto;
  
  ${props => props.isAuthenticated ? 'max-width: 99vw;' : 'max-width: 1300px;'}
`

const InnerContainer = ({ children, isAuthenticated }) => {
  return (
    <Wrapper isAuthenticated={isAuthenticated}>
        {children}
    </Wrapper>
  );
};

InnerContainer.defaultProps = {

};

InnerContainer.propTypes = {

};

export default InnerContainer;
