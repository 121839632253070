import publicIp from "public-ip";

const iplocation = require("iplocation").default;

async function getIp() {
    const ip = await publicIp.v4()
    return iplocation(ip)
        .then((res) => {
            return res
        })
        .catch(err => {
        });
};

export default function PublicIp(location) {
    return getIp();
}
