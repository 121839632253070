import React from 'react'

import styled from 'styled-components'
import { Space, Menu, Dropdown, Skeleton } from "antd";
import {  Link, useHistory } from "react-router-dom"
import {CloseOutlined, BellOutlined, EllipsisOutlined} from "@ant-design/icons";
import { InTypography, SearchInput, EmptyTable, TableImage, CancelPopup, EventReminder } from '../../../../components'
import { EventsContext } from "../../EventsProvider";
import noEvents from '../../../../assets/events/no-events.svg'


const travelArt = 'https://res.cloudinary.com/pcgwa/image/upload/v1600696578/invigo/email/clip-travel_1.png'
const TableWrapper = styled.div`

`

const SearchBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    
    @media (max-width: 768px) {
      flex-direction: column;
    }
  
`

const Table = styled.div`
    background: ${props => props.header ? 'none' : '#ffffff'};
    border-radius: 8px;
    margin: 1em 0;
    padding: 1em 0;
    cursor: pointer;
    ${props => props.header ? '' : 'box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.2); border: 1px solid rgba(0, 0, 0, 0.1);'};
      
    @media (min-width: 1024px) {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        min-height: 64px;
    }

    @media all and (max-width: 1023px) {
      display: ${props => props.header ? 'none' : 'block'};
    }
`

const TableCell = styled.div`
    width: ${props => props.width ? props.width : '11.42%'};
    ${props => props.maxWidth ? 'max-width: 200px' : ''};
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 64px;
    padding: 0 1em;
    
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #101B43;
    text-align: left;
    text-transform: capitalize;
    
    span {
      text-align: left;
      width: 100%;
    }
  
    @media all and (max-width: 1023px) {
        width: 100%;
        padding: 0.5em 1em;
    }
`

const RowFooter = styled.div`
    width: 5%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    flex-grow: 1;
    height: 100%;
    padding: 0.5em 1em;
    
    @media all and (max-width: 1023px) {
      width: 100%;
    }
    
    @media (min-width: 1024px) {
        border-radius: 0 8px 8px 0;
    }
`

const ActionMenu = styled(Menu)`
    width: 160px;
    
`

const ActionItem = styled(Menu.Item)`
    width: 160px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #101B43;
    
    &:hover {
      color: ${props => props.color};
    }

`

const HeaderCell = styled.div`
    width: ${props => props.width ? props.width : '11.42%'};
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;
    padding: 0 1em;
    
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 100%;
    color: #8696AE;
  
    @media all and (max-width: 1023px) {
        width: 100%;
    }
`

const HeaderFooter = styled.div`
    width: 5%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    flex-grow: 1;
    height: 100%;
    padding: 0.8em 1.2em;
    
    @media all and (max-width: 1023px) {
      width: 100%;
    }
    
    @media (min-width: 1024px) {
        border-radius: 0 8px 8px 0;
    }
`

const Title = styled.p`
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #2E2C9A;
    margin: 0;
    padding: 0;
`

const LinkTo = styled(Link)`
  text-decoration: none;
 display: flex;
 width: 100%;
`

const MenuAction = ({ id, showCancelModal, showReminderModal }) => {
    return (
        <ActionMenu>
            <ActionItem color='#2E2C9A' icon={<BellOutlined />} onClick={() => showReminderModal(id)} >
                Remind
            </ActionItem>
            <ActionItem color='#EE4238' icon={<CloseOutlined />} onClick={() => showCancelModal(id)}>
                Cancel
            </ActionItem>
        </ActionMenu>
    )
};

const DropMenu = styled(Dropdown)`
  cursor: pointer;
`

const createActions = (data, showCancelModal, showReminderModal) => {
    return (
        <Space size="middle">
            <DropMenu
                overlay={<MenuAction id={data.id} showCancelModal={showCancelModal} showReminderModal={showReminderModal}/>}
                placement="bottomRight"
            >
                <EllipsisOutlined style={{ fontSize: 28 }} />
            </DropMenu>
        </Space>
    )
}

function createMarkup(displayName) {
    return {__html: `Hi <span>${displayName}</span>, here are the events you are planning:`};
}

const RenderRows = ({ tableData, showCancelModal, showReminderModal }) => {
    let history = useHistory();
    return tableData.map((data, index) => {
        return (
            <Table key={data.key}>

                <TableCell width='20%' onClick={() => history.push(`/event/${data.id}/dashboard`)} >
                    <Title>{data.name}</Title>
                </TableCell>
                <TableCell width='20%' onClick={() => history.push(`/event/${data.id}/dashboard`)}>
                    {data.dates ? data.dates.map((item, index) => <span key={index}>{item}</span>) : '-'}
                </TableCell>
                <TableCell width='20%' onClick={() => history.push(`/event/${data.id}/dashboard`)}>
                    {data.destination ? data.destination.map((item, index) => <span key={index}>{item}</span>) : '-'}
                </TableCell>
                <TableCell width='15%' maxWidth={!data.setDetails} onClick={() => history.push(`/event/${data.id}/dashboard`)}>
                    {data.rsvpStarted ? 'RSVP' : data.setDetails ? data.status : `Finalize date and location on event dashboard`}
                </TableCell>
                <TableCell width='15%' onClick={() => history.push(`/event/${data.id}/dashboard`)}>
                    {data.rsvpStarted || data.setDetails ? data.confirmedGuests : 'Collecting Dates and Location preferences'}
                </TableCell>
         
                <RowFooter>{createActions({ id: data.id }, showCancelModal, showReminderModal)}</RowFooter>
            </Table>
        );
    })
};

const EmptyData = () => {
    return <Skeleton active />
}

const ResponsiveTable = ({ action }) => {
  return (
      <EventsContext.Consumer>
          {({
                planned,
                displayName,
                showCancel,
                showCancelModal,
                hideCancelModal,
                cancelEventId,
                showReminderModal,
                hideReminderModal,
                reminderEventId,
                showReminder
            }) => {
              return (
                  <div>
                      <TableWrapper>
                          <SearchBar>
                              <InTypography.InH1 dangerouslySetInnerHTML={createMarkup(displayName)} />
                              <SearchInput />
                          </SearchBar>
                          <Table header>
                              <HeaderCell width='20%'>EVENT NAME</HeaderCell>
                              <HeaderCell width='20%'>DATE</HeaderCell>
                              <HeaderCell width='20%'>DESTINATION(S)</HeaderCell>
                              <HeaderCell width='15%'>EVENT STATUS</HeaderCell>
                              <HeaderCell width='15%'>RSVPs</HeaderCell>
                              <HeaderFooter />
                          </Table>
                          {planned && planned.length
                              ? <div>
                                  <RenderRows tableData={planned} showCancelModal={showCancelModal} showReminderModal={showReminderModal} />
                                  <TableImage image={travelArt} />
                              </div>
                              : planned
                              ? <EmptyTable image={noEvents} text="You don't have any events yet, create one" action={action}/>
                              : <EmptyData />}
                      </TableWrapper>
                      <CancelPopup
                            visible={showCancel}
                            onClose={hideCancelModal}
                            cancelEventId={cancelEventId}
                        />
                        <EventReminder
                            visible={showReminder}
                            onClose={hideReminderModal}
                            eventId={reminderEventId}
                        />
                  </div>
              )
          }}
      </EventsContext.Consumer>
  );
};

ResponsiveTable.defaultProps = {

};

ResponsiveTable.propTypes = {

};

export default ResponsiveTable;