import React, { useState } from "react";
import { connect } from "react-redux";
import styled, { keyframes } from "styled-components";
import { Row, Col, Skeleton } from "antd";
import { HeartSvg, HeartOutlinedSvg } from "../../../assets/icons/heart";
import { useDispatch } from "react-redux";
import { InButton, PollTimer } from "../../../components";
import { polls } from "../../../helpers/firestore";
import { PlusOutlined, BellOutlined, DeleteOutlined } from "@ant-design/icons";
import Modal from "./closeCustomPollModal";

export const Container = styled(Row)`
  padding: 0 1.25em;
`;

export const Footer = styled(Col)`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;

  h2 {
    font-family: "Clearface Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 34px;
    color: #101b43;
    padding: 0 15px;
    margin: 0;
  }

  h3 {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 120%;
    color: #2e2c9a;
    padding: 0;
    margin: 0;
  }
`;

export const Header = styled(Col)`
  height: 60px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
  ${(props) => props.theme.breakpoints.maxTablet} {
    display: block;
    height: auto;
  }

  h2 {
    font-family: "Clearface Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 34px;
    color: #101b43;
    padding: 0 15px;
    margin: 0;
    ${(props) => props.theme.breakpoints.maxTablet} {
      padding: 15px;
    }
  }

  h3 {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 120%;
    color: #2e2c9a;
    padding: 0;
    margin: 0;
  }
`;

export const CardContent = styled(Row)`
  /* padding: 1em; */
  flex-wrap: wrap;
`;

export const CardHeader = styled(Row)`
  padding: 1em;
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;

  h5 {
    padding: 0;
    margin: 0;
    font-family: "Proxima Nova Semibold";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 100%;
    color: #101b43;
    flex: 0 0 auto;
  }

  ${(props) => props.theme.breakpoints.maxTablet} {
    margin-top: 15px;
    margin-bottom: 15px;
    align-items: flex-start;
    h5 {
      padding-top: 15px;
    }
  }
`;

const ActionInner = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CityCard = styled.div`
  /* height: 254px; */
  background: #fafafc;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

export const Content = styled.div`
  padding: 15px;
  h5 {
    margin-bottom: 15px;
    padding: 0;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: #101b43;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

export const SvgContainer = styled.div`
  margin-top: 1em;
  display: flex;
  align-items: center;
  svg {
    font-size: 1em;
    margin: 0;
    padding: 0;
    width: 28px;
  }
  span {
    font-weight: 600;
    text-transform: uppercase;
    color: #000;
    margin-left: 5px;
  }
`;

export const Price = styled.div`
  color: #4f4db8;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
`;

export const Note = styled.div`
  font-size: 16px;
`;

export const HeaderImage = styled.div`
  width: 100%;
  height: 150px;
  background: transparent url(${(props) => props.src}) no-repeat center;
  background-size: cover;
  border-radius: 8px 8px 0 0;
  padding: 12px;
  cursor: pointer;
`;

export const VotesWrapper = styled.div`
  width: 40px;
  height: 40px;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const VotesContainer = styled.div`
  margin: 0;
  position: relative;
  width: 28px;
  height: 25px;

  svg {
    font-size: 1.8em;
    margin: 0;
    padding: 0;
  }

  span {
    position: absolute;
    width: 28px;
    height: 25px;
    left: 0;
    top: 3px;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    text-align: center;
    color: #ffffff;
  }
`;

const ring = keyframes`
0% { transform: rotate(0); }
  1% { transform: rotate(30deg); }
  3% { transform: rotate(-28deg); }
  5% { transform: rotate(34deg); }
  7% { transform: rotate(-32deg); }
  9% { transform: rotate(30deg); }
  11% { transform: rotate(-28deg); }
  13% { transform: rotate(26deg); }
  15% { transform: rotate(-24deg); }
  17% { transform: rotate(22deg); }
  19% { transform: rotate(-20deg); }
  21% { transform: rotate(18deg); }
  23% { transform: rotate(-16deg); }
  25% { transform: rotate(14deg); }
  27% { transform: rotate(-12deg); }
  29% { transform: rotate(10deg); }
  31% { transform: rotate(-8deg); }
  33% { transform: rotate(6deg); }
  35% { transform: rotate(-4deg); }
  37% { transform: rotate(2deg); }
  39% { transform: rotate(-1deg); }
  41% { transform: rotate(1deg); }

  43% { transform: rotate(0); }
  100% { transform: rotate(0); }
`;

export const IconWrapper = styled.button`
  width: 40px;
  height: 40px;
  background: #ffffff;
  border-radius: 4px;
  border: 2px solid #ededed;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-right: 1em; */
  margin-bottom: 1em;
  ${(props) => props.theme.breakpoints.maxPhone} {
    margin-left: 1em;
    margin-top: 1em;
  }

  svg {
    fill: #4f4db8;
    font-size: 22px;

    &:hover {
      animation: ${ring} 4s 0.7s ease-in-out infinite;
      transform-origin: 50% 4px;
    }
  }
`;

export const IconWrapper2 = styled.button`
  width: 40px;
  height: 40px;
  background: #ffffff;
  border-radius: 4px;
  border: 2px solid #ededed;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-right: 1em; */
  margin-bottom: 1em;
  ${(props) => props.theme.breakpoints.maxPhone} {
    margin-left: 1em;
    margin-top: 1em;
  }

  svg {
    fill: #4f4db8;
    font-size: 22px;
  }
`;

const Column = styled(Col)`
  display: flex;
  flex-direction: column;
`;

const PollsPage = ({ name, recommendationPolls, setActivePoll, event, isGuest, user, eventId }) => {
  const [visible, setVisibility] = useState(null);
  const dispatch = useDispatch();

  const toggleModal = (indexPoll) => {
    setVisibility(indexPoll);
  };

  const finalize = (item, pollData) => {
    polls.closeVoting(event.id, pollData.id, item).then((result) => setVisibility(null));
  };

  const reopenPoll = (pollData) => {
    polls.reopenVoting(event.id, pollData.id);
  };

  const deletePoll = (pollData) => {
    polls.deletePoll(event.id, pollData.id);
  };

  const guestReminder = async (poll, eventId) => {
    polls
      .sendPollReminder(poll, eventId, dispatch)
      .then((res) => {
        if (res === "ok") {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const voteCast = (vote, poll) => {
    const userDetails = {
      uid: user.uid,
      photoURL: user.photoURL,
      name: user.displayName,
    };
    polls.updateVote(userDetails, eventId, vote, poll);
  };

  if (!recommendationPolls.length)
    return (
      <Container>
        <Skeleton active />
      </Container>
    );
  return (
    <div>
      <Row>
        <Header xs={24}>
          <h2>{name}</h2>
        </Header>
      </Row>

      {recommendationPolls.map((poll, index) => {
        return (
          <>
            <CardHeader gutter={16} key={index}>
              <Header xs={24}>
                <h3>{poll.name}</h3>
                {poll.finalizedPoll && poll.finalizedPoll.length ? (
                  <ActionContainer>
                    {/* <InButton
                      width="160px"
                      type="button"
                      onClick={() => reopenPoll(poll)}
                      text="REOPEN POLL"
                    /> */}
                  </ActionContainer>
                ) : (
                  <ActionContainer>
                    <h5>POLL CLOSES:</h5>
                    <ActionInner>
                      <PollTimer closeDate={poll.countdown} />
                      <IconWrapper type="button" onClick={() => guestReminder(poll, event.id)}>
                        <BellOutlined size="xtra-large" />
                      </IconWrapper>
                      <IconWrapper2 type="button" onClick={() => deletePoll(poll)} style={{ marginLeft: "13px" }}>
                        <DeleteOutlined size="xtra-large" />
                      </IconWrapper2>
                      <InButton
                        width="160px"
                        type="button"
                        onClick={() => toggleModal(index)}
                        text="CLOSE VOTING"
                        primary
                        style={{ marginLeft: "13px" }}
                      />
                    </ActionInner>
                  </ActionContainer>
                )}
              </Header>
            </CardHeader>
            <CardContent gutter={16}>
              {poll && poll.finalizedPoll && poll.finalizedPoll.length
                ? poll.finalizedPoll.map((item, index) => {
                    return (
                      <Column xs={24} sm={8} key={index}>
                        <CityCard>
                          <HeaderImage
                            src={item.image}
                            onClick={() =>
                              item.url
                                ? window.open(
                                    item.url.startsWith("http://") || item.url.startsWith("https://")
                                      ? item.url
                                      : `https://${item.url}`,
                                    "_blank"
                                  )
                                : {}
                            }
                          />
                          <Content>
                            <h5>{item.name}</h5>
                            <div>
                              <Note>{item.note}</Note>
                              <Price>{item.price}</Price>
                              <SvgContainer>
                                <HeartSvg />
                                <span>{item && item.votes ? item.votes.length : 0} votes</span>
                              </SvgContainer>
                            </div>
                          </Content>
                        </CityCard>
                      </Column>
                    );
                  })
                : poll &&
                  poll.poll &&
                  poll.poll.map((item, index) => {
                    const didVote =
                      item && item.votes && item.votes.length
                        ? item.votes.some((voter) => voter.uid === user.uid)
                        : false;
                    return (
                      <Column xs={24} sm={8} key={index}>
                        <CityCard>
                          <HeaderImage
                            src={item.image}
                            onClick={() =>
                              item.url
                                ? window.open(
                                    item.url.startsWith("http://") || item.url.startsWith("https://")
                                      ? item.url
                                      : `https://${item.url}`,
                                    "_blank"
                                  )
                                : {}
                            }
                          />
                          <Content>
                            <h5>{item.name}</h5>
                            <div>
                              <Note>{item.note}</Note>
                              <Price>{item.price}</Price>
                              {isGuest ? (
                                <SvgContainer onClick={() => voteCast(item, poll)}>
                                  {didVote ? <HeartSvg /> : <HeartOutlinedSvg />}
                                  <span>{item && item.votes ? item.votes.length : 0} votes</span>
                                </SvgContainer>
                              ) : (
                                <SvgContainer>
                                  <HeartSvg />
                                  <span>{item && item.votes ? item.votes.length : 0} votes</span>
                                </SvgContainer>
                              )}
                            </div>
                          </Content>
                        </CityCard>
                      </Column>
                    );
                  })}
              <Modal visible={visible === index} toggleModal={toggleModal} poll={poll} finalize={finalize} />
            </CardContent>
          </>
        );
      })}
      <CardContent>
        <Footer xs={24}>
          <InButton
            icon={<PlusOutlined />}
            width="257px"
            type="button"
            onClick={() => {
              setActivePoll();
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
            text="CREATE ANOTHER POLL"
            primary
          />
        </Footer>
      </CardContent>
    </div>
  );
};

PollsPage.defaultProps = {};

PollsPage.propTypes = {};

export function mapStateToProps(state) {
  return {
    user: state.auth.user.user,
  };
}

export default connect(mapStateToProps)(PollsPage);
