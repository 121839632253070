import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import Login from '../containers/Login';

const ProtectedRoute = ({
	component: Component,
	isAuthenticated,
	isVerifying,
	redirectTo,
	history,
	collapsed,
	setRoute,
	url,
	loginComponent: LoginComponent,
	login,
	...rest
}) => {
	return (
		<Route
			{...rest}
			render={(props) => {
				return isVerifying ? (
					<div />
				) : isAuthenticated ? (
					<Component {...props} history={history} collapsed={collapsed} setRoute={setRoute} url={url} />
				) : !isAuthenticated && login ? (
					<Login name='login' width='227px' primary visible={true} />
				) : (
					<Redirect
						to={{
							pathname: redirectTo,
							state: { from: props.location },
						}}
					/>
				);
			}}
		/>
	);
};

export default withRouter(ProtectedRoute);
