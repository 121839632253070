import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { guests } from "../../helpers/firestore";
export const BudgetContext = React.createContext()

class BudgetProvider extends Component {
	constructor(props) {
		super(props)

		this.state = {
			guests: []
		}
	}

	componentDidMount() {
		this.getData(this.props.eventId)
	}

	setGuests = (guests) => {
		this.setState({ guests })
	};

	getData = (eventId) => {
		guests.get(eventId, this.setGuests)
	}

	render() {
		const { guests } = this.state
		const { children } = this.props

		const providerData = { guests }
		return (
			<BudgetContext.Provider value={providerData}>
				{children}
			</BudgetContext.Provider>
		)
	}
}

BudgetProvider.defaultProps = {
	//<!--  children: PropTypes.object-->
};

BudgetProvider.propTypes = {
	//<!--  children: {}-->
};

function mapStateToProps(state) {
	return {
		//redux data
	}
}

export default connect(mapStateToProps, null)(BudgetProvider)