import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col } from "antd";
import { InTypography } from "../..";
import { Field } from "redux-form";
import { ATextarea } from "../FormComponents";

export const FieldWrapper = styled.div`
  //overflow-y: scroll;
  //height: 100vh;
`;

export const Label = styled.label`
  cursor: pointer;
`;

export const Text = styled.div`
  background: #fafafc;
  max-width: 27.5em;
  width: 100%;
  height: 3.375em;
  font-weight: 700;
  font-size: 16px;
  text-transform: capitalize;
  padding: 1em;
  margin: 0.375em 0;
  display: flex;
  align-items: center;

  background: ${(props) => (props.checked ? "#4F4DB8" : " #FFFFFF")};
  border: 1px solid ${(props) => (props.checked ? "#4F4DB8" : " #EDEDED")};
  border-radius: 4px;

  p {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: ${(props) => (props.checked ? "#FFFFFF" : " #101B43")};
    padding: 0;
    margin: 0;
  }
`;
export const Image = styled.img`
  width: 100%;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 150px;
  background: transparent url(${(props) => props.src}) no-repeat center;
  background-size: cover;
  border-radius: 8px 8px 0 0;
`;

export const Input = styled.input`
  width: 0;
  height: 0;
  visibility: hidden;
  position: absolute;
`;

export const Content = styled.div`
  margin: 0 0 2.5em 0;
  max-width: 555px;
  position: relative;
`;

export const Circle = styled.div`
  margin: 0 0.5em;
  border: 1px solid ${(props) => (props.checked ? "#FFFFFF" : "#4F4DB8")};
  background: ${(props) => (props.checked ? "#4F4DB8" : "#FFFFFF")};
  width: 20.57px;
  height: 20.57px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  span {
    background: white;
    width: 13.33px;
    height: 13.33px;
    border-radius: 50%;
  }
`;

export default (props) => {
	const { field, input, options } = props;
	const [showField, setField] = useState(false);
	const onChange = (value) => () => {
		input.onChange(value);
	};

	const validate = (rule) => (value) => {
		console.log(rule, value);
		if (!rule) {
			return undefined;
		}

		return value && new RegExp(rule).test(value) ? undefined : "Validation Error";
	};

	useEffect(() => {
		if (input.value.value === "Other") {
			setField(true);
		} else {
			setField(false);
		}
	}, [input]);

	return (
		<Content>
			<InTypography.InH1>{props.label}</InTypography.InH1>
			<Row gutter={16} style={{ marginTop: 32 }}>
				{options.map((option, index) => {
					const { value, label } = option;
					return (
						<Col xs={24} key={index}>
							<Label>
								<Text checked={value === input.value.value}>
									<Circle checked={value === input.value.value}>
										<span />
									</Circle>
									<p>{label}</p>
									<Input type="radio" name={input.name} checked={value === input.value} onChange={onChange(option)} />
								</Text>
							</Label>
						</Col>
					);
				})}
			</Row>
			{field && showField && (
				<Field
					name={field.name}
					type={field.type || undefined}
					component={ATextarea}
					placeholder={field.placeholder}
					validate={validate(field.validationRule)}
				/>
			)}
		</Content>
	);
};
