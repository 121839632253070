import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ShowMore from "@vinspee/react-show-more";
import { Button, Space, Row, Col, Checkbox, Menu, Dropdown } from "antd";
import { DeleteOutlined, EllipsisOutlined } from "@ant-design/icons";
import EditIcon from "../../assets/icons/edit";
import DownArrowIcon from "../../assets/icons/downArrow";
import { tasks } from "../../helpers/firestore";
import { formatStart } from "../../helpers/normalizeDate";

const Wrapper = styled(ShowMore)``;

const Column = styled(Col)`
	display: flex;
	@media (max-width: 1024px) {
    padding: 0.5em 0;
  }
	
`;

const BtnWrapper = styled(Button)`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 80%;
  margin: 0 0 1em 0;
  border: none;

  background: #fafafc;
  height: 44px;
  width: 100%;
  border-radius: 8px;
  color: #2e2c9a;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin: 0 1em;
  }

  &:hover {
    background: #e1e1e2;
    color: #2e2c9a;
  }

  &:focus {
    background: #fafafc;
    color: #2e2c9a;
  }
`;

const List = styled.ul`
  list-style: none;
  padding: 0;

  li {
    width: 100%;
    padding: 16px 20px;
    margin: 4px 0;
    background: #fafafc;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #101b43;

    span {
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 100%;
    }
  }
	@media (min-width: 1024px) {
    li {
   	 height: 64px;
		}
  }
`;

const Truncate = styled.span`
	display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
`;

const CheckboxButton = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #4f4db8;
    border-color: #4f4db8;
  }
`;

const RowCustom = styled(Row)`
  .ant-space-item {
    text-decoration: ${(props) => (props.completed ? "line-through" : "none")};
  }
`;

const TaskCheckbox = ({ task }) => {
	const handleChange = (input) => {
		if (task.completed) {
			tasks.updateTaskChecked(task, false);
		} else {
			tasks.updateTaskChecked(task, true);
		}
	};
	return (
		<CheckboxButton
			name={task.id}
			size="large"
			onChange={(e) => handleChange(e)}
			checked={task.completed}
		/>
	);
};

const ActionMenu = styled(Menu)`
  width: 160px;
`;

const ActionItem = styled(Menu.Item)`
  width: 160px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #101b43;

  &:hover {
    color: ${(props) => props.color};
  }
`;

const MobTitle = styled.div`
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: #8696AE;
    padding-right: 1rem;
		width: 150px;
  @media (min-width: 1024px) {
    display: none;
  }
`;
// const MenuAction = ({ id, showCancelModal }) => {
//     return (
//         <ActionMenu>
//             <ActionItem color='#2E2C9A' icon={<EditIcon style={{ marginRight: 6 }} />} >
//                 Edit
//             </ActionItem>
//             <ActionItem color='#2E2C9A' icon={<BellOutlined />} >
//                 Remind
//             </ActionItem>
//             <ActionItem color='#EE4238' icon={<CloseOutlined />} onClick={() => showCancelModal(id)}>
//                 Cancel
//             </ActionItem>
//             <ActionItem color='#EE4238' icon={<DeleteOutlined />}>
//                 Delete
//             </ActionItem>
//         </ActionMenu>
//     )
// };

const MenuAction = ({ id, showCancelModal }) => {
	return (
		<ActionMenu>
			<ActionItem
				color="#2E2C9A"
				icon={<EditIcon style={{ marginRight: 6 }} />}
			>
				Edit
			</ActionItem>
			<ActionItem color="#EE4238" icon={<DeleteOutlined />}>
				Delete
			</ActionItem>
		</ActionMenu>
	);
};

const DropMenu = styled(Dropdown)`
  cursor: pointer;
`;

const createActions = (data, showCancelModal) => {
	return (
		<Space size="middle">
			<DropMenu
				overlay={<MenuAction id={data.id} showCancelModal={showCancelModal} />}
				placement="bottomRight"
			>
				<EllipsisOutlined style={{ fontSize: 28 }} />
			</DropMenu>
		</Space>
	);
};

const TaskListCard = ({ items }) => {
	return (
		<Wrapper items={items} by={3}>
			{({ current, onMore }) => (
				<div>
					<List>
						{current.map((item) => {
							return (
								<li key={item.name}>
									<RowCustom
										align="middle"
										completed={item.completed}
										style={{ width: "100%" }}
									>
										<Column xs={{ order: 0, span: 20 }} sm={1}>
											<TaskCheckbox task={item} />
										</Column>
										<Column xs={24} sm={5} order={2}>
											<MobTitle>TASK</MobTitle>
											<Space>{item.name}</Space>
										</Column>
										<Column xs={24} sm={5} order={2}>
											<MobTitle>DUE</MobTitle>
											<Space>{item.due === "" ? "" : formatStart(item.due)}</Space>
										</Column>
										<Column xs={24} sm={8} order={2}>
											{
												item.description && (
													<>
														<MobTitle>DESCRIPTION</MobTitle>
														<Space>
															<Truncate>{item.description}</Truncate>
														</Space>
													</>
												)
											}

										</Column>
										<Column xs={0} sm={4}></Column>
										<Column xs={{ order: 1, span: 4 }} sm={{ order: 3, span: 1 }}>
											{/* <Space>{createActions(items)}</Space> */}
										</Column>
									</RowCustom>
								</li>
							);
						})}
					</List>
					{onMore && (
						<BtnWrapper
							disabled={!onMore}
							onClick={() => {
								if (!!onMore) onMore();
							}}
							text=""
							htmlType="button"
							primary
						>
							SHOW MORE <DownArrowIcon />
						</BtnWrapper>
					)}
				</div>
			)}
		</Wrapper>
	);
};

TaskListCard.defaultProps = {};

TaskListCard.propTypes = {};

export default TaskListCard;
