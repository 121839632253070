import React from "react";
import styled from "styled-components";
import { Image } from "../../components";
import Header from "../Home/Sections/Header";
import bgLineImage from "../Home/bg_hero_line.png";
import ResetPassword from "./Sections/ResetPassword";

const Wrapper = styled.div`
  padding-bottom: 1em;
`;

const ResetPasswordPage = () => {
  return (
    <Wrapper>
      <ResetPassword />
    </Wrapper>
  );
};

export default ResetPasswordPage;
