import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { createBrowserHistory } from "history";
import createStore from "./redux/store";
import "normalize.css";
import "./index.css";
import breakpoints from "./theme/breakpoints";
import colors from "./theme/colors";
import App from "./app/index";
import { hotjar } from "react-hotjar";
// import ReactGA from "react-ga";
import * as serviceWorker from "./serviceWorker";

const initialState = {};

const { store, rrfProps, persistor } = createStore(initialState, createBrowserHistory);

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // dev code
  console.log("development mode");
} else {
  hotjar.initialize(2910622);

  // Identify the user
  // hotjar.identify('USER_ID', { userProperty: 'value' });

  // const TRACKING_ID = "UA-224984936-1";
  // ReactGA.initialize(TRACKING_ID);
  console.log("production mode");
}

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider
          theme={{
            colors,
            breakpoints,
          }}
        >
          <App />
        </ThemeProvider>
      </PersistGate>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
