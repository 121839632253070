import React, { Component } from 'react';
import styled from "styled-components"
import banner from '../../../../../assets/home/wine.png'

const HeaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
`;

const ImageWrapper = styled.div`
  margin-top: 28px;
  width: 340px;
  // margin-right: 50px;
  z-index: 2;
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
`;

class CarouselComponent extends Component {
  render() {
    return (
      <HeaderWrapper>
            <ImageWrapper>
              <Image src={banner} alt='' />
            </ImageWrapper>
      </HeaderWrapper>
    );
  }
}

export default CarouselComponent
