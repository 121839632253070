import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import styled from "styled-components";
import GuestAddForm from "./GuestAddForm";

const Wrapper = styled.div`
  max-width: 670px;
`;

const GuestList = ({
  countryCode,
  initForm,
  handleCancel,
  disabled,
  guestList,
}) => {
  const inputElement = React.createRef();
  const [guests, setGuests] = useState([]);

  const [currentItem, setCurrentItem] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    key: "",
  });
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    mobileNumber: false,
  });

  useEffect(() => {
    if (guestList && guestList.length) {
      setGuests(guestList);
    } else {
      setGuests([]);
    }
  }, [guestList, initForm]);

  // const deleteItem = key => {
  //     const filteredItems = guests.filter((item, index) => {
  //         return index !== key
  //     })
  //     setGuests(filteredItems)
  // }

  const handleName = (e) => {
    const name = e.target.value;
    console.log("name:: ", name);
    // if(name){
    setCurrentItem({
      ...currentItem,
      name: name,
    });
    // }
  };

  const handleMobileNumber = (number) => {
    if (number) {
      setCurrentItem({
        ...currentItem,
        mobileNumber: number,
      });
    }
  };

  const handleEmail = (e) => {
    const email = e.target.value;
    setCurrentItem({
      ...currentItem,
      email: email,
    });
  };

  const addGuest = (newGuest) => {
    if (
      newGuest.name !== "" &&
      newGuest.email !== "" &&
      newGuest.mobileNumber !== ""
    ) {
      newGuest.key = uuidv4();
      const guestList = [...guests, newGuest];

      initForm({ guestList: guestList });
      // setGuests(guestList)
      setCurrentItem({
        name: "",
        email: "",
        mobileNumber: "",
        key: "",
      });
    } else {
      setErrors({
        name: newGuest.name === "",
        email: newGuest.email === "",
        mobileNumber: newGuest.mobileNumber === "",
      });
    }
  };

  const addGuestAndComplete = (newGuest) => {
    if (
      newGuest.name !== "" &&
      newGuest.email !== "" &&
      newGuest.mobileNumber !== ""
    ) {
      newGuest.key = uuidv4();
      const guestList = [...guests, newGuest];

      initForm({ guestList: guestList });
      // setGuests([])
      setCurrentItem({
        name: "",
        email: "",
        mobileNumber: "",
        key: "",
      });
      handleCancel();
    } else {
      setErrors({
        name: newGuest.name === "",
        email: newGuest.email === "",
        mobileNumber: newGuest.mobileNumber === "",
      });
    }
  };

  return (
    <Wrapper>
      <GuestAddForm
        addItem={addGuest}
        done={addGuestAndComplete}
        inputElement={inputElement}
        handleName={handleName}
        handleEmail={handleEmail}
        handleMobileNumber={handleMobileNumber}
        currentItem={currentItem}
        countryCode={countryCode}
        disabled={setCurrentItem.name === ""}
        errors={errors}
      />
    </Wrapper>
  );
};

GuestList.defaultProps = {};

GuestList.propTypes = {};

export default GuestList;
