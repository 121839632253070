import React from 'react'
import styled from 'styled-components'
import { Row, Col, Skeleton } from 'antd';
import { InTypography, PollTimer } from "../../";
import funkyImage from '../../../assets/console/console_header.svg'
import Timer from './CountdownTimer'

export const Container = styled(Row)`
    padding: 1.25em;
    ${props => props.theme.breakpoints.maxTablet}  {
        img {
            display: none !important;
        }
    } 
`;

export const TextWrapper = styled.div`
    margin: 8px 0;
`;

export const TimerWrapper = styled.div`
    position: absolute;
    bottom: 0;

    border: 1px solid #A9C5F4;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 20px;
    height: 102px;
    // display: flex;
    // align-items: center;
    // flex-direction: column;
    ${props => props.theme.breakpoints.maxTablet}  {
        position: relative;
        height: auto;
      } 
  p { 
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 10px;
        color: #101B43;
    }
`;

const Header = ({ event, displayName, itineraryData }) => {
	if (!event) return <Container><Skeleton active /></Container>


	const startEvent = itineraryData && itineraryData.dates && itineraryData.dates.start

	return (
		<Container>
			<Col sm={12}>
				{!startEvent ? <InTypography.InH1>Hey {displayName},</InTypography.InH1> : <InTypography.InH1>Pack Your Bags!</InTypography.InH1>}
				<TextWrapper>
					<InTypography.InParagraph>
						It's time to celebrate.
					</InTypography.InParagraph>
					<InTypography.InParagraph>
						Let's make {event.bride.map(bach => `${bach.fullname}'s, `)} bachelorette amazing!
					</InTypography.InParagraph>
				</TextWrapper>
				<TimerWrapper>
					{!startEvent && <p>Waiting on dates to be finalized to start countdown</p>}
					<Timer itineraryData={itineraryData} />
				</TimerWrapper>
			</Col>
			<Col sm={12}>
				<img src={funkyImage} alt="" />
			</Col>
		</Container>
	)
}

export default Header