import React, { useState } from "react";
import styled from "styled-components";
import { Drawer, Row, Col } from "antd";
import AddPollOptionForm from "../addPollOptionForm2";
import AddCircle from "../../../../assets/icons/addCircle";
import backIcon from "../../../../assets/icons/backArrow.svg";
import cancelIcon from "../../../../assets/icons/cancel.svg";
import PollItems from "./PollItems";

export const AddButton = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  height: 357px;
  margin-bottom: 1.3125em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  h4 {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 80%;
    padding: 0;
    margin: 2.09375em 0 0 0;
    text-transform: uppercase;
    color: #2e2c9a;
  }
`;

const DrawerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* max-width: 670px; */
  /* margin: 44px auto 27px auto;
	 */
  margin: 0 auto;
  /* padding: 0 15px; */
  h2 {
    font-family: "Clearface Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 34px;
    color: #101b43;
    margin: 0;
    text-transform: uppercase;
    /* margin-left: 15px; */
    /* text-transform: capitalize; */
  }
`;

const DrawerTitleTwo = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  width: 100%;
  /* max-width: 670px; */
  /* margin: 44px auto 27px auto;
	 */
  margin: 0 auto;
  /* padding: 0 15px; */
  h2 {
    font-family: "Clearface Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 34px;
    color: #101b43;
    margin: 0;
    text-transform: uppercase;
    margin-left: 15px;
    /* text-transform: capitalize; */
  }
`;

const IconButton = styled.button`
  padding: 0;
  margin: 0;
  outline: none;
  width: 44px;
  height: 44px;
  color: #494d5b;
  background: #ffffff;
  border: 1px solid #eeeeef;
  box-sizing: border-box;
  border-radius: 4px;
`;

const PollList = ({
	fields,
	meta: { error, submitFailed },
	optionIndex,
	openOption,
	closeOption,
	isEdit,
}) => {
	const openPollOption = () => {
		fields.push({});
		openOption(fields.length);
	};

	return (
		<Row gutter={16}>
			<Col xs={24} md={6}>
				<AddButton type="button" onClick={() => openPollOption()}>
					<AddCircle />
					<h4>Add New Option</h4>
				</AddButton>
			</Col>
			{fields.map((option, index) => (
				<Drawer
					key={index}
					closable={false}
					onClose={closeOption}
					visible={optionIndex === index}
					placement="bottom"
					height="100%"
					destroyOnClose
					title={
						!isEdit ? (
							<DrawerTitle>
								<h2>Add option</h2>
								<IconButton
									type="button"
									onClick={() => {
										fields.remove(index);
										closeOption();
										window.scrollTo({
											top: 0,
											behavior: "smooth"
										});
									}}
								>
									<img src={cancelIcon} alt="" />
								</IconButton>
							</DrawerTitle>
						) : (
							<DrawerTitleTwo>
								<IconButton type="button" onClick={() => closeOption()}>
									<img src={backIcon} alt="" />
								</IconButton>
								<h2>Edit option</h2>
							</DrawerTitleTwo>
						)
					}
				>
					<AddPollOptionForm
						key={index}
						index={index}
						fields={fields}
						option={option}
						closePollOption={closeOption}
						openPollOption={openPollOption}
						isEdit={isEdit}
					/>
				</Drawer>
			))}

			<PollItems openOption={openOption} fields={fields} />
		</Row>
	);
};

export default PollList;
