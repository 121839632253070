import firebase from "firebase";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { db } from "../../config/firebase";
import { COMPLETED_RSVP, DECLINED, PENDING, PENDING_MAYBE } from "../constants";
import { formatStart } from "../normalizeDate";
import { toast } from "../../redux/actions";

const addGuest = async (eventId, guest) => {
  const guestsRef = db.collection(`guests`);
  const promise = new Promise((resolve, reject) => {
    const uid = uuidv4();

    guestsRef
      .doc(uid)
      .set({
        id: uid,
        userExists: false,
        eventId: eventId,
        name: guest.name,
        email: guest.email,
        mobileNumber: guest.mobileNumber,
        state: PENDING,
        isBride: true,
        preferences: null,
        guestAdded: false,
      })
      .then(() => resolve(eventId))
      .catch((err) => reject(err));
  });

  promise.then((res) => console.log(res));

  return promise.then((res) => {
    return res;
  });
};

const deleteGuest = async (eventId, guestId) => {
  // console.log(eventId, guestId);

  const guestsRef = db.collection("events").doc(eventId);

  const promise = new Promise((resolve, reject) => {
    guestsRef
      .collection("guests")
      .doc(guestId)
      .delete()
      .then(() => resolve(guestId))
      .catch((err) => {
        console.log(err);
        return reject(err);
      });
  });

  return promise.then((res) => {
    return res;
  });
};

const getGuests = async (eventId, setData) => {
  const guestRef = db.collection(`events`).doc(eventId);

  const promise = new Promise((resolve, reject) => {
    guestRef
      .collection("guests")
      .where("eventId", "==", eventId)
      .onSnapshot((querySnapshot) => {
        let guests = [];
        querySnapshot.forEach(function (doc) {
          guests.push(doc.data());
        });

        setData(guests);
        resolve(guests);
      });
  });

  return promise.then((values) => {
    return values;
  });
};

function formatDates(date) {
  const start = date.start.seconds;
  const end = date.end.seconds;

  return `${formatStart(start)} - ${formatStart(end)}`;
}

const updateGuestPreferences = (
  eventId,
  userId,
  preferences,
  organizerId,
  history,
  displayName,
  dispatch,
  isSetDetails,
  notAttending,
  userDetails
) => {
  // Set the guest preferences
  console.log(userId);
  const guestRef = db.collection(`events`).doc(eventId).collection("guests").doc(userId);

  const timestamp = moment().unix();

  const activityRef = db.collection(`events`).doc(eventId).collection("activities").doc(`${eventId}-${timestamp}`);

  // Update the confirmed guests
  console.log(eventId);
  const eventRef = db.collection(`events`).doc(eventId);

  let isOrganizer = false;
  // / Create a reference to the SF doc.
  return db
    .runTransaction((transaction) => {
      return transaction.get(eventRef).then(function (eventDoc) {
        if (!eventDoc.exists) {
          throw "Document does not exist!";
        }

        const oldDoc = eventDoc.data();
        const oldDates = oldDoc.dates;
        const oldCity = oldDoc.cities;
        const isOrganizer = oldDoc.organizer.uid === userId;

        if (
          (preferences && preferences.attendance && preferences.attendance.attendance.value === "NO") ||
          (!isSetDetails && notAttending)
        ) {
          console.log("!!!!!!!!!!!!");
          const confirmedGuests = oldDoc.confirmedGuests.filter((guestId) => guestId !== userId);
          console.log("remove confirmed guest");
          // remove confirmed guest
          transaction.update(eventRef, {
            confirmedGuests: confirmedGuests,
          });

          transaction.update(guestRef, {
            preferences: null,
            state: DECLINED,
            userExists: true,
          });
        } else if (isSetDetails) {
          transaction.update(eventRef, {
            confirmedGuests: firebase.firestore.FieldValue.arrayUnion(userId),
            "timeline.viewRsvp": false,
          });

          preferences.city =
            userDetails && userDetails.preferences && userDetails.preferences.city ? userDetails.preferences.city : [];
          preferences.dates =
            userDetails && userDetails.preferences && userDetails.preferences.dates
              ? userDetails.preferences.dates
              : preferences.dates.length
              ? preferences.dates
              : [];

          transaction.update(guestRef, {
            detailsSet: true,
            preferences,
            state:
              preferences && preferences.attendance && preferences.attendance.attendance.value === "MAYBE"
                ? PENDING_MAYBE
                : COMPLETED_RSVP,
            userExists: true,
          });
        } else {
          const newDates = oldDates.map((oldDate) => {
            const selectedDates = formatDates(oldDate);
            if (preferences.dates.some((someDate) => someDate.value === selectedDates)) {
              return {
                start: oldDate.start,
                end: oldDate.end,
                votes:
                  oldDate && oldDate.voterIds && oldDate.voterIds.some((id) => id !== userId)
                    ? oldDate.votes + 1
                    : oldDate && oldDate.voterIds && oldDate.voterIds.some((id) => id === userId)
                    ? oldDate.votes
                    : 1,
                voterIds:
                  oldDate && oldDate.voterIds && oldDate.voterIds.some((id) => id !== userId)
                    ? [...oldDate.voterIds, userId]
                    : oldDate && oldDate.voterIds && oldDate.voterIds.some((id) => id === userId)
                    ? oldDate.voterIds
                    : [userId],
              };
            }

            return {
              start: oldDate.start,
              end: oldDate.end,
              votes:
                oldDate && oldDate.voterIds && oldDate.voterIds.some((id) => id === userId)
                  ? oldDate.votes - 1
                  : oldDate && oldDate.voterIds && oldDate.voterIds.some((id) => id !== userId)
                  ? oldDate.votes
                  : 0,
              voterIds:
                oldDate && oldDate.voterIds && oldDate.voterIds.some((id) => id === userId)
                  ? oldDate.voterIds.filter((voterId) => voterId !== userId)
                  : oldDate && oldDate.voterIds && oldDate.voterIds.some((id) => id !== userId)
                  ? oldDate.voterIds
                  : [],
            };
          });
          const newCities = oldCity.map((oldCity) => {
            if (preferences.city.some((city) => city.value === oldCity.name)) {
              return {
                imageUrl: oldCity.imageUrl,
                key: oldCity.name,
                name: oldCity.name,
                votes:
                  oldCity && oldCity.voterIds && oldCity.voterIds.some((id) => id !== userId)
                    ? oldCity.votes + 1
                    : oldCity && oldCity.voterIds && oldCity.voterIds.some((id) => id === userId)
                    ? oldCity.votes
                    : 1,
                voterIds:
                  oldCity && oldCity.voterIds && oldCity.voterIds.some((id) => id !== userId)
                    ? [...oldCity.voterIds, userId]
                    : oldCity && oldCity.voterIds && oldCity.voterIds.some((id) => id === userId)
                    ? oldCity.voterIds
                    : [userId],
              };
            }

            return {
              imageUrl: oldCity.imageUrl,
              key: oldCity.name,
              name: oldCity.name,
              votes:
                oldCity && oldCity.voterIds && oldCity.voterIds.some((id) => id === userId)
                  ? oldCity.votes - 1
                  : oldCity && oldCity.voterIds && oldCity.voterIds.some((id) => id !== userId)
                  ? oldCity.votes
                  : 0,
              voterIds:
                oldCity && oldCity.voterIds && oldCity.voterIds.some((id) => id === userId)
                  ? oldCity.voterIds.filter((voterId) => voterId !== userId)
                  : oldCity && oldCity.voterIds && oldCity.voterIds.some((id) => id !== userId)
                  ? oldCity.voterIds
                  : [],
            };
          });

          // console.log(newCities, newDates);
          transaction.update(eventRef, {
            cities: newCities,
            dates: newDates,
            "timeline.viewRsvp": false,
          });

          transaction.update(guestRef, {
            preferences,
            detailsSet: true,
            userExists: true,
          });

          transaction.set(activityRef, {
            id: activityRef.id,
            activity: `${displayName} has selected the dates and city`,
            userId: userId,
            timestamp,
          });
        }
      });
    })
    .then(() => {
      history.push({
        pathname: "/events",
        state: { tab: isOrganizer ? "0" : "1" },
      });
    })
    .catch((err) => {
      // This will be an "population is too big" error.
      console.error(err);
    });
};

const getBride = (eventId, setData) => {
  const guestRef = db.collection(`events`).doc(eventId);

  return guestRef
    .collection("guests")
    .where("isBride", "==", true)
    .onSnapshot((querySnapshot) => {
      let guests = [];
      querySnapshot.forEach(function (doc) {
        guests.push(doc.data());
      });
      setData(guests);
    });
};

const getGuest = (eventId, userId) => {
  const guestRef = db.collection(`events`).doc(eventId);

  return guestRef
    .collection("guests")
    .get(userId)
    .then((doc) => {
      if (doc.exists) {
        return doc.data();
      } else {
        return null;
      }
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
    });
};

const sendGuestReminder = async (eventId, guestId, onClose, dispatch, request = fetch) => {
  const requestObject = {
    data: {
      eventId,
      guestId,
    },
  };

  const postBody = JSON.stringify(requestObject);

  let url;
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    url = `${process.env.REACT_APP_SEND_GUEST_REMINDER_DEV}`;
  } else {
    url = `${process.env.REACT_APP_SEND_GUEST_REMINDER_PROD}`;
  }

  try {
    const response = await request(url, { method: "POST", body: postBody });
    if (response.status === 200) {
      const body = await response;
      dispatch(toast("Reminder sent", "success"));
      onClose();
      return body.body;
    }
  } catch (error) {
    onClose();
    console.log("errors: ", error);
  }
};

const updateGuestSettings = (eventId, userId, userData) => {
  const guestRef = db.collection(`events`).doc(eventId).collection("guests").doc(userId);

  return db
    .runTransaction((transaction) => {
      return transaction.get(guestRef).then(function (guestDoc) {
        if (!guestDoc.exists) {
          throw "Guest does not exist!";
        }
        transaction.update(guestRef, {
          email: userData.email,
          name: userData.name,
          mobileNumber: userData.mobileNumber,
        });
      });
    })
    .then(() => {
      return "ok";
    })
    .catch((err) => {
      // This will be an "population is too big" error.
      console.error(err);
    });
};

const updateGuest = (eventId, userId, userData) => {
  const guestRef = db.collection(`events`).doc(eventId).collection("guests").doc(userId);

  const eventRef = db.collection(`events`).doc(eventId);

  return db
    .runTransaction((transaction) => {
      return transaction.get(guestRef).then(function (guestDoc) {
        if (!guestDoc.exists) {
          throw "Guest does not exist!";
        }
        const oldDoc = guestDoc.data();
        transaction.update(guestRef, {
          email: userData.email,
          name: userData.name,
          mobileNumber: userData.mobileNumber,
          state: userData.rsvp,
        });

        const roommateOld = oldDoc?.preferences?.destination?.roommate;
        const roommateNew = userData.roommates.map((roommate) => ({
          id: roommate,
        }));

        if (!!roommateOld) {
          transaction.update(guestRef, {
            "preferences.destination.roommate": firebase.firestore.FieldValue.arrayRemove(...roommateOld),
          });
        }
        if (roommateNew.length) {
          transaction.update(guestRef, {
            "preferences.destination.roommate": firebase.firestore.FieldValue.arrayUnion(...roommateNew),
          });
        }

        if (userData.rsvp === "declined" || userData.rsvp === "pending") {
          eventRef
            .get()
            .then((doc) => {
              if (doc.exists) {
                const oldEvent = doc.data();
                const confirmedGuests = oldEvent.confirmedGuests.filter((guestId) => guestId !== userId);
                eventRef.update({
                  confirmedGuests: confirmedGuests,
                });
              } else {
                return null;
              }
            })
            .catch(function (error) {
              console.log("Error getting document:", error);
            });
        } else if (userData.rsvp === "completed rsvp") {
          eventRef
            .get()
            .then((doc) => {
              if (doc.exists) {
                const oldEvent = doc.data();
                const confirmedGuests = oldEvent.confirmedGuests.filter((guestId) => guestId === userId);

                if (!confirmedGuests.length) {
                  eventRef.update({
                    confirmedGuests: firebase.firestore.FieldValue.arrayUnion(userId),
                  });
                }
              } else {
                return null;
              }
            })
            .catch(function (error) {
              console.log("Error getting document:", error);
            });
        }
      });
    })
    .then(() => {
      // history.push({
      // 	pathname: "/events",
      // 	state: { tab: isOrganizer ? "0" : "1" },
      // });
    })
    .catch((err) => {
      // This will be an "population is too big" error.
      console.error(err);
    });
};

export default {
  add: addGuest,
  get: getGuests,
  updatePreferences: updateGuestPreferences,
  getBride: getBride,
  getGuest: getGuest,
  delete: deleteGuest,
  sendGuestReminder: sendGuestReminder,
  updateGuest: updateGuest,
  updateGuestSettings,
};
