import React from 'react';
import styled from "styled-components";
import {Menu} from "antd";

const Wrapper = styled.svg`
    fill: #494D5B;
     stroke: #494D5B;
    
    &:hover {
      fill: #2E2C9A;
     stroke: #2E2C9A;
    }
`

export default ({ style }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M11.5956 4.50008C11.5823 4.34417 11.4497 4.2247 11.2917 4.22623H8.98203V3.35932C8.98203 1.94624 7.82541 0.800781 6.39885 0.800781C4.97217 0.800781 3.81567 1.94624 3.81567 3.35932V4.22623H1.50601C1.34871 4.22682 1.21742 4.34511 1.2021 4.50008L0.405901 13.5664C0.369694 13.9868 0.513098 14.403 0.80145 14.7138C1.08968 15.0244 1.49627 15.2011 1.92233 15.2008H10.8783C11.3044 15.2011 11.711 15.0244 11.9992 14.7138C12.2876 14.403 12.4311 13.9868 12.3949 13.5664L11.5956 4.50008ZM4.42348 3.35932C4.42348 2.27876 5.30788 1.40279 6.39885 1.40279C7.48981 1.40279 8.37422 2.27876 8.37422 3.35932V4.22623H4.42348V3.35932ZM11.55 14.3068C11.3781 14.4942 11.1339 14.6004 10.8783 14.5988H1.91924C1.66366 14.5989 1.41982 14.4927 1.24698 14.3062C1.07425 14.1196 0.988422 13.8698 1.01062 13.6176L1.78249 4.82824H3.81567V6.70646C3.32693 6.85544 3.02172 7.33598 3.09782 7.83687C3.17379 8.33765 3.60828 8.70802 4.11957 8.70802C4.63087 8.70802 5.06524 8.33765 5.14133 7.83687C5.21731 7.33598 4.9121 6.85544 4.42348 6.70646V4.82519H8.37422V6.70646C7.88548 6.85544 7.58027 7.33598 7.65637 7.83687C7.73234 8.33765 8.16683 8.70802 8.67812 8.70802C9.18942 8.70802 9.62379 8.33765 9.69988 7.83687C9.77586 7.33598 9.47065 6.85544 8.98203 6.70646V4.82519H11.0121L11.784 13.6145C11.8089 13.8678 11.7238 14.1195 11.55 14.3068ZM4.07399 7.26638C4.10426 7.27037 4.13489 7.27037 4.16516 7.26638C4.38988 7.29036 4.55655 7.48437 4.54445 7.70789C4.53222 7.93153 4.3456 8.10672 4.11957 8.10672C3.89343 8.10672 3.70681 7.93153 3.6947 7.70789C3.6826 7.48437 3.84915 7.29036 4.07399 7.26638ZM8.63254 7.26638C8.66281 7.27037 8.69344 7.27037 8.72371 7.26638C8.94843 7.29036 9.1151 7.48437 9.10299 7.70789C9.09077 7.93153 8.90415 8.10672 8.67812 8.10672C8.45198 8.10672 8.26536 7.93153 8.25325 7.70789C8.24114 7.48437 8.4077 7.29036 8.63254 7.26638Z" strokeWidth="0.2"/>
    </svg>
);
