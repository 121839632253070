import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Row, Col, Button } from "antd";

export const Header = styled(Col)`
  height: 60px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;

  h2 {
    font-family: "Clearface Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 34px;
    color: #101b43;
    padding: 0 1.25em;
    margin: 0;
  }
`;

export const CardContent = styled(Row)`
  padding: 1.25em;
`;

export const CityCard = styled.div`
  height: 254px;
  background: #fafafc;
  border-radius: 8px;
  padding: 20px;
`;

export const HeaderImage = styled.div`
  width: 100%;
  height: 150px;
  background: transparent url(${(props) => props.src}) no-repeat center;
  background-size: cover;
`;

export const Image = styled.div`
  width: 100%;
  height: 292px;
  background: transparent url(${(props) => props.src}) no-repeat center;
  background-size: cover;
  border-radius: 8px;
  padding: 1em 1.25em 40px 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${(props) => props.theme.breakpoints.maxPhone} {
    padding: 50px 1em 1em 1em;
  }

  &:after {
    background-color: #000000;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.25;
    border-radius: 8px;
  }

  span {
    z-index: 2;
  }

  h4 {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: #ffffff;
    z-index: 2;
  }

  h1 {
    font-family: "Clearface Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 140%;
    color: #ffffff;
    z-index: 2;
  }
`;

export const BtnContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 2;
`;

const InButton = styled(Button)`
  background: transparent;
  border-color: #ffffff;
  width: ${(props) => (props.width ? props.width : "172px")};
  height: 44px;
  border-radius: 4px;
  color: #ffffff;
  text-transform: uppercase;

  &:hover {
    background: ${(props) => (props.primary ? "#2E2C9A" : "#ffffff")};
    border-color: #2e2c9a;
    color: ${(props) => (props.primary ? "#ffffff" : "#2E2C9A")};
  }

  &:focus {
    background: ${(props) => (props.primary ? "#2E2C9A" : "#ffffff")};
    border-color: #2e2c9a;
    color: ${(props) => (props.primary ? "#ffffff" : "#2E2C9A")};
  }
`;

const ReopenDestinations = ({ name, reopenDestination, imageUrl, guest }) => {
  return (
    <Row>
      <Col xs={24}>
        <Image src={imageUrl} alt="">
          {/* {!guest &&
						<BtnContainer>
							<InButton
								width='170px'
								type='button'
								onClick={() => reopenDestination()}
							>
								Reopen poll
							</InButton>
						</BtnContainer>
					} */}

          <span>
            <h4>You are going to</h4>
            <h1>{name}</h1>
          </span>
        </Image>
      </Col>
    </Row>
  );
};

ReopenDestinations.defaultProps = {};

ReopenDestinations.propTypes = {};

export default ReopenDestinations;
