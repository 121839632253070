import React, { Component } from "react";
import { connect } from "react-redux";
import { logoutUser } from "../../helpers/auth";
import { guests, events } from "../../helpers/firestore";
export const SettingsContext = React.createContext();

class SettingsProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      event: null,
      guests: [],
      mobileNumber: null,
      guestName: null,
      isGuest: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  setGuests = (guests) => {
    this.setState({ guests });
  };

  getData = async () => {
    const { eventId, userUid } = this.props;
    if (!eventId) return;
    const event = await events.getEvent(eventId);
    const isOnGuestList = event.guestList.includes(userUid);
    if (isOnGuestList) {
      const guestList = await guests.get(eventId, this.setGuests);
      const { mobileNumber, name } = guestList.filter((guest) => guest.id === userUid)[0];

      this.setState({ mobileNumber, guestName: name, isGuest: isOnGuestList });
    }
  };

  logout = () => {
    logoutUser(this.props.dispatch);
    window.location.pathname = "/login";
  };

  render() {
    const { mobileNumber, guestName, isGuest } = this.state;
    const { children, user } = this.props;

    const providerData = {
      mobileNumber,
      guestName,
      user,
      isGuest,
      logout: this.logout,
    };
    return <SettingsContext.Provider value={providerData}>{children}</SettingsContext.Provider>;
  }
}

SettingsProvider.defaultProps = {
  //<!--  children: PropTypes.object-->
};

SettingsProvider.propTypes = {
  //<!--  children: {}-->
};

function mapStateToProps(state) {
  return {
    user: state?.auth?.user?.user,
    userUid: state?.auth?.user?.user?.uid,
  };
}

export default connect(mapStateToProps, null)(SettingsProvider);
