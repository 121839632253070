import React, { Component } from 'react';
import styled from "styled-components"
import { DefaultBtn } from '../../../../../components/InButton/Buttons'
import { HeadingOne, HeadingTwo } from '../../../../../components/Text'
import hashIcon from '../../../../../assets/home/hashIcon.svg'
import superStar from '../../../../../assets/home/superstar-element.png'

const HeaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: relative;
`;

const TitleWrapper = styled.div`
  width: 100%;
  
  h1 {
    flex-wrap: nowrap;
  }
`;

const ParagraphWrapper = styled.div`

`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

const Heading = styled.h3`
  font-family: 'Proxima Nova Semibold';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 130%;
  color: #101B43;
`;

const SideElement = styled.div`
  position: absolute;
  right: -100px;

  @media (min-width: 768px) {
    right: -20px;
  }
`;

const BraekMobile = styled.br`
  display: none;
  @media (max-width: 520px) {
    display: block;
  }
`;

class CarouselComponent extends Component {
  render() {
    return (
      <HeaderWrapper>
        <TitleWrapper>
          <HeadingOne>
            Be A Planning <BraekMobile /><span><img src={hashIcon} alt=""/></span> SUPERSTAR
          </HeadingOne>
        </TitleWrapper>
        <ParagraphWrapper>
          <Heading>
            For you, from us, with love.
          </Heading>
        </ParagraphWrapper>
        <ButtonContainer>
          <DefaultBtn primary>Sign Up Now</DefaultBtn>
        </ButtonContainer>
        <SideElement>
          <img src={superStar} />
        </SideElement>
      </HeaderWrapper>
    );
  }
}

export default CarouselComponent
