import { LOCATION_DETAILS } from './constants'

const initialState = {
  data: null,
}

export default function location (
  /* istanbul ignore next */ state = initialState,
  action
) {
  switch (action.type) {
    case LOCATION_DETAILS:
      return { ...state, data: action.payload };
    default:
      return state
  }
}
