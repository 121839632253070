import React from 'react';
import styled from "styled-components";
import {Menu} from "antd";

const Wrapper = styled.svg`
    fill: #494D5B;
     stroke: #494D5B;
    
    &:hover {
      fill: #2E2C9A;
     stroke: #2E2C9A;
    }
`

export default ({ style }) => (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.15" cx="40" cy="40" r="40" fill="#FF8E95"/>
        <rect x="38.998" y="23" width="2" height="34" rx="1" fill="#FF8E95"/>
        <rect x="57" y="39" width="2" height="34" rx="1" transform="rotate(90 57 39)" fill="#FF8E95"/>
    </svg>

);
