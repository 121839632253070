import React from 'react';
import styled from "styled-components";
import {Menu} from "antd";

const Wrapper = styled.svg`
    fill: #494D5B;
     stroke: #494D5B;
    
    &:hover {
      fill: #2E2C9A;
     stroke: #2E2C9A;
    }
`

export default ({ style }) => (
    <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.990895 12.8864C0.554117 12.4527 0.55287 11.7467 0.988113 11.3114L5.29922 7.00033L0.988112 2.68922C0.552869 2.25398 0.554117 1.54793 0.990895 1.11422C1.42549 0.682685 2.12726 0.683925 2.56033 1.117L8.30224 6.8589C8.38035 6.93701 8.38035 7.06364 8.30224 7.14175L2.56033 12.8837C2.12726 13.3167 1.4255 13.318 0.990895 12.8864Z" />
    </svg>
);
