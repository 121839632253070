import React from 'react'
import styled from 'styled-components'
import { Col, Row } from "antd";
import { ChatsTab } from './ChatLists'
import { ChatThread } from './ChatThread'

const Wrapper = styled.div`
min-height: 90vh;
padding: 20px 54px 0 6px;
`

const ChatList = styled.div`
  background: #FFFFFF;
border-radius: 8px;
min-height: 80vh;
`

const MainChat = styled.div`
  background: #FFFFFF;
border-radius: 8px;
min-height: 80vh;
`

const ChatComponent = ({ chatThread, activeGroup, setActiveGroupChat, pathUrl }) => {
	return (
		<Wrapper>
			<Row gutter={16}>
				<Col className="gutter-row" xs={24} md={8}>
					<ChatList>
						<ChatsTab activeGroup={activeGroup} setActiveGroupChat={setActiveGroupChat} />
					</ChatList>
				</Col>
				<Col className="gutter-row" xs={24} md={16}>
					<MainChat>
						{activeGroup && <ChatThread pathUrl={pathUrl} activeGroup={activeGroup} chatThread={chatThread} />}
					</MainChat>
				</Col>
			</Row>

		</Wrapper>
	);
};

ChatComponent.defaultProps = {

};

ChatComponent.propTypes = {

};

export default ChatComponent;
