import React from 'react';
import styled from "styled-components";
import {Menu} from "antd";

const Wrapper = styled.svg`
    fill: #494D5B;
     stroke: #494D5B;
    
    &:hover {
      fill: #2E2C9A;
     stroke: #2E2C9A;
    }
`

export default ({ style }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8C15.5 12.1421 12.1421 15.5 8 15.5H0.761905C0.617259 15.5 0.5 15.3827 0.5 15.2381V8Z"/>
        <path d="M7.45898 10.3443C7.32614 10.4056 7.16603 10.3838 7.07405 10.27C6.86972 10.0173 6.76755 9.72204 6.76755 9.38428C6.76755 9.12227 6.82002 8.8821 6.92494 8.66376C7.03793 8.44542 7.17514 8.26638 7.33656 8.12664C7.49798 7.9869 7.67151 7.8428 7.85714 7.69432C8.05085 7.53712 8.22841 7.39301 8.38983 7.26201C8.55125 7.131 8.68442 6.9738 8.78935 6.79039C8.90234 6.60699 8.95884 6.40611 8.95884 6.18777C8.95884 5.83843 8.83374 5.55022 8.58353 5.32314C8.3414 5.08734 7.99031 4.96943 7.53027 4.96943C6.92992 4.96943 6.41262 5.18 5.97839 5.60113C5.76774 5.80541 5.4193 5.82627 5.22852 5.60331C5.09431 5.44646 5.08214 5.21596 5.21756 5.06016C5.83185 4.35339 6.63503 4 7.62712 4C8.34544 4 8.91848 4.19651 9.34625 4.58952C9.78208 4.9738 10 5.45415 10 6.03057C10 6.47598 9.8749 6.87336 9.6247 7.22271C9.38257 7.56332 9.11622 7.82533 8.82567 8.00873C8.53511 8.19214 8.26473 8.41048 8.01453 8.66376C7.7724 8.91703 7.65133 9.18341 7.65133 9.46288C7.65133 9.52204 7.65771 9.57941 7.67046 9.63499C7.72997 9.89434 7.70061 10.2328 7.45898 10.3443ZM7.954 12.7904C7.82486 12.9301 7.67151 13 7.49395 13C7.31638 13 7.159 12.9301 7.02179 12.7904C6.89266 12.6507 6.82809 12.4804 6.82809 12.2795C6.82809 12.0786 6.89266 11.9083 7.02179 11.7686C7.159 11.6288 7.31638 11.559 7.49395 11.559C7.67151 11.559 7.82486 11.6288 7.954 11.7686C8.0912 11.9083 8.15981 12.0786 8.15981 12.2795C8.15981 12.4804 8.0912 12.6507 7.954 12.7904Z"/>
    </svg>
);
