import React from "react";
import styled from "styled-components";
import { Button, Space } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

export const BtnContainer = styled.div`
  position: ${(props) => (props.float ? "relative" : "absolute")};
  display: flex;
  margin-bottom: 5em;
  /* justify-content: space-between; */
  button+button {
	  margin-left: 10px;
  }
`;

const WrapperButton = styled(Button)`
  background: ${(props) => (props.primary ? "#4F4DB8" : "#ffffff")};
  border-color: #4f4db8;
  width: ${(props) => (props.width ? props.width : "129px")};
  height: 44px;
  border-radius: 4px;
  color: ${(props) => (props.primary ? "#ffffff" : "#2E2C9A")};

  ${props => props.theme.breakpoints.maxPhone} {
  	width: 100%;
  }

  &:hover {
    background: ${(props) => (props.primary ? "#2E2C9A" : "#ffffff")};
    border-color: #2e2c9a;
    color: ${(props) => (props.primary ? "#ffffff" : "#2E2C9A")};
  }

  &:focus {
    background: ${(props) => (props.primary ? "#2E2C9A" : "#ffffff")};
    border-color: #2e2c9a;
    color: ${(props) => (props.primary ? "#ffffff" : "#2E2C9A")};
  }
`;

export const Buttons = ({
	back,
	next,
	float,
	nextText,
	continuePage,
	attendance,
	goToSlide,
	numberOfSteps,
	submit,
	disabled,
}) => {
	const [loading, setloading] = React.useState(false);

	return (
		<BtnContainer float={float}>
			{/* <Space size="large"> */}
			{!continuePage && (
				<WrapperButton
					type="default"
					icon={<LeftOutlined style={{ fontSize: 12 }} />}
					disabled={false}
					onClick={() => back()}
				>
					BACK
				</WrapperButton>
			)}
			{attendance || attendance === undefined ? (
				<WrapperButton
					type="default"
					htmlType={submit ? "submit" : "button"}
					disabled={disabled}
					onClick={() => (submit ? setloading(!loading) : next())}
					loading={loading}
					primary
				>
					{nextText ? nextText : "NEXT"} {submit ? "" : <RightOutlined style={{ fontSize: 12 }} />}
				</WrapperButton>
			) : (
				<WrapperButton type="default" disabled={false} onClick={() => goToSlide(numberOfSteps - 1)} primary>
					{nextText ? nextText : "NEXT"} <RightOutlined style={{ fontSize: 12 }} />
				</WrapperButton>
			)}
			{/* </Space> */}
		</BtnContainer>
	);
};
