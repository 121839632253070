import React from 'react'
import styled from 'styled-components'
import { Drawer, Row, Col, Skeleton } from 'antd';
import backIcon from "../../../assets/icons/backArrow.svg";
import cancelIcon from "../../../assets/icons/cancel.svg";

export const Container = styled(Row)`
    padding:  0 1.25em;
`;

const Title = styled.div`
  background: #ffffff;
    h3 {
      font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 120%;
        color: #101B43;
        padding: 0;
        margin: 1em 0 0 0;
    }
`

export const Header = styled(Col)`
    height: 60px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
    border-radius: 8px 8px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
        
    
    h2 {
        font-family: 'Clearface Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 34px;
        color: #101B43;
        padding: 0 1em;
        margin: 0;
    }
`;

export const ColContent = styled(Col)`
  margin: 1.5em 2em;
  
  p {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */
    
    display: flex;
    align-items: center;
    
    /* Text/Midnight Blue */
    
    color: #101B43;
  }
`;

export const Content = styled.div`
          h5 {
            margin: 20px 0;
            padding: 0;
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 100%;
            color: #101B43;
        }
`;


export const HeaderImage = styled.div`
    width: 100%;
    height: 150px;
    background: transparent url(${props => props.src}) no-repeat center;
    background-size: cover;
    border-radius: 8px 8px 0 0;
`;

const IconButton = styled.button`
    padding: 0;
    margin: 0;
    outline: none;
    width: 44px;
    height: 44px;
    color: #494D5B;
    background: #FFFFFF;
    border: 1px solid #EEEEEF;
    box-sizing: border-box;
    border-radius: 4px;
`;

const DrawerTitle = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    
    h2 {        
        font-family: 'Clearface Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 34px;
        color: #101B43;
        margin: 0 1em;
        text-transform: capitalize;
    }
`

const PopUpDrawer = styled(Drawer)`
position: fixed;

.ant-drawer-content-wrapper {
	
	height: 97% !important;
	
	${props => props.theme.breakpoints.tablet} {
		height: 95% !important;
  }
	${props => props.theme.breakpoints.desktop} {
		height: 92% !important;
  }
	
}

  background: ${props => props.custom ? '#ffffff' : '#ffffff'};
  .ant-drawer-header {
    border: none;
    background: ${props => props.custom ? '#ffffff' : '#ffffff'} !important;
	padding-left: 15px;
	padding-right: 15px;
  }
  
  .ant-drawer-body {
    padding: 0;
  }
  
  .ant-drawer-content {
    background: #ffffff;
  }
`

const DrawerTitleTwo = styled.div`
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    width: 100%;
    /* max-width: 670px; */
    /* margin: 44px auto 27px auto;
	 */
    margin: 0 auto;
	/* padding: 0 15px; */
    h2 {
		        
        font-family: 'Clearface Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 34px;
        color: #101B43;
        margin: 0;
		text-transform: uppercase;
		margin-left: 15px;
        /* text-transform: capitalize; */
    }
`

const PollDrawer = ({ children, event, visible, closeDrawer, title, custom, theme }) => {

	if (!event) return <Container><Skeleton active /></Container>
	return (
		<Drawer
			custom={custom}
			title={custom ?
				<DrawerTitleTwo>
					<IconButton type='button' onClick={() => closeDrawer(null)}>
						<img src={backIcon} alt='' />
					</IconButton>
					<h2>{title}</h2>
				</DrawerTitleTwo>
				: <Title>
					<DrawerTitle>
						<IconButton type='button' onClick={() => closeDrawer(null)}>
							<img src={backIcon} alt='' />
						</IconButton>
						<h2>{title}</h2>
					</DrawerTitle>
					<h3>Choose up to 3 {title.endsWith('s') ? `${title.toLowerCase()}` : `${title.toLowerCase()} options`} and let the guests vote</h3>
				</Title>}
			placement="bottom"
			closable={false}
			onClose={() => closeDrawer(null)}
			visible={visible}
			height={'100%'}
			// getContainer={false}
			headerStyle={{ background: '#ffffff' }}
			destroyOnClose
		>
			{children}
		</Drawer>

	);
};

PollDrawer.defaultProps = {

};

PollDrawer.propTypes = {

};

export default PollDrawer;
