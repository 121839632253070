import React from "react";
import styled from "styled-components";
import { FormSection, Field } from "redux-form";
import { Row, Col, Select } from "antd";
import { Intro } from "../FormComponents/intro";
import { SubmitPage } from "../FormComponents/submit";
import { BudgetIntro } from "../FormComponents/budgetIntro";
import { GuestList } from "../FormComponents/guests";
import RadioImages from "../FormComponents/RadioImages";
import RadioImagesGroup from "../FormComponents/RadioImagesGroup";
import BudgetRadioImages from "../FormComponents/BudgetRadioImages";
import RadioBudgetImageGroup from "../FormComponents/BudgetRadioImagesGroup";
import RadioList from "../FormComponents/RadioList";
import RadioListGroup from "../FormComponents/RadioListGroup";
import RadioListWithInput from "../FormComponents/RadioListWithInput";
import InputSearch from "../FormComponents/InputSearch";
import SetDatesAndDestination from "../FormComponents/SetDatesAndDestination";
import RadioListWithOther from "../FormComponents/RadioListWithOther";
import {
	AInput,
	AInputAlt,
	ARadioGroup,
	ASelect,
	AltSelect,
	ATextarea,
	ADatePicker,
	ACheckboxGroup,
} from "../FormComponents";
import { Buttons } from "../FormComponents/Buttons";
import { RsvpContext } from "../../../containers/Rsvp/RsvpProvider";

const { Option } = Select;

const stringToComponentMapper = {
	input: AInput,
	inputAlt: AInputAlt,
	inputSearch: InputSearch,
	datePicker: ADatePicker,
	radio: ARadioGroup,
	radioImage: RadioImages,
	radioImagesGroup: RadioImagesGroup,
	radioBudgetImage: BudgetRadioImages,
	radioBudgetImageGroup: RadioBudgetImageGroup,
	radioList: RadioList,
	radioListGroup: RadioListGroup,
	radioListWithInput: RadioListWithInput,
	select: ASelect,
	selectAlt: AltSelect,
	textarea: ATextarea,
	checkboxGroup: ACheckboxGroup,
	setDatesAndDestination: SetDatesAndDestination,
	radioListWithOther: RadioListWithOther,
};

const validate = (rule) => (value) => {
	if (!rule) {
		return undefined;
	}

	return value && new RegExp(rule).test(value) ? undefined : "Validation Error";
};

const Wrapper = styled.div`
	@media (max-width: 767px) {
		// margin-top: 65px;
		// height: calc(100% - 65px);
	}
`;
const Column = styled(Col)`
  background: #ffffff;
  ${(props) => (props.layout === 24 ? "" : "height: 99vh")};
  ${(props) => (props.layout === 24 ? "" : "padding-top: 128px")};
  ${(props) => (props.layout === 24 ? "" : "padding-left: 135px")};
  ${(props) => (props.layout === 24 ? "" : "overflow: auto")};
  ${(props) => (props.layout === 24 ? "" : "margin-bottom: 1em")};
	@media (max-width: 767px) {
		height: auto;
	}
  ${props => props.theme.breakpoints.maxTablet} {
   padding-left: 30px;
  }
  ${props => props.theme.breakpoints.maxPhone} {
   padding-top: 15px;
   padding-left: 0px;
   overflow: unset;
   @media (max-width: 767px) {
	padding-top: 0px;
	margin-top: 2.5em;
	}
  }
`;

export const HeaderImage = styled.div`
  width: 100%;
  height: ${(props) => (props.sub === 24 ? "200px" : "100vh")};
  background: transparent url(${(props) => props.src}) no-repeat center;
  background-size: cover;
  @media (max-width: 767px) {
	  height: 40vh;
  }

`;

export const Section = styled(FormSection)`
  margin-left: 1em;
  margin-right: 1em;
  padding-bottom: 2em;
  //min-height: 100vh;
`;

const SectionTitle = styled.div`
  h4 {
    font-family: "Clearface Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 140%;
    color: #101b43;
  }
`;

const FormComponentBundler = ({
	formState,
	currentIndex,
	back,
	next,
	initForm,
	displayName,
	budget,
	attendance,
	goToSlide,
	submitForm,
}) => {
	// console.log('comp isBride:: ', isBride)
	// console.log("syncErrors", !!formState?.syncErrors);
	return (
		<Wrapper>
			<RsvpContext.Consumer>
				{({ guests, data, steps, isBride, isSetDetails, guestDetails }) => {
					const submitUnset = steps.length === currentIndex + 1 && !isSetDetails;
					return (
						<div>
							{steps[currentIndex].sections.map((step) => {
								return (
									<div key={`section_${step.name}`}>
										{step.name === "intro" ? (
											<Intro next={next} displayName={displayName} isBride={isBride} data={data} />
										) : step.name === "guest-list" ? (
											<GuestList back={back} next={next} guests={guests} />
										) : step.name === "submit" ? (
											<SubmitPage
												next={next}
												budget={budget}
												initForm={initForm}
												attendance={attendance || isBride || guestDetails.detailsSet}
												goToSlide={goToSlide}
												step={steps[currentIndex]}
											/>
										) : (
											<Row key={`section_${step.name}`}>
												<Col xs={24} md={step.layout.sub}>
													{step.image && <HeaderImage sub={step.layout.sub} src={step.image} />}
												</Col>
												<Column xs={24} md={step.layout.main} layout={step.layout.main}>
													<Section name={step.name} component="div">

														{step.caption && (
															<SectionTitle>
																<h4>{step.caption}</h4>
															</SectionTitle>

														)}
														<div>
															{step.name === "budget-quiz" ? (
																<div>
																	<BudgetIntro next={next} />
																</div>
															) : (
																step.fields.map((f) => {
																	return (
																		<div key={f.name}>
																			{f.component === "select" ? (
																				<Field
																					mode="multiple"
																					label={f.caption}
																					subLabel={f.subCaption || undefined}
																					name={f.name}
																					component={ASelect}
																					placeholder={f.placeholder}
																					validate={validate(f.validationRule)}
																					back={back}
																					next={next}
																					defaultValue={["none"]}
																					options={f.options || undefined}
																				>
																					{f.options.map((option) => {
																						return <Option value={option.value}>{option.label}</Option>;
																					})}
																				</Field>
																			) : (
																				<Field
																					label={f.caption}
																					subLabel={f.subCaption || ""}
																					name={f.name}
																					image={f.image}
																					days={f.days || undefined}
																					type={f.type || undefined}
																					options={f.options || undefined}
																					altOptions={f.altOptions || undefined}
																					component={stringToComponentMapper[f.component]}
																					placeholder={f.placeholder}
																					validate={validate(f.validationRule)}
																					back={back}
																					next={next}
																					initForm={initForm}
																					field={f.field || undefined}
																					rows={f.rows || undefined}
																				/>
																			)}
																		</div>
																	);
																})
															)}
														</div>
														<Row justify={step.justify}>
															<Col xs={24} sm={8} >
																<Buttons
																	nextText={
																		step.name === "budget-quiz" || step.name === "attendance"
																			? "CONTINUE"
																			: submitForm || submitUnset
																				? "SUBMIT"
																				: "NEXT"
																	}
																	back={back}
																	next={next}
																	continuePage={step.name === "attendance"}
																	attendance={attendance}
																	goToSlide={goToSlide}
																	numberOfSteps={steps.length}
																	submit={submitForm || submitUnset}
																	disabled={!!formState?.syncErrors}
																	float
																/>
															</Col>
														</Row>
													</Section>
												</Column>
											</Row>
										)}
									</div>
								);
							})}
						</div>
					);
				}}
			</RsvpContext.Consumer>
		</Wrapper>
	);
};

FormComponentBundler.defaultProps = {};

FormComponentBundler.propTypes = {};

export default FormComponentBundler;
