import { db } from '../../config/firebase'

async function generateRecommendations(
    eventId,
    city,
    request = fetch,
) {
    const requestObject = {
        data: {
            city,
            eventId
        }
    };

    const postBody = JSON.stringify(requestObject);

    let url;
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        url = `${process.env.REACT_APP_GENERATE_RECOMMENDATIONS_DEV}`
    } else {
        url = `${process.env.REACT_APP_GENERATE_RECOMMENDATIONS_PROD}`
    }

    try {
        const response = await request(url, { method: 'POST', body: postBody });
        if (response.status === 200) {
            const body = await response;
            return body.body
        }
    } catch (error) {
        console.log('errors: ', error)
    }
}



function getRecommendationsData(eventId, setRecommendationsData){
    const eventRef = db.collection('events').doc(eventId)
    const sfDocRef = eventRef.collection(`recommendations`).orderBy('key')

    sfDocRef.onSnapshot((querySnapshot) => {
        let recommendations = []
        querySnapshot.forEach((doc) => {
            recommendations.push(doc.data())
        })

        setRecommendationsData(recommendations)
    })
}

const recommendations = {
    generate: generateRecommendations,
    get: getRecommendationsData,
};

export default recommendations;