import React from 'react';
import styled from "styled-components";
import decor from '../../../../assets/home/decor.svg'

export const Divider = styled.div`
    display: none;
    position: relative;
    
    @media (min-width: 480px) {
        width: 100%;
        height: 113px;
        // background: #e5e5e5;
        background: #ffffff;
        display: flex;
        justify-content: flex-end;
        margin-top: ${props => props.top ? props.top : '0'};
        
        img {
              top: -85px;
              left: 200px;
              position: absolute;
        }    
   }   
    
`;
function DecorDivider({ decorAdd, top }) {
    return (
        <Divider top={top}>
          {decorAdd && <img src={decor} alt=""/>}
        </Divider>
    );
}

export default DecorDivider


