import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const Heading = styled.h1`
color: #101B43;
    font-family: 'Clearface Bold';
    font-style: normal;
    font-weight: bold;
    font-size: 1.625em;
    line-height: 34px;
    padding: 0;
    margin: 0;
    
    span {
      color: #2E2C9A;
    }
`

const TitleClearface = ({ children }) => {
  return (
    <Heading>
        {children}
    </Heading>
  );
};

TitleClearface.defaultProps = {

};

TitleClearface.propTypes = {

};

export default TitleClearface;
