import moment from 'moment'
import { v4 as uuidv4 } from 'uuid';
import { db } from '../../config/firebase'

const add = async (eventId, template, userType, notificationType, data, salt = uuidv4()) => {
    const timestamp = moment().unix()
    const notificationRef = db.collection(`events`).doc(eventId).collection('notifications').doc(eventId + timestamp + salt);
    return await notificationRef.set({
        id: notificationRef.id,
        userType: userType,
        type: notificationType,
        template: template,
        data: data,
        createdAt: timestamp
    })
};

const notifications = {
    add
};

export default notifications;
