import React, { Component } from 'react'
import { connect } from 'react-redux'
import {guests, tasks, itinerary, activities} from "../../helpers/firestore";

export const EventGuestViewContext = React.createContext(undefined, undefined)

class EventGuestViewProvider extends Component {
  constructor(props) {
    super(props)

    this.state = {
      itineraryData: null,
      guests: [],
      tasksLists: [],
      myTasks: [],
      activities: []
    }
  }

  componentDidMount() {
    // this.getData()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.event !== this.props.event) {
      this.getData(this.props.event.id)
    }
  }

  setItineraryData = (itineraryData) => {
    this.setState({ itineraryData })
  };

  setGuests = (guests) => {
    this.setState({ guests })
  };

  setTasks = (tasksLists) => {
    let myTasks = [];
    tasksLists.forEach(tasklist => {
        if(tasklist && tasklist.tasks){
          const myTaskList = tasklist.tasks.filter(task => {
            if(task.assignTo.some(member => member.id === this.props.uid)){
              return task
            }
          })
          myTasks.push(...myTaskList)
        }
    });
    this.setState({ tasksLists, myTasks })
  };

  setActivities = (activities) => {
    this.setState({ activities })
  };

  getData = (eventId) => {
    guests.get(eventId, this.setGuests)
    itinerary.getItineraryData(eventId, this.setItineraryData)
    tasks.getTaskLists(eventId, this.setTasks)
    activities.getActivitiesData(eventId, this.setActivities)
  }



  render() {
    const { guests, itineraryData, tasksLists, myTasks, activities } = this.state
    const { children, event, pathUrl, displayName  } = this.props

    const providerData = {
      event,
      guests,
      tasksLists,
      pathUrl,
      displayName,
      itineraryData,
      myTasks,
      activities
    }
    return (
      <EventGuestViewContext.Provider value={providerData}>
        {children}
      </EventGuestViewContext.Provider>
    )
  }
}

EventGuestViewProvider.defaultProps = {
// <!--  children: PropTypes.object-->
};

EventGuestViewProvider.propTypes = {
// <!--  children: {}-->
};

function mapStateToProps (state) {
  return {
    uid: state.auth.user.user.uid,
    displayName: state.auth.user && state.auth.user.user ? state.auth.user.user.displayName : 'User',
  }
}

export default connect(mapStateToProps, null)(EventGuestViewProvider)