import React from 'react';
import styled from "styled-components";
import {Menu} from "antd";

const Wrapper = styled.svg`
    fill: #494D5B;
     stroke: #494D5B;
    
    &:hover {
      fill: #2E2C9A;
     stroke: #2E2C9A;
    }
`

export default ({ style }) => (
    <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none">
        <rect x="12.8867" y="1.33301" width="1.97531" height="15.8025" rx="0.987654" transform="rotate(45 12.8867 1.33301)" fill="#8696AE"/>
        <rect x="14.2852" y="12.5068" width="1.97531" height="15.8025" rx="0.987654" transform="rotate(135 14.2852 12.5068)" fill="#8696AE"/>
    </svg>
);
