import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Row, Col } from "antd";
import MySwitch from "../Switch";
import backIcon from "../../assets/icons/backArrow.svg";

const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 60vw;
  ${(props) => props.theme.breakpoints.maxTablet} {
    max-width: 100%;
  }

  h1 {
    margin: 0 20px;
    font-family: "Clearface Bold";
    font-style: normal;
    font-size: 32px;
    line-height: 140%;

    display: flex;
    align-items: center;

    color: #101b43;
    ${(props) => props.theme.breakpoints.maxTablet} {
      font-size: 25px;
    }
  }

  p {
    padding: 0;
    margin: 0 0 0 16px;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #101b43;
  }

  h4 {
    margin: 16px 0 0 65px;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: #8696ae;
  }
`;

const IconButton = styled.button`
  padding: 0;
  margin: 0;
  outline: none;
  width: 44px;
  height: 44px;
  color: #494d5b;
  background: #ffffff;
  border: 1px solid #eeeeef;
  box-sizing: border-box;
  border-radius: 4px;
`;

const Content = styled.div`
  margin: 2em 0 5em 0;
  overflow-y: scroll;
  height: 70vh;
  padding-bottom: 50px;
  @media (max-width: 500px) {
    height: fit-content;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;

const CheckboxContent = styled.div`
  display: flex;
`;

const ModalLayout = ({
  children,
  title,
  checkboxLabel,
  handleCancel,
  toggleCheck,
  checkValue,
  id,
  subTitle,
  subTitleStyle,
}) => {
  const desktop = {
    span: 18,
    offset: 6,
  };

  const mobile = {
    span: 24,
    offset: 0,
  };
  return (
    <Row>
      <Col xs={mobile} md={desktop}>
        <NavWrapper>
          <CheckboxContent>
            <IconButton type="button" onClick={() => handleCancel()}>
              <img src={backIcon} alt="" />
            </IconButton>
            <h1>{title}</h1>
          </CheckboxContent>
          <CheckboxContent>
            {toggleCheck && (
              <MySwitch
                id={id}
                toggleCheck={toggleCheck}
                checkValue={checkValue}
              />
            )}
            {checkboxLabel && <p>{checkboxLabel}</p>}
          </CheckboxContent>
        </NavWrapper>
        {subTitle && (
          <NavWrapper>
            <h4 style={subTitleStyle}>{subTitle}</h4>
          </NavWrapper>
        )}
        <Content>{children}</Content>
      </Col>
    </Row>
  );
};

ModalLayout.defaultProps = {};

ModalLayout.propTypes = {};

export default ModalLayout;

export const ModalSidebarLayout = ({
  children,
  title,
  checkboxLabel,
  handleCancel,
  toggleCheck,
  sidebar,
  checkValue,
}) => {
  return (
    <Row>
      <Col xs={24} md={6}>
        {sidebar}
      </Col>
      <Col xs={24} md={18}>
        <NavWrapper>
          <IconButton type="button" onClick={() => handleCancel()}>
            <img src={backIcon} alt="" />
          </IconButton>
          <h1>{title}</h1>
          <MySwitch
            size="small"
            toggleCheck={toggleCheck}
            checkValue={checkValue}
          />
          <p>{checkboxLabel}</p>
        </NavWrapper>
        <Content>{children}</Content>
      </Col>
    </Row>
  );
};
