import React from "react";
import EventsProvider, { EventsContext } from "./EventsProvider";
import { PageMotion, MaxWidthContainer, EventListTabs } from "../../components";
import styled from "styled-components";
import PlanningList from "./EventTables/PlanningList";
import InvitedList from "./EventTables/InvitedList";
import PastList from "./EventTables/PastList";
import CanceledList from "./EventTables/CanceledList";
import { CreateEvent } from "../";

const tabData = [
  {
    name: "PLANNING",
    component: PlanningList,
  },
  {
    name: "INVITED",
    component: InvitedList,
  },
  {
    name: "PAST",
    component: PastList,
  },
  {
    name: "CANCELED",
    component: CanceledList,
  },
];

const Container = styled.div`
  margin-top: 90px;
  @media (max-width: 500px) {
    margin-top: 56px;
    // overflow-y: scroll !important;
    height: calc(100vh - 56px);
  }
`;

const Events = (props) => {
  return (
    <EventsProvider>
      <EventsContext.Consumer>
        {({ drawerVisible, showDrawer, onClose }) => {
          return (
            <PageMotion>
              <MaxWidthContainer isAuthenticated>
                <Container>
                  <EventListTabs
                    defaultTab={
                      props.location &&
                      props.location.state &&
                      props.location.state.tab
                        ? props.location.state.tab
                        : "0"
                    }
                    data={tabData}
                    action={showDrawer}
                  />
                </Container>
              </MaxWidthContainer>
              <CreateEvent drawerVisible={drawerVisible} onClose={onClose} />
            </PageMotion>
          );
        }}
      </EventsContext.Consumer>
    </EventsProvider>
  );
};

Events.defaultProps = {};

Events.propTypes = {};

export default Events;
