import React, { Component } from 'react';
import styled from "styled-components"
import { SectionHeading, SectionHeadingUnderLine, HeadingFour, Paragraph } from '../../../../../components/Text'
import checkMark from './Check.svg'
import {Button} from "antd";

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1em;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

const Title = styled(HeadingFour)`
margin: 0;
text-align: left;
`;

const ParagraphWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
  
  ul {
    list-style: none;
    margin: 16px 0 0 0;
    padding: 0;
    
    li {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 1em 0;
      
      h4 {
        margin: 0;
        text-align: left;
      }
      
      p {
        margin: 0;
      }
      img {
        margin-right: 10px;
      }
    }
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin: 0;
`;

const DefaultBtn = styled(Button)`
  background: ${props => props.primary ? '#EC6E5B' : '#ffffff'};
  border-color: #EC6E5B;
  width: ${props => props.width ? props.width : '172px'};
  height: 44px;
  border-radius: 4px;
  color: ${props => props.primary ? '#ffffff' : '#EC6E5B'};
  text-transform: uppercase;
  
  &:hover {
    background: ${props => props.primary ? '#EC6E5B' : '#ffffff'};
    border-color: #EC6E5B;
    color: ${props => props.primary ? '#ffffff' : '#EC6E5B'};
  }
  
  &:focus {
    background: ${props => props.primary ? '#EC6E5B' : '#ffffff'};
    border-color: #EC6E5B;
    color: ${props => props.primary ? '#ffffff' : '#EC6E5B'};
  }

`

class CarouselComponent extends Component {
  render() {
    return (
        <Container>
            <TitleWrapper>
              <SectionHeading align='left'>
                Behold the&nbsp;
              </SectionHeading>
              <SectionHeadingUnderLine width='130px'>
                Dashboard
              </SectionHeadingUnderLine>
            </TitleWrapper>
            <Title>Stay calm and organized with Invigo’s easy-to-use planning tool.</Title>
          <ParagraphWrapper>
            <ul>
              <li>
                <img src={checkMark} alt=""/>
                <HeadingFour>
                  Your centralized hub to create, plan, and track the party
                </HeadingFour>
              </li>
              <li>
                <img src={checkMark} alt=""/>
                <HeadingFour>
                  Easy to delegate to-do’s and customizable task lists
                </HeadingFour>
              </li>
              <li>
                <img src={checkMark} alt=""/>
                <HeadingFour>
                  Get the entire squad involved
                </HeadingFour>
              </li>
            </ul>
          </ParagraphWrapper>
          <ButtonContainer>
            <DefaultBtn primary>Get Started</DefaultBtn>
          </ButtonContainer>
        </Container>
    );
  }
}

export default CarouselComponent
