import moment from 'moment';

export const formatStart = (value) => moment.unix(value).format("ddd MMM DD");
export const formatEnd = (value) => moment.unix(value).format("ddd MMM DD YYYY");

export const timestampToDate = (value) => moment.unix(value).format("MM/DD/YYYY");
export const timestampToDateDatePicker = (value) => moment.unix(value).format("YYYY-MM-DD");

export const formatDates = value => (value ? moment(value) : null);

export const formatDate = (dates) => {
	const start = dates.start.seconds;
	const end = dates.end.seconds;
	return `${formatStart(start)} - ${formatStart(end)}`;
}

export const formatDateWithYear = (dates) => {
	const start = dates.start.seconds;
	const end = dates.end.seconds;
	return `${formatStart(start)} - ${formatEnd(end)}`;
}

const normalizeDate = value =>
	value ? value.format('ddd MMM d') : null;

export const normalizeMomentDate = (value) => {
	moment.defaultFormat = "ddd MMM d";
	const newDate = moment(value, moment.defaultFormat).toDate()
	const tmp = moment(newDate).format('llll')
	const newstr = tmp.split(",");

	return `${newstr[0]} ${newstr[1]}`;
};

export function getNumberOfDays(start, end) {
	const startDateString = moment(start);
	const endDateString = moment(end);
	return endDateString.diff(startDateString, 'days')
}


export const sortDates = (dateItem) => moment(dateItem).unix();

export {
	normalizeDate
};
