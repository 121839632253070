import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Space, Menu, Dropdown } from "antd";
import { BellOutlined, DeleteOutlined, EllipsisOutlined, EditOutlined } from "@ant-design/icons";
import commentIcon from "../../assets/icons/comment.svg";
import { COMPLETED_RSVP, DECLINED, PENDING, PENDING_MAYBE } from "../../helpers/constants";

const TableWrapper = styled.div``;

const Table = styled.div`
  background: ${(props) => (props.header ? "none" : "#ffffff")};
  border-radius: 8px;
  margin: 0.5em 0;
  padding: 0;

  ${(props) => (props.header ? "" : "box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);")};

  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 64px;
  }

  @media all and (max-width: 1023px) {
    display: ${(props) => (props.header ? "none" : "block")};
  }
`;

const TableCell = styled.div`
  width: ${(props) => (props.width ? props.width : "11.42%")};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  padding: 0 1em;

  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #101b43;
  text-align: left;

  span {
    text-align: left;
    width: 100%;
  }

  @media all and (max-width: 1023px) {
    width: 100%;
    padding: 0.5em 1em;
    padding-right: 0;
    display: flex;
    align-items: flex-start;
  }
`;

const TableCellCapitalize = styled(TableCell)`
  text-transform: capitalize;
`;

const RowHead = styled.div`
  width: 12%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-grow: 1;
  height: 100%;
  padding: 0.5em 1em;

  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  /* Text/Endeavour */

  color: #2e2c9a;

  @media all and (max-width: 1023px) {
    width: 100%;
    border-radius: 8px 8px 0 0;
  }

  @media (min-width: 1024px) {
    border-radius: 0 8px 8px 0;
  }
`;

const RowFooter = styled.div`
  width: 3%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-grow: 1;
  height: 100%;
  padding: 0.5em 1em;

  @media all and (max-width: 1023px) {
    width: 100%;
  }

  @media (min-width: 1024px) {
    border-radius: 0 8px 8px 0;
  }
`;

const ActionMenu = styled(Menu)`
  width: 160px;
`;

const ActionItem = styled(Menu.Item)`
  width: 160px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #101b43;

  &:hover {
    color: ${(props) => props.color};
  }
`;

const Icon = styled.img`
  margin-left: 4px;
`;

const MenuAction = ({ user, showDeleteModal, showReminderModal, showEditModal }) => {
  return (
    <ActionMenu>
      <ActionItem color="#2E2C9A" icon={<BellOutlined />} onClick={() => showReminderModal(user)}>
        Remind
      </ActionItem>
      <ActionItem color="#2E2C9A" icon={<EditOutlined />} onClick={() => showEditModal(user)}>
        Edit
      </ActionItem>
      <ActionItem color="#EE4238" icon={<DeleteOutlined />} onClick={() => showDeleteModal(user)}>
        Delete
      </ActionItem>
    </ActionMenu>
  );
};

const DropMenu = styled(Dropdown)`
  cursor: pointer;
`;

const createActions = (data, showDeleteModal, showReminderModal, showEditModal) => {
  return (
    <Space size="middle">
      <DropMenu
        overlay={
          <MenuAction
            user={data}
            showDeleteModal={showDeleteModal}
            showReminderModal={showReminderModal}
            showEditModal={showEditModal}
          />
        }
        placement="bottomRight"
      >
        <EllipsisOutlined style={{ fontSize: 28 }} />
      </DropMenu>
    </Space>
  );
};

const HeaderCell = styled.div`
  width: ${(props) => (props.width ? props.width : "11.42%")};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  padding: 0 1em;

  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;

  color: #8696ae;

  @media all and (max-width: 1023px) {
    width: 100%;
  }
`;

const RowStart = styled.div`
  width: 12%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-grow: 1;
  height: 100%;
  padding: 0.8em 1.2em;

  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;

  color: #2e2c9a;

  @media all and (max-width: 1023px) {
    width: 100%;
    border-radius: 8px 8px 0 0;
  }

  @media (min-width: 1024px) {
    border-radius: 0 8px 8px 0;
  }
`;

const HeaderFooter = styled.div`
  width: 3%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-grow: 1;
  height: 100%;
  padding: 0.8em 1.2em;

  @media all and (max-width: 1023px) {
    width: 100%;
  }

  @media (min-width: 1024px) {
    border-radius: 0 8px 8px 0;
  }
`;

const MobTitle = styled.div`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #8696ae;
  padding-right: 1rem;
  width: 130px;
  @media (min-width: 1024px) {
    display: none;
  }
`;
const TableData = styled.div`
  display: flex;
  flex-direction: column;
`;

const RenderRows = ({ tableData, showDeleteModal, showReminderModal, showEditModal }) => {
  return tableData.map((data, index) => {
    return (
      <Table key={data.key}>
        <RowHead>{data.name}</RowHead>
        <TableCell width="15%">
          <MobTitle>RSVP</MobTitle>
          <TableData>
            {data.rsvp === COMPLETED_RSVP
              ? "Going"
              : data.rsvp === DECLINED
              ? "Not going"
              : data.rsvp === PENDING
              ? "No response"
              : data.rsvp === PENDING_MAYBE
              ? "Maybe"
              : ""}
          </TableData>
        </TableCell>

        <TableCellCapitalize width="15%">
          <MobTitle>FOOD ALLERGIES</MobTitle>
          <TableData>{data.allergies ? data.allergies : "-"}</TableData>
        </TableCellCapitalize>
        <TableCell width="20%">
          <MobTitle>DATE(S)</MobTitle>
          <TableData> {data.dates ? data.dates.map((item, index) => <span key={index}>{item}</span>) : "-"}</TableData>
        </TableCell>

        <TableCell width="20%">
          <MobTitle>EMAIL & MOBILE</MobTitle>
          <TableData>
            {data.emailMobile ? data.emailMobile.map((item, index) => <span key={index}>{item}</span>) : "-"}
          </TableData>
        </TableCell>
        <TableCell width="10%">
          <MobTitle>VENMO</MobTitle>
          <TableData>
            {data.paymentMethod && data.paymentMethod.map((item, index) => <span key={index}>{item.label}</span>)}
            {data.paymentUsername && <span>{data.paymentUsername}</span>}
          </TableData>
        </TableCell>
        <RowFooter></RowFooter>
      </Table>
    );
  });
};

const GuestListTableForGuests = ({ tableData, showDeleteModal, showReminderModal, showEditModal }) => {
  return (
    <TableWrapper>
      <Table header>
        <RowStart>NAME</RowStart>
        <HeaderCell width="15%">RSVP</HeaderCell>
        <HeaderCell width="15%">FOOD ALLERGIES</HeaderCell>
        <HeaderCell width="20%">DATE(S)</HeaderCell>
        <HeaderCell width="20%">EMAIL & MOBILE</HeaderCell>
        <HeaderCell width="10%">PAYMENT</HeaderCell>
        <HeaderFooter />
      </Table>
      <RenderRows
        tableData={tableData}
        showDeleteModal={showDeleteModal}
        showReminderModal={showReminderModal}
        showEditModal={showEditModal}
      />
    </TableWrapper>
  );
};

GuestListTableForGuests.defaultProps = {};

GuestListTableForGuests.propTypes = {};

export default GuestListTableForGuests;
