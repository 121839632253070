import React, { Component } from 'react'
import { connect } from 'react-redux'
import { logoutUser, registerUser } from "../../helpers/auth";
export const LoginContext = React.createContext()

class LoginProvider extends Component {
	constructor(props) {
		super(props)

		this.state = {
			showLogin: true,
			url: null
		}
	}

	logout = () => {
		logoutUser(this.props.dispatch);
	}

	onClose = () => {
		this.setState({ showLogin: false })
	};

	googleLogin = () => {
		// loginWithGoogle(this.props.dispatch, this.onClose)
	}

	submitUserDetails = (values) => {
		registerUser(values, this.props.dispatch, this.onClose).then(r => console.log(r))
	}
	setUrl = (value) => {
		this.setState({ url: value })
	}

	render() {
		const { showLogin, url } = this.state
		const { children, user, isAuthenticated } = this.props

		const providerData = {
			isAuthenticated, user,
			showLogin,
			url,
			googleLogin: this.googleLogin,
			submitUserDetails: this.submitUserDetails,
			logout: this.logout,
			setUrl: this.setUrl,
		}
		return (
			<LoginContext.Provider value={providerData}>
				{children}
			</LoginContext.Provider>
		)
	}
}

LoginContext.defaultProps = {
	// children: PropTypes.object
};

LoginContext.propTypes = {
	// children: {}
};

function mapStateToProps(state) {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		user: {
			displayName: state.auth.user && state.auth.user.user ? state.auth.user.user.displayName : 'User',
			photoURL: state.auth.user && state.auth.user.user ? state.auth.user.user.photoURL : null,
		}
	}
}

export default connect(mapStateToProps, null)(LoginProvider)