import React from 'react';
import { Row, Col} from 'antd'
import styled from "styled-components"
import ImageColA from './ImageColA'
import { HeadingThree, HeadingFour } from '../../../../components/Text'

const citiesA = [
  {
    text: 'How much does Invigo cost?',
    answer: 'Nothing. Signing up with Invigo is free. All you need to do is fill in your details online and start planning the party of a lifetime.'

  },
  {
    text: 'Who can see the event dashboard?',
    answer: 'Just you. Capable you! Unless you’re feeling trusty and would like to invite the rest of the bride squad and the M.O.H. All possible.  '
  },
  {
    text: 'How do my guests receive their invites, notices, and information?',
    answer: 'Not on email, not on broadcast, and not on social media. Thank goodness! We eliminate the anxiety of long-winded chat threads. Guests receive text notifications with a simple link that jets them off quickly and painlessly to your info page and event listing.'
  },
  {
    text: 'Are my voting polls anonymous? ',
    answer: 'Oh, yeah! We understand the sensitivities around talking money and deciding on what activities the bride tribe should do. You can send the bride and bride tribe a personalized budget quiz and getaway options for them to decide and vote in private. Poll scores are tallied. The majority vote wins and it’s that easy!'
  },
]

const citiesB = [
  {
    text: 'Is Invigo a concierge that will book my hotels and flights for me?',
    answer: 'Um...not our thing. We focus on providing you with the best technology to make planning the trip easy as can be.'

  },
  {
    text: 'How many bachelorette parties can I plan with Invigo?',
    answer: 'Way more than 27 (dresses), if you like. With a tailored dashboard that supports unlimited events, the party never ends.'
  },
  {
    text: 'Will Invigo order me more wine and pizza when I’m having a bad M.O.H day?',
    answer: 'The good news: There are no bad MOH days with Invigo. You’re going to be A-okay and be saying “yay!”  not “nay.”'
  },
  {
    text: 'How do I know the City Guides are legit?',
    answer: 'Our city guides are locally curated with love and legacy. All hotspots and destinations have been carefully reviewed, experienced, and rated with Invigo’s accredited stamp of approval. Yes, we’ve done the homework babe, so you don’t have to.'
  },
]

export const Wrapper = styled.div`

`;

export const ParagraphContainer = styled.div`
  width: 100%;
  
   ${props => props.theme.breakpoints.tablet}{
        width: 30%;
    }
  p {
    text-align: center !important;
    opacity: 1 !important;
    
    span {
      font-weight: bold;
    }
  }
`;

export const GridItem = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const HeadingTwo = styled.h2`  
    font-family: 'Proxima Nova';
    text-align: ${props => props.align ? props.align : 'center'};
    margin: 12px 0; 

    font-weight: 600;
font-size: 26px;
line-height: 130%;
    color: #101B43;
    mix-blend-mode: normal;  
`;

export const MailTo = styled.a`
 text-transform: uppercase;
 font-family: 'Proxima Nova Semibold';
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 150%;

color: #101B43;
`;

function DashboardSection() {
    return (
      <Row>
        <GridItem xs={24}>
          <HeadingTwo>Frequently Asked Questions</HeadingTwo>
          <ParagraphContainer>
            <HeadingFour>Still have questions? We've got answers.<br />
              Email us at <span><MailTo href="mailto:help@goinvigo.com">help@goinvigo.com</MailTo></span></HeadingFour>
          </ParagraphContainer>
        </GridItem>
        <GridItem xs={24} sm={12}>
          <ImageColA cities={citiesA} />
        </GridItem>
        <GridItem xs={24} sm={12}>
          <ImageColA cities={citiesB} />
        </GridItem>
      </Row>
    );
}

export default DashboardSection


