import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const useGuests = () => {
  let location = useLocation();

  return location.pathname.includes("event-guest-view");
};

export default useGuests;
