import React from 'react'
import styled from "styled-components";
import { parsePhoneNumberFromString } from 'libphonenumber-js'

import { Row, Col } from 'antd';
import { InTypography } from '../../'
import landing from './assets/guests.png'
import { Buttons } from "./Buttons";



export const Image = styled.div`
/* max-width: 460px; */
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    flex-direction: column;
    background: transparent url(${props => props.src}) no-repeat center;
    background-size: cover;
    /* margin-top: 5vh; */
	${props => props.theme.breakpoints.maxPhone} {
		margin-top: 0;
		height: 47vh;
		background-position: center;
  	}
`;

export const Container = styled.div`
    /* width: 100%; */
    /* height: 100%; */
    padding: 1em;
    /* margin: 138px 0 0 0; */
    //text-align: center;
	
	/* ${props => props.theme.breakpoints.maxTablet} {
		margin: 128px 0 0 30px;
	}
	${props => props.theme.breakpoints.maxPhone} {
		margin-top: 1rem;
		margin-left: 0;
	} */
    
`;

export const Subtitle = styled(InTypography.InParagraph)`
    margin: 1em 0;
    padding: 12px;
    max-width: 620px;
    width: 100%;
    border: 1px solid #E9E8F0;
    box-sizing: border-box;
    border-radius: 4px;
`;

export const GuestContainer = styled.div`
  /* height: 50vh; */
`;

export const BtnContainer = styled.div`
 margin: 1em;
  bottom: 0;
    position: absolute;
`;

export const RowContainer = styled(Row)`
 width: 100%;
`;

const formatPhoneNumber = (str) => {
	const phoneNumber = parsePhoneNumberFromString(str)
	return phoneNumber.formatInternational();
};

const Column = styled(Col)`
  background: #ffffff;
  ${(props) => (props.layout === 24 ? "" : "height: 99vh")};
  ${(props) => (props.layout === 24 ? "" : "padding-top: 128px")};
  /* ${(props) => (props.layout === 24 ? "" : "padding-left: 135px")}; */
  ${(props) => (props.layout === 24 ? "" : "overflow: auto")};
  ${(props) => (props.layout === 24 ? "" : "margin-bottom: 1em")};
	@media (max-width: 767px) {
		height: auto;
	}
  ${props => props.theme.breakpoints.maxTablet} {
   padding-left: 30px;
  }
  ${props => props.theme.breakpoints.maxPhone} {
   padding-top: 15px;
   padding-left: 0px;
   overflow: unset;
   @media (max-width: 767px) {
	padding-top: 0px;
	margin-top: 2.5em;
	}
  }
`;

export const GuestList = ({ back, next, guests }) => {
	return (
		<Row>
			<Col xs={24} md={8}>
				<Image src={landing} />
			</Col>
			<Column xs={24} md={{offset: 3, span: 13}}>
				<Container >
					<InTypography.InH1>
						Your Bride Tribe
					</InTypography.InH1>
					<GuestContainer>
						{guests.map((guest, index) => {
							return (
								<Subtitle key={index}>
									<RowContainer>
										<Col xs={24} md={8}>
											<span>
												{guest.name}
											</span>
										</Col>
										<Col xs={24} md={10}>
											<span>
												{guest.email}
											</span>
										</Col>
										<Col xs={24} md={6}>
											<span>
												{formatPhoneNumber(guest.mobileNumber.toString())}
											</span>
										</Col>
									</RowContainer>
								</Subtitle>
							)
						})}
					</GuestContainer>

					<Buttons
						back={back}
						next={next}
						float
					/>
				</Container>
			</Column>
		</Row>
	)
}