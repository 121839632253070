import React, { useEffect, useState } from 'react'
import styled from "styled-components";
import { Field } from "redux-form";
import { InputNumber, Space } from 'antd';
import { InTypography, InButton } from '../../'
import bgImage from '../../../assets/quiz/submit.png'

export const Image = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: transparent url(${props => props.src}) no-repeat center;
    background-size: cover;
    //margin-top: 5vh;
`;

export const Container = styled.div`
    margin-top: 40vh;
    width: 575px;
    height: 374px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1em;
    text-align: center;
    background: #FFFFFF;
    border-radius: 4px;
	${props => props.theme.breakpoints.maxPhone} {
		width: 100%;
  	}
	${props => props.theme.breakpoints.maxTablet}  {
		h1 {
			font-size: 22px;
		}
	}
    
`;

export const SubtitleContainer = styled.div`
    margin: 1.3em 0;
    display: flex;
    align-items: center;
`;

export const Subtitle = styled.h3`
    margin: 1px 0;
    display: flex;
    align-items: center;
    font-family: 'Proxima Nova Semibold';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 100%;
text-align: center;
padding: 0;
color: #101B43;
`;

export const Paragraph = styled.p`
    margin: 1px 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #8696AE;
    padding: 0;
`;

export const NumberInput = styled.div`
    margin: 0 0.5em;
`;

export const Cover = styled.div`
    margin: 0 1em;
`;

const CurrencyInput = ({ input }) => {
	return (
		<NumberInput>
			<InputNumber
				value={input.value}
				formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
				parser={value => value.replace(/\$\s?|(,*)/g, '')}
				onChange={(e) => input.onChange(e)}
				size="large"
			/>
		</NumberInput>
	)
}


function budgetCalculator(budget) {
	let total = 0

	// eslint-disable-next-line no-unused-vars
	for (let key in budget) {

		let value = 0;
		if (key === 'day_activities') {
			let activityTotal = 0;

			budget[key].map(item => {

				activityTotal += item.cost;
			});
			value = activityTotal;
		} else {
			value = budget[key].cost;
		}

		total += value;
	}

	return total
}

export const SubmitPage = ({ budget, initForm, attendance, goToSlide, step }) => {
	const [loading, setLoading] = useState(false);
	const [budgetIsSet, setBudget] = useState(false);
	useEffect(() => {
		if (budget && !budgetIsSet) {
			setBudget(true)
			const total = budgetCalculator(budget)
			initForm({ totalBudget: total })
		}
	}, [budget, budgetIsSet, initForm]);

	return (
		<Image src={bgImage} >
			{attendance ?
				<Container >
					<InTypography.InH1>
						You’re all set! <br />
						Cue the confetti!
					</InTypography.InH1>
					<SubtitleContainer>
						<Cover>
							<Subtitle>
								Your estimated budget is
							</Subtitle>
							<Paragraph>
								*This budget does not include flights
							</Paragraph>
						</Cover>

						<Field name="totalBudget" type='text' component={CurrencyInput} totalAmount={budgetCalculator(budget)} />
					</SubtitleContainer>
					<InButton
						type='button'
						onClick={() => setLoading(true)}
						text='SUBMIT'
						// disabled={this.props.disabled}
						htmlType='submit'
						primary='true'
						loading={loading}
					/>
				</Container>
				: <Container >
					<InTypography.InH1>
						Ah, bummer! We will let {step.birdieList} <br />
						know you are unable to attend.
					</InTypography.InH1>
					<SubtitleContainer>
						<Cover>
							<Subtitle>
								For future parties, use Invigo! <br /><br />
								The Smartest Bachelorette Suite from Start to Vegas
							</Subtitle>
						</Cover>
					</SubtitleContainer>
					<Space>
						<InButton
							type='button'
							onClick={() => goToSlide(1)}
							text='BACK'
							htmlType='button'
						/>
						<InButton
							type='button'
							onClick={() => setLoading(true)}
							text='SUBMIT'
							htmlType='submit'
							primary='true'
							loading={loading}
						/>
					</Space>
				</Container>}

		</Image>
	)
}