import React, { Component } from 'react';
import styled from "styled-components"
import { HeadingOne, HeadingOneUnderLine, Paragraph } from '../../../../../components/Text'
import { Button } from "antd";
import Login from '../../../../../containers/Login';
import LoginProvider, { LoginContext } from '../../../../../containers/LoginContainer'

const HeaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
`;

const TitleWrapper = styled.div`

`;

const TitleUnderlineContainer = styled.div`
  display: flex;
`;

const ParagraphWrapper = styled.div`
  width: 100%;
  p {
    color: ${props => props.theme.colors.tarawera};
    font-family: 'Proxima Nova';
    font-size: 20px;
    line-height: 32px;
    mix-blend-mode: normal;
    opacity: 0.65;
    margin-right: 1em;
  }
  
      @media (min-width: 786px) {
        width: 65%;
   }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 18px;
  
   @media (max-width: 480px) {
      flex-direction: column;
   }
`;

const Spacer = styled.div`
  width: 1em;
  height: 1em;
`;

const InButton = styled(Button)`
  background: ${props => props.primary ? '#EC6E5B' : '#ffffff'};
  border-color: #EC6E5B;
  width: ${props => props.width ? props.width : '172px'};
  height: 44px;
  border-radius: 4px;
  color: ${props => props.primary ? '#ffffff' : '#EC6E5B'};
  text-transform: uppercase;
  
  &:hover {
    background: ${props => props.primary ? '#EC6E5B' : '#ffffff'};
    border-color: #EC6E5B;
    color: ${props => props.primary ? '#ffffff' : '#EC6E5B'};
  }
  
  &:focus {
    background: ${props => props.primary ? '#EC6E5B' : '#ffffff'};
    border-color: #EC6E5B;
    color: ${props => props.primary ? '#ffffff' : '#EC6E5B'};
  }

`
const CarouselComponent = () => {

	return (
		<LoginProvider>
			<LoginContext.Consumer>
				{({
					submitUserDetails
				}) => {
					return (
						<HeaderWrapper>
							<TitleWrapper>
								<HeadingOne>
									Bachelorette Party
								</HeadingOne>
								<TitleUnderlineContainer>
									<HeadingOne>
										Planning&nbsp;
									</HeadingOne>
									<HeadingOneUnderLine>
										Made Easy.

									</HeadingOneUnderLine>
								</TitleUnderlineContainer>
							</TitleWrapper>

							<ParagraphWrapper>
								<Paragraph>
									Create, invite, and plan the ultimate bachelorette with
									Invigo’s suite of online bachelorette planning tools.<br />
									Let us be your Unofficial Maid of Honor!
								</Paragraph>
							</ParagraphWrapper>
							<ButtonContainer>
								<Login name='register' title="Get Started It's Free!" submitUserDetails={submitUserDetails} width='227px' primary />
								<Spacer />
								<InButton>Refer A Friend</InButton>
							</ButtonContainer>
						</HeaderWrapper>
					);
				}}
			</LoginContext.Consumer>
		</LoginProvider>
	)
}

export default CarouselComponent
