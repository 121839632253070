import { v4 as uuidv4 } from "uuid";
import { RSVPBRIDESET, GUEST, BRIDE, RSVPGUESTSET } from "../constants";
import notifications from "./notifications";

function generateBrideNotification(bride, eventData) {
	const link =
		process.env.NODE_ENV === "production"
			? `https://app.goinvigo.com/rsvp/${bride.id}/${eventData.id}`
			: `http://localhost:3000/rsvp/${bride.id}/${eventData.id}`;

	const smsmessage = `Yay, date and location are set for ${eventData.name} ! Let’s get your RSVP and a few other fun deets: ${link}`;

	const subject = `You are a bride at ${eventData.name} event! The date and location are set.`;

	const notificationData = {
		link: link,
		email: {
			address: bride.email,
			subject: subject,
			message: "",
			extra: {
				organiser: "",
				brides: "",
				title: "",
			},
		},
		sms: {
			number: bride.mobileNumber,
			message: smsmessage,
		},
	};

	const emailTemplate = RSVPBRIDESET;
	const messageType = {
		sms: true,
		email: true,
	};
	const salt = uuidv4();

	return {
		eventId: eventData.id,
		emailTemplate,
		userType: BRIDE,
		messageType,
		notificationData,
		salt,
	};
}

function generateGuestNotification(guest, eventData, brideNames) {
	const link =
		process.env.NODE_ENV === "production"
			? `https://app.goinvigo.com/rsvp/${guest.id}/${eventData.id}`
			: `http://localhost:3000/rsvp/${guest.id}/${eventData.id}`;

	// const smsmessage = `Hi! Help plan a bachelorette party for ${brideNames.join()}. RSVP here: ${link}`;
	const smsmessage = `Yay, date and location are set for ${eventData.name} ! Let’s get your RSVP and a few other fun deets: ${link}`;

	const subject = `Yay, date and location are set for ${eventData.name}!`;

	const notificationData = {
		link: link,
		email: {
			address: guest.email,
			subject: subject,
			message: "",
			extra: {
				organiser: eventData.organizer.displayName,
				brides: brideNames.join(),
				title: eventData.name,
			},
		},
		sms: {
			number: guest.mobileNumber,
			message: smsmessage,
		},
	};

	const emailTemplate = RSVPGUESTSET;
	const messageType = {
		sms: true,
		email: true,
	};
	const salt = uuidv4();
	return {
		eventId: eventData.id,
		emailTemplate,
		userType: GUEST,
		messageType,
		notificationData,
		salt,
	};
}

const sendToGuestList = async (event, guests) => {
	const brideNames = event.bride.map((bride, i) =>
		event.bride.length === i + 1 ? `${bride.fullname}'s` : bride.fullname
	);

	// event.brides.map(async (bride) => {
	//     console.log('bride promise:: ', bride)

	//     const notification = generateBrideNotification(bride, event)
	//             await notifications.add(
	//                 notification.eventId,
	//                 notification.emailTemplate,
	//                 notification.userType,
	//                 notification.messageType,
	//                 notification.notificationData,
	//                 notification.salt
	//             )

	//             return bride.id
	// });

	guests.forEach(async (guest) => {
		// standard forEach
		if (guest && guest.isBride) {
			const notification = generateBrideNotification(guest, event);
			console.log("bride promise:: ", notification.salt);
			await notifications.add(
				notification.eventId,
				notification.emailTemplate,
				notification.userType,
				notification.messageType,
				notification.notificationData,
				notification.salt
			);
		} else {
			const notification = generateGuestNotification(guest, event, brideNames);
			console.log("guest promise:: ", notification.salt);
			await notifications.add(
				notification.eventId,
				notification.emailTemplate,
				notification.userType,
				notification.messageType,
				notification.notificationData,
				notification.salt
			);
		}
	});
};

const sendToList = {
	guests: sendToGuestList,
};

export default sendToList;
