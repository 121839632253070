import React from "react";
import PropTypes from "prop-types";
import { TaskListsComponent } from "../../components";
import TaskListsProvider, { TaskListsContext } from "./TaskListsProvider";

const TaskLists = (props) => {
  return (
    <TaskListsProvider eventId={props.match.params.eventId}>
      <TaskListsContext.Consumer>
        {({ taskLists, myTasks, eventId, guests }) => {
          return (
            <div>
              <TaskListsComponent
                title="My Tasks"
                taskLists={taskLists}
                eventId={eventId}
                myTasks={myTasks}
                guests={guests}
              />
            </div>
          );
        }}
      </TaskListsContext.Consumer>
    </TaskListsProvider>
  );
};

TaskLists.defaultProps = {};

TaskLists.propTypes = {};

export default TaskLists;
