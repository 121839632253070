import React from "react";
import PropTypes from "prop-types";
import { reduxForm, initialize, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import styled from "styled-components";
import SettingsProvider, { SettingsContext } from "./SettingsProvider";
import { Layout, Col, Button, Form, Row } from "antd";
import { InTypography } from "../../components";
import ProfileUser from "./ProfileUser";
import PasswordUser from "./PasswordUser";
import NotificationsForm from "./notifications";

const { Content } = Layout;

export const FormItem = Form.Item;

export const FormWrapper = styled.form`
  margin: 0;
  .ant-form-item-label {
    text-align: left;
  }

  .ant-form-item-label label::after {
    content: "";
  }
`;

const Main = styled(Layout)`
  background: #ffffff;
  transition: all 0.2s linear;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 3rem;
`;

export const Wrapper = styled.div`
  margin-top: ${(props) => props.top};
  margin-right: 1em;
  margin-left: ${(props) => props.margin};
  ${(props) => props.theme.breakpoints.maxTablet} {
    margin-left: 1em;
  }
`;

export const Cards = styled.div`
  min-height: 292px;
  background: ${(props) => (props.bg ? props.bg : "#ffffff")};
  border-radius: 8px;
  margin: 1.25em 0 3.75em 0;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  min-height: 292px;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const ProfileImageContainer = styled.div`
  margin: 70px 64px;
  display: flex;
  justify-content: center;
`;

export const ProfileImage = styled.div``;

export const Image = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: column;
  background: transparent url(${(props) => props.src}) no-repeat center;
  background-size: cover;
  border-radius: 8px 8px 0 0;
  padding: 12px;

  button {
    border: none;
    outline: none;
    background: none;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 1em;
`;

export const Header = styled(Col)`
  height: 60px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  padding: 1.25em;
  justify-content: space-between;

  h5 {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 100%;
    color: #2e2c9a;
    margin-bottom: 0;
  }
  span {
    color: #2e2c9a;
    font-size: 15px;
    cursor: pointer;
  }
`;

export const Section = styled(Col)`
  padding: 1em;
`;

const BtnWrapper = styled(Button)`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 80%;
  margin: 0;
  width: 125px;

  background: ${(props) => (props.primary ? "#4F4DB8" : "#ffffff")};
  border-color: #4f4db8;
  height: 44px;
  border-radius: 4px;
  color: ${(props) => (props.primary ? "#ffffff" : "#2E2C9A")};
  text-transform: uppercase;

  &:hover {
    background: ${(props) => (props.primary ? "#2E2C9A" : "#ffffff")};
    border-color: #2e2c9a;
    color: ${(props) => (props.primary ? "#ffffff" : "#2E2C9A")};
  }

  &:focus {
    background: ${(props) => (props.primary ? "#2E2C9A" : "#ffffff")};
    border-color: #2e2c9a;
    color: ${(props) => (props.primary ? "#ffffff" : "#2E2C9A")};
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    width: auto;
  }
`;

const Settings = ({ dispatch, passwords, match, location }) => {
  const eventId = match.params.eventId;
  return (
    <SettingsProvider eventId={eventId}>
      <SettingsContext.Consumer>
        {({ logout, user, mobileNumber, guestName, isGuest }) => {
          const { photoURL } = user;
          return (
            <Main>
              <Content>
                <Wrapper
                  top={location.pathname === "/settings" ? "85px" : "25px"}
                  margin={location.pathname === "/settings" ? "0 1em" : "0 1em 0 0"}
                >
                  <Container>
                    <InTypography.InH2>Settings</InTypography.InH2>
                    <BtnWrapper onClick={() => logout()} text="" htmlType="button">
                      LOG OUT
                    </BtnWrapper>
                  </Container>
                  <Cards>
                    <ProfileUser
                      user={user}
                      photoURL={photoURL}
                      mobileNumber={mobileNumber}
                      guestName={guestName}
                      eventId={eventId}
                      isGuest={isGuest}
                      dispatch={dispatch}
                    />
                    <PasswordUser dispatch={dispatch} passwords={passwords} />
                    <Row>
                      <Header xs={24}>
                        <h5>NOTIFICATIONS</h5>
                      </Header>
                      <Section xs={24}>
                        <NotificationsForm />
                      </Section>
                      <br />
                      <br />
                    </Row>
                  </Cards>
                </Wrapper>
              </Content>
            </Main>
          );
        }}
      </SettingsContext.Consumer>
    </SettingsProvider>
  );
};

Settings.defaultProps = {};

Settings.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = "Required";
  }

  if (!values.email) {
    errors.email = "Required";
  }

  if (!values.mobileNumber) {
    errors.mobileNumber = "Required";
  }
  return errors;
};

const SettingsForm = reduxForm({
  form: `settings`,
  validate,
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(Settings);

const selector = formValueSelector("settings");

export function mapStateToProps(state) {
  return {
    formState: state.form.settings,
    passwords: {
      currentPassword: selector(state, "currentPassword"),
      newPassword: selector(state, "newPassword"),
      confirmPassword: selector(state, "confirmPassword"),
    },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    formInit: (data) => {
      dispatch(initialize("settings", data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsForm);
