import React from 'react';
import { Row, Col } from 'antd';
import styled from "styled-components";
import ImageBanner from './ImageSection'
import TextSection from './TextSection'
import { MaxWidthContainer } from '../../../../components'

export const Wrapper = styled.div`
    //width: 100%;
`;

export const GridItem = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

function DashboardSection() {
    return (
        <MaxWidthContainer>
            <Row>
                <Col xs={24} md={12}>
                    <ImageBanner />
                </Col>
                <GridItem xs={24} md={12}>
                    <TextSection />
                </GridItem>
            </Row>
        </MaxWidthContainer>
    );
}

export default DashboardSection


