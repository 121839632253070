import React from 'react';
import styled from "styled-components";
import {Menu} from "antd";

const Wrapper = styled.svg`
    fill: #494D5B;
     stroke: #494D5B;
    
    &:hover {
      fill: #2E2C9A;
     stroke: #2E2C9A;
    }
`

export default ({ style }) => (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.15776 0.544863C1.43966 0.260958 1.8986 0.260147 2.1815 0.543055L4.98372 3.34527L7.78594 0.543054C8.06885 0.260146 8.52778 0.260958 8.80969 0.544863C9.09019 0.827354 9.08938 1.2835 8.80789 1.565L5.12514 5.24774C5.04704 5.32585 4.92041 5.32585 4.8423 5.24774L1.15956 1.565C0.878062 1.2835 0.877257 0.827353 1.15776 0.544863Z" fill="#4F4DB8"/>
    </svg>
);
