import React from 'react'
import { motion } from "framer-motion"
import styled, { keyframes } from 'styled-components'
import { useHistory } from "react-router-dom";
import { Progress } from 'antd';
import PageTemplate from "./PageTemplate";
import { RsvpContext } from "../../containers/Rsvp/RsvpProvider";
import { Intro } from './FormComponents/noDataWizardIntro'

const Wrapper = styled.div`

`


export const Header = styled.div`
    width: 105%;
    height: 3vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: black;
    position: fixed;
    left: -8px;
    top: 50px;
    background-color: none;
    z-index: 999;
`;

export const Divider = styled.div`
    height: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 5px solid #E8E8E8;
    margin: 0;
    //max-width: 1325px;
    
    .MuiLinearProgress-colorPrimary {
    //background-color: rgb(255, 255, 255);
}
`;

export const Content = styled.div`
    width: 100%;
    height: 100vh;
`;

const fadeIn = keyframes`
0% { opacity: 0; }
100% { opacity: 1; }
`

const fadeOut = keyframes`
0%   { opacity: 1; }
20%   { opacity: 0.8; }
40%   { opacity: 0.6; }
60%   { opacity: 0.4; }
80%   { opacity: 0.2; }
100% { opacity: 0; }
`

export const IntroWrapper = styled.div`
width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

.enter {
  animation: ${fadeIn} 0s linear;
}
.exit {
  animation: ${fadeOut} 0s linear;
  visibility: hidden;
}
`;

const Progressbar = ({ progress }) => (
    <>
      <Progress
        strokeColor={{
          '0%': '#FF8E95',
          '100%': '#FF8E95',
        }}
        percent={progress}
        showInfo={false}
      />
    </>
  );
  
  export const MyComponent = () => (
    <motion.div
      animate={{
        scale: [1, 2, 2, 1, 1],
        rotate: [0, 0, 270, 270, 0],
        borderRadius: ["20%", "20%", "50%", "50%", "20%"],
      }}
    />
  )

  const pageVariants = {
    initial: {
        opacity: 0,
        x: "100vw",
        scale: 0.95
    },
    in: {
        opacity: 1,
        x: 0,
        scale: 1
    },
    out: {
        opacity: 0,
        x: "-100vw",
        scale: 1
    }
};

const pageTransition = {
  type: "tween",
  ease: "anticipate",
  duration: 0.5
};

const pageStyle = {
  // position: "absolute"
};

const RsvpWizard = () => {
    const history = useHistory();
  return (
    <Wrapper>
        <RsvpContext.Consumer>
            {({ isAuthenticated, guests, data, userId, eventId, steps, calcProgress, progress, isSetDetails, isBride, guestDetails}) => {
                return (
                    <div>
                        <Header>
                            <Divider>
                            <Progressbar progress={progress} />
                            </Divider>
                        </Header>
                        {isAuthenticated
                         ?
                            <PageTemplate
                                isSetDetails={isSetDetails}
                                steps={steps}
                                guestList={guests}
                                history={history}
                                userId={userId}
                                eventId={eventId}
                                data={data}
                                calcProgress={calcProgress}
                                isBride={isBride}
                                guestDetails={guestDetails}
                            />   
                          : ''}            
                    </div>
                )
            }}
        </RsvpContext.Consumer>
    </Wrapper>
  );
};

RsvpWizard.defaultProps = {

};

RsvpWizard.propTypes = {

};

export default RsvpWizard;
