import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Form } from "antd";
import { CustomModal, CustomModalLayout } from "../../";
import { initialize, reduxForm } from "redux-form";
import { connect } from "react-redux";
import GuestList from "./GuestList/GuestList";
import { guests } from "../../../helpers/firestore";
const FormItem = Form.Item;

const FormWrapper = styled.form`
  max-width: 670px;
  margin: 6.75em auto 0 auto;

  .ant-form-item-label {
    text-align: left;
  }

  .ant-form-item-label label::after {
    content: "";
  }
`;

export async function addGuestToList(values, eventId, toggleLoader, request = fetch) {
  const requestObject = {
    data: {
      guest: values.guest,
      eventId,
    },
  };
  toggleLoader();

  const postBody = JSON.stringify(requestObject);

  let url;
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    url = `${process.env.REACT_APP_ADD_GUEST_TO_LIST_DEV}`;
  } else {
    url = `${process.env.REACT_APP_ADD_GUEST_TO_LIST_PROD}`;
  }
  try {
    const response = await request(url, { method: "POST", body: postBody });
    if (response.status === 200) {
      const body = await response;
      return body.body;
    }
  } catch (error) {
    console.log("errors: ", error);
  }
}

const AddGuests = ({ handleSubmit, visible, handleOk, handleCancel, eventId }) => {
  const [loading, setLoader] = useState(false);

  const toggleLoader = () => {
    setLoader(!loading);
  };

  const initForm = (data, done) => {
    guests.add(eventId, data.guest).then((res) => {
      console.log("submit res", res);
      // toggleLoader();
      setLoader(false);
      handleCancel();
    });
  };

  return (
    <FormWrapper onSubmit={handleSubmit((values) => console.log("submit guest", values, eventId))}>
      <FormItem>
        <CustomModal visible={visible} handleOk={handleOk} handleCancel={handleCancel}>
          <CustomModalLayout handleCancel={handleCancel} title="Start your Guestlist">
            <GuestList countryCode="ZA" initForm={initForm} handleCancel={handleCancel} loading={loading} />
          </CustomModalLayout>
        </CustomModal>
      </FormItem>
    </FormWrapper>
  );
};

AddGuests.defaultProps = {};

AddGuests.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const AddGuestsForm = reduxForm({
  form: `guests`,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(AddGuests);

export function mapStateToProps(state) {
  return {
    uid: state.auth.user.user.uid,
    formState: state.form.createEvent,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    formInit: (data) => {
      dispatch(initialize("guests", data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddGuestsForm);
