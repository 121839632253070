import React from 'react'
import AppContextProvider from './ContextProvider'
import { connect } from "react-redux"
import { BrowserRouter } from "react-router-dom";
import Routes from './Routes'
import 'antd/dist/antd.css'

const App = ({ isAuthenticated, isVerifying, user, dispatch }) => {
  return (
      <AppContextProvider user={user} dispatch={dispatch}>
          <BrowserRouter>
            <Routes isAuthenticated={isAuthenticated} isVerifying={isVerifying}  />
          </BrowserRouter>
      </AppContextProvider>
  )
}

function mapStateToProps(state) {
    return {
        user: state.auth && state.auth.user ? state.auth.user : null,
        isAuthenticated: state.auth.isAuthenticated,
        isVerifying: state.auth.isVerifying
    };
}

export default connect(mapStateToProps, null)(App);
