import React, { Component } from 'react'
import { connect } from 'react-redux'
import { itinerary, recommendations, polls } from "../../helpers/firestore";
export const PollsContext = React.createContext()

class PollsProvider extends Component {
  constructor(props) {
    super(props)

    this.state = {
      itineraryData: null,
      recommendations: [],
      polls: [],
    }
  }

  componentDidMount() {
    this.getData(this.props.eventId)
  }

  setItineraryData = (itineraryData) => {
    this.setState({ itineraryData })
  };

  setRecommendationsData = (recommendations) => {
    this.setState({ recommendations })
  };

  setPollList = (polls) => {
    this.setState({ polls })
  };

  getData = (eventId) => {
    itinerary.getItineraryData(eventId, this.setItineraryData)
    recommendations.get(eventId, this.setRecommendationsData)
    polls.getPolls(eventId, this.setPollList)
  }

  render() {
    const { itineraryData, recommendations, polls } = this.state
    const { children, event, eventId  } = this.props

    const providerData = {
      eventId,
      event,
      itineraryData,
      recommendations,
      polls
    }
    return (
      <PollsContext.Provider value={providerData}>
        {children}
      </PollsContext.Provider>
    )
  }
}

PollsProvider.defaultProps = {
// <!--  children: PropTypes.object-->
};

PollsProvider.propTypes = {
// <!--  children: {}-->
};

function mapStateToProps (state) {
  return {
    //redux data
  }
}

export default connect(mapStateToProps, null)(PollsProvider)