import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
	Field,
	FieldArray,
	reduxForm,
	FormSection,
	formValueSelector,
	initialize,
	change,
	reset,
} from "redux-form";
import styled from "styled-components";
import { Button, Checkbox, Select, Row } from "antd";
import {
	InInput,
	InDatePicker,
	InMultiSelect,
	InSelect,
} from "./FormComponents";
import { SaveOutlined } from "@ant-design/icons";
import { tasks } from "../../../helpers/firestore";
import {
	sortDates,
	timestampToDate,
	timestampToDateDatePicker,
} from "../../../helpers/normalizeDate";
import moment from "moment";

const { Option } = Select;

const FormWrapper = styled.form`
  .ant-form-item-label {
    text-align: left;
  }

  .ant-form-item-label label::after {
    content: "";
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
`;

// ****************************************************

const Table = styled.div`
  background: ${(props) => (props.header ? "none" : "#ffffff")};
  border-radius: 8px;
  margin: 0.5em 0;
  padding: 0;

  border: 1px solid #e9e8f0;
  box-sizing: border-box;
  border-radius: 8px;

  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 64px;
  }

  @media all and (max-width: 1023px) {
    display: ${(props) => (props.header ? "none" : "block")};
  }
`;

const TableCell = styled.div`
  width: ${(props) => (props.width ? props.width : "11.42%")};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  padding: 0 1em;

  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #101b43;
  text-align: left;

  .ant-form-item {
    margin: 0;
    width: 100%;
  }

  @media all and (max-width: 1023px) {
    width: 100%;
    padding: 0.5em 1em;
  }
`;

const RowHead = styled.div`
  width: 3%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-grow: 1;
  height: 100%;
  padding: 0.5em 1em;

  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  /* Text/Endeavour */

  color: #2e2c9a;

  @media all and (max-width: 1023px) {
    width: 100%;
    border-radius: 8px 8px 0 0;
  }

  @media (min-width: 1024px) {
    border-radius: 0 8px 8px 0;
  }
`;

const RowFooter = styled.div`
  width: 5%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-grow: 1;
  height: 100%;
  padding: 0.5em 1em;

  @media all and (max-width: 1023px) {
    width: 100%;
  }

  @media (min-width: 1024px) {
    border-radius: 0 8px 8px 0;
  }
`;

const IconButton = styled(Button)`
  background: ${(props) => (props.primary ? "#4F4DB8" : "#ffffff")};
  border-color: #4f4db8;
  height: 44px;
  border-radius: 4px;
  color: ${(props) => (props.primary ? "#ffffff" : "#2E2C9A")};
  text-transform: uppercase;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 80%;
  display: flex;
  align-items: center;

  &:hover {
    background: ${(props) => (props.primary ? "#2E2C9A" : "#ffffff")};
    border-color: #2e2c9a;
    color: ${(props) => (props.primary ? "#ffffff" : "#2E2C9A")};
  }

  &:focus {
    background: ${(props) => (props.primary ? "#2E2C9A" : "#ffffff")};
    border-color: #2e2c9a;
    color: ${(props) => (props.primary ? "#ffffff" : "#2E2C9A")};
  }
`;

const CheckItem = ({ item }) => {
	const [checkValue, setCheck] = useState(false);
	const handleChange = (input) => {
		setCheck(!checkValue);
	};
	return (
		<Checkbox
			name={item.name}
			size="large"
			onChange={(e) => handleChange(e)}
			checked={checkValue}
		/>
	);
};

const sortGuests = (guests) => {
	return guests.filter(guest => guest && guest.state !== "declined").map((guest) => {
		return {
			label: guest.name,
			value: guest.id,
			id: guest.id,
		};
	});
};

const EditSingleTask = ({
	handleSubmit,
	pristine,
	submitting,
	guests,
	singleTask,
	formInit,
	activeList,
	handleClose,
}) => {
	const guestList = sortGuests(guests);

	useEffect(() => {
		if (!activeList.tasks || !singleTask) return;
		const task = activeList.tasks.filter(
			(task) => task.id === singleTask.id
		)[0];

		formInit({
			name: task.name,
			due:
				task.due === ""
					? ""
					: moment(timestampToDateDatePicker(task.due), "YYYY-MM-DD"),
			assignTo: task.assignTo.map((assign) => assign.id),
			description: task.description,
			id: task.id,
		});
	}, [formInit, singleTask]);

	return (
		<FormWrapper
			onSubmit={handleSubmit((task) => {
				const newTask = {
					...task,
					due: task.due === "" || task.due === null ? "" : sortDates(task.due),
				};
				const data = {
					eventId: activeList.eventId,
					...newTask,
					taskListId: activeList.id,
					assignTo: task.assignTo.map((guestId) => ({ id: guestId })),
				};

				tasks.updateTask(data);
				handleClose();
			})}
		>
			<Table>
				<TableCell width="20%">
					<Field
						name="name"
						component={InInput}
						placeholder="Task Name"
						bordered
						hasFeedback
					/>
				</TableCell>
				<TableCell width="20%">
					<Field
						name="due"
						component={InDatePicker}
						placeholder="Date"
						bordered
						hasFeedback
					/>
				</TableCell>
				<TableCell width="22%">
					<Field
						name="assignTo"
						component={InMultiSelect}
						bordered
						placeholder="Assign To"
						hasFeedback
						mode="multiple"
						style={{ width: "100%" }}
					>
						{guestList.map((guest, index) => {
							return (
								<Option key={index} value={guest.id} label={guest.label}>
									{guest.label}
								</Option>
							);
						})}
					</Field>
				</TableCell>
				<TableCell width="30%">
					<Field
						name="description"
						component={InInput}
						bordered
						placeholder="Description"
						hasFeedback
					/>
				</TableCell>
				<RowFooter />
			</Table>

			<IconButton
				primary
				icon={<SaveOutlined size="large" />}
				disabled={pristine || submitting}
				htmlType="submit"
			>
				UPDATE TASK
			</IconButton>
		</FormWrapper>
	);
};

EditSingleTask.defaultProps = {};

EditSingleTask.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
};

const validate = (values) => {
	const errors = {};
	if (!values.name) {
		errors.name = "Required";
	}

	// if (!values.due) {
	//     errors.due = "Required";
	// }

	if (!values.assignTo) {
		errors.assignTo = "Required";
	}

	// if (!values.description) {
	//     errors.description = "Required";
	// }
	return errors;
};

const EditSingleTaskForm = reduxForm({
	form: `editSingleTask`,
	validate,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
})(EditSingleTask);


export function mapStateToProps(state) {
	return {
		formState: state.form.editSingleTask,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		formInit: (data) => {
			dispatch(initialize("editSingleTask", data));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(EditSingleTaskForm);
