import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ReactSelect from "react-select";
import { Form, Input, Radio, Select, Checkbox, DatePicker } from "antd";

const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 24 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 24 },
	},
};

const FormField = styled(FormItem)``;

const radioStyle = {
	display: "block",
	height: "30px",
	lineHeight: "30px",
};

const makeRadioGroup =
	(Component) =>
		({ input, meta, children, options, hasFeedback, label, ...rest }) => {
			const hasError = meta.touched && meta.invalid;
			return (
				<FormField
					{...formItemLayout}
					label={label}
					validateStatus={hasError ? "error" : "success"}
					hasFeedback={hasFeedback && hasError}
					help={hasError && meta.error}
				>
					<Component {...input} {...rest} buttonStyle="solid">
						{options.map((option) => {
							return option.value === "other" ? (
								<Radio style={radioStyle} value={option.value}>
									{option.title}
									{option.value ? (
										<Input {...input} style={{ width: 100, marginLeft: 10 }} />
									) : null}
								</Radio>
							) : (
								<Radio style={radioStyle} value={option.value}>
									{option.title}
								</Radio>
							);
						})}
					</Component>
				</FormField>
			);
		};

const makeCheckboxGroup =
	(Component) =>
		({ input, meta, children, options, hasFeedback, label, ...rest }) => {
			const hasError = meta.touched && meta.invalid;
			return (
				<FormField
					{...formItemLayout}
					label={label}
					validateStatus={hasError ? "error" : "success"}
					hasFeedback={hasFeedback && hasError}
					help={hasError && meta.error}
				>
					<Component {...input} {...rest} buttonStyle="solid">
						{options.map((option) => {
							return option.value === "other" ? (
								<Radio style={radioStyle} value={option.value}>
									{option.title}
									{option.value ? (
										<Input {...input} style={{ width: 100, marginLeft: 10 }} />
									) : null}
								</Radio>
							) : (
								<Radio style={radioStyle} value={option.value}>
									{option.title}
								</Radio>
							);
						})}
					</Component>
				</FormField>
			);
		};

export const ImageGroup = styled.div`
  margin-top: 62px;
  //width: 100%;
  //height: 100%;
  //border: 1px solid red;
`;

export const Image = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: column;
  background: transparent url(${(props) => props.src}) no-repeat center;
  background-size: cover;
  border-radius: 8px 8px 0 0;
  padding: 12px;
`;

const makeRadioImageGroup =
	(Component) =>
		({ input, image, meta, children, options, hasFeedback, label, ...rest }) => {
			const hasError = meta.touched && meta.invalid;
			return (
				<ImageGroup>
					<Image src={image} />
					<FormField
						{...formItemLayout}
						label={label}
						validateStatus={hasError ? "error" : "success"}
						hasFeedback={hasFeedback && hasError}
						help={hasError && meta.error}
					>
						<Component {...input} {...rest} buttonStyle="solid">
							{options.map((option) => {
								return (
									<Radio style={radioStyle} value={option.value}>
										{option.title}
									</Radio>
								);
							})}
						</Component>
					</FormField>
				</ImageGroup>
			);
		};

const FormFieldItem = styled(FormItem)`
  max-width: 31.5em;

  label {
    font-family: "Clearface Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 140%;
    color: #101b43;
  }

  &:focus {
    border: 1px solid #4f4db8;
  }

  input {
    height: 44px;
    margin-top: 1.5em;
    outline: none;

    &:hover {
      border: 1px solid #4f4db8;
    }

    &:focus {
      border: 1px solid #4f4db8;
    }
  }
`;

const FieldItem = styled(FormItem)`
  max-width: 31.5em;

  label {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #101b43;
  }

  &:focus {
    border: 1px solid #4f4db8;
  }

  input,
  textarea {
    height: 50px;
    outline: none;
    border-radius: 4px;

    &:hover {
      border: 1px solid #4f4db8;
    }

    &:focus {
      border: 1px solid #4f4db8;
    }
  }
`;

const SelectFieldItem = styled(FormItem)`
  max-width: 31.5em;
  position: relative;

  label {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #101b43;
  }

  &:focus {
    border: 1px solid #4f4db8;
  }

  input,
  select {
    height: 44px;
    outline: none;

    &:hover {
      border: 1px solid #4f4db8;
    }

    &:focus {
      border: 1px solid #4f4db8;
    }
  }
`;

const AltSelectFieldItem = styled(FormItem)`
  max-width: 31.5em;
  position: relative;

  label {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #101b43;
  }

  &:focus {
    border: 1px solid #4f4db8;
  }

  input,
  select {
    height: 44px;
    outline: none;

    &:hover {
      border: 1px solid #4f4db8;
    }

    &:focus {
      border: 1px solid #4f4db8;
    }
  }

  label {
    font-family: "Clearface Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 140%;
    color: #101b43;
  }
`;

const makeField =
	(Component) =>
		({ input, meta, children, hasFeedback, label, subLabel, ...rest }) => {
			const hasError = meta.touched && meta.invalid;
			return (
				<FieldItem
					{...formItemLayout}
					label={label}
					validateStatus={hasError ? "error" : "success"}
					hasFeedback={hasFeedback && hasError}
					help={hasError && meta.error}
				>
					<Component rows={4} {...input} {...rest} children={children} />
				</FieldItem>
			);
		};

const makeAltField =
	(Component) =>
		({ input, meta, children, hasFeedback, label, ...rest }) => {
			const hasError = meta.touched && meta.invalid;
			return (
				<FormFieldItem
					{...formItemLayout}
					label={label}
					validateStatus={hasError ? "error" : "success"}
					hasFeedback={hasFeedback && hasError}
					help={hasError && meta.error}
				>
					<Component {...input} {...rest} children={children} />
				</FormFieldItem>
			);
		};

const SelectWrapper = styled.div`
  position: relative;
  max-width: 440px;
  width: 100%;
`;

const SubLabel = styled.h5`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #8696ae;
  position: absolute;
  left: 228px;
  top: 8px;
  ${props => props.theme.breakpoints.maxPhone} {
	  left: auto;
	  right: 0;
	  font-size: 10px;
  }
`;

const makeSelectField =
	(Component) =>
		({
			input,
			options,
			meta,
			children,
			hasFeedback,
			label,
			subLabel,
			...rest
		}) => {
			const hasError = meta.touched && meta.invalid;
			const handleChange = (e, input) => {
				return input.onChange(e);
			};

			return (
				<SelectWrapper>
					{subLabel && <SubLabel>{subLabel}</SubLabel>}
					<SelectFieldItem
						{...formItemLayout}
						label={label}
						validateStatus={hasError ? "error" : "success"}
						hasFeedback={hasFeedback && hasError}
						help={hasError && meta.error}
						size="large"
					>
						<Component
							{...input}
							{...rest}
							children={children}
							onChange={(e) => input.onChange(e)}
							onFocus={(e) => e.preventDefault()}
							onBlur={(e) => e.preventDefault()}
							size="large"
						/>
					</SelectFieldItem>
				</SelectWrapper>
			);
		};

const makeAltSelectField =
	(Component) =>
		({
			input,
			options,
			meta,
			children,
			hasFeedback,
			label,
			subLabel,
			...rest
		}) => {
			const hasError = meta.touched && meta.invalid;
			const handleChange = (e, input) => {
				return input.onChange(e);
			};

			return (
				<SelectWrapper>
					{subLabel && <SubLabel>{subLabel}</SubLabel>}
					<SelectFieldItem
						{...formItemLayout}
						label={label}
						validateStatus={hasError ? "error" : "success"}
						hasFeedback={hasFeedback && hasError}
						help={hasError && meta.error}
						size="large"
					>
						<Component
							{...input}
							{...rest}
							children={children}
							onChange={(e) => input.onChange(e)}
							onFocus={(e) => e.preventDefault()}
							onBlur={(e) => e.preventDefault()}
							size="large"
						/>
					</SelectFieldItem>
				</SelectWrapper>
			);
		};

// const InSelect = MakeSelectField(Select);
//
// const selectField = ({ input, label, ...rest }) => {
//   return (
//       <Field label="Favoraite Color" name="favoriteColor" component={InSelect} {...rest}>
//         <Option value="ff0000">Red</Option>
//         <Option value="00ff00">Green</Option>
//         <Option value="0000ff">Blue</Option>
//       </Field>
//   );
// };

const makeDatePicker =
	(Component) =>
		({ input, meta, children, options, hasFeedback, label, ...rest }) => {
			const hasError = meta.touched && meta.invalid;

			const handleChange = (e, input) => {
				return input.onChange(e);
			};
			return (
				<FormField
					{...formItemLayout}
					label={label}
					validateStatus={hasError ? "error" : "success"}
					hasFeedback={hasFeedback && hasError}
					help={hasError && meta.error}
				>
					<Component
						{...input}
						{...rest}
						children={children}
						onChange={(e) => handleChange(e, input)}
						onFocus={(e) => e.preventDefault()}
						onBlur={(e) => e.preventDefault()}
					/>
				</FormField>
			);
		};

const SubText = styled.h3`
  max-width: 670px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 120%;
  color: #101b43;
  margin: 0;
  padding: 0 0 31px 0;
`;

const makeTextArea =
	(Component) =>
		({ input, meta, children, hasFeedback, label, subLabel, rows, ...rest }) => {
			const hasError = meta.touched && meta.invalid;
			return (
				<div>
					{subLabel && <SubText>{subLabel}</SubText>}
					<FieldItem
						{...formItemLayout}
						label={label}
						validateStatus={hasError ? "error" : "success"}
						hasFeedback={hasFeedback && hasError}
						help={hasError && meta.error}
					>
						<Component
							rows={rows || 4}
							{...input}
							{...rest}
							children={children}
						/>
					</FieldItem>
				</div>
			);
		};

const FieldItemSelect = styled(FormItem)`
  label {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #101b43;
  }

  &:focus {
    border: 1px solid #101b43;
  }

  select {
    outline: none;
    border-radius: 4px;
    height: 40px;

    &:hover {
      border: 1px solid #101b43;
    }

    &:focus {
      border: 1px solid #101b43;
    }
  }

  input {
    outline: none;
    border-radius: 4px;
    height: 40px;

    &:hover {
      border: 1px solid #101b43;
    }

    &:focus {
      border: 1px solid #101b43;
    }
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }
`;

const makeReactSelect =
	(Component) =>
		({
			input,
			meta,
			children,
			options,
			defaultValue,
			hasFeedback,
			label,
			subLabel,
			...rest
		}) => {
			const hasError = meta.touched && meta.invalid;
			const handleChange = (e, input) => {
				if (e && e.find((item) => item.label === "Everyone")) {
					const all = e.find((item) => item.label === "Everyone");
					return input.onChange(
						all.everyone.filter((item) => item.label !== "Everyone")
					);
				}
				return input.onChange(e);
			};

			return (
				<SelectWrapper>
					{subLabel && <SubLabel>{subLabel}</SubLabel>}
					<FieldItemSelect
						{...formItemLayout}
						label={label}
						validateStatus={hasError ? "error" : "success"}
						hasFeedback={hasFeedback && hasError}
					// help={hasError && meta.error}
					>
						<Component
							{...input}
							{...rest}
							// closeMenuOnSelect={false}
							isMulti
							children={children}
							onChange={(e) => handleChange(e, input)}
							onFocus={(e) => e.preventDefault()}
							onBlur={(e) => e.preventDefault()}
							options={options}
							defaultValue={options}
						/>
					</FieldItemSelect>
				</SelectWrapper>
			);
		};

const AltSelectWrapper = styled.div`
  position: relative;
  max-width: 440px;
  width: 100%;

  label {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #101b43;
  }
`;

const AltLabel = styled.h5`
  font-family: "Clearface Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 140%;
  color: #101b43;
`;

const makeReactAltSelect =
	(Component) =>
		({
			input,
			meta,
			children,
			options,
			defaultValue,
			hasFeedback,
			label,
			subLabel,
			...rest
		}) => {
			const hasError = meta.touched && meta.invalid;
			const handleChange = (e, input) => {
				if (e && e.find((item) => item.label === "Everyone")) {
					const all = e.find((item) => item.label === "Everyone");
					return input.onChange(
						all.everyone.filter((item) => item.label !== "Everyone")
					);
				}
				return input.onChange(e);
			};

			return (
				<AltSelectWrapper>
					{label && <AltLabel>{label}</AltLabel>}
					<FieldItemSelect
						{...formItemLayout}
						label={subLabel}
						validateStatus={hasError ? "error" : "success"}
						hasFeedback={hasFeedback && hasError}
					// help={hasError && meta.error}
					>
						<Component
							{...input}
							{...rest}
							// closeMenuOnSelect={false}
							isMulti
							children={children}
							onChange={(e) => handleChange(e, input)}
							onFocus={(e) => e.preventDefault()}
							onBlur={(e) => e.preventDefault()}
							options={options}
							defaultValue={options}
						/>
					</FieldItemSelect>
				</AltSelectWrapper>
			);
		};

export const AInput = makeField(Input);
export const AInputAlt = makeAltField(Input);
export const ARadioGroup = makeRadioGroup(RadioGroup);
export const ARadioImageGroup = makeRadioImageGroup(RadioGroup);
// export const ASelect = makeField(Select);
export const ASelect = makeReactSelect(ReactSelect);
export const AltSelect = makeReactAltSelect(ReactSelect);
export const ACheckbox = makeField(Checkbox);
export const ACheckboxGroup = makeCheckboxGroup(Checkbox.Group);
export const ATextarea = makeTextArea(TextArea);
export const ARangePicker = makeField(RangePicker);
export const ADatePicker = makeDatePicker(DatePicker);
export const InReactSelect = makeReactSelect(ReactSelect);
