import React from 'react';
import styled from 'styled-components'
import { Link } from 'react-router-dom';
import { Col, Row, Button } from "antd";
import { ScheduleContext } from "../../containers/Schedule/ScheduleProvider";
import { ScheduleSidebar } from "../../components";
import { InTypography } from "../";
import image from '../../assets/itinerary/vector-creator.png'

const Main = styled.div`
  margin: 1.25em 1em 0 0;
	${props => props.theme.breakpoints.maxTablet} {
		margin-left: 0;
		margin-right: 0;
	}

`

const SiderActivityContainer = styled(Col)`
${props => props.theme.breakpoints.maxPhone} {
	padding: 0 30px !important;
}
		${props => props.theme.breakpoints.desktop} {
			 //margin-top: 25px;
			 height: 85vh;
    position: absolute;
    right: 1em;
    overflow-x: hidden;
    overflow-y: auto;
    max-width: 376px;
    ::-webkit-scrollbar {
      display: none;
    }
  	}
`;

const Header = styled.div`
	text-align: center;
	padding-top: 50px;
	padding-bottom: 50px;
	padding-left: 13%;
	padding-right: 13%;
	h1 {
		color: #2E2C9A;

	}
	${props => props.theme.breakpoints.maxPhone} {
		padding-left: 0;
		padding-right: 0;
	}
`

export const TextWrapper = styled.div`
    margin: 15px 0 20px 0;
		p {
			color: #8696AE;
		}
`;

const InButton = styled(Button)`
  background: transparent;
  border-color: #2E2C9A;
  border: 1px solid;
  width: ${props => props.width ? props.width : '172px'};
  height: 44px;
  border-radius: 4px;
  color: #2E2C9A;
  text-transform: uppercase;
  
  &:hover {
    background: ${props => props.primary ? '#2E2C9A' : '#ffffff'};
    border-color: #2E2C9A;
    color: ${props => props.primary ? '#ffffff' : '#2E2C9A'};
  }
  
  &:focus {
    background: ${props => props.primary ? '#2E2C9A' : '#ffffff'};
    border-color: #2E2C9A;
    color: ${props => props.primary ? '#ffffff' : '#2E2C9A'};
  }

`

const LinkButton = styled(Link)`

  background: transparent;
  border-color: #2E2C9A;
  width: ${props => props.width ? props.width : '200px'};
  height: 44px;
  border-radius: 4px;
  text-transform: uppercase;
  border: 1px solid;
  
  font-family: 'Proxima Nova Semibold';
font-style: normal;
font-weight: 600;
font-size: 15px;
line-height: 44px;
/* identical to box height, or 12px */
color: #2E2C9A;

display: inline-block;


 color: #2E2C9A;
  text-transform: uppercase;
  
  &:hover {
    background: ${props => props.primary ? '#2E2C9A' : '#ffffff'};
    border-color: #2E2C9A;
    color: ${props => props.primary ? '#ffffff' : '#2E2C9A'};
  }
  
  &:focus {
    background: ${props => props.primary ? '#2E2C9A' : '#ffffff'};
    border-color: #2E2C9A;
    color: ${props => props.primary ? '#ffffff' : '#2E2C9A'};
  }

`

export const Image = styled.div`
	img {
		width: 100%;
		height: auto;
	}
`;


const ScheduleGuests = ({ pathUrl }) => {
	return (<ScheduleContext.Consumer>
		{({ event, brides, activeMenu, toggleMenu, polls }) => {
			return (<Main>
				<Row gutter={{ xs: 32, md: 16 }}>
					<Col xs={{ order: 2, span: 24 }} lg={{ span: 16, order: 1 }}>
						<Header>
							<InTypography.InH1>
								The event organizer is planning a memorable trip for the group!
							</InTypography.InH1>
							<TextWrapper>
								<InTypography.InParagraph>
									Check back in to see the completed itinerary!
								</InTypography.InParagraph>
							</TextWrapper>
							<LinkButton
								to={`${pathUrl}/dashboard`}
								primary
							>
								Back to Dashboard
							</LinkButton>
						</Header>
						<Image>
							<img src={image} alt="" />
						</Image>
					</Col>
					<SiderActivityContainer xs={{ span: 24, order: 1 }} lg={{ span: 8, order: 2 }}>
						<ScheduleSidebar
							completeSchedule={event.timeline.completeSchedule}
							itineraryData={event.itinerary}
							brides={brides}
							activeMenu={activeMenu}
							toggleMenu={toggleMenu}
							polls={polls}
						// initForm={initForm}
						// registeredValues={formState && formState.values ? formState.values : null}
						/>
					</SiderActivityContainer>
				</Row>
			</Main>)
		}}</ScheduleContext.Consumer>)



}

export default ScheduleGuests;