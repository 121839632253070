import React from 'react';
import styled from 'styled-components'
import { Drawer, Button, Skeleton, Modal } from 'antd';
import backIcon from "../../../assets/icons/backArrow.svg";
import EditSingleTaskForm from './EditSingleTaskForm';


const IconButton = styled.button`
    padding: 0;
    margin: 0;
    outline: none;
    width: 44px;
    height: 44px;
    color: #494D5B;
    background: #FFFFFF;
    border: 1px solid #EEEEEF;
    box-sizing: border-box;
    border-radius: 4px;
`

const Title = styled.div`
    display: flex;
    align-items: center;
    width: 100%;

    h2 {        
        font-family: 'Clearface Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 34px;
        color: #101B43;
        margin: 0 1em;
    }
`



const SubHeading = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    
    p {        
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #101B43;
        max-width: 47.75em;
        //width: 100%;
    }
`



const EditSingleTask = ({ visible, onClose, activeList, guests, singleTask }) => {
	return (
		<Modal
			title={<Title>
				<IconButton type='button' onClick={() => onClose()}>
					<img src={backIcon} alt='' /></IconButton>
				<h2>Edit Single Task</h2>
			</Title>}
			closable={false}
			onClose={onClose}
			visible={visible}
			footer={null}
			width="90%"
		>
			<EditSingleTaskForm guests={guests} activeList={activeList} singleTask={singleTask} handleClose={onClose} />

		</Modal>
	);
}

export default EditSingleTask;