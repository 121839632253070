import React from 'react';
import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { BudgetComponent } from '../../components'
import BudgetProvider, { BudgetContext } from './BudgetProvider'

const Budget = props => {
	useFirestoreConnect(() => [
		{ collection: 'events', doc: props.match.params.eventId }
	])
	const event = useSelector(
		({ firestore: { data } }) => data.events && data.events[props.match.params.eventId]
	)
	return (
		<BudgetProvider eventId={props.match.params.eventId}>
			<BudgetContext.Consumer>
				{({ guests }) => {
					return (
						<div>
							<BudgetComponent guests={guests} event={event} />
						</div>
					)
				}}
			</BudgetContext.Consumer>
		</BudgetProvider>
	);
};

Budget.defaultProps = {

};

Budget.propTypes = {

};

export default Budget