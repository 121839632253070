import React from "react";
import styled from "styled-components";
import { Col } from "antd";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { DeleteOutlined } from "@ant-design/icons";


const TodoContainer = styled.div`
  width: 100%;
  height: 357px;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 1.3125em;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05);
	/* cursor: pointer; */
	position: relative;
`;

export const Text = styled.div`
  padding: 0.5em 1.25em 0.5em 1.25em;
  border-radius: 8px;

  h4 {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: #101b43;
    display: flex;
    justify-content: space-between;
    margin: 0.5em 0;
    padding: 0;
    span {
      color: #2e2c9a;
    }
  }

  p {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #101b43;
    margin: 0 0 0.5em 0;
    padding: 0;
  }
`;
export const Image = styled.img`
  width: 100%;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 188px;
  background: transparent url(${(props) => props.src}) no-repeat center;
  background-size: cover;
  border-radius: 8px 8px 0 0;
`;

const Ratings = styled.div`
  display: flex;
  align-items: center;

  h5 {
    font-family: "Proxima Nova Semibold";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 100%;
    color: #2e2c9a;
    padding: 0;
    margin: 0 0.5em 0 0;
  }

  .ant-rate {
    font-size: 6px;
  }

  .ant-rate-star {
    margin: 0;
  }

  ul {
    margin: 0;
    padding: 0;
  }
  svg {
    width: 15px;
    height: 15px;
  }
`;

export const IconWrapper = styled.button`
  width: 40px;
  height: 40px;
  background: transparent;
  border-radius: 8px;
  border: 1px solid #ededed;
  display: flex;
  align-items: center;
  justify-content: center;
	position: absolute;
	top: 10px;
	right: 10px;

	svg {
    fill: #fff;
    font-size: 20px;
	}
  
`;

const PollItems = ({ options, fields, openOption }) => {
	if (!options?.length) return <div></div>;
	const colors = [
		"https://res.cloudinary.com/pcgwa/image/upload/f_auto/w_640/v1610578555/reveleaze/colors/light-blue-image",
		"https://res.cloudinary.com/pcgwa/image/upload/f_auto/w_640/v1610578555/reveleaze/colors/pink-image",
		"https://res.cloudinary.com/pcgwa/image/upload/f_auto/w_640/v1610578555/reveleaze/colors/blue-image.jpg",
	];

	return options.map((option, index) => (
		<Col xs={24} md={6} key={index}>
			{/* todo in feature: onClick={() => openOption(index, "edit")} */}
			<TodoContainer>
				<IconWrapper
					type="button"
					onClick={() => fields.remove(index)}

				>
					<DeleteOutlined size="xtra-large" />
				</IconWrapper>
				<Wrapper src={!option.image ? colors[index % 3] : option.image} />
				<Text>
					<h4>{option.name}</h4>
					<p>{option.note}</p>
					<Ratings>
						<h5>{option.price}</h5>
					</Ratings>
				</Text>
			</TodoContainer>
		</Col>
	));
};

const selector = formValueSelector("customPoll");

export function mapStateToProps(state) {
	return {
		options: selector(state, "options"),
	};
}

export default connect(mapStateToProps, null)(PollItems);
