import React, { useState } from "react";
import { Field, initialize } from "redux-form";
import styled from "styled-components";
import { Button, Radio, Form, Col, Row } from "antd";
import { connect } from "react-redux";
import { InInput, InTextarea } from "./FormComponents";
import FileUpload from "../../../components/FileUpload";

const Column = styled(Col)`
  display: flex;
  button + button {
    margin-left: 10px;
  }
`;

const FormItem = styled(Form.Item)`
  .ant-form-item-label {
    text-align: left;
  }

  .ant-form-item-label label::after {
    content: "";
  }
`;

const FormWrapper = styled.form`
  max-width: 670px;
  margin: 0 auto;
  .ant-form-item-label {
    text-align: left;
  }

  .ant-form-item-label label::after {
    content: "";
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
`;

const IconButton = styled(Button)`
  background: ${(props) => (props.primary ? "#4F4DB8" : "#ffffff")};
  border-color: #4f4db8;
  height: 44px;
  border-radius: 4px;
  color: ${(props) => (props.primary ? "#ffffff" : "#2E2C9A")};
  text-transform: uppercase;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 80%;
  display: flex;
  align-items: center;

  &:hover {
    background: ${(props) => (props.primary ? "#2E2C9A" : "#ffffff")};
    border-color: #2e2c9a;
    color: ${(props) => (props.primary ? "#ffffff" : "#2E2C9A")};
  }

  &:focus {
    background: ${(props) => (props.primary ? "#2E2C9A" : "#ffffff")};
    border-color: #2e2c9a;
    color: ${(props) => (props.primary ? "#ffffff" : "#2E2C9A")};
  }
`;

export const RadioItem = styled(FormItem)`
  .ant-radio-button {
    color: #2e2c9a;
    border-radius: 4px;
    border: 1px solid #eeeeef;
  }

  .ant-radio-button-checked {
    color: #2e2c9a;
    border: 1px solid #eeeeef;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #2e2c9a;
    border: 1px solid #2e2c9a;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #2e2c9a;
  }

  .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
    border-right-color: #2e2c9a;
  }

  label {
    margin-right: 1em;
    color: #2e2c9a;
    font-family: "Proxima Nova Semibold";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 100%;
  }

  &:hover {
    label {
      color: #2e2c9a;
    }
  }
`;

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 24 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 24 },
	},
};

const options = [
	{ label: "$", value: "$" },
	{ label: "$$", value: "$$" },
	{ label: "$$$", value: "$$$" },
	{ label: "$$$$", value: "$$$$" },
];

const DollarSign = ({ input, meta, children, hasFeedback, label, ...rest }) => {
	const [rating, setRating] = useState(0);

	const handleRating = (value) => {
		setRating(value);
	};

	const handleChange = (e, input) => {
		return input.onChange(e);
	};
	const hasError = meta.touched && meta.invalid;
	return (
		<RadioItem
			{...formItemLayout}
			label={label}
			validateStatus={hasError ? "error" : "success"}
			hasFeedback={hasFeedback && hasError}
		// help={hasError && meta.error}
		>
			<Radio.Group
				options={options}
				onChange={(e) => handleChange(e, input)}
				value={rating}
				optionType="button"
				{...input}
				{...rest}
				children={children}
				size="large"
			/>
		</RadioItem>
	);
};

const AddPollOption = ({
	closePollOption,
	openPollOption,
	option,
	formInit,
	formState,
	index,
	isEdit,
}) => {
	function initForm(data) {
		const options = formState.values.options.map((optionObj, idx) => {
			if (index === idx) {
				return {
					...optionObj,
					...data,
				};
			}
			return optionObj;
		});

		const dataObj =
			formState && formState.values
				? { ...formState.values, options }
				: { ...data };

		formInit(dataObj);
	}

	const disableButton = () => {
		const dataForm = formState.values.options[index];
		if (!dataForm.name) {
			return true;
		}
		return false;
	};

	return (
		<Row>
			<Col xs={24}>
				<FormItem>
					<Field
						label="Name of Option"
						name={`${option}.name`}
						type="text"
						component={InInput}
						placeholder="Name"
					/>
				</FormItem>
				<FormItem>
					<Field
						label="URL"
						name={`${option}.url`}
						type="text"
						component={InInput}
						placeholder="Type URL link"
					/>
				</FormItem>
				<FormItem>
					<Field
						label="Note"
						name={`${option}.note`}
						type="textarea"
						component={InTextarea}
						placeholder="Note"
					/>
				</FormItem>
				<FormItem>
					<Field
						label="Image"
						name={`${option}.image`}
						type="file"
						component={FileUpload}
						placeholder="image"
						initForm={initForm}
					/>
				</FormItem>
				<FormItem>
					<Field
						label="Price"
						name={`${option}.price`}
						component={DollarSign}
						placeholder="Price"
					/>
				</FormItem>
			</Col>
			<Column xs={24}>
				{!isEdit && (
					<IconButton
						onClick={() => {
							closePollOption();
							openPollOption();
						}}
						disabled={disableButton()}
					>
						Save And add one more
					</IconButton>
				)}

				<IconButton
					primary
					onClick={() => closePollOption()}
					disabled={disableButton()}
				>
					DONE
				</IconButton>
			</Column>
		</Row>
	);
};

export function mapStateToProps(state) {
	return {
		formState: state.form.customPoll,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		formInit: (data) => {
			dispatch(initialize("customPoll", data));
		},
	};
}
export default connect(mapStateToProps, mapDispatchToProps)(AddPollOption);
