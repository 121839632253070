import React from 'react'
import styled from "styled-components";
import { Row, Col } from 'antd';
import { InTypography } from '../../'
import landing from '../../../assets/rsvp/landing.png'

export const Wrapper = styled.div`
    max-width: 100vw;
`;

export const Image = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    flex-direction: column;
    background: transparent url(${props => props.src}) no-repeat center;
    background-size: cover;
    //margin-top: 5vh;
`;

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1em;
    text-align: center;
    
`;

export const Subtitle = styled(InTypography.InH3)`
    margin: 1em 0;
`;

export const Intro = () => {
	return (
		<Wrapper>
			<Row>
				<Col xs={24} md={16}>
					<Image src={landing} />
				</Col>
				<Col xs={24} md={8}>
					<Container >
						{/* <InTypography.InH1>
                        Congratulations
                    </InTypography.InH1>
                    <Subtitle>
                        We can’t wait to help you plan this bachelorette party
                    </Subtitle> */}
					</Container>
				</Col>
			</Row>
		</Wrapper>
	)
}