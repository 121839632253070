import React from "react";
import styled from "styled-components";
import { Field, FieldArray, reduxForm } from "redux-form";
import { Form, Input, Radio, Select, Checkbox, DatePicker } from "antd";
import moment from "moment";
import { ConsoleSqlOutlined } from "@ant-design/icons";

const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const InputField = styled(Input)`
  .ant-form-vertical .ant-form-item-label > label {
    color: red;
  }

  .ant-form-item-label {
    position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    height: 32px;
    color: red;
    font-size: 14px;
  }

  label {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    display: none;
    /* identical to box height, or 24px */

    /* Text/Midnight Blue */

    color: #101b43;
  }
`;

const SelectFieldItem = styled(FormItem)`
  max-width: 31.5em;
  border: none;
  label {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #101b43;
  }

  &:focus {
    border: none;
  }

  input,
  select {
    outline: none;

    &:hover {
      border: none;
    }

    &:focus {
      border: none;
    }
  }

  .ant-select-multiple .ant-select-selector {
    border: none;
  }
`;
const FieldItem = styled(FormItem)`
  label {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #101b43;
  }

  &:focus {
    border: none;
  }

  input,
  textarea,
  select {
    outline: none;
    border: none;

    &:hover {
      border: none;
    }

    &:focus {
      border: none;
    }
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }

  .ant-picker {
    border: none;
  }
`;

const makeField =
  (Component) =>
  ({ input, meta, children, hasFeedback, label, ...rest }) => {
    const hasError = meta.touched && meta.invalid;
    return (
      <FieldItem
        {...formItemLayout}
        label={label}
        validateStatus={hasError ? "error" : "success"}
        hasFeedback={hasFeedback && hasError}
        // help={hasError && meta.error}
      >
        <Component {...input} {...rest} children={children} size="large" />
      </FieldItem>
    );
  };

const makeDatePicker =
  (Component) =>
  ({ input, meta, children, options, hasFeedback, label, ...rest }) => {
    const hasError = meta.touched && meta.invalid;

    const handleChange = (e, input) => {
      return input.onChange(e);
    };

    return (
      <FieldItem
        {...formItemLayout}
        label={label}
        validateStatus={hasError ? "error" : "success"}
        hasFeedback={hasFeedback && hasError}
        // help={hasError && meta.error}
      >
        <Component
          {...input}
          {...rest}
          children={children}
          onChange={(e) => handleChange(e, input)}
          onFocus={(e) => e.preventDefault()}
          onBlur={(e) => e.preventDefault()}
          size="large"
        />
      </FieldItem>
    );
  };

const makeMultiSelect =
  (Component) =>
  ({ input, options, meta, children, hasFeedback, label, ...rest }) => {
    const hasError = meta.touched && meta.invalid;
    const handleChange = (e, input) => {
      return input.onChange(e);
    };
    return (
      <SelectFieldItem
        {...formItemLayout}
        label={label}
        validateStatus={hasError ? "error" : "success"}
        hasFeedback={hasFeedback && hasError}
        // help={hasError && meta.error}
        size="large"
      >
        <Component
          {...input}
          // value={valueInput}
          {...rest}
          children={children}
          onChange={(e) => handleChange(e, input)}
          onFocus={(e) => e.preventDefault()}
          onBlur={(e) => e.preventDefault()}
          size="large"
        />
      </SelectFieldItem>
    );
  };

export const InInput = makeField(InputField);
export const InRadioGroup = makeField(RadioGroup);
export const InSelect = makeMultiSelect(Select);
export const InCheckbox = makeField(Checkbox);
export const InTextarea = makeField(TextArea);
export const InDatePicker = makeDatePicker(DatePicker);
export const InMultiSelect = makeMultiSelect(Select);

const Wrapper = styled.div``;

const FormComponents = (props) => {
  return <Wrapper>FormComponents</Wrapper>;
};

FormComponents.defaultProps = {};

FormComponents.propTypes = {};

export default FormComponents;
