import React from 'react'
import styled from "styled-components";
import PhoneInput from 'react-phone-number-input'
import { Space } from 'antd';
import {Field, Form} from 'redux-form'
import { InReactSelect } from '../../../FormComponents/FormComponents'
import {InButton} from "../../../";

const FormItem = Form.Item;

const Wrapper = styled.div`
  margin: 18px 0;
`

const BoxWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 1.125em 0 5em;
  display: flex;
  flex-direction: column;
`

const InputWrapper = styled.div`
    margin: 1em 0;
    label {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #101B43;
    }
`

const Input = styled.input`
    width: 100%;
    height: 44px;
    padding: 10px 12px;
    margin: 0;
    border: 1px solid #EEEEEF;
    border-radius: 4px;
    outline: none;
    
        &:hover {
          border: 1px solid #EEEEEF;
          
    }
    
`

const TextArea = styled.textarea`
    width: 100%;
    height: 88px;
    padding: 10px 12px;
    margin: 0;
    border: 1px solid #EEEEEF;
    border-radius: 4px;
    outline: none;
    
        &:hover {
          border: 1px solid #EEEEEF;
          
    }
    
`

export const PhoneNumber = styled(PhoneInput)`
    border: 1px solid #EEEEEF;
    border-radius: 4px;
    display: flex;
    outline: none;
    
    input { 
        border: 1px solid #EEEEEF;
        border-radius: 4px;
        display: flex;
        outline: none;
        height: 44px;
        padding: 10px 12px;
        
        &:focus {
          border: 1px solid #EEEEEF;
        }
    }
    
    
        .PhoneInput {
            /* This is done to stretch the contents of this component. */
            display: flex;
            align-items: center;
        }

    .PhoneInputInput {
        /* The phone number input stretches to fill all empty space */
        flex: 1;
        /* The phone number input should shrink to make room for the extension input */
        min-width: 0;
    }

    .PhoneInputCountryIcon {
        width: 30px;
        height: 1em;
    }

    .PhoneInputCountryIcon--square {
      width: 100%;
    }

    .PhoneInputCountryIcon--border {

    }

    .PhoneInputCountryIconImg {
        display: block;
        width: 100%;
        height: 100%;
    }

    .PhoneInputInternationalIconPhone {
      opacity: 1;
    }

    .PhoneInputInternationalIconGlobe {
      opacity: 1;
    }

/* Styling native country \`<select/>\`. */

.PhoneInputCountry {
        position: relative;
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 12px;
    }

    .PhoneInputCountrySelect {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        border: 0;
        opacity: 0;
        cursor: pointer;
    }

.PhoneInputCountrySelect[disabled] {
cursor: default;
}

.PhoneInputCountrySelectArrow {
    display: block;
    content: '';
    width: 1em;
    height: 1em;
    margin-top: 0.5em;
    margin-left: 0;
    border-style: solid;
    border-color: black;
    border-top-width: 0;
    border-bottom-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    opacity: 1;
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
opacity: 1;
color: #EEEEEF;
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
box-shadow: 0 0 0 1px #EEEEEF;
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
opacity: 1;
color: #EEEEEF;
}

`

const GuestAddForm = (props) =>{
    const sortGuests = props.guestList ? props.guestList.map(guest => {
        return {
            label: guest.name,
            value: guest.id,
            details: {
              id: guest.id,
              name: guest.name
            } 
          }
      }) : []
    return (
        <Wrapper>
            <BoxWrapper>
                <InputWrapper>
                    <label htmlFor="name">Name of Task</label>
                    <Input
                        label='Name of Task'
                        name='name'
                        placeholder="Type name"
                        ref={props.inputElement}
                        value={props.currentItem.name}
                        onChange={props.handleName}
                    />
                </InputWrapper>
                <InputWrapper>
                    <label htmlFor="note">Note</label>
                    <TextArea
                        name='description'
                        placeholder="Type description"
                        ref={props.inputElement}
                        value={props.currentItem.description}
                        onChange={props.handleDescription}
                        type='textarea'
                    />
                </InputWrapper>
                {/* <FormItem>
                    <Field
                     label="Invited Guests"
                     name="invitedGuests" 
                     options={sortGuests} 
                     type='select' 
                     component={InReactSelect} 
                     placeholder="Select Guests"
                    />
                </FormItem> */}
            </BoxWrapper>
            <Space size='large'>
                <InButton
                    type='button'
                    onClick={() => props.done(props.currentItem)}
                    text='SAVE'
                    disabled={props.disabled}
                    primary
                />
            </Space>
        </Wrapper>
    )
}

export default GuestAddForm
