import {
	airbnb,
	standardhotel,
	luxehotel,
	lowkeyspot,
	basicBundle,
	beyondBasics,
	aLittleExtra,
	decorGalore,
	winetasting,
	tourthecity,
	poolbeach,
	spaday,
	transportHeader,
	publicTransport,
	rideShare,
	limo,
	partyBus,
	alcoholHeader,
	twoBuckChuck,
	beer,
	champagne,
	hardliquor,
	nightActivityheader,
	gameNight,
	dinner,
	bottleService,
	allAboveNightActivities,
	diningHeader,
	fastfood,
	hipcasual,
	privateChef,
	finedining,
	paidActivitiesHeader,
	poledancing,
	soulcycle,
	rentaboat,
	vipAccess,
	groceriesHeader,
	coffeeOnly,
	coffeeSnacks,
	coffeeSnacks2,
	allSnacks,
} from "../../../assets/cloudinary/images";
import guestsImage from "../../../assets/quiz/guests.png";
import budget from "../../../assets/quiz/budget.png";
import decorHeader from "../../../assets/quiz/decor/main.png";
import destination from "../../../assets/quiz/destinations.png";
import activitiesHeader from "../../../assets/quiz/city-activities.png";

const mockGraphqlData = (city, dates, event) => ({
	type: "bride-set-details",
	steps: [
		{
			name: "intro",
			title: "Intro",
			sections: [
				{
					name: "intro",
					caption: "Identifying Information",
					justify: "start",
					fields: [],
				},
			],
		},
		{
			name: "attendance",
			title: "Attendance",
			sections: [
				{
					name: "attendance",
					caption: null,
					image: guestsImage,
					justify: "start",
					layout: {
						sub: 8,
						main: 16,
					},
					attendance: true,
					fields: [
						{
							name: "attendanceset-date",
							placeholder: "",
							component: "setDatesAndDestination",
							caption: `YAY! It's time to celebrate You!`,
							subCaption: "The date and location for your bachelorette have been set!",
							altOptions: {
								city: city,
								dates: dates,
								brides: event.bride,
							},
						},
					],
				},
			],
		},
		{
			name: "guest-list",
			title: "guest-list",
			sections: [
				{
					name: "guest-list",
					caption: "Guests List",
					justify: "start",
					fields: [
						{
							name: "fullName",
							placeholder: "Full Name",
							component: "table",
							caption: "What is your full name?",
							initialValue: "",
							validationRule: "^(?!\\s*$).+",
						},
					],
				},
			],
		},
		{
			name: "theme",
			title: "Theme",
			sections: [
				{
					name: "theme",
					caption: null,
					image: guestsImage,
					justify: "start",
					layout: {
						sub: 8,
						main: 16,
					},
					fields: [
						{
							name: "theme",
							placeholder: "",
							component: "radioListWithOther",
							validationRule: "^(?!\\s*$).+",
							caption: "Is there a particular theme you would like for your bachelorette?",
							options: [
								{ value: "Let’s Flamingle", label: "Let’s Flamingle" },
								{ value: "Same Penis Forever", label: "Same Penis Forever" },
								{ value: "Final Fiesta", label: "Final Fiesta" },
								{ value: "Let’s Get Nauti", label: "Let’s Get Nauti" },
								{ value: "Bach & Boujee", label: "Bach & Boujee" },
								{ value: "Bohemian Vibes", label: "Bohemian Vibes" },
								{ value: "Country Bride", label: "Country Bride" },
								{ value: "Other", label: "Other" },
							],
							field: {
								name: "other",
								placeholder: "Enter other",
								component: "textarea",
								caption: "",
								validationRule: "^(?!\\s*$).+",
							},
						},
					],
				},
			],
		},
		{
			name: "vibeRisque",
			title: "Vibe & Risque",
			sections: [
				{
					name: "vibe",
					caption: null,
					image: guestsImage,
					justify: "start",
					layout: {
						sub: 8,
						main: 16,
					},
					fields: [
						{
							name: "vibe",
							placeholder: "",
							component: "radioList",
							caption: "What vibe do you want?",
							validationRule: "^(?!\\s*$).+",
							options: [
								{ value: "Wild and Crazy", label: "Wild and Crazy" },
								{ value: "Mellow and Relaxing", label: "Mellow and Relaxing" },
								{ value: "Fit and Healthy", label: "Fit and Healthy" },
								{ value: "A Hybrid Blend", label: "A Hybrid Blend" },
							],
						},
						{
							name: "risque",
							placeholder: "",
							component: "radioList",
							caption: "How risqué do you want the party to be?",
							validationRule: "^(?!\\s*$).+",
							options: [
								{
									value: "The raunchier the better!",
									label: "The raunchier the better!",
								},
								{
									value: "Everything in moderation",
									label: "Everything in moderation",
								},
								{
									value: "Let’s keep it classy!",
									label: "Let’s keep it classy!",
								},
							],
						},
					],
				},
			],
		},
		{
			name: "choice",
			title: "Alcohol & Age",
			sections: [
				{
					name: "choice",
					caption: null,
					image: guestsImage,
					justify: "start",
					layout: {
						sub: 8,
						main: 16,
					},
					fields: [
						{
							name: "drink",
							placeholder: "",
							component: "radioListGroup",
							caption: "What is your drink of choice?",
							subCaption: "Select up to 3 choices",
							validationRule: "^(?!\\s*$).+",
							options: [
								{ value: "Vodka", label: "Vodka" },
								{ value: "Tequila", label: "Tequila" },
								{ value: "Wine", label: "Wine" },
								{ value: "Whiskey", label: "Whiskey" },
								{
									value: "Anything non-alcoholic",
									label: "Anything non-alcoholic",
								},
							],
						},
						{
							name: "age",
							placeholder: "Enter Age",
							component: "inputAlt",
							caption: "How old are you?",
							validationRule: "^(?!\\s*$).+",
						},
					],
				},
			],
		},
		{
			name: "destination",
			title: "",
			sections: [
				{
					name: "destination",
					caption: "Destination",
					image: guestsImage,
					justify: "start",
					layout: {
						sub: 8,
						main: 16,
					},
					fields: [
						{
							name: "travellingFrom",
							placeholder: "Search City",

							component: "inputSearch",
							caption: "Where will you be traveling from?",
							validationRule: "^(?!\\s*$).+",
						},
						{
							name: "roommate",
							placeholder: "Select",
							component: "select",
							caption: "Who do you want to room with?",
							options: [],
							validationRule: "^(?!\\s*$).+",
						},
						{
							name: "allergies",
							placeholder: "gluten-free, allergic to dairy, vegan etc.",
							component: "textarea",
							caption: "Specify any food allergies?",
							validationRule: "^(?!\\s*$).+",
						},
					],
				},
			],
		},
		{
			name: "socialMedia",
			title: "social media",
			sections: [
				{
					name: "socialMedia",
					caption: null,
					justify: "start",
					image: guestsImage,
					layout: {
						sub: 8,
						main: 16,
					},
					fields: [
						{
							name: "socialHashtag",
							placeholder: "",
							component: "radioListWithInput",
							caption: "Do you have a bachelorette hashtag for social media?",
							subCaption: null,
							options: [
								{ value: "NO", label: "NO" },
								{ value: "YES", label: "YES" },
							],
							validationRule: "^(?!\\s*$).+",
							field: {
								name: "hashtag",
								placeholder: "#",
								component: "inputAlt",
								caption: "",
							},
						},
					],
				},
			],
		},
		{
			name: "dressCode",
			title: "Dress Code",
			sections: [
				{
					name: "dressCode",
					caption: "Dress Code",
					subCaption:
						"As the bride-to-be, this night is all about you, so you’ll want to look your best. Is there a specific dress code you want your guests to follow?",
					image: guestsImage,
					justify: "start",
					layout: {
						sub: 8,
						main: 16,
					},
					fields: [
						{
							name: "dressCode",
							subCaption:
								"As the bride-to-be, this night is all about you, so you’ll want to look your best. Is there a specific dress code you want your guests to follow?",
							placeholder:
								"Friday night - Bride wears white, bride tribe wears any red outfit. Saturday Boat Day - Bride wears white bathing suit, bride tribe wears black bathing suits. Saturday Night - Any fun wig of your choosing.",
							component: "textarea",
							caption: "Specific dress code",
							validationRule: "^(?!\\s*$).+",
						},
					],
				},
			],
		},
		// {
		// 	name: "additionalDetails",
		// 	title: "Additional Details",
		// 	sections: [
		// 		{
		// 			name: "additionalDetails",
		// 			caption: "Additional Details",
		// 			subCaption: "Is there anything else you want the Event Organizer to know?",
		// 			image: guestsImage,
		// 			justify: "start",
		// 			layout: {
		// 				sub: 8,
		// 				main: 16,
		// 			},
		// 			fields: [
		// 				{
		// 					name: "additionalDetails",
		// 					subCaption: "Is there anything else you want the Event Organizer to know?",
		// 					placeholder:
		// 						"The girls in my Bride Tribe are from different parts of my life. Playing ice breaker games would be fun to make sure everyone feels comfortable and included.",
		// 					component: "textarea",
		// 					caption: "Share details",
		// 					validationRule: "^(?!\\s*$).+",
		// 				},
		// 			],
		// 		},
		// 	],
		// },
		{
			name: "budget-quiz-intro",
			title: "Budget Quiz Intro",
			sections: [
				{
					name: "budget-quiz",
					caption: null,
					justify: "center",
					image: budget,
					layout: {
						sub: 8,
						main: 16,
					},
					fields: [],
				},
			],
		},
		{
			name: "budget",
			title: "Budget",
			sections: [
				{
					name: "budget",
					caption: null,
					image: destination,
					layout: {
						sub: 24,
						main: 24,
					},
					fields: [
						{
							name: "accommodation",
							component: "radioBudgetImage",
							caption: "Where should we stay?",
							subCaption: null,
							validationRule: "^(?!\\s*$).+",
							options: [
								{
									value: "$",
									label: "Low-key spot",
									cost: 40,
									image: lowkeyspot,
								},
								{
									value: "$$",
									label: "A spacious Airbnb",
									cost: 80,
									image: airbnb,
								},
								{
									value: "$$$",
									label: "Standard hotel",
									cost: 125,
									image: standardhotel,
								},
								{
									value: "$$$$",
									label: "Luxe hotel",
									cost: 225,
									image: luxehotel,
								},
							],
						},
					],
				},
			],
		},
		{
			name: "budget",
			title: "Budget",
			sections: [
				{
					name: "budget",
					caption: null,
					image: decorHeader,
					layout: {
						sub: 24,
						main: 24,
					},
					fields: [
						{
							name: "decor",
							component: "radioBudgetImage",
							caption: "How should we decorate?",
							subCaption: null,
							validationRule: "^(?!\\s*$).+",
							options: [
								{
									value: "$",
									label: "Basic Bundle",
									cost: 25,
									image: basicBundle,
								},
								{
									value: "$$",
									label: "Beyond the Basics",
									cost: 50,
									image: beyondBasics,
								},
								{
									value: "$$$",
									label: "Be Extra",
									cost: 75,
									image: aLittleExtra,
								},
								{
									value: "$$$$",
									label: "Decor Galore",
									cost: 100,
									image: decorGalore,
								},
							],
						},
					],
				},
			],
		},
		{
			name: "budget",
			title: "Budget",
			sections: [
				{
					name: "budget",
					caption: null,
					image: activitiesHeader,
					layout: {
						sub: 24,
						main: 24,
					},
					fields: [
						{
							name: "day_activities",
							component: "radioBudgetImageGroup",
							caption: "How do you want to spend the day?",
							subCaption: "Choose up to 2 different options",
							validationRule: "^(?!\\s*$).+",
							options: [
								{
									value: "$",
									label: "Tour the City",
									cost: 15,
									image: tourthecity,
								},
								{
									value: "$$",
									label: "Day at Beach Cabana",
									cost: 30,
									image: poolbeach,
								},
								{
									value: "$$$",
									label: "Wine Tasting",
									cost: 200,
									image: winetasting,
								},
								{ value: "$$$$", label: "Spa Day", cost: 100, image: spaday },
							],
						},
					],
				},
			],
		},
		{
			name: "budget",
			title: "Budget",
			sections: [
				{
					name: "budget",
					caption: null,
					image: transportHeader,
					layout: {
						sub: 24,
						main: 24,
					},
					fields: [
						{
							name: "transport",
							component: "radioBudgetImage",
							caption: "How will you get around?",
							subCaption: null,
							validationRule: "^(?!\\s*$).+",
							options: [
								{
									value: "$",
									label: "Public Transport",
									cost: 8,
									image: publicTransport,
								},
								{
									value: "$$",
									label: "Ride Share",
									cost: 13,
									image: rideShare,
								},
								{ value: "$$$", label: "Limo", cost: 17, image: limo },
								{
									value: "$$$$",
									label: "Party Bus",
									cost: 22,
									image: partyBus,
								},
							],
						},
					],
				},
			],
		},
		{
			name: "budget",
			title: "Budget",
			sections: [
				{
					name: "budget",
					caption: null,
					image: alcoholHeader,
					layout: {
						sub: 24,
						main: 24,
					},
					fields: [
						{
							name: "alcohol",
							component: "radioBudgetImage",
							caption: "What’s your pregame drink of choice?",
							subCaption: null,
							validationRule: "^(?!\\s*$).+",
							options: [
								{
									value: "$",
									label: "Two Buck Chuck",
									cost: 25,
									image: twoBuckChuck,
								},
								{ value: "$$", label: "Beer", cost: 35, image: beer },
								{
									value: "$$$",
									label: "Champagne",
									cost: 50,
									image: champagne,
								},
								{
									value: "$$$$",
									label: "Hard Liquor",
									cost: 75,
									image: hardliquor,
								},
							],
						},
					],
				},
			],
		},
		{
			name: "budget",
			title: "Budget",
			sections: [
				{
					name: "budget",
					caption: null,
					image: nightActivityheader,
					layout: {
						sub: 24,
						main: 24,
					},
					fields: [
						{
							name: "night_activities",
							component: "radioBudgetImage",
							caption: "What would you prefer for a night out?",
							subCaption: null,
							validationRule: "^(?!\\s*$).+",
							options: [
								{
									value: "$",
									label: "Game Night/Slumber Party",
									cost: 50,
									image: gameNight,
								},
								{
									value: "$$",
									label: "A Lovely Dinner",
									cost: 100,
									image: dinner,
								},
								{
									value: "$$$",
									label: "Bottle Service",
									cost: 300,
									image: bottleService,
								},
								{
									value: "$$$$",
									label: "All of the Above",
									cost: 500,
									image: allAboveNightActivities,
								},
							],
						},
					],
				},
			],
		},
		{
			name: "budget",
			title: "Budget",
			sections: [
				{
					name: "budget",
					caption: null,
					image: diningHeader,
					layout: {
						sub: 24,
						main: 24,
					},
					fields: [
						{
							name: "food",
							component: "radioBudgetImage",
							caption: "What’s your dining preference?",
							subCaption: null,
							validationRule: "^(?!\\s*$).+",
							options: [
								{ value: "$", label: "Fast Food", cost: 25, image: fastfood },
								{
									value: "$$",
									label: "Hip Casual",
									cost: 50,
									image: hipcasual,
								},
								{
									value: "$$$",
									label: "Private Chef",
									cost: 150,
									image: privateChef,
								},
								{
									value: "$$$$",
									label: "Fine Dining",
									cost: 250,
									image: finedining,
								},
							],
						},
					],
				},
			],
		},
		{
			name: "budget",
			title: "Budget",
			sections: [
				{
					name: "budget",
					caption: null,
					image: paidActivitiesHeader,
					layout: {
						sub: 24,
						main: 24,
					},
					fields: [
						{
							name: "paid_misc_activity",
							component: "radioBudgetImage",
							caption: "What would you definitely pay for?",
							subCaption: null,
							validationRule: "^(?!\\s*$).+",
							options: [
								{
									value: "$",
									label: "Pole Dancing Classes",
									cost: 20,
									image: poledancing,
								},
								{
									value: "$$",
									label: "Soul Cycle",
									cost: 40,
									image: soulcycle,
								},
								{
									value: "$$$",
									label: "Rent A Boat",
									cost: 85,
									image: rentaboat,
								},
								{
									value: "$$$$",
									label: "VIP Access to See a DJ",
									cost: 120,
									image: vipAccess,
								},
							],
						},
					],
				},
			],
		},
		{
			name: "budget",
			title: "Budget",
			sections: [
				{
					name: "budget",
					caption: null,
					image: groceriesHeader,
					layout: {
						sub: 24,
						main: 24,
					},
					fields: [
						{
							name: "groceries",
							component: "radioBudgetImage",
							caption: "What groceries do you want?",
							subCaption: null,
							validationRule: "^(?!\\s*$).+",
							options: [
								{
									value: "$",
									label: "Only Coffee",
									cost: 7,
									image: coffeeOnly,
								},
								{
									value: "$$",
									label: "Coffee & Snacks",
									cost: 10,
									image: coffeeSnacks,
								},
								{
									value: "$$$",
									label: "Coffee, Snacks & Breakfast",
									cost: 13,
									image: coffeeSnacks2,
								},
								{
									value: "$$$$",
									label: "All of the Above & Alcohol",
									cost: 17,
									image: allSnacks,
								},
							],
						},
					],
				},
			],
		},
		{
			name: "submit",
			title: "Submit",
			sections: [
				{
					name: "submit",
					caption: "Identifying Information",
					justify: "start",
					fields: [],
				},
			],
		},
	],
});

export default mockGraphqlData;
