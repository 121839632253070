import React, { Component } from 'react';
import styled from "styled-components"
import { DefaultBtn } from '../../../../../components/InButton/Buttons'
import checkMark from './Check.svg'
import { SectionHeading, SectionHeadingUnderLine, Paragraph, HeadingFour } from '../../../../../components/Text'

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 1em;
  
  ${props => props.theme.breakpoints.tablet}{
    margin-top: 0;
  }
`;


const TitleWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  text-align: left;
`;

const ParagraphWrapper = styled.div`
  margin-bottom: 16px;
  
  ul {
    list-style: none;
    margin: 16px 0 0 0;
    padding: 0;
    
    li {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 1em 0;
      
      h4 {
        margin: 0;
      }
      
      p {
        margin: 0;
      }
      img {
        margin-right: 10px;
      }
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 24px 0;
`;

class CarouselComponent extends Component {
  render() {
    return (
        <Container>
          <TitleWrapper>
            <SectionHeadingUnderLine width='135px'>
              Personalize
            </SectionHeadingUnderLine>
            <SectionHeading>
              &nbsp;The Party
            </SectionHeading>
          </TitleWrapper>
          <ParagraphWrapper>
            <HeadingFour align='left'>Your guests will say “YAY” because they’ve been heard.</HeadingFour>
            <ul>
              <li>
                <img src={checkMark} alt=""/>
                <HeadingFour align='left'>
                  Hassle-free voting poll so everyone has a voice
                </HeadingFour>
              </li>
              <li>
                <img src={checkMark} alt=""/>
                <HeadingFour align='left'>
                  Plan the perfect trip and let it be a vacation for all
                </HeadingFour>
              </li>
              <li>
                <img src={checkMark} alt=""/>
                <HeadingFour align='left'>
                  Personalized recommendations based on the tribe’s preferences and budgets
                </HeadingFour>
              </li>
            </ul>
          </ParagraphWrapper>
          <ButtonContainer>
            <DefaultBtn primary>Join Now</DefaultBtn>
          </ButtonContainer>
        </Container>
    );
  }
}

export default CarouselComponent
