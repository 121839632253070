import React from 'react';
import styled from "styled-components";
import {Menu} from "antd";

const Wrapper = styled.svg`
    fill: #494D5B;
     stroke: #494D5B;
    
    &:hover {
      fill: #2E2C9A;
     stroke: #2E2C9A;
    }
`

export default ({ style }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
        <rect x="15.5" y="12" width="3.5" height="15" rx="0.5" transform="rotate(90 15.5 12)" stroke="#4F4DB8"/>
        <rect x="13.5" y="6" width="4" height="13" rx="0.5" transform="rotate(90 13.5 6)" stroke="#4F4DB8"/>
        <rect x="15.5" y="0.5" width="3.5" height="15" rx="0.5" transform="rotate(90 15.5 0.5)" stroke="#4F4DB8"/>
    </svg>
);
