import React, { useEffect } from "react";
import styled from "styled-components";
import { FormSection, reduxForm } from "redux-form";
import { connect, useDispatch } from "react-redux";
import { Button } from "antd";
import { timestampToDate } from "../../../helpers/normalizeDate";
import { events } from "../../../helpers/firestore";
import moment from "moment";
import TimelinePageOne from "./TimelinePageOne";
import TimelinePageTwo from "./TimelinePageTwo";
import FinalScheduleTimeline from "./FinalScheduleTimeline";
import { useSelector } from "react-redux";
import { toast } from "../../../redux/actions";
import copy from "copy-to-clipboard";

const Wrapper = styled.div`
  max-height: 85vh;
  max-width: 726px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  ${(props) => props.theme.breakpoints.maxTablet} {
    max-height: 100%;
    overflow-y: auto;
    margin-top: 15px;
  }
`;

const FormWrapper = styled.form`
  .ant-form-item-label {
    text-align: left;
  }

  .ant-form-item-label label::after {
    content: "";
  }
`;

export const Section = styled(FormSection)``;

export const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  margin-bottom: 100px;

  ${(props) => props.theme.breakpoints.tablet} {
    width: 230px;
  }

  ${(props) => props.theme.breakpoints.maxPhone} {
    flex-wrap: wrap;
  }
`;

const InDownloadLinkWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2rem;
`;

const InNoBorderButton = styled(Button)`
  color: #2e2c9a;
  border: none;
  box-shadow: none;
`;

const InButton = styled(Button)`
  background: ${(props) => (props.primary ? "#2E2C9A" : "transparent")};
  border-color: #2e2c9a;
  width: ${(props) => (props.width ? props.width : "172px")};
  height: 44px;
  border-radius: 4px;
  color: ${(props) => (props.primary ? "#ffffff" : "#2E2C9A")};
  text-transform: uppercase;

  &:hover {
    background: ${(props) => (props.primary ? "#2E2C9A" : "#ffffff")};
    border-color: #2e2c9a;
    color: ${(props) => (props.primary ? "#ffffff" : "#2E2C9A")};
  }

  &:focus {
    background: ${(props) => (props.primary ? "#2E2C9A" : "#ffffff")};
    border-color: #2e2c9a;
    color: ${(props) => (props.primary ? "#ffffff" : "#2E2C9A")};
  }
`;

function formatDates(date) {
  const start = date.start.seconds;
  const end = date.end.seconds;

  const startTimestamp = timestampToDate(start);
  const startDateString = moment(startTimestamp);

  const endTimestamp = timestampToDate(end);
  const endDateString = moment(endTimestamp);

  return getDates(startDateString, endDateString);
}

const getDates = (startDate, endDate) => {
  let dates = [],
    currentDate = startDate,
    addDays = function (days) {
      const date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
  while (currentDate <= endDate) {
    dates.push(currentDate);
    currentDate = addDays.call(currentDate, 1);
  }
  return dates;
};

export const formatDate = (value) => {
  return moment(value).format("MMM DD YYYY");
};

const submitData = (eventId, values, finalSubmit) => {
  events.updateItinerary(eventId, values, finalSubmit);
};

const TimelineForm = ({ handleSubmit, itineraryData, toggleMenu, formState, eventId, formInit, timeline, uid }) => {
  const [page, setPage] = React.useState(1);
  const [isSubmitting, finalSubmit] = React.useState(false);
  const dateList = formatDates(itineraryData.dates);
  const dispatch = useDispatch();
  const event = useSelector(({ firestore: { data } }) => data.events && data.events[eventId]);
  const isOrganizer = event?.organizer?.uid === uid;

  useEffect(() => {
    if (itineraryData && itineraryData.details) {
      formInit(itineraryData.details);
    }
  }, [itineraryData, formInit]);

  const nextOrPrevPage = (value) => {
    setPage(value);
    finalSubmit(false);
  };

  const showMessageCopied = () => {
    dispatch(toast("Link copied", "success"));
  };

  if (!dateList.length) return <div>no data</div>;

  if (timeline.completeSchedule) {
    return (
      <Wrapper>
        <FinalScheduleTimeline dateList={dateList} toggleMenu={toggleMenu} registeredValues={itineraryData.details} />

        <BtnContainer>
          {isOrganizer && (
            <InDownloadLinkWrap>
              <InNoBorderButton htmlType="button" onClick={() => events.reopenItinerary(eventId, false)}>
                BACK TO EDIT
              </InNoBorderButton>
            </InDownloadLinkWrap>
          )}
          <InDownloadLinkWrap>
            <InNoBorderButton
              htmlType="button"
              onClick={() => {
                copy(`${window.location.origin}/event-guest-view/${eventId}/schedule`);
                showMessageCopied();
              }}
              primary={+true}
            >
              COPY LINK AND SHARE WITH GUESTS
            </InNoBorderButton>
          </InDownloadLinkWrap>
        </BtnContainer>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <FormWrapper onSubmit={handleSubmit((values) => submitData(eventId, values, isSubmitting))}>
        {page === 1 && <TimelinePageOne dateList={dateList} toggleMenu={toggleMenu} />}
        {page === 2 && (
          <TimelinePageTwo
            dateList={dateList}
            toggleMenu={toggleMenu}
            registeredValues={formState && formState.values ? formState.values : null}
          />
        )}
        <BtnContainer>
          {page === 1 ? (
            <InButton width="103px" onClick={() => finalSubmit(false)} text="" htmlType="submit">
              SAVE
            </InButton>
          ) : (
            <InDownloadLinkWrap>
              <InNoBorderButton htmlType="button" onClick={() => nextOrPrevPage(1)}>
                BACK TO EDIT
              </InNoBorderButton>
            </InDownloadLinkWrap>
          )}

          {page === 1 ? (
            <InButton
              width="103px"
              onClick={() => {
                finalSubmit(true);
                //	nextOrPrevPage(2)
              }}
              text=""
              htmlType="submit"
              primary={+true}
              disabled={!!formState?.syncErrors}
            >
              FINALIZE
            </InButton>
          ) : (
            <InDownloadLinkWrap>
              <InNoBorderButton
                htmlType="submit"
                // onClick={() => finalSubmit(true)}
                primary={+true}
              >
                COPY LINK AND SHARE WITH GUESTS
              </InNoBorderButton>
            </InDownloadLinkWrap>
          )}
        </BtnContainer>
      </FormWrapper>
    </Wrapper>
  );
};

TimelineForm.defaultProps = {};

TimelineForm.propTypes = {};

const CreateItinerary = reduxForm({
  form: `itinerary`,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(TimelineForm);

// const selector = formValueSelector('itinerary')

export function mapStateToProps(state) {
  return {
    uid: state.auth.user.user.uid,
    formState: state.form.itinerary,
  };
}

export default connect(mapStateToProps, null)(CreateItinerary);
