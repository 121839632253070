import React from 'react';
import styled from 'styled-components'
import line from './line.png'
import vectorUnderline from '../../assets/home/VectorUnderline.svg'
import longUnderline from './longunderline.svg'
import unionLine from './under-line.svg'
import { Image } from '../'

export const HeadingOne = styled.h1`
    font-family: 'Clearface Bold';
    font-style: normal;
    font-size: 32px;
    line-height: 140%;
    color: #101B43;
    
    ${props => props.theme.breakpoints.tablet}{
        font-size: 4vw;
        line-height: 5vw;
    }
  
  ${props => props.theme.breakpoints.desktop} {
    font-size: 44px;
        line-height: 150%;
  }
`;

const TitleUnderline = styled(HeadingOne)`
  display: flex;
  flex-direction: column;
  width: auto;
`;

export function HeadingOneUnderLine({ children, minWidth  }) {
  return (
    <TitleUnderline>
      <span>{children}</span>
      <Image src={vectorUnderline}/>
    </TitleUnderline>
  );
}

export const SectionHeading = styled.h2`
    font-family: 'Proxima Nova Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    color: #101B43;
    text-align: ${props => props.align ? props.align : 'center'};

    ${props => props.theme.breakpoints.tablet}{
      font-size: 26px;
    }
`;

const SectionUnderline = styled(SectionHeading)`
  display: flex;
  flex-direction: column;
  width: auto;
  margin: 0;
  padding: 0;
`;

export function SectionHeadingUnderLine({ children, width, minWidth, maxWidth, white, color  }) {
  return (
    <SectionUnderline color={color}>
      <span>{children}</span>
      <Image
        src={white ? unionLine : vectorUnderline}
        width={width}
        minWidth={minWidth}
        maxWidth={maxWidth}
      />
    </SectionUnderline>
  );
}

const LongUnderline = styled(SectionHeading)`
  display: flex;
  flex-direction: column;
  width: auto;
  margin: 0;
  padding: 0;
`;

export function SectionLongUnderLine({ children, width, minWidth, maxWidth, white, color  }) {
    return (
        <LongUnderline color={color}>
            <span>{children}</span>
            <Image
                src={longUnderline}
                width={width}
                minWidth={minWidth}
                maxWidth={maxWidth}
            />
        </LongUnderline>
    );
}

export const HeadingThree = styled.h3`
    color: ${props => props.theme.colors.tarawera};   
    font-family: 'Proxima Nova', sans-serif;
    font-size: 30px;
    line-height: 42px;
    text-align: ${props => props.align ? props.align : 'center'};
    margin: 12px 0;   
    
    ${props => props.theme.breakpoints.tablet}{
      font-size: 26px;
    } 
`;

export const HeadingFour = styled.h4`  
    font-family: 'Proxima Nova';
    text-align: ${props => props.align ? props.align : 'center'};
    margin: 12px 0; 

    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    color: #101B43;
    mix-blend-mode: normal;  
    
    ${props => props.theme.breakpoints.tablet}{
        font-size: 18px;
        line-height: 150%;
    } 
`;

export const Paragraph = styled.p`
    font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 150%;
color: #101B43
    text-align: left;
    margin: 6.5px 0;
    
    @media (max-width: 480px) {
        font-size: 16px;
        line-height: 150%;
        color: #101B43;
   }
`;