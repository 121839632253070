import React, { useState } from "react";
import PropTypes from "prop-types";
import { Field, reduxForm, SubmissionError } from "redux-form";
import styled from "styled-components";
import { FormComponents, InTypography, InButton } from "../../index";
// import { fbApp } from "../../../config/firebase";
import { resetPassword } from "../../../helpers/auth";
const FormWrapper = styled.form`
  max-width: 670px;
  width: 100%;
  margin: 27px 0 0 0;
  text-align: center;

  p {
    margin: 1em 0;
  }

  .ant-form-item-label {
    text-align: left;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;

    color: #101b43;
  }

  .ant-form-item-label label::after {
    content: "";
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  color: #8696ae;
  cursor: pointer;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;

  span {
    color: #101b43;
    margin: 0 0.4em;
  }
`;

export const ContainerEmailSent = styled.div`
  margin: 27px 80px 40px 80px;
  text-align: center;
  p {
    margin: 1em 0;
  }
`;

const ForgotPassword = ({ handleSubmit, handleActiveForm }) => {
	const [emailSent, setEmailSent] = useState(false);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const resetPasswordHandler = async (email, handleActiveForm) => {
		resetPassword(email)
			.then(() => {
				setEmailSent(true);
				setTimeout(() => handleActiveForm("login"), 2500);
			})
			.catch((error) => {
				setError(error);
				setEmailSent(true);
			});
	};

	return !emailSent ? (
		<FormWrapper
			onSubmit={handleSubmit((values) => {
				setLoading(true);
				resetPasswordHandler(values.email, handleActiveForm)
			}
			)}
		>
			<InTypography.InH1>Forgot password</InTypography.InH1>
			<InTypography.InParagraph>
				Enter your email and we’ll send the instruction
			</InTypography.InParagraph>
			<Field
				label="Email"
				name="email"
				type="email"
				component={FormComponents.InInput}
				placeholder="Email"
				hasFeedback
			/>
			<InButton
				type="primary"
				// disabled={pristine || submitting}
				htmlType="submit"
				text="RESET PASSWORD"
				primary
				width="100%"
				disabled={false}
				loading={loading}
			/>
			<Container onClick={() => handleActiveForm("login")}>
				BACK TO <span> SIGN IN</span>
			</Container>
		</FormWrapper>
	) : (
		<ContainerEmailSent>
			<InTypography.InH1>
				{error ? "Email didn't send" : "Email sent"}
			</InTypography.InH1>
			<InTypography.InParagraph>
				{error
					? "User not found"
					: "We've sent you an email with a link to update your password."}
			</InTypography.InParagraph>
		</ContainerEmailSent>
	);
};

ForgotPassword.defaultProps = {};

ForgotPassword.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
};

const validate = (values) => {
	const errors = {};
	if (!values.email) {
		errors.email = "Required";
	}

	return errors;
};

const ForgotPasswordForm = reduxForm({
	form: "forgotPassword",
	validate,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
})(ForgotPassword);

export default ForgotPasswordForm;
