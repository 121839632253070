import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
export const CreateEventContext = React.createContext()

class CreateEventProvider extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: null,
      drawerVisible: false
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {

  }

  showDrawer = () => {
    this.setState({ drawerVisible: true })
  };

  onClose = () => {
    this.setState({ drawerVisible: false })
  };

  render() {
    const { drawerVisible } = this.state
    const { children  } = this.props

    const providerData = {
      drawerVisible,
      showDrawer: this.showDrawer,
      onClose: this.onClose,
    }
    return (
      <CreateEventContext.Provider value={providerData}>
        {children}
      </CreateEventContext.Provider>
    )
  }
}

CreateEventProvider.defaultProps = {
// <!--  children: PropTypes.object-->
};

CreateEventProvider.propTypes = {
// <!--  children: {}-->
};

function mapStateToProps (state) {
  return {
    //redux data
  }
}

export default connect(mapStateToProps, null)(CreateEventProvider)