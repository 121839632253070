import React, { Component } from 'react';
import PropTypes from 'prop-types'
import styled from "styled-components";
import moment from "moment";

export const Container = styled.div`
    display: flex;
    width: 100%;
    max-width: 342px;
    
`;

export const Column = styled.div`
   //margin-right: 12px;
`;

export const Timer = styled.div`
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 100%;
    
    display: flex;
    align-items: flex-end;

    color: #2E2C9A;

  span {
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
  }
`;

export const Divider = styled.div`
   margin: 0 0.25em;
`;

export const timestampToDate = (value) => moment.unix(value).format('YYYY-MM-DD');

class Countdown extends Component {
	constructor(props) {
		super(props);

		this.state = {
			days: 0,
			hours: 0,
			min: 0,
			sec: 0,
		}
	}

	componentDidMount() {
		const { itineraryData } = this.props;
		if (itineraryData && itineraryData.dates && itineraryData.dates.start) {
			const countdown = timestampToDate(itineraryData.dates.start.seconds);
			const now = new Date(countdown);
			// update every second
			const somedate = now.setDate(now.getDate());
			this.interval = setInterval(() => {
				const date = this.calculateCountdown(somedate);
				date ? this.setState(date) : this.stop();
			}, 1000);
		}
	}

	componentWillUnmount() {
		this.stop();
	}

	calculateCountdown(endDate) {
		let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;

		if (diff <= 0) return false;

		const timeLeft = {
			years: 0,
			days: 3,
			hours: 0,
			min: 0,
			sec: 0,
			millisec: 0,
		};

		// calculate time difference between now and expected date
		if (diff >= (365.25 * 86400)) { // 365.25 * 24 * 60 * 60
			timeLeft.years = Math.floor(diff / (365.25 * 86400));
			diff -= timeLeft.years * 365.25 * 86400;
		}
		if (diff >= 86400) { // 24 * 60 * 60
			timeLeft.days = Math.floor(diff / 86400);
			diff -= timeLeft.days * 86400;
		}
		if (diff >= 3600) { // 60 * 60
			timeLeft.hours = Math.floor(diff / 3600);
			diff -= timeLeft.hours * 3600;
		}
		if (diff >= 60) {
			timeLeft.min = Math.floor(diff / 60);
			diff -= timeLeft.min * 60;
		}
		timeLeft.sec = diff;
		return timeLeft;
	}

	stop() {
		clearInterval(this.interval);
	}

	addLeadingZeros(value) {
		value = String(value);
		// while (value.length < 2) {
		//     value = '0' + value;
		// }
		return value;
	}

	render() {
		const countDown = this.state;

		return (
			<Container>
				<Column style={{ marginRight: 12 }}>
					<Timer>
						<div>{this.addLeadingZeros(countDown.days)}</div>
						<span>{countDown.days === 1 ? 'Day' : 'Days'}</span>
					</Timer>
				</Column>
				<Column>
					<Timer>
						<strong>{this.addLeadingZeros(countDown.hours)}</strong>
						<Divider>:</Divider>
					</Timer>
				</Column>

				<Column>
					<Timer>
						<div>{this.addLeadingZeros(countDown.min)}</div>
						<Divider>:</Divider>
					</Timer>
				</Column>

				<Column>
					<Timer>
						<div>{this.addLeadingZeros(countDown.sec)}</div>
					</Timer>
				</Column>
			</Container>
		);
	}
}

Countdown.propTypes = {
	// date: PropTypes.string.isRequired
};

Countdown.defaultProps = {
	date: new Date()
};

export default Countdown;