import React from 'react';
import CreateEventProvider, { CreateEventContext } from './CreateEventProvider'
import { Drawer } from "../../components/";
import { CreateEventForm } from "../../components/Forms";

const CreateEvent = ({
                         drawerVisible,
                         onClose
                     }) => {
  return (
    <CreateEventProvider>
      <CreateEventContext.Consumer>
          {({ ipLocation }) => {
              return (
                <div>
                    <Drawer
                        visible={drawerVisible}
                        onClose={onClose}
                    >
                        <CreateEventForm
                            ipLocation={ipLocation}
                            onClose={onClose}
                        />
                    </Drawer>
                </div>
              )
          }}
      </CreateEventContext.Consumer>
    </CreateEventProvider>
  );
};

CreateEvent.defaultProps = {

};

CreateEvent.propTypes = {

};

export default CreateEvent