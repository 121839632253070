import React, { Component } from 'react';
import styled from "styled-components"
import checkMark from './Check.svg'
import { SectionHeading, SectionHeadingUnderLine, HeadingFour } from '../../../../../components/Text'
import {Button} from "antd";

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

const DefaultBtn = styled(Button)`
  background: ${props => props.primary ? '#EC6E5B' : '#ffffff'};
  border-color: #EC6E5B;
  width: ${props => props.width ? props.width : '172px'};
  height: 44px;
  border-radius: 4px;
  color: ${props => props.primary ? '#ffffff' : '#EC6E5B'};
  text-transform: uppercase;
  
  &:hover {
    background: ${props => props.primary ? '#EC6E5B' : '#ffffff'};
    border-color: #EC6E5B;
    color: ${props => props.primary ? '#ffffff' : '#EC6E5B'};
  }
  
  &:focus {
    background: ${props => props.primary ? '#EC6E5B' : '#ffffff'};
    border-color: #EC6E5B;
    color: ${props => props.primary ? '#ffffff' : '#EC6E5B'};
  }

`

const TitleWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

const ParagraphWrapper = styled.div`  
    width: 100%;
  margin-bottom: 16px;
  
  ul {
    list-style: none;
    margin: 16px 0 0 0;
    padding: 0;
    
    li {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 1em 0;
      
      h4 {
        margin: 0;
        text-align: left;
      }
      
      p {
        margin: 0;
      }
      img {
        margin-right: 10px;
      }
    }
  }
`;

const ButtonContainer = styled.div`
  width: 75%;
  display: flex;
  justify-content: flex-start;
  margin: 4px 0 1em 0;
`;

class CarouselComponent extends Component {
  render() {
    return (
        <Container>
          <TitleWrapper>
            <SectionHeading>
              Ditch The&nbsp;
            </SectionHeading>
            <SectionHeadingUnderLine width='180px'>
              Group Threads
            </SectionHeadingUnderLine>
          </TitleWrapper>
          <ParagraphWrapper>
            <HeadingFour align='left'>
              For all yes’s, no’s and maybe’s - there’s one special link for that.
            </HeadingFour>
            <ul>
              <li>
                <img src={checkMark} alt=""/>
                <HeadingFour>
                  Say goodbye to "Hey Ladies!" group threads
                </HeadingFour>
              </li>
              <li>
                <img src={checkMark} alt=""/>
                <HeadingFour>
                  One initial text to guests to gather all the deets you need
                </HeadingFour>
              </li>
              <li>
                <img src={checkMark} alt=""/>
                <HeadingFour>
                  So long inbox clutter and reply-all
                </HeadingFour>
              </li>
            </ul>
          </ParagraphWrapper>
          <ButtonContainer>
            <DefaultBtn primary>Join Now</DefaultBtn>
          </ButtonContainer>
        </Container>
    );
  }
}

export default CarouselComponent
