import React from 'react';
import { Row, Col } from "antd";
import styled from "styled-components";
import DesktopIcon from './planningDashboard.svg';
import WatchIcon from './watches.svg';
import MapIcon from './Map.svg';
import DividerIcon from './divider.svg';
import ArrowIcon from './arrow.svg';
import { HeadingFour } from '../../../../components/Text'

export const GridContainer = styled(Row)`
    width: 100%;
`;

export const GridLayer = styled.div`
    width: 100%;
    margin: 28.9px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 210px;
`;

export const Button = styled.button`
    color: ${props => props.theme.colors.tarawera};   
    font-family: Proxima Nova, 'Montserrat', sans-serif;
    font-size: 30px;
    line-height: 42px;
    text-align: center;    
`;

export const NoBorderBtn = styled.button`
    font-family: 'Proxima Nova';
    border: none;
    border-radius: 6px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #EC6E5B;
    display: flex;
    align-items: center;
    justify-content: center;

  margin: 1em 0 0 0;
  padding: 0;
  outline: none;
  ${props => {
    return props.primary
        ? `background: #EC6E5B; color: #FFFFFF;`
        : `background: #FFFFFF; color: #EC6E5B;`;
}} ;

img {
    margin-left: 12.5px;
}
  
`;


export const Divider = styled.div`
    display: flex;
    justify-content: center;
`;

function Benefits() {
    return (
        <GridContainer>
            <Col xs={24} md={8}>
                <GridLayer>
                    <img src={DesktopIcon} alt="" />
                    <HeadingFour>
                        Free Bachelorette
                        Planning Dashboard
                    </HeadingFour>
                    <NoBorderBtn variant="h4" component="h1" gutterBottom>
                        <span>Create Your Event</span> <img src={ArrowIcon} alt=""/>
                    </NoBorderBtn>
                </GridLayer>
                <Divider>
                    <img src={DividerIcon} alt=""/>
                </Divider>
            </Col>
            <Col xs={24} md={8}>
                <GridLayer>
                    <img src={WatchIcon} alt="" />
                    <HeadingFour>
                        Easiest Bachelorette
                        Planning Tool Suite
                    </HeadingFour>
                    <NoBorderBtn variant="h4" component="h1" gutterBottom>
                    <span>Plan Your Party</span> <img src={ArrowIcon} alt=""/>
                    </NoBorderBtn>
                </GridLayer>
                <Divider>
                    <img src={DividerIcon} alt=""/>
                </Divider>
            </Col>
            <Col xs={24} md={8}>
                <GridLayer>
                    <img src={MapIcon} alt="" />
                    <HeadingFour>
                        Insider Guide To Cities,
                        Merch, Ideas & More!
                    </HeadingFour>
                    <NoBorderBtn variant="h4" component="h1" gutterBottom>
                    <span>Shop Now</span> <img src={ArrowIcon} alt=""/>
                    </NoBorderBtn>
                </GridLayer>
                <Divider>
                    <img src={DividerIcon} alt=""/>
                </Divider>
            </Col>
        </GridContainer>
    );
}

export default Benefits


