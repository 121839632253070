import React, { useState } from "react";
import styled from "styled-components";
import { Checkbox } from "antd";
import { Image } from "../";
import CitySuggest from "./CitySuggest";
import { ModalSidebarLayout } from "../CustomModalLayout/CitiesModalLayout";
import {
	caboLucas,
	palmSprings,
	newYork,
	napa,
	nashville,
	vegas,
	newOrleans,
	miami,
	tulum,
	austin,
	losAngeles,
	charleston,
	scottsdale,
	montreal,
	cancun,
	lakeTahoe,
	portland,
} from "../../assets/cloudinary/images";

const trendingCities = [
	{
		key: "New Orleans",
		name: "New Orleans",
		imageUrl: newOrleans,
	},
	{
		key: "Austin",
		name: "Austin",
		imageUrl: austin,
	},
	{
		key: "Charleston",
		name: "Charleston",
		imageUrl: charleston,
	},
	{
		key: "Palm Springs",
		name: "Palm Springs",
		imageUrl: palmSprings,
	},
	{
		key: "Miami, FL, USA",
		name: "Miami, FL, USA",
		imageUrl: miami,
	},
	{
		name: "Nashville",
		imageUrl: nashville,
	},
	{
		key: "Scottsdale ",
		name: "Scottsdale ",
		imageUrl: scottsdale,
	},
	{
		key: "Napa",
		name: "Napa",
		imageUrl: napa,
	},
	{
		key: "Las Vegas",
		name: "Las Vegas",
		imageUrl: vegas,
	},
	{
		key: "Portland",
		name: "Portland",
		imageUrl: portland,
	},
	{
		key: "Lake Tahoe",
		name: "Lake Tahoe",
		imageUrl: lakeTahoe,
	},
	{
		key: "Los Angeles",
		name: "Los Angeles",
		imageUrl: losAngeles,
	},
	{
		key: "New York",
		name: "New York",
		imageUrl: newYork,
	},
	{
		key: "Montreal, Canada",
		name: "Montreal, Canada",
		imageUrl: montreal,
	},
	{
		key: "Cabo San Lucas",
		name: "Cabo San Lucas",
		imageUrl: caboLucas,
	},
	{
		key: "Tulum",
		name: "Tulum",
		imageUrl: tulum,
	},
	{
		key: "Cancún",
		name: "Cancún",
		imageUrl: cancun,
	},
];

const Wrapper = styled.div`
  display: -webkit-box;
  overflow-y: scroll;
  height: 100vh;
  margin-right: 1em;
  border-radius: 8px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05);
  background: #fafafc;
  padding-top: 0;
  position: relative;
  padding: 20px;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 0; 
    background: transparent;
  }
  ${props => props.theme.breakpoints.maxTablet}  {
    display: none;
  }
`;

const CitiesList = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: auto;
  position: absolute;
  padding-bottom: 150px;
  h1 {
    /* Desktop/HEADLINES/H4 18SB */
    margin-left: 20px;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: #8696ae;
  }
`;

const BoxWrapper = styled.div`
  margin: 14px 0;
  width: fit-content;
`;

const Box = styled.div`
  //width: 285px;
  //height: 150px;
  margin-bottom: 12px;
  img {
    border-radius: 8px 8px 0px 0px;
  }
`;
const BoxLabel = styled.label`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  color: #101b43;
`;

const CityCard = ({ city, addItem, isChecked }) => {
	const handleChange = (input, city) => {
		if (!isChecked) {
			addItem(city);
		}
	};

	return (
		<BoxWrapper>
			<Box>
				<Image src={city.imageUrl} />
			</Box>
			<BoxLabel>
				<Checkbox
					name={city.name}
					size="large"
					onChange={(e) => handleChange(e, city)}
					checked={isChecked}
				>
					{city.name}
				</Checkbox>
			</BoxLabel>
		</BoxWrapper>
	);
};

const SideBar = ({ addItem, destinations, cities }) => {
	return (
		<Wrapper>
			<CitiesList>
				<h1>Trending locations</h1>
				{trendingCities.map((city, index) => {
					const isChecked = destinations.some(
						(place) => place.name === city.name
					);
					return (
						<CityCard
							key={index}
							city={city}
							addItem={addItem}
							isChecked={isChecked}
						/>
					);
				})}
			</CitiesList>
		</Wrapper>
	);
};

class Cities extends React.Component {
	constructor() {
		super();
		this.state = {
			items: [],
			currentItem: {
				name: "",
				key: "",
				imageUrl: "",
			},
		};
	}

	componentDidUpdate(prevProps) {
		if (
			this.props.checkValue &&
			this.props.checkValue !== prevProps.checkValue
		) {
			this.props.handleDestinations([]);
			this.setState({
				items: [],
				currentItem: {
					name: "",
					key: "",
				},
			});
		}

		if (
			this.props.resetCities &&
			this.props.resetCities !== prevProps.resetCities
		) {
			this.setState({
				items: [],
				currentItem: {
					name: "",
					key: "",
				},
			});
		}
	}
	deleteItem = (key) => {
		const filteredItems = this.state.items.filter((item) => {
			return item.key !== key;
		});
		this.setState({
			items: filteredItems,
		});
		this.props.handleDestinations(filteredItems);
	};

	onSelect = (city, image) => {
		this.setState((prevState) => {
			return {
				currentItem: {
					...prevState.currentItem,
					name: city,
					imageUrl: image,
					key: city,
				},
			};
		});

		this.addItem(this.state.currentItem);
	};

	addItem = (newItem) => {
		if (newItem.name !== "" && this.state.items.length < 3) {
			if (this.props.checkValue) {
				if (this.state.items.length < 1) {
					const items = [...this.state.items, newItem];
					this.props.handleDestinations(items);
					this.setState({
						items: items,
						currentItem: {
							name: "",
							key: "",
						},
					});
				}
			} else {
				const items = [...this.state.items, newItem];
				this.props.handleDestinations(items);
				this.setState({
					items: items,
					currentItem: {
						name: "",
						key: "",
					},
				});
			}
		}
	};

	render() {
		const {
			toggleCheck,
			handleCancel,
			disabled,
			handleAdd,
			destinations,
			checkValue,
			cities,
		} = this.props;
		const { items, currentItem } = this.state;
		return (
			<ModalSidebarLayout
				handleCancel={handleCancel}
				title="Add Destination"
				//   checkboxLabel='Do you have a destination set already?'
				//   toggleCheck={toggleCheck}
				//   checkValue={checkValue}
				id="add-cities"
				sidebar={
					<SideBar
						cities={cities}
						addItem={this.addItem}
						destinations={destinations}
					/>
				}
			>
				<CitySuggest
					disabled={disabled}
					handleAdd={handleAdd}
					items={destinations}
					currentItem={currentItem}
					addItem={this.addItem}
					onSelect={this.onSelect}
					deleteItem={this.deleteItem}
					checkValue={checkValue}
				/>
			</ModalSidebarLayout>
		);
	}
}

Cities.defaultProps = {};

Cities.propTypes = {};

export default Cities;
