import React from 'react';
import styled from "styled-components";
import {Menu} from "antd";

const Wrapper = styled.svg`
    fill: #494D5B;
     stroke: #494D5B;
    
    &:hover {
      fill: #2E2C9A;
     stroke: #2E2C9A;
    }
`

export default ({ style }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M13.7998 1.59961H1.7998C1.24752 1.59961 0.799805 2.04732 0.799805 2.59961V9.79961C0.799805 10.3519 1.24752 10.7996 1.7998 10.7996H2.2998C2.57595 10.7996 2.7998 11.0235 2.7998 11.2996V13.2012C2.7998 13.4782 3.14317 13.6072 3.32558 13.3987L5.4504 10.9704C5.54534 10.8618 5.68251 10.7996 5.82669 10.7996H13.7998C14.3521 10.7996 14.7998 10.3519 14.7998 9.79961V2.59961C14.7998 2.04732 14.3521 1.59961 13.7998 1.59961Z" />
        <path d="M7.74764 7.07632C8.07426 7.07632 8.33904 6.81658 8.33904 6.49617C8.33904 6.17576 8.07426 5.91602 7.74764 5.91602C7.42103 5.91602 7.15625 6.17576 7.15625 6.49617C7.15625 6.81658 7.42103 7.07632 7.74764 7.07632Z" />
        <path d="M9.72811 7.07632C10.0547 7.07632 10.3195 6.81658 10.3195 6.49617C10.3195 6.17576 10.0547 5.91602 9.72811 5.91602C9.40149 5.91602 9.13672 6.17576 9.13672 6.49617C9.13672 6.81658 9.40149 7.07632 9.72811 7.07632Z" />
        <path d="M5.7662 7.07632C6.09281 7.07632 6.35759 6.81658 6.35759 6.49617C6.35759 6.17576 6.09281 5.91602 5.7662 5.91602C5.43958 5.91602 5.1748 6.17576 5.1748 6.49617C5.1748 6.81658 5.43958 7.07632 5.7662 7.07632Z" />
    </svg>
);
