import React from "react";
import { Tabs } from "antd";
import styled from "styled-components";
import { TimelineActivity } from "./timeline";
import { ActivityTimeline } from "./activityList";

const { TabPane } = Tabs;

const Wrapper = styled(Tabs)`
  width: 100%;
  height: 100%;
  background: white;
  padding: 0 1em;
  border-radius: 8px 8px 0 0;
  //overflow-y: scroll;

  ${(props) => props.theme.breakpoints.tablet} {
    //width: 352px;
    height: 85vh;
  }
  .ant-tabs-nav {
    margin: 0 0 1.5em 0;
  }
  .ant-tabs-tab {
    color: #8696ae;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 100%;
    margin-bottom: 12px;
    margin-top: 6px;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    color: #2e2c9a;
    font-weight: 600;
  }

  .ant-tabs-ink-bar {
    background: #2e2c9a;
  }

  .ant-tabs-tab:hover {
    color: #2e2c9a;
  }
`;
function callback(key) {
  console.log(key);
}

export const ActivityTabs = () => (
  <Wrapper defaultActiveKey="1" onChange={callback}>
    <TabPane tab="Timeline" key="1">
      <TimelineActivity />
    </TabPane>
  </Wrapper>
);
