import React, {useState} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Popover, Button } from 'antd';
import { BellOutlined } from '@ant-design/icons';

const text = <span>Title</span>;
const content = (
    <div>
        <p>Content</p>
        <p>Content</p>
    </div>
);

export default class App extends React.Component {
    state = {
        visible: false,
    };

    hide = () => {
        this.setState({
            visible: false,
        });
    };

    handleVisibleChange = visible => {
        // this.setState({ visible });
    };

    render() {
        return (
            <Popover
                title={text} content={content}
                trigger="click"
                visible={this.state.visible}
                onVisibleChange={this.handleVisibleChange}
            >
                <Button type="text" icon={<BellOutlined size='large' />} />
            </Popover>
        );
    }
}
