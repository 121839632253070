import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col, Skeleton } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { InButton } from "../../../components";
import { PollsContext } from "../PollsProvider";
import CreatePollDrawer from "./createPollDrawer";
import AddPollOptionDrawer from "./addPollOptionDrawer";
import AddPollOptionForm from "./addPollOptionForm";
import CreateCustomForm from "./createCustomPollForm";
import CustomPollForm from "./customPollForm";
import CustomPollDrawer from "./customPollDrawer";
import DisplayAddedPoll from "./displayAddedPoll";
import { polls } from "../../../helpers/firestore";

export const Container = styled(Row)`
  padding: 0 1.25em;
`;

const Cards = styled.div`
  min-height: 184px;
  background: ${(props) => (props.bg ? props.bg : "#ffffff")};
  border-radius: 8px;
  margin: 1.25em 0 3.75em 0;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.05);
`;

export const Header = styled(Col)`
  height: 60px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    font-family: "Clearface Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 34px;
    color: #101b43;
    padding: 0 1em;
    margin: 0;
    text-transform: capitalize;
  }
`;

export const ColContent = styled(Col)`
  margin: 1.5em 2em;

  p {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */

    display: flex;
    align-items: center;

    /* Text/Midnight Blue */

    color: #101b43;
  }
`;

export const Content = styled.div`
  h5 {
    margin: 20px 0;
    padding: 0;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: #101b43;
  }
`;

export const HeaderImage = styled.div`
  width: 100%;
  height: 150px;
  background: transparent url(${(props) => props.src}) no-repeat center;
  background-size: cover;
  border-radius: 8px 8px 0 0;
`;

const AllPolls = () => {
  const [activeRecommendations, setActiveRecommendations] = useState(null);
  const [customDrawer, setCustomDrawer] = useState(false);
  const [customPollData, setCustomPollData] = useState(null);
  const [addOption, setAddPollOption] = useState(false);

  const toggleModal = (name) => {
    setActiveRecommendations(name);
  };

  const toggleCustomDrawer = () => {
    setCustomDrawer(!customDrawer);
  };

  const openPollOption = (pollOption) => {
    setAddPollOption(true);
    setCustomPollData(pollOption);
  };

  const closePollOption = () => {
    setAddPollOption(false);
  };

  const findPolls = (poll, name) => {
    return poll.recommendationName === name;
  };

  const addPollOptionData = (poll) => {
    setCustomPollData(poll);
    toggleCustomDrawer();
  };

  const handleCustomData = (eventId, data, recommendationName) => {
    polls.addPoll(eventId, data, recommendationName).then((res) => {
      // setCustomPollData(res)
      toggleCustomDrawer();
      setActiveRecommendations(null);
    });
  };

  return (
    <PollsContext.Consumer>
      {({ event, recommendations, polls, guests, isGuest, eventId }) => {
        if (!recommendations && !event)
          return (
            <Container>
              <Skeleton active />
            </Container>
          );
        return recommendations.map((recommendation) => {
          const recommendationPolls = polls.filter((poll) =>
            findPolls(poll, recommendation.name)
          );
          return (
            <Cards>
              {recommendationPolls && recommendationPolls.length ? (
                <DisplayAddedPoll
                  name={recommendation.name}
                  event={event}
                  eventId={eventId}
                  recommendationPolls={recommendationPolls}
                  setActivePoll={() =>
                    setActiveRecommendations(recommendation.name)
                  }
                  addPollOptionData={addPollOptionData}
                  isGuest={isGuest}
                />
              ) : (
                <Row>
                  <Header xs={24}>
                    <h2>
                      {recommendation.name === "custom"
                        ? "Custom Poll"
                        : recommendation.name}
                    </h2>
                  </Header>
                  <ColContent xs={24}>
                    <p>{recommendation.title}</p>
                    <InButton
                      icon={<PlusOutlined />}
                      width="170px"
                      type="button"
                      onClick={() => {
                        setActiveRecommendations(recommendation.name);
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                      text="CREATE POLL"
                      primary
                    />
                  </ColContent>
                </Row>
              )}

              {/* Creates a poll name, question and note */}
              {recommendation.name === activeRecommendations && (
                <CreatePollDrawer
                  visible={recommendation.name === activeRecommendations}
                  closeDrawer={toggleModal}
                  event={event}
                  // title={recommendation.name === 'custom' ? 'Create New Poll' : `Create New ${recommendation.name} Poll`}
                  title={"Create New Poll"}
                  custom={true}
                >
                  <CreateCustomForm
                    eventId={event && event.id ? event.id : null}
                    closePollOption={closePollOption}
                    handleCustomData={handleCustomData}
                    recommendationName={recommendation.name}
                    guests={guests}
                    openPollOption={openPollOption}
                  />
                </CreatePollDrawer>
              )}

              {/* Add options to voting poll */}
              {customPollData &&
                customPollData.recommendationName === recommendation.name && (
                  <CustomPollDrawer
                    visible={customDrawer}
                    closeDrawer={toggleCustomDrawer}
                    event={event}
                    title={customPollData.name}
                  >
                    <CustomPollForm
                      customDrawer={customDrawer}
                      customPollData={customPollData}
                      eventId={event && event.id ? event.id : null}
                      pollId={recommendation.id}
                      pollName={recommendation.name}
                      openPollOption={openPollOption}
                      onClose={() => toggleCustomDrawer()}
                      recommendation={recommendation}
                      recommendationPolls={recommendationPolls}
                      pollOptions={
                        customPollData.options ? customPollData.options : []
                      }
                    />
                  </CustomPollDrawer>
                )}

              {/* Add options */}
              <AddPollOptionDrawer
                visible={addOption}
                closeDrawer={closePollOption}
                event={event}
                title={"Add New Option"}
              >
                <AddPollOptionForm
                  eventId={event && event.id ? event.id : null}
                  closePollOption={closePollOption}
                  recommendationName={recommendation.name}
                  customPollData={customPollData}
                  setCustomPollData={setCustomPollData}
                />
              </AddPollOptionDrawer>
            </Cards>
          );
        });
      }}
    </PollsContext.Consumer>
  );
};

AllPolls.defaultProps = {};

AllPolls.propTypes = {};

export default AllPolls;
