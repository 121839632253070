import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Row, Col } from "antd";

import { reduxForm, formValueSelector, initialize } from "redux-form";
import MySwitch from "../../components/Switch";
import { settings } from "../../helpers/firestore";

const FormWrapper = styled.form`
  margin: 0;

  .ant-form-item-label {
    text-align: left;
  }

  .ant-form-item-label label::after {
    content: "";
  }
`;

const InputWrapper = styled.div`
  margin: 1em 0;
  display: flex;

  h5 {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #101b43;
    margin: 0 1em;
  }
`;

const ChangeUserPasswordForm = (props) => {
  const [sendEmail, setSendEmail] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const userSettings = await settings.get(props.uid);
      setSendEmail(userSettings.sendEmail);
    }

    if (props.uid) {
      fetchData();
    }
  }, [props.uid]);

  const handleChange = async () => {
    setSendEmail((prev) => !prev);
    const settingsData = {
      sendEmail: !sendEmail,
    };
    await settings.set(props.uid, settingsData);
  };
  return (
    <FormWrapper onSubmit={props.handleSubmit((values) => console.log(values))}>
      <Row>
        <Col xs={24}>
          <InputWrapper>
            <MySwitch toggleCheck={handleChange} checkValue={sendEmail} id="sendEmail" />
            <h5>Send emails</h5>
          </InputWrapper>
        </Col>
      </Row>
    </FormWrapper>
  );
};

const UserDetailsForm = reduxForm({
  form: `notifications`,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(ChangeUserPasswordForm);

const selector = formValueSelector("notifications");

export function mapStateToProps(state) {
  return {
    uid: state.auth.user.user.uid,
    displayName: state.auth.user.user.displayName,
    countryCode: state.location.data.country.code,
    formState: state.form.userDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    formInit: (data) => {
      dispatch(initialize("notifications", data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailsForm);
