import React from "react";
import styled from "styled-components";
import dressCode from "../../../../assets/itinerary/dressCode.svg";
import mood from "../../../../assets/itinerary/mood.svg";
import risque from "../../../../assets/itinerary/risque.svg";
import theme from "../../../../assets/itinerary/theme.svg";
import instagram from "../../../../assets/itinerary/instagram.svg";
import drinks from "../../../../assets/itinerary/drinks.svg";
import { formatDate } from "../../../../helpers/normalizeDate";

const Wrapper = styled.div`
  /* background: #fff; */

  h1 {
    font-family: "Clearface Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 140%;
    color: #fff;
    margin: 0;
    padding: 0;
  }
`;

const Details = styled.div`
  height: 109px;
  background: #4f4db8;
  border-radius: 4px;
  margin-top: 2em;
  padding: 12px;
  display: flex;
  align-items: center;

  h4 {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    color: #fff;
    padding: 0;
    margin: 0 0 0 12px;
  }
`;

const AccommodationDetails = styled.div`
  border-radius: 4px;
  margin-top: 1em;
  /* padding: 12px; */
  display: flex;
  flex-direction: column;
  // align-items: center;

  h4 {
    font-family: "Proxima Nova Semibold";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: #fff;
    padding: 10px 0;
  }
`;

const AccommodationImageWrapper = styled.div`
  width: 100%;
  height: 150px;

  background: transparent url(${(props) => props.src}) no-repeat center;
  background-size: cover;
  border-radius: 8px 8px 0px 0px;
`;

const Preferences = styled.div`
  h4 {
    font-family: "Proxima Nova Semibold";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: #fff;
    margin: 40px 0;
  }

  p {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #101b43;
    margin: 0 12px;
  }
`;

const ImageWrapper = styled.div`
  width: 80px;
  height: 80px;

  background: transparent url(${(props) => props.src}) no-repeat center;
  background-size: cover;
  border-radius: 8px;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  p {
    color: #fff;
  }
`;

const ItineraryPlan = ({ itineraryData, brides }) => {
  return (
    <Wrapper>
      <h1>Your Plan</h1>
      <Details>
        <ImageWrapper src={itineraryData.city.imageUrl} />
        <div>
          <h4>{itineraryData.eventName}</h4>
          <h4>{itineraryData.city.name}</h4>
          <h4>{formatDate(itineraryData.dates)}</h4>
        </div>
      </Details>

      <AccommodationDetails>
        <h4>Accommodation:</h4>
        <AccommodationImageWrapper src={itineraryData?.city?.imageUrl} />
        <div>
          <h4>{itineraryData?.details?.day_1?.accommodation}</h4>
        </div>
      </AccommodationDetails>
      <Preferences>
        <h4>Bride's Preferences:</h4>
        {brides.map((bride) => {
          return (
            <div>
              <Option>
                <img src={dressCode} alt="" />
                <p>
                  Dress Code:{" "}
                  {bride.preferences
                    ? bride.preferences.dressCode.dressCode
                    : "Waiting for response"}
                </p>
              </Option>
              <Option>
                <img src={mood} alt="" />
                <p>
                  Mood:{" "}
                  {bride.preferences
                    ? bride.preferences.vibe.vibe.label
                    : "Waiting for response"}{" "}
                </p>
              </Option>
              <Option>
                <img src={risque} alt="" />
                <p>
                  Risque:{" "}
                  {bride.preferences
                    ? bride.preferences.vibe.risque.label
                    : "Waiting for response"}
                </p>
              </Option>
              <Option>
                <img src={theme} alt="" />
                <p>
                  Theme:{" "}
                  {bride.preferences && bride.preferences.theme
										? (bride.preferences.theme?.other ? bride.preferences.theme.other : bride.preferences.theme.theme.label)
										: "Waiting for response"}
                </p>
              </Option>

              {!bride?.preferences ? (
                <Option>
                  <img src={instagram} alt="" />
                  <p>#HASHTAG Waiting for response</p>
                </Option>
              ) : bride.preferences.socialMedia.hashtag ? (
                <Option>
                  <img src={instagram} alt="" />
                  <p>#{bride.preferences.socialMedia.hashtag}</p>
                </Option>
              ) : (
                ""
              )}

              <Option>
                <img src={drinks} alt="" />
                <p>
                  Drinks:{" "}
                  {bride.preferences
                    ? bride.preferences.choice.drink.map(
                        (el, idx) => `${idx > 0 ? ` ,` : ``}${el.label}`
                      )
                    : "Waiting for response"}
                </p>
              </Option>
            </div>
          );
        })}
      </Preferences>
    </Wrapper>
  );
};

ItineraryPlan.defaultProps = {};

ItineraryPlan.propTypes = {};

export default ItineraryPlan;
