import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll'

export class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      scroll.scrollToTop({
        duration: 0,
        delay: 0,
        smooth: true,
        offset: 50,
      });
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
