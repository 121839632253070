import React from "react";
import { formValueSelector, initialize, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import FormComponentBundler from "./FormComponentBundler";
import { guests } from "../../helpers/firestore";
import { logoutUser } from "../../helpers/auth";
import { formatStart, formatEnd } from "../../helpers/normalizeDate";

const Wrapper = styled.div`
  height: 100vh;
  background: #ffffff;
`;

const SlideWrapper = styled.div`
  width: 100%;
  height: 100%;

  .slide {
    position: absolute;
    padding: 0;
    margin: 0 0 0 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    @media (max-width: 767px) {
      height: calc(100% - 65px);
      position: fixed;
      top: 65px;
      left: 0;
      right: 0;
      bottom: 0;
      overflow-y: scroll;
    }
  }

  .slide-enter {
    transform: ${(props) =>
		props.reverse ? "translateY(-100vh)" : "translateY(100vh)"};
    opacity: 0;
    transition: transform 350ms ease, opacity 350ms ease;
  }

  .slide-enter-done {
    transform: translateY(100vh);
    opacity: 0.5;
    transition: transform 350ms ease, opacity 350ms ease;
  }

  .slide-enter-active,
  .slide-enter-done {
    transform: translateY(0);
    opacity: 1;
    transition: transform 350ms ease, opacity 350ms ease;
  }

  .slide-exit {
    transform: ${(props) =>
		props.reverse ? "translateY(100vh)" : "translateY(-100vh)"};
    opacity: 1;
    transition: transform 350ms ease, opacity 350ms ease;
  }

  .slide-exit-active {
    transform: ${(props) =>
		props.reverse ? "translateY(100vh)" : "translateY(-100vh)"};
    opacity: 0.5;
    transition: transform 350ms ease, opacity 350ms ease;
  }

  .slide-exit-done {
    transform: ${(props) =>
		props.reverse ? "translateY(100vh)" : "translateY(-100vh)"};
    opacity: 0;
    transition: transform 350ms ease, opacity 350ms ease;
  }
`;

const Content = styled.div`
  overflow: hidden;
`;

const FormWrapper = styled.form`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  @media (max-width: 767px) {
    
  }

  .ant-form-item-label {
    text-align: left;
  }

  .ant-form-item-label label::after {
    content: "";
  }
`;
const slideHOC = (InputComponent) => {
	return (props) => {
		return (
			<CSSTransition in={props.inValue} {...props}>
				<InputComponent className="slide" {...props} />
			</CSSTransition>
		);
	};
};

const Slide = slideHOC((props) => (
	<Content {...props}>
		<FormComponentBundler {...props} />
	</Content>
));

function formatDates(dates) {
	const start = dates.start.seconds;
	const end = dates.end.seconds;
	return `${formatStart(start)} - ${formatEnd(end)}`;
}

class PageTemplate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			slide: 0,
			reverse: false,
		};
	}

	// shouldComponentUpdate(nextProps, nextState){
	//     if(nextProps && nextProps.attendance && nextProps.attendance.attendance.value === "NO"){
	//         this.nextSlide(nextProps.steps.length - 1)
	//     }

	//     return true
	// }

	nextSlide(slide) {
		if (slide > this.state.slide && slide !== this.props.steps.length) {
			const progress = ((slide + 1) / this.props.steps.length) * 100;
			this.props.calcProgress(progress);
			this.setState({ slide, reverse: false });
		}
		if (slide < this.state.slide && slide >= 0) {
			const progress = ((slide + 1) / this.props.steps.length) * 100;
			this.props.calcProgress(progress);
			this.setState({ slide, reverse: true });
		}
	}

	render() {
		const { slide, reverse } = this.state;
		const {
			guestList,
			steps,
			handleSubmit,
			formState,
			formInit,
			displayName,
			budget,
			history,
			userId,
			eventId,
			data,
			dispatch,
			attendance,
			isSetDetails,
			dates,
			cities,
			guestDetails,
		} = this.props;
		const transProps = {
			appear: true,
			timeout: {
				enter: 500,
				exit: 500,
			},
			classNames: "slide",
		};
		function initForm(data) {
			formInit({ ...formState.values, ...data });
		}

		const submitFunction = (eventId, userId, preferences) => {
			const notAttending =
				(dates && dates.dates.value === "I am unable to attend.") ||
				(cities && cities.city.value === "I am unable to attend.");

			if (
				isSetDetails &&
				guestDetails &&
				guestDetails.preferences &&
				guestDetails.preferences.city &&
				guestDetails.preferences.city.length
			) {
				preferences = {
					...preferences,
					city: [...guestDetails.preferences.city],
				};
				preferences = {
					...preferences,
					dates: [...guestDetails.preferences.dates],
				};
			}

			if (data.setDates) {
				const datesEvent = formatDates(data.itinerary.dates);
				preferences = {
					...preferences,
					dates: [datesEvent],
				};
			}

			const userDetails = guestList.filter((guest) => guest.id === userId);
			guests.updatePreferences(
				eventId,
				userId,
				preferences,
				data.userId,
				history,
				logoutUser,
				dispatch,
				isSetDetails,
				notAttending,
				userDetails[0]
			);
		};

		return (
			<Wrapper>
				<FormWrapper
					onSubmit={handleSubmit((values) =>
						submitFunction(eventId, userId, values)
					)}
				>
					<SlideWrapper reverse={reverse}>
						{steps.length &&
							steps.map((step, index) => {
								return (
									<Slide
										formState={formState}
										data={step.name}
										steps={steps}
										guests={guestList}
										currentIndex={slide}
										key={index}
										mountOnEnter
										unmountOnExit
										inValue={index === slide}
										back={() => this.nextSlide(slide - 1)}
										next={() => this.nextSlide(slide + 1)}
										initForm={initForm}
										displayName={guestDetails.name}
										budget={budget}
										goToSlide={(value) => this.nextSlide(value)}
										attendance={
											attendance &&
											attendance.attendance &&
											attendance.attendance.value !== "NO"
										}
										dates={dates}
										cities={cities}
										submitForm={
											(dates &&
												dates.dates.value === "I am unable to attend.") ||
											(cities && cities.city.value === "I am unable to attend.")
										}
										{...transProps}
									/>
								);
							})}
					</SlideWrapper>
				</FormWrapper>
			</Wrapper>
		);
	}
}

const PageTemplateForm = reduxForm({
	form: "rsvp",
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
})(PageTemplate);

const selector = formValueSelector("rsvp");

export function mapStateToProps(state) {
	return {
		displayName:
			state.auth &&
				state.auth.user &&
				state.auth.user.user &&
				state.auth.user.user.displayName
				? state.auth.user.user.displayName
				: null,
		formState: state.form.rsvp,
		attendance: selector(state, "attendance"),
		budget: selector(state, "budget"),
		dates: selector(state, "dates"),
		cities: selector(state, "city"),
	};
}

export function mapDispatchToProps(dispatch) {
	return {
		formInit: (data) => {
			dispatch(initialize("rsvp", data));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(PageTemplateForm);
