import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import { Col, Row, Skeleton } from "antd";
import ScheduleProvider, { ScheduleContext } from "./ScheduleProvider";
import clipArt from "../../assets/itinerary/clip-1.png";
import { ScheduleTimeline, ScheduleGuests } from "../../components";
import { itinerary } from "../../helpers/firestore";
import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";

const Main = styled.div`
  background: #ffffff;
  min-height: 80vh;
  overflow: hidden;
`;

const Intro = styled.div`
  max-width: 453px;
  margin: 60px 0 0 122px;

  h1 {
    font-family: "Clearface Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 140%;
    color: #101b43;
  }
`;

const LinkButton = styled(Link)`
  background: #4f4db8;
  border-color: #4f4db8;
  width: 154px;
  height: 44px;
  border-radius: 4px;
  color: #ffffff;
  text-transform: uppercase;

  font-family: "Proxima Nova Semibold";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 80%;
  /* identical to box height, or 12px */

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: ${(props) => (props.primary ? "#2E2C9A" : "#ffffff")};
    border-color: #2e2c9a;
    color: ${(props) => (props.primary ? "#ffffff" : "#2E2C9A")};
  }

  &:focus {
    background: ${(props) => (props.primary ? "#2E2C9A" : "#ffffff")};
    border-color: #2e2c9a;
    color: ${(props) => (props.primary ? "#ffffff" : "#2E2C9A")};
  }
`;

const ActionButton = styled.button`
  background: #4f4db8;
  border-color: #4f4db8;
  width: 154px;
  height: 44px;
  border-radius: 4px;
  color: #ffffff;
  text-transform: uppercase;

  font-family: "Proxima Nova Semibold";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 80%;
  /* identical to box height, or 12px */

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: ${(props) => (props.primary ? "#2E2C9A" : "#ffffff")};
    border-color: #2e2c9a;
    color: ${(props) => (props.primary ? "#ffffff" : "#2E2C9A")};
  }

  &:focus {
    background: ${(props) => (props.primary ? "#2E2C9A" : "#ffffff")};
    border-color: #2e2c9a;
    color: ${(props) => (props.primary ? "#ffffff" : "#2E2C9A")};
  }
`;

const Image = styled.div`
  //max-width: 250px;

  img {
    max-width: 600px;
  }
`;

const Container = styled.div`
  /* overflow: hidden; */
  /* max-height: 85vh; */
  /* ${(props) => props.theme.breakpoints.maxTablet} {
		max-height: 100%;
		overflow: scroll;
  } */
`;

const Schedule = (props) => {
	useFirestoreConnect(() => [
		{ collection: "events", doc: props.match.params.eventId },
	]);
	const event = useSelector(
		({ firestore: { data } }) =>
			data.events && data.events[props.match.params.eventId]
	);
	return (
		<ScheduleProvider
			eventId={props.match.params.eventId}
			event={event}
			pathUrl={props.url}
			userUid={props.userUid}
		>
			<ScheduleContext.Consumer>
				{({ event, eventId, userUid }) => {
					const isOrganizer = event?.organizer?.uid === userUid;
					if (!event)
						return (
							<div>
								<Skeleton active />
							</div>
						);
					return (
						<Container>
							<Main>
								{false &&
									isOrganizer &&
									event.itinerary &&
									event.itinerary.dates &&
									event.itinerary.dates.start &&
									!event.timeline.finalizePolls && (
										<>
											{/* <Fade> */}
											<Row justify="start">
												<Col xs={12}>
													<Intro>
														<h1>
															Close the voting. Finalize the winning option.
															Schedule the itinerary.
														</h1>
														<LinkButton to={`${props.url}/polls`} primary>
															GO TO POLLS
														</LinkButton>
													</Intro>
												</Col>
											</Row>
											<Row justify="end">
												<Col xs={12}>
													<Image>
														<img src={clipArt} alt="" />
													</Image>
												</Col>
											</Row>
											{/* </Fade> */}
										</>
									)}

								{false &&
									isOrganizer &&
									event.itinerary &&
									event.itinerary.dates.start &&
									!event.itinerary.start &&
									event.timeline.finalizePolls && (
										<>
											{/* <Fade> */}
											<Row justify="start">
												<Col xs={12}>
													<Intro>
														<h1>
															Great news!
															<br />
															Now you can start building your itinerary
														</h1>
														<ActionButton
															onClick={() => itinerary.startItinerary(eventId)}
															primary
														>
															START BUILDING
														</ActionButton>
													</Intro>
												</Col>
											</Row>
											<Row justify="end">
												<Col xs={12}>
													<Image>
														<img src={clipArt} alt="" />
													</Image>
												</Col>
											</Row>
											{/* </Fade> */}
										</>
									)}

								{isOrganizer && event.itinerary && event.itinerary.dates.start && (
									<ScheduleTimeline itineraryData={event.itinerary} />
								)}
								{!isOrganizer && !event.timeline.completeSchedule && (
									<ScheduleGuests pathUrl={props.url} itineraryData={event.itinerary} />
								)}
								{!isOrganizer && event.timeline.completeSchedule && (
									<ScheduleTimeline itineraryData={event.itinerary} />
								)}
							</Main>
						</Container>
					);
				}}
			</ScheduleContext.Consumer>
		</ScheduleProvider>
	);
};

Schedule.defaultProps = {};

Schedule.propTypes = {};

export default Schedule;
