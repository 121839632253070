import React from "react";
import { Drawer, Space } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { scroller } from "react-scroll";
import decor from "../../../assets/home/decor.svg";
import menuDecor from "../../../assets/home/menu-decor.png";
import { Login } from "../../";

const Dialog = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
  }
`;

const Menu = styled.ul`
  list-style: none;
  margin: 56px 0 0 0;
  padding: 1em;
  min-height: 90vh;
  position: relative;
  z-index: 1;
  li {
    width: 100%;

    a {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-family: "Proxima Nova Semibold";
      font-style: normal;
      font-weight: 600;
      font-size: 26px;
      line-height: 130%;
      color: #101b43;
      padding: 0.5em 0;
      width: 100%;
      height: 100%;
    }
  }
`;
const Decor = styled.div`
  transform: rotate(155deg);
  align-self: flex-start;
  position: absolute;
  right: 11.99px;
`;

const MenuDecor = styled.div`
  position: absolute;
  bottom: 0;
  z-index: -1;

  img {
    width: 100%;
  }
`;

const scrollOnPage = (name, e, onClose) => {
  onClose();
  scroller.scrollTo(name, {
    duration: 800,
    delay: 0,
    smooth: "easeInOutQuart",
  });

  e.preventDefault();
};

const MobileMenu = ({
  location,
  visible,
  onClose,
  isAuthenticated,
  submitUserDetails,
  logout,
}) => {
  return (
    <Dialog
      title=""
      placement={`top`}
      closable={true}
      onClose={() => onClose()}
      visible={visible}
      key={`top`}
      height="100%"
      style={{ zIndex: 1000, padding: 0 }}
    >
      <Menu>
        <li>
          <Link to="/" onClick={() => onClose()}>
            Home{" "}
            <Decor>
              <img src={decor} />
            </Decor>
          </Link>
        </li>
        {/* <li>
					<Link to='/settings' onClick={() => onClose()}>
						Settings
					</Link>
				</li> */}
        {/* <li>
					<Link to='/about' onClick={() => onClose()}>
						About <Decor><img src={decor} /></Decor>
					</Link>
				</li>
				<li>
					<Link
						to={{
							pathname: '/',
							state: {
								scrollToSection: 'info'
							}
						}}
						onClick={(e) => location.pathname === '/' ? scrollOnPage('info', e, onClose) : null}
					>
						Features
					</Link>
				</li>
				<li>
					<Link to='/' onClick={() => onClose()}>
						Planning Tools
					</Link>
				</li>
				<li>
					<Link to='/' onClick={() => onClose()}>
						City Guides
					</Link>
				</li>
				<li>
					<Link to='/' onClick={() => onClose()}>
						Ideas & Advice
					</Link>
				</li>
				<li>
					<Link to='/' onClick={() => onClose()}>
						<a href='https://www.goinvigo.com/city-guides/' target='_blank'>
							Shop
						</a>
					</Link>
				</li> */}
        {!isAuthenticated ? (
          <li>
            <Space>
              <Space>
                <Login name="login" title="sign in" onClose={onClose} />
              </Space>
              <Space>
                <Login
                  name="register"
                  title="sign up"
                  submitUserDetails={submitUserDetails}
                  onClose={onClose}
                  primary
                />
              </Space>
            </Space>
          </li>
        ) : (
          <li>
            <Link
              to="/"
              onClick={() => {
                logout();
                onClose();
              }}
            >
              LogOut
            </Link>
          </li>
        )}

        <MenuDecor>
          <img src={menuDecor} alt="" />
        </MenuDecor>
      </Menu>
    </Dialog>
  );
};

export default MobileMenu;
