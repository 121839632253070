import React from "react";
import styled from "styled-components";
import { Header, FinalizeDetails, Destination, GuestList, Budget, TaskList, Shop } from "./ConsoleComponents";
import { EventContext } from "../../containers/Event/EventProvider";
import { Skeleton, Row, Col, Image } from "antd";
import { itinerary } from "../../helpers/firestore";
import funkyImage from "../../assets/console/task-list-is-empty.png";
import { Link } from "react-router-dom";
import RightArrowIcon from "../../assets/icons/rightArrow";

const Wrapper = styled.div`
  margin-top: 25px;
`;

const Cards = styled.div`
  height: ${(props) => (props.height ? props.height : "292px")};
  background: ${(props) => (props.bg ? props.bg : "#ffffff")};
  border-radius: 8px;
  margin: 1.25em 0;
  ${(props) => props.theme.breakpoints.maxPhone} {
    height: auto;
    margin-bottom: 25px;
  }
  ${(props) => props.theme.breakpoints.maxTablet} {
    img {
      /* width: 259px; */
      height: 100%;
      width: auto;
      padding-bottom: 1rem;
    }
  }
`;

const ShowMoreCard = styled(Cards)`
  min-height: 292px;
  height: auto;
  overflow: auto;
`;

const Title = styled.div`
  display: flex;
  align-items: center;

  h2 {
    font-family: "Clearface Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 140%;
    color: #ffffff;
    text-align: left;
    padding-left: 16px;
  }
  ${(props) => props.theme.breakpoints.maxTablet} {
    h2 {
      padding-top: 20px;
      font-size: 25px;
      line-height: 35px;
    }
  }
`;

export const HeaderElement = styled(Col)`
  height: 60px;
  width: 100%;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25em;
  text-transform: uppercase;

  h5 {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 100%;
    color: #ffffff;
    padding: 0;
    margin: 0;
  }

  a {
    svg {
      fill: white;
    }
  }
`;

const EventConsole = () => {
  return (
    <EventContext.Consumer>
      {({ event, guests, pathUrl, displayName, tasksLists, myTasks }) => {
        const reopenDestination = () => {
          itinerary
            .addDestination(event, { name: null, imageUrl: null }, false, [], false)
            .then((result) => console.log(result));
        };
        const itineraryData = event && event.itinerary ? event.itinerary : null;
        return (
          <Wrapper>
            <Cards>
              <Header event={event} displayName={displayName} itineraryData={itineraryData} pathUrl={pathUrl} />
            </Cards>
            {!event ? (
              <div>
                <Skeleton active />
              </div>
            ) : event && event.itinerary && event.itinerary.city.name ? (
              <Cards>
                <Destination
                  name={event.itinerary.city.name}
                  dates={event.itinerary.dates}
                  reopenDestination={reopenDestination}
                  imageUrl={event.itinerary.city.imageUrl}
                  pathUrl={pathUrl}
                />
              </Cards>
            ) : (
              <Cards bg="#4F4DB8">
                <FinalizeDetails pathUrl={pathUrl} event={event} guests={guests} />
              </Cards>
            )}
            <ShowMoreCard>
              <GuestList guests={guests} pathUrl={pathUrl} />
            </ShowMoreCard>
            <Cards bg="#47B0B7">
              <Budget pathUrl={pathUrl} event={event} guests={guests} />
            </Cards>
            {!event ? (
              <div>
                <Skeleton active />
              </div>
            ) : myTasks.length ? (
              <ShowMoreCard>
                <TaskList guests={guests} myTasks={myTasks} eventId={event.id} pathUrl={pathUrl} />
              </ShowMoreCard>
            ) : (
              <Cards bg="#A9C5F4">
                <HeaderElement xs={24}>
                  <h5>My Tasks</h5>
                  <Link to={`${pathUrl}/task-lists`}>
                    <RightArrowIcon />
                  </Link>
                </HeaderElement>
                <Row align="middle" justify="center">
                  <Col xs={24} md={12} align="middle" justify="center">
                    <Title>
                      <h2>YAY! You have no tasks to complete. Cheers to you, Darling!</h2>
                    </Title>
                  </Col>
                  <Col xs={24} md={12}>
                    <Image style={{ marginTop: 25 }} src={funkyImage} alt="" preview={false} />
                  </Col>
                </Row>
              </Cards>
            )}
            <Cards bg="#FF8E95">
              <Shop event={event} />
            </Cards>
          </Wrapper>
        );
      }}
    </EventContext.Consumer>
  );
};

EventConsole.defaultProps = {};

EventConsole.propTypes = {};

export default EventConsole;
