import React from "react";
import { motion } from "framer-motion";


const pageVariants = {
    initial: {
        opacity: 0,
        // x: "-100vw",
        scale: 0.95
    },
    in: {
        opacity: 1,
        // x: 0,
        scale: 1
    },
    out: {
        opacity: 0,
        // x: "100vw",
        scale: 1
    }
};

const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.3
};

const pageStyle = {
    position: "absolute"
};

function PageContainer({ children }) {
    return (
        <motion.div
            style={pageStyle}
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
        >
            {children}
        </motion.div>
    );
}

export default PageContainer