import React from 'react'
import styled from "styled-components";
import { Row, Col } from 'antd';
import { InTypography } from '../../'
import landing from './assets/landing.png'
import { InButton } from "../../";

export const Image = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    flex-direction: column;
    background: transparent url(${props => props.src}) no-repeat center;
    background-size: cover;
    //margin-top: 5vh;
	${props => props.theme.breakpoints.maxPhone} {
		height: 47vh;
		background-position: center top;
  	}
`;

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1em;
    text-align: center;
	@media (max-width: 767px) {
		h1 {
			font-size: 22px !important;
		}
		button {
			width: -webkit-fill-available;
		}
  	}
    
`;

export const Subtitle = styled(InTypography.InH3)`
    margin: 1em 0;
	@media (max-width: 767px) {
		font-size: 20px !important;
		
  	}
`;

export const Intro = ({ next, displayName, data, isBride }) => {
	const brides = data.bride.map((bride) => {
		return bride.fullname
	})
	return (
		<Row>
			<Col xs={24} md={12} lg={14} xl={16}>
				<Image src={landing} />
			</Col>
			<Col xs={24} md={12} lg={10} xl={8}>
				<Container >
					<InTypography.InH1>

						{isBride
							? `Congratulations ${displayName}!`
							: <div>
								YAY! It's time to celebrate <br /> the Bride!
							</div>
						}
					</InTypography.InH1>
					<Subtitle>
						{isBride
							? `We can’t wait to help you plan your bachelorette party.`
							: `We would love your input to help plan a ${data.name} for ${brides}.`
						}
					</Subtitle>
					<InButton
						type='button'
						onClick={() => next()}
						text='START'
						width='120px'
						// disabled={this.props.disabled}
						primary
					/>
				</Container>
			</Col>
		</Row>
	)
}