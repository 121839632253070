import React from 'react';
import { Row, Col } from 'antd';
import { MaxWidthContainer } from '../../../../components'
import ImageBanner from './ImageSection'
import TextSection from './TextSection'

function Benefits() {
    return (
        <MaxWidthContainer>
            <Row>
                <Col xs={24}>
                    <ImageBanner />
                </Col>
                <Col xs={24}>
                    <TextSection />
                </Col>
            </Row>
            
        </MaxWidthContainer>
    );
}

export default Benefits


