import React from 'react';
import styled from "styled-components";

const Wrapper = styled.svg`
    fill: #494D5B;
     stroke: #494D5B;
    
    &:hover {
      fill: #2E2C9A;
     stroke: #2E2C9A;
    }
`

export default ({ style }) => (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" style={style}>
        <rect x="0.5" y="0.5" width="43" height="43" rx="3.5" fill="white" stroke="#EEEEEF"/>
        <rect x="28.1094" y="13.666" width="2.46914" height="19.7531" rx="1.23457" transform="rotate(45 28.1094 13.666)" fill="#494D5B"/>
        <rect x="29.8555" y="27.6338" width="2.46914" height="19.7531" rx="1.23457" transform="rotate(135 29.8555 27.6338)" fill="#494D5B"/>
    </svg>
);
