import React from "react";
import styled from "styled-components";
import { Menu } from "antd";

const Wrapper = styled.svg`
  fill: #494d5b;
  stroke: #494d5b;

  &:hover {
    fill: #2e2c9a;
    stroke: #2e2c9a;
  }
`;

export default ({ style }) => (
  <Wrapper width="12.5" height="12.5" viewBox="0 0 20 20" fill="none" style={style}>
    <path
      d="M18.3338 3.27449L16.7228 1.66337C15.9868 0.927296 14.7892 0.92733 14.0531 1.66337C13.3601 2.35647 2.77479 12.9426 2.06739 13.65C1.99205 13.7253 1.94156 13.8255 1.92358 13.9234L1.11809 18.2734C1.08687 18.4421 1.14062 18.6153 1.26191 18.7366C1.38333 18.858 1.5566 18.9117 1.72503 18.8805L6.07466 18.0749C6.17518 18.0561 6.274 18.0051 6.3481 17.931L18.3338 5.94439C19.0716 5.20661 19.0717 4.01237 18.3338 3.27449ZM2.28024 17.7182L2.7675 15.0868L4.91146 17.2309L2.28024 17.7182ZM5.97983 16.8261L3.17222 14.0183L13.4226 3.76716L16.2302 6.57498L5.97983 16.8261ZM17.5973 5.20783L16.9667 5.83842L14.1591 3.0306L14.7896 2.40001C15.1195 2.07008 15.6563 2.07004 15.9863 2.40001L17.5973 4.01112C17.928 4.34185 17.928 4.87706 17.5973 5.20783Z"
      strokeWidth="0.5"
    />
  </Wrapper>
);
