import React from "react";
import styled from "styled-components";
import { Field, FieldArray, FormSection } from "redux-form";
import Accommodation from "../../../assets/itinerary/Accommodation.png";
import Morning from "../../../assets/itinerary/Morning.png";
import Afternoon from "../../../assets/itinerary/Afternoon.png";
import Evening from "../../../assets/itinerary/Evening.png";
import { Timeline, Form, Row, Col, Collapse } from "antd";
import moment from "moment";
import { FormComponents } from "../..";
import { renderMembers } from "./AddFeature";

const { Panel } = Collapse;

const Header = styled.h1`
  font-family: "Clearface Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 34px;
  color: #101b43;
`;

const DateBar = styled.div`
  margin: 32px 0;
  height: 44px;
  background: #fafafc;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 20px;

  h5 {
    font-family: "Proxima Nova Semibold";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 100%;
    color: #2e2c9a;
    margin: 0;
    padding: 0;
  }
`;

const TimelineSection = styled.div`
  min-height: 100px;
  background: #ffffff;
  padding: 0.5em 1em;
	${(props) => props.theme.breakpoints.maxTablet} {
		padding: 0.5em 0;
		}
`;

const TimelineItem = styled(Timeline.Item)`
  background: #ffffff;

  margin: 0;

  .ant-timeline-item-head-custom {
    padding: 0;
		${(props) => props.theme.breakpoints.maxTablet} {
			display: none;
		}
  }
	.ant-timeline-item-tail {
		${(props) => props.theme.breakpoints.maxTablet} {
			border: none;
		}
	}
	.ant-timeline-item-content {
		${(props) => props.theme.breakpoints.maxTablet} {
			margin: 0;
		}
	}
`;

export const Section = styled(FormSection)``;

const FormItem = styled(Form.Item)``;
const TimelineWrapper = styled(Timeline)`
  margin: 1em 32px;
	${(props) => props.theme.breakpoints.maxTablet} {
		margin: 1em 0;
	}
`;

const TimelineIcon = styled.div`
  background: #ffffff;
`;

const Image = styled.img`
  height: 24px;
`;

const Title = styled.h5`
  font-family: "Proxima Nova Semibold";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;
  text-transform: uppercase;
  color: #101b43;
`;

const PanelDay = styled(Panel)`
	border-bottom: 1px solid black;
	.ant-collapse-header {
		background: #fafafc;
		font-family: "Proxima Nova Semibold";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 100%;
    color: #2e2c9a !important;
		
    /* margin: 0; */
    /* padding: 0; */
	}
	.ant-collapse-content-box {
		${(props) => props.theme.breakpoints.maxTablet} {
			padding: 0;
		}
	}
`

export const formatDate = (value) => {
	return moment(value).format("MMM DD YYYY");
};

const validate = (rule) => (value) => {
	if (!rule) {
		return undefined;
	}

	return value && new RegExp(rule).test(value) ? undefined : "Required";
};

const TimelinePageOne = ({ dateList, toggleMenu }) => {
	return (
		<>
			<Header>Plan the perfect trip for your group!</Header>
			<Collapse defaultActiveKey={['0']} ghost expandIconPosition={'right'} style={{ marginBottom: '2rem' }} accordion>



				{dateList.map((day, index) => {
					const sectionName = `day_${index + 1}`;
					return (
						<PanelDay header={`Day ${index + 1}: ${formatDate(day)}`} key={index}>
							<Section key={sectionName} name={sectionName} component="div">
								<TimelineWrapper>
									<TimelineItem
										dot={
											<TimelineIcon>
												<img src={Accommodation} alt="accommodation" />
											</TimelineIcon>
										}
									>
										<TimelineSection>
											<Title>Accommodation</Title>
											<FormItem
												onClick={(e) =>
													toggleMenu("accommodation", e.target.name)
												}
												style={{ marginBottom: 0 }}
											>
												<Row>
													<Col xs={24} sm={22}>
														<Field
															name="accommodation"
															type="text"
															component={FormComponents.InInput}
															prefix={
																<Image src={Accommodation} alt="accommodation" />
															}
															placeholder="Select Accommodation"
														// validate={validate("^(?!\\s*$).+")}
														/>
													</Col>
												</Row>
											</FormItem>
										</TimelineSection>
									</TimelineItem>
									<TimelineItem
										dot={
											<TimelineIcon>
												<img src={Morning} alt="Morning" />
											</TimelineIcon>
										}
									>
										<TimelineSection>
											<Title>Morning</Title>
											<FieldArray
												name="mornings"
												component={renderMembers}
												defaultFields="mornings[0]"
												toggleMenu={toggleMenu}
											/>
										</TimelineSection>
									</TimelineItem>
									<TimelineItem
										dot={
											<TimelineIcon>
												<img src={Afternoon} alt="Afternoon" />
											</TimelineIcon>
										}
									>
										<TimelineSection>
											<Title>Afternoon</Title>
											<FieldArray
												name="afternoon"
												component={renderMembers}
												defaultFields="afternoon[0]"
												toggleMenu={toggleMenu}
											/>
										</TimelineSection>
									</TimelineItem>
									<TimelineItem
										dot={
											<TimelineIcon>
												<img src={Evening} alt="Evening" />
											</TimelineIcon>
										}
									>
										<TimelineSection>
											<Title>Evening</Title>
											<FieldArray
												name="evening"
												component={renderMembers}
												defaultFields="evening[0]"
												toggleMenu={toggleMenu}
											/>
										</TimelineSection>
									</TimelineItem>
								</TimelineWrapper>
							</Section>
						</PanelDay>
					);
				})}
			</Collapse>
		</>
	);
};

TimelinePageOne.defaultProps = {};

TimelinePageOne.propTypes = {};

export default TimelinePageOne;
