import React from 'react';
import { Row, Col } from 'antd';
import styled from "styled-components";
import ImageBanner from './ImageSection'
import TextSection from './TextSection'
import { MaxWidthContainer } from '../../../../components'


export const Wrap = styled.div`
    background: #FAFAFC;
    border-radius: 80px 0px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 768px) {
        height: 482px;
      }
`;

function DashboardSection() {

    return (
        <MaxWidthContainer>
            <Wrap>
                <Row gutter={16}>
                    <Col xs={24} md={12}>
                        <ImageBanner />
                    </Col>
                    <Col item xs={24} md={12}>
                        <TextSection />
                    </Col>
                </Row>
            </Wrap>
            
        </MaxWidthContainer>
    );
}

export default DashboardSection


