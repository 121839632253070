import React from 'react';
import styled from "styled-components";
import decor from './decor.svg'

export const Divider = styled.div`
    width: 100%;
    height: 70px;
    background: ${props => props.theme.colors.white};
    display: flex;
    justify-content: flex-end;
    
    img {
          margin-right: 225px;
    }
    
`;
function DecorDivider({ decorAdd }) {
    return (
        <Divider container spacing={1}>
          {decorAdd && <img src={decor} alt=""/>}
        </Divider>
    );
}

export default DecorDivider


