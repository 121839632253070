import React from 'react';
// import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { Link } from "react-router-dom";
import styled from "styled-components";
import footerBg from '../../assets/website/footer-bg.png'
import logo from '../../assets/logo/logo-red-dot.svg'
import facebook from '../../assets/website/facebook.svg'
import twitter from '../../assets/website/twitter.svg'
import instagram from '../../assets/website/instagram.svg'
import pintrest from '../../assets/website/pintrest.svg'
import decor from '../../assets/home/decor.svg'
import { MaxWidthContainer } from '../../components'
import { scroller } from 'react-scroll'

export const FooterWrapper = styled.footer`    
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
    position:relative;
    ${props => props.disable ? 'display: none' : ''};
`;

export const ImageContainer = styled.div`
    width: 100%;
    height: 200px;
`;

export const Image = styled.div`
    width: 100%;
    height: 100%;
    background: transparent url(${props => props.src}) no-repeat center;
    background-size: cover;
`;

const Container = styled.div`
    height: 100%;
    background: ${props => props.theme.colors.tarawera};
    padding: 16px 0;
    
    ${props => props.theme.breakpoints.tablet}{
       height: 380px;
    }
`;

export const LinkTo = styled(Link)`
    outline: none;
    color: #C0C0C0;
    margin: 0 12px;
`;

export const GridItem = styled.div`
    color: #F9F9F9;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    padding: 0;
    text-align: left;
    margin-top: 20px;
  
  a {
    text-decoration: none;
    color: #F9F9F9;
    font-family: 'Proxima Nova';
    font-size: 16px;
    line-height: 150%;
  }
  
  div {
    display: flex;
    align-items: center;
    color: #ffffff;
    
    img {
      margin-right: 12px;
    }
  }
`;

export const LinkBtn = styled(Link)`
  margin: 0 15px;
`

export const GridRight = styled(Row)`
  display: flex;
  justify-content: flex-end;
`;

export const TermsContainer = styled(Row)`
  margin-top: 48px;
`;

export const GridItemRight = styled.div`

`;

const SocialMedia = styled.div`
  margin: 20px 0;
`;

const Logo = styled.div`
    display: flex;
    flex-direction: column;
    width: 180px;
`;

const DecorImage = styled.img`
    align-self: flex-start;
    margin-left: 40px;
`;

const HashTag = styled.div`
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    color: #ffffff;
`;

const scrollOnPage = (name, e) => {
    scroller.scrollTo(name, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
    })
    e.preventDefault()
}

function StickyFooter({ pathname }) {
    return (
        <FooterWrapper disable={!!pathname.includes('/rsvp/') || !!pathname.includes('/event/') || !!pathname.includes('/events/')}>
            <ImageContainer>
                <Image src={footerBg} />
            </ImageContainer>
            <Container>
                <MaxWidthContainer>
                    <Row>
                        <Col xs={24}>
                          <Logo>
                            <DecorImage src={decor} />
                            <Link to='/'>
                                <img src={logo} alt=""/>
                            </Link>
                          </Logo>
                        </Col>
                        <Col xs={24} sm={12}>
                            <GridItem>
                                The smarter bachelorette planning experience.
                            </GridItem>
                            <GridItem>
                                <SocialMedia>
                                    <img src={facebook} alt=""/>
                                    <img src={twitter} alt=""/>
                                    <img src={instagram} alt=""/>
                                    <img src={pintrest} alt=""/>
                                </SocialMedia>
                                <HashTag>@GO_INVIGO | #CUETHECONFETTI</HashTag>
                            </GridItem>
                        </Col>
                        <Col xs={24} sm={12}>
                            <GridRight>
                                <Col xs={10}>
                                    <GridItemRight>
                                        <GridItem>
                                            <Link
                                                to={{
                                                    pathname: '/',
                                                    state: {
                                                        scrollToSection: 'info'
                                                    }
                                                }}
                                                onClick={(e) => pathname === '/' ? scrollOnPage('info', e) : null}
                                            >
                                                Features
                                            </Link>
                                        </GridItem>
                                        <GridItem>
                                            <Link>
                                                <a href='https://www.goinvigo.com/city-guides/' target='_blank'>
                                                City Guides
                                                </a>
                                            </Link>

                                        </GridItem>
                                        <GridItem>
                                            <Link to='/'>
                                                City Guides
                                            </Link>

                                        </GridItem>
                                    </GridItemRight>
                                </Col>
                                <Col xs={10}>
                                    <GridItem>
                                        <Link to='/about'>
                                            About Invigo
                                        </Link>
                                    </GridItem>
                                    <GridItem>
                                        <Link to='/'>
                                            Blog
                                        </Link>
                                    </GridItem>
                                    <GridItem>
                                        <Link to='/'>
                                            Refer a Friend
                                        </Link>
                                    </GridItem>
                                </Col>
                                <Col xs={4}>
                                    <GridItem>
                                        <Link to='/'>
                                            Help
                                        </Link>
                                    </GridItem>
                                    <GridItem>
                                        <Link to='/frequently-asked-questions'>
                                            FAQs
                                        </Link>
                                    </GridItem>
                                    <GridItem>
                                        <Link to='/'>
                                            Press
                                        </Link>
                                    </GridItem>
                                </Col>
                            </GridRight>
                        </Col>
                        <Col xs={24}>
                            <TermsContainer>
                                <Col xs={24} md={6}>
                                    <GridItem>
                                        © 2020 Invigo, LLC. All rights reserved.
                                    </GridItem>
                                </Col>
                                <Col xs={24} md={12}>
                                    <GridItem>
                                        Privacy Policy | Terms of Use
                                    </GridItem>
                                </Col>
                            </TermsContainer>
                        </Col>
                    </Row>
                </MaxWidthContainer>
            </Container>
        </FooterWrapper>
    );
}

const Footer = ({ pathname }) => {
  return (
    <div className='footer'>
        <StickyFooter pathname={pathname} />
    </div>
  );
};

Footer.defaultProps = {

};

Footer.propTypes = {

};

export default Footer