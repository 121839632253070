import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.img`
  width: ${props => props.width ? props.width : '100%'};
  height: 100%;
`

const Image = ({ src, width }) => {
  return <Wrapper src={src} width={width} />
};

Image.defaultProps = {

};

Image.propTypes = {

};

export default Image;
