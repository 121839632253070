import { ConsoleSqlOutlined } from "@ant-design/icons";
import React, { Component } from "react";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { guests, tasks } from "../../helpers/firestore";
export const TaskListsContext = React.createContext();

class TaskListsProvider extends Component {
	constructor(props) {
		super(props);

		this.state = {
			taskLists: null,
			guests: null,
			myTasks: null,
		};
	}

	componentDidMount() {
		this.getData(this.props.eventId);
	}

	setTaskLists = (taskLists) => {
		// uid
		let myTasks = [];
		taskLists.forEach((tasklist) => {
			if (tasklist && tasklist.tasks) {
				const myTaskList = tasklist.tasks.filter((task) => {
					if (task.assignTo.some((member) => member.id === this.props.uid)) {
						return task;
					}
				});
				myTasks.push(...myTaskList);
			}
		});
		this.setState({ taskLists, myTasks });
	};

	setGuests = (guests) => {
		this.setState({ guests });
	};

	getData = (eventId) => {
		tasks.getTaskLists(eventId, this.setTaskLists);
		guests.get(eventId, this.setGuests);
	};

	createTask = async (
		eventId,
		taskListId,
		task,
		activeList,
		close,
		resetForm
	) => {
		// close()
		resetForm();

		const newTask = {
			id: uuidv4(),
			taskListId: taskListId,
			name: task.name,
			due: task.due || "",
			assignTo: task.assignTo && task.assignTo.length ? task.assignTo.map(guest => ({ id: guest.id, label: guest.label })) : [],
			description: task.description || "",
		};

		console.log("new task !!", newTask);

		await tasks.updateList(eventId, taskListId, newTask, activeList);
	};

	render() {
		const { taskLists, myTasks, guests } = this.state;
		const { children, eventId } = this.props;

		const providerData = {
			taskLists,
			myTasks,
			guests,
			eventId,
			createTask: this.createTask,
		};
		return (
			<TaskListsContext.Provider value={providerData}>
				{children}
			</TaskListsContext.Provider>
		);
	}
}

TaskListsProvider.defaultProps = {
	//<!--  children: PropTypes.object-->
};

TaskListsProvider.propTypes = {
	//<!--  children: {}-->
};

function mapStateToProps(state) {
	return {
		uid: state.auth.user.user.uid,
	};
}

export default connect(mapStateToProps, null)(TaskListsProvider);
