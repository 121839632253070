import React from "react";
import PollsProvider, { PollsContext } from "./PollsProvider";
import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";

import styled from "styled-components";
import { Layout, Row, Col } from "antd";
import budgetImage from "../../assets/console/polls.png";
import Destinations from "./Components/destinations";
import Dates from "./Components/dates";
import AllPolls from "./Components/allPolls";
import { itinerary } from "../../helpers/firestore";
import ReopenDates from "./Components/reopenDates";

const { Content } = Layout;

const Main = styled(Layout)`
  background: #ffffff;
  transition: all 0.2s linear;
  min-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

const Wrapper = styled.div`
  margin-top: 25px;
  margin-right: 1em;
  margin-left: 1em;
`;

const Cards = styled.div`
  height: 292px;
  background: ${(props) => (props.bg ? props.bg : "#ffffff")};
  border-radius: 8px;
  margin: 1.25em 0 20px 0;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.05);
  ${(props) => props.theme.breakpoints.maxPhone} {
    height: auto;
  }
`;

const ShowMoreCard = styled.div`
  min-height: 292px;
  height: auto;
  width: 100%;
  background: #ffffff;
  margin: 1.25em 0 3.75em 0;
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-radius: 8px;
`;

const DatesCard = styled.div`
  min-height: 222px;
  height: auto;
  width: 100%;
  border-radius: 8px;
  background: #ffffff;
  margin: 1.25em 0 3.75em 0;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.05);
`;

const TextContainer = styled(Col)`
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;

  h1 {
    color: #fff;
    font-family: "Clearface Bold";
    font-style: normal;
    font-size: 2em;
    line-height: 140%;
    padding: 0;
    margin: 0 0 0 60px;
    ${(props) => props.theme.breakpoints.maxPhone} {
      margin: 15px 0 15px 15px;
    }
  }
`;

export const Header = styled(Col)`
  height: 60px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;

  h2 {
    font-family: "Clearface Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 34px;
    color: #101b43;
    padding: 0 1.25em;
    margin: 0;
  }
`;

export const CardContent = styled(Row)`
  padding: 1.25em;
`;

export const CityCard = styled.div`
  height: 254px;
  background: #fafafc;
  border-radius: 8px;
  padding: 20px;
`;

export const HeaderImage = styled.div`
  width: 100%;
  height: 150px;
  background: transparent url(${(props) => props.src}) no-repeat center;
  background-size: cover;
`;

export const Image = styled.div`
  width: 100%;
  height: 292px;
  background: transparent url(${(props) => props.src}) no-repeat center;
  background-size: cover;
  border-radius: 8px;
  padding: 1em 1em 40px 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &:after {
    background-color: #000000;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.25;
    border-radius: 8px;
  }

  span {
    z-index: 2;
  }

  h4 {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: #ffffff;
    z-index: 2;
  }

  h1 {
    font-family: "Clearface Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 140%;
    color: #ffffff;
    z-index: 2;
  }
`;

export const BtnContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 2;
`;

const Polls = (props) => {
  useFirestoreConnect(() => [
    { collection: "events", doc: props.match.params.eventId },
  ]);
  const event = useSelector(
    ({ firestore: { data } }) =>
      data.events && data.events[props.match.params.eventId]
  );
  return (
    <PollsProvider event={event} eventId={props.match.params.eventId}>
      <PollsContext.Consumer>
        {({ event, guests }) => {
          const reopenDestination = () => {
            itinerary
              .addDestination(
                event,
                { name: null, imageUrl: null },
                false,
                [],
                false
              )
              .then((result) => console.log(result));
          };
          const reopenDates = () => {
            itinerary
              .addDates(event, { start: null, end: null }, false, [], false)
              .then((result) => console.log(result));
          };
          return (
            <Main>
              <Content>
                <Wrapper>
                  <>
                    {/* <Fade> */}
                    {!event?.timeline?.finalizePolls && (
                      <Cards bg={"#4F4DB8"}>
                        <Row>
                          <TextContainer xs={24} md={12}>
                            <h1>
                              Close the polls. <br />
                              Choose the winning option. <br />
                              Build the itinerary.{" "}
                            </h1>
                          </TextContainer>
                          <Col xs={0} md={12}>
                            <img
                              style={{ marginTop: "1em", height: 265 }}
                              src={budgetImage}
                              alt=""
                            />
                          </Col>
                        </Row>
                      </Cards>
                    )}
                    {/* </Fade> */}
                  </>
                  <>
                    {/* <Fade> */}

                    {/* <Cards>
											{!event ? (
												<div>
													<Skeleton active />
												</div>
											) : event &&
												event.itinerary &&
												event.itinerary.city.name ? (
												<ReopenDestinations
													name={event.itinerary.city.name}
													reopenDestination={reopenDestination}
													imageUrl={event.itinerary.city.imageUrl}
												/>
											) : (
												<Row>
													<TextContainer xs={12}>
														<h1>
															To continue planning, close voting and finalize
															the destination
														</h1>
													</TextContainer>
													<Col xs={12}>
														<img
															style={{ marginTop: "1.8em", height: 265 }}
															src={budgetImage}
															alt=""
														/>
													</Col>
												</Row>
											)}
										</Cards> */}
                    {/* </Fade> */}
                  </>
                  {event && event.itinerary && !event.itinerary.city.name && (
                    <>
                      {/* <Fade> */}
                      <ShowMoreCard>
                        <Destinations
                          event={event}
                          itineraryData={event.itinerary}
                          guests={guests}
                        />
                      </ShowMoreCard>
                      {/* </Fade> */}
                    </>
                  )}
                  {event &&
                  event.itinerary &&
                  event.itinerary.dates &&
                  event.itinerary.dates.start ? (
                    <>
                      {/* <Fade> */}
                      <DatesCard>
                        <ReopenDates
                          dates={event.itinerary.dates}
                          reopenDates={reopenDates}
                        />
                      </DatesCard>
                      {/* </Fade> */}
                    </>
                  ) : (
                    <>
                      {/* <Fade> */}
                      <ShowMoreCard>
                        <Dates
                          event={event}
                          itineraryData={
                            event && event.itinerary ? event.itinerary : null
                          }
                          guests={guests}
                        />
                      </ShowMoreCard>
                      {/* </Fade> */}
                    </>
                  )}
                  {event && event.itinerary && event.itinerary.city.name && (
                    <AllPolls />
                  )}
                </Wrapper>
              </Content>
            </Main>
          );
        }}
      </PollsContext.Consumer>
    </PollsProvider>
  );
};

Polls.defaultProps = {};

Polls.propTypes = {};

export default Polls;
