import React from "react";
import styled from "styled-components";
import moment from "moment";
import ReactSelect from "react-select";
import {
  Form,
  Input,
  Radio,
  Select,
  Checkbox,
  DatePicker,
  TimePicker,
} from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const RadioGroup = Radio.Group;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const dateFormat = "MM/DD/YYYY";

const FormItem = styled(Form.Item)`
  .ant-form-item-label > label {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */

    /* Text/Midnight Blue */

    color: #101b43;
  }

  .ant-form-item-label {
    position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    height: 32px;
    font-size: 14px;
  }

  label {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #101b43;
  }

  .ant-picker-range .ant-picker-active-bar {
    background: #4f4db8;
  }

  .ant-picker-cell-inner {
    &:before {
      border: 1px solid #4f4db8;
      border-radius: 50%;
    }
  }

  .ant-picker-panel-container .ant-picker-panel {
    background: red;
  }

  .ant-form-item-explain {
    color: #ff4d4f;
    text-align: right;
  }

  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell:hover
    .ant-picker-calendar-date {
    background: #000;
  }
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell
    .ant-picker-calendar-date-today::before {
    display: none;
  }
`;

const InputRangePicker = styled(RangePicker)`
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: #fff;
    background: #000;
  }
`;

const InputField = styled(Input)``;

const RightText = styled.span`
  position: absolute;
  top: -28px;
  right: 0;

  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #8696ae;
`;

const makeField =
  (Component) =>
  ({ input, meta, children, hasFeedback, label, addonAfter, ...rest }) => {
    const hasError = meta.touched && meta.invalid;
    return (
      <FormItem
        {...formItemLayout}
        label={label}
        validateStatus={hasError ? "error" : "success"}
        hasFeedback={hasFeedback && hasError}
        help={hasError && meta.error}
      >
        <RightText>{addonAfter}</RightText>
        <Component {...input} {...rest} children={children} size="large" />
      </FormItem>
    );
  };

const makePasswordField =
  (Component) =>
  ({ input, meta, children, hasFeedback, label, addonAfter, ...rest }) => {
    const hasError = meta.touched && meta.invalid;
    return (
      <FormItem
        {...formItemLayout}
        label={label}
        validateStatus={hasError ? "error" : "success"}
        hasFeedback={hasFeedback && hasError}
        help={hasError && meta.error}
      >
        <RightText>{addonAfter}</RightText>
        <Component
          {...input}
          {...rest}
          children={children}
          size="large"
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />
      </FormItem>
    );
  };

const FormFieldItem = styled(FormItem)`
  max-width: 31.5em;

  label {
    font-family: "Clearface Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 140%;
    color: #101b43;
  }

  &:focus {
    border: 1px solid #4f4db8;
  }

  .ant-picker {
    width: 100%;
  }

  input {
    height: 30px;
    margin: 0;
    outline: none;

    &:hover {
      border: none;
    }

    &:focus {
      border: none;
    }
  }
`;

const FieldItem = styled(FormItem)`
  label {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #101b43;
  }

  &:focus {
    border: 1px solid #4f4db8;
  }

  input,
  textarea,
  select {
    height: 44px;
    outline: none;
    border-radius: 4px;

    &:hover {
      border: 1px solid #4f4db8;
    }

    &:focus {
      border: 1px solid #4f4db8;
    }
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }
`;

const SelectFieldItem = styled(FormItem)`
  border: none;
  label {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #101b43;
  }

  &:focus {
    border: none;
  }

  input,
  select {
    outline: none;

    &:hover {
      border: none;
    }

    &:focus {
      border: none;
    }
  }

  .ant-select-multiple .ant-select-selector {
    /* border: none; */
  }
`;

const makeTimePicker =
  () =>
  ({ input, meta, defaultOpenValue, hasFeedback, label, placeholder }) => {
    const hasError = meta.touched && meta.invalid;

    const format = "HH:mm";

    const handleChange = (e, input) => {
      const defaultValue = moment(e).format(format);
      return input.onChange(defaultValue);
    };

    return (
      <FormFieldItem
        {...formItemLayout}
        label={label}
        validateStatus={hasError ? "error" : "success"}
        hasFeedback={hasFeedback && hasError}
        help={hasError && meta.error}
      >
        <TimePicker
          onChange={(e) => handleChange(e, input)}
          placeholder={placeholder}
          defaultOpenValue={defaultOpenValue}
          value={input.value ? moment(input.value, "HH:mm") : null}
          format={format}
        />
      </FormFieldItem>
    );
  };

const makeDatePicker =
  (Component) =>
  ({ input, meta, children, options, hasFeedback, label, ...rest }) => {
    const hasError = meta.touched && meta.invalid;

    const handleChange = (e, input) => {
      return input.onChange(e);
    };
    return (
      <FormItem
        {...formItemLayout}
        label={label}
        validateStatus={hasError ? "error" : "success"}
        hasFeedback={hasFeedback && hasError}
        help={hasError && meta.error}
      >
        <Component
          {...input}
          {...rest}
          children={children}
          onChange={(e) => handleChange(e, input)}
          onFocus={(e) => e.preventDefault()}
          onBlur={(e) => e.preventDefault()}
          size="large"
          format={dateFormat}
        />
      </FormItem>
    );
  };

const makeDateRangePicker =
  (Component) =>
  ({ input, meta, children, options, hasFeedback, label, ...rest }) => {
    const hasError = meta.touched && meta.invalid;

    const handleChange = (e, input) => {
      return input.onChange(e);
    };

    return (
      <FormItem
        {...formItemLayout}
        label={label}
        validateStatus={hasError ? "error" : "success"}
        hasFeedback={hasFeedback && hasError}
        help={hasError && meta.error}
      >
        <Component
          {...input}
          {...rest}
          children={children}
          onChange={(e) => handleChange(e, input)}
          onFocus={(e) => e.preventDefault()}
          onBlur={(e) => e.preventDefault()}
          format={dateFormat}
          bordered={false}
          dateRender={(current) => {
            const style = {};
            style.border = "none";
            style.borderRadius = "50%";
            // style.background = '#000';
            return (
              <div className="ant-picker-cell-inner" style={style}>
                {current.date()}
              </div>
            );
          }}
        />
      </FormItem>
    );
  };

const makeReactSelect =
  (Component) =>
  ({ input, meta, children, options, hasFeedback, label, ...rest }) => {
    const hasError = meta.touched && meta.invalid;
    const handleChange = (e, input) => {
      return input.onChange(e);
    };

    return (
      <FieldItem
        {...formItemLayout}
        label={label}
        validateStatus={hasError ? "error" : "success"}
        hasFeedback={hasFeedback && hasError}
        // help={hasError && meta.error}
      >
        <Component
          {...input}
          {...rest}
          closeMenuOnSelect={false}
          isMulti
          children={children}
          onChange={(e) => handleChange(e, input)}
          onFocus={(e) => e.preventDefault()}
          onBlur={(e) => e.preventDefault()}
          options={options}
        />
      </FieldItem>
    );
  };

const makeMultiSelect =
  (Component) =>
  ({ input, options, meta, children, hasFeedback, label, ...rest }) => {
    const hasError = meta.touched && meta.invalid;
    const handleChange = (e, input) => {
      return input.onChange(e);
    };
    return (
      <SelectFieldItem
        {...formItemLayout}
        label={label}
        validateStatus={hasError ? "error" : "success"}
        hasFeedback={hasFeedback && hasError}
        // help={hasError && meta.error}
        size="large"
      >
        <Component
          {...input}
          // value={valueInput}
          {...rest}
          children={children}
          onChange={(e) => handleChange(e, input)}
          onFocus={(e) => e.preventDefault()}
          onBlur={(e) => e.preventDefault()}
          size="large"
        />
      </SelectFieldItem>
    );
  };

const makeSelect =
  (Component) =>
  ({ input, options, meta, children, hasFeedback, label, ...rest }) => {
    const hasError = meta.touched && meta.invalid;
    const handleChange = (e, input) => {
      return input.onChange(e);
    };
    return (
      <SelectFieldItem
        {...formItemLayout}
        label={label}
        validateStatus={hasError ? "error" : "success"}
        hasFeedback={hasFeedback && hasError}
        // help={hasError && meta.error}
        size="large"
      >
        <Component
          {...input}
          // value={valueInput}
          {...rest}
          children={children}
          onChange={(e) => handleChange(e, input)}
          onFocus={(e) => e.preventDefault()}
          onBlur={(e) => e.preventDefault()}
          size="large"
        />
      </SelectFieldItem>
    );
  };

export const InInput = makeField(InputField);
const InInputPassword = makePasswordField(Input.Password);
const InRadioGroup = makeField(RadioGroup);
export const InSelect = makeSelect(Select);
const InCheckbox = makeField(Checkbox);
const InTextarea = makeField(TextArea);
const InDatePicker = makeDatePicker(DatePicker);
const InRangePicker = makeDateRangePicker(InputRangePicker);
const InTimePicker = makeTimePicker(TimePicker);
export const InReactSelect = makeReactSelect(ReactSelect);
export const InMultiSelect = makeMultiSelect(Select);

export const { Option } = Select;
