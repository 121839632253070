import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col, Skeleton, Image } from "antd";
import AddCircleIcon from "../../assets/icons/addCircle";
import { InTypography } from "../";
import MyTaskList from "./MyTaskList";
import funkyImage from "../../assets/console/task-list-is-empty.png";
import AddTaskList from "./AddGuests";
import TaskList from "./TaskList";
import { Link } from "react-router-dom";
import RightArrowIcon from "../../assets/icons/rightArrow";
import useGuests from "../../hooks/useGuests";

const Wrapper = styled.div`
  margin: 2.5em 1em 1em 1em;
  background: #ffffff;
  ${(props) =>
    props.fixed ? "height: 80vh; overflow: hidden" : "min-height: 80vh;"};
  position: relative;
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: #ffffff;
`;

const Cards = styled.div`
  height: 292px;
  background: ${(props) => (props.bg ? props.bg : "#ffffff")};
  border-radius: 8px;
  margin: 1.25em 0 3.75em 0;
  padding: 2em;
  ${(props) => props.theme.breakpoints.maxTablet} {
    height: auto;
  }
`;

const ShowMoreCard = styled(Cards)`
  min-height: 292px;
  height: auto;
  width: 100%;
  background: #ffffff;
  padding: 0;
  margin: 0 0 3.75em 0;
`;

const CreateTaskCard = styled.div`
  height: 221px;
  background: #ffffff;
  border-radius: 8px;
  margin: 1em 0;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;

  h5 {
    font-family: " Proxima Nova Semibold";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 80%;
    text-transform: uppercase;
    margin: 1em;
    color: #2e2c9a;
  }
`;

const TaskCards = styled.div`
  height: 221px;
  background: #ffffff;
  border-radius: 8px;
  margin: 1em 0;
  padding: 20px;
  cursor: pointer;

  h3 {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 120%;
    color: #101b43;
  }

  p {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #101b43;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;

  h2 {
    font-family: "Clearface Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 140%;
    color: #ffffff;
    text-align: left;
  }
`;

export const HeaderElement = styled(Col)`
  height: 60px;
  width: 100%;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25em;
  text-transform: uppercase;

  h5 {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 100%;
    color: #ffffff;
    padding: 0;
    margin: 0;
  }

  a {
    svg {
      fill: white;
    }
  }
`;

function generateTableData(data, eventId) {
  return data
    ? data.map((task, index) => {
        return {
          id: task.id,
          key: index,
          name: task.name ? task.name : "-",
          description: task.description,
          due: task.due, //&& task.due.seconds ? formatStart(task.due.seconds) : task.due,
          assignTo: task.assignTo ? task.assignTo : "-",
          taskListId: task.taskListId,
          eventId: eventId,
          completed: task.completed ? task.completed : false,
          listName: task.listName,
        };
      })
    : [];
}

const TaskListsPage = ({ title, taskLists, myTasks, eventId, guests }) => {
  const [visible, setVisibility] = useState(false);
  const [visibleDrawer, setDrawerVisibility] = useState(false);
  const [activeList, setActiveList] = useState(null);
  const isGuest = useGuests();
  const showDrawer = (list) => {
    setActiveList(list);
    setDrawerVisibility(true);
  };

  const onCloseDrawer = () => {
    setDrawerVisibility(false);
    setActiveList(null);
  };

  const showModal = () => {
    setVisibility(true);
  };

  const handleOk = () => {
    setVisibility(false);
  };

  const handleCancel = () => {
    setVisibility(false);
  };

  const formInit = (data) => {
    // console.log(data)
  };

  const list =
    taskLists && activeList
      ? taskLists.filter((list) => activeList.id === list.id)
      : [];

  return (
    <Wrapper fixed={visibleDrawer}>
      <TableHeader>
        <InTypography.InH1>{title}</InTypography.InH1>
      </TableHeader>

      {myTasks && myTasks.length ? (
        <>
          <ShowMoreCard>
            <MyTaskList
              activeList={activeList}
              tableData={generateTableData(myTasks, eventId)}
            />
          </ShowMoreCard>
        </>
      ) : (
        <>
          <Cards bg="#A9C5F4">
            <Row align="middle" justify="center">
              <Col sm={12} align="middle" justify="center">
                <Title>
                  <h2>
                    YAY! You have no tasks to complete. Cheers to you, Darling!
                  </h2>
                </Title>
              </Col>
              <Col sm={12}>
                <Image
                  style={{ marginTop: 25 }}
                  src={funkyImage}
                  alt=""
                  preview={false}
                />
              </Col>
            </Row>
          </Cards>
        </>
      )}
      <>
        <InTypography.InH1>Tasklists</InTypography.InH1>
        <ShowMoreCard>
          <Row gutter={16}>
            {!isGuest && (
              <Col xs={24} sm={8}>
                <CreateTaskCard onClick={() => showModal()}>
                  <AddCircleIcon />
                  <h5>Create new checklist</h5>
                </CreateTaskCard>
              </Col>
            )}

            {taskLists
              ? taskLists.map((list) => {
                  return (
                    <Col xs={24} sm={8}>
                      <TaskCards onClick={() => showDrawer(list)}>
                        <h3>{list.name}</h3>
                        <p>{list.description}</p>
                      </TaskCards>
                    </Col>
                  );
                })
              : [1, 2, 3, 4].map(() => {
                  return (
                    <Col xs={24} sm={8}>
                      <Skeleton active />
                    </Col>
                  );
                })}
          </Row>
        </ShowMoreCard>
      </>
      <AddTaskList
        visible={visible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        eventId={eventId}
        guests={guests}
      />
      <TaskList
        activeList={list.length ? list[0] : activeList}
        onClose={onCloseDrawer}
        visible={visibleDrawer}
        guests={guests}
      />
    </Wrapper>
  );
};

TaskListsPage.defaultProps = {};

TaskListsPage.propTypes = {};

export default TaskListsPage;
