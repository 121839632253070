export const AWAITING_RESPONSE = "awaiting response";
export const COMPLETED_RSVP = "completed rsvp";
export const CONFIRMED = "confirmed";
export const CANCELED = "canceled";
export const PAST = "past";
export const DECLINED = "declined";
export const PENDING = "pending";
export const PENDING_MAYBE = "penging maybe";

// email types
export const VOTE = "vote";
export const RSVPBRIDE = "rsvp_bride";
export const RSVPGUEST = "rsvp_guest";
export const POLLCOUNTDOWN = "poll_countdown";
export const EVENTCANCELLED = "event_cancelled";
export const ITINERARY = "itinerary";
export const TASKS = "tasks";
export const RESETPASSWORD = "reset_password";
export const NOTIFICATIONS = "notifications";

export const RSVPBRIDESET = "rsvp_bride_set";
export const RSVPGUESTSET = "rsvp_guest_set";
export const RSVPBRIDENOTSET = "rsvp_bride_not_set";
export const RSVPGUESTNOTSET = "rsvp_guest_not_set";

// users
export const BRIDE = "bride_user";
export const GUEST = "guest_user";
export const ORGANISER = "organiser";

// notification type
export const SMS = "sms";
export const EMAIL = "EMAIL";
