import { db } from '../../config/firebase'
import moment from "moment";

const addChatGroup = (eventId, groupData, onClose) => {
    const timestamp = moment().unix()
    const groupRef = db.collection(`events`).doc(eventId).collection('chat-group').doc(`${timestamp}`);
    const references = groupData.members.map((member) => {
        return member.id
    })
    return groupRef.set({
        id: groupRef.id,
        eventId: eventId,
        name: groupData.name,
        memberReference: references,
        members: groupData.members
    }).then(() => {
        return onClose()
    })
        .catch(function(error) {
            console.error("Error updating document: ", error);
            return error
        });

};

const addChatToGroup = (eventId, groupId, messageData, onClose) => {
    const timestamp = moment().unix()
    const messageRef = db.collection(`events`)
                        .doc(eventId)
                        .collection('chat-group')
                        .doc(groupId)
                        .collection('messages')
                        .doc(`${timestamp}`);

    return messageRef.set({
        id: messageRef.id,
        groupId: groupId,
        created: timestamp,
        senderId: messageData.senderId,
        senderName: messageData.senderName,
        content: messageData.content,

    }).then(() => {
        return onClose()
    })
        .catch(function(error) {
            console.error("Error updating document: ", error);
            return error
        });

};

const getAllChats = (eventId, setChatList) => {
    const eventRef = db.collection('events').doc(eventId)
    const groupRef = eventRef.collection(`chat-group`)
        .where('eventId', '==', eventId);

    groupRef.onSnapshot(async (querySnapshot) => {
        let chatList = []
        await querySnapshot.forEach((doc) => {
            chatList.push(doc.data())
        })
        return setChatList(chatList)
    })
}

const getChatThread = (eventId, groupId, setChatThread) => {
    const eventRef = db.collection('events').doc(eventId)
    const groupRef = eventRef.collection(`chat-group`).doc(groupId).collection('messages')
        .where('groupId', '==', groupId)
        .orderBy('created', 'asc');

    groupRef.onSnapshot(async (querySnapshot) => {
        let messages = []
        await querySnapshot.forEach((doc) => {
            messages.push(doc.data())
        })
        return setChatThread(messages)
    })
}

const chat = {
    addChatGroup: addChatGroup,
    addChatToGroup: addChatToGroup,
    getAllChats: getAllChats,
    getChatThread: getChatThread,
};

export default chat;
