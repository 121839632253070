import React, { Component } from 'react'
import styled from "styled-components";
import { SelectLocation } from "../GeoSuggest";

const Wrapper = styled.div`
  max-width: 670px;
  width: 100%;
  height: 100%;
  margin: 18px 0;
  display: flex;
  flex-direction: row;
`

class TodoList extends Component {
    render() {
        return (
            <Wrapper>
                <SelectLocation placeholder="Enter City Name" onSelect={this.props.onSelect} />
            </Wrapper>
        )
    }
}

export default TodoList
