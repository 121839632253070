import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import GuestAddForm from "./GuestAddForm";

const Wrapper = styled.div`
  max-width: 670px;
`;

const GuestList = ({ countryCode, initForm, disabled, loading }) => {
  const inputElement = React.createRef();
  const [guests, setGuests] = useState([]);
  const [currentItem, setCurrentItem] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    key: "",
  });

  const deleteItem = (key) => {
    const filteredItems = guests.filter((item, index) => {
      return index !== key;
    });
    setGuests(filteredItems);
  };

  const handleName = (e) => {
    const name = e.target.value;
    setCurrentItem({
      ...currentItem,
      name: name,
    });
  };

  const handleMobileNumber = (number) => {
    if (number) {
      setCurrentItem({
        ...currentItem,
        mobileNumber: number,
      });
    }
  };

  const handleEmail = (e) => {
    const email = e.target.value;
    setCurrentItem({
      ...currentItem,
      email: email,
    });
  };

  const addGuest = (newGuest) => {
    if (newGuest !== "") {
      initForm({ guest: newGuest }, false);
      setCurrentItem({
        name: "",
        email: "",
        mobileNumber: "",
        key: "",
      });
    }
  };

  const addGuestAndComplete = (newGuest) => {
    if (newGuest !== "") {
      initForm({ guest: newGuest }, true);

      setCurrentItem({
        name: "",
        email: "",
        mobileNumber: "",
        key: "",
      });
    }
  };

  return (
    <Wrapper>
      <GuestAddForm
        addItem={addGuest}
        done={addGuestAndComplete}
        inputElement={inputElement}
        handleName={handleName}
        handleEmail={handleEmail}
        handleMobileNumber={handleMobileNumber}
        currentItem={currentItem}
        countryCode={countryCode}
        disabled={currentItem.name === ""}
        loading={loading}
      />
    </Wrapper>
  );
};

GuestList.defaultProps = {};

GuestList.propTypes = {};

export default GuestList;
