import React from 'react'
import styled from 'styled-components'
import { Row, Col, Button, Skeleton } from 'antd';
import BudgetIcon from "../../../assets/icons/shop";
import shopImage from "../../../assets/console/balloons.png";

export const Container = styled(Row)`
    padding:  0 1.25em;
`;

const BtnWrapper = styled(Button)`
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 80%;
    position: absolute;
    bottom: 0;
    margin: 8px 0 1.5em 0;
    display: flex;
    align-items: center;
    
    background: #ffffff;
    height: 44px;
    border-radius: 4px;
    color: #101B43;
    text-transform: uppercase;
  
  &:hover {
    background: #ffffff;
    color: #101B43;
  }
  ${props => props.theme.breakpoints.maxTablet}  {
    position: relative;
    } 
  &:focus {
    background: #ffffff;
    color: #101B43;
  }
  
  svg {
   margin: 0 1em;
  }
`

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;
  ${props => props.theme.breakpoints.maxTablet}  {
    padding-top: 20px;
    }   
  
  h1 {
        font-family: 'Clearface Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 140%;
        color: #FFFFFF;
        margin: 0;
        padding: 0;
    }
  
  div {
    margin-right: 1em;
    p {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #FFFFFF;
        margin: 0;
        padding: 0;
    }
  }
`;

export const Paragraph = styled.p`
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
    margin: 20px 0 0 0;
    padding: 0;
`;

export const Image = styled.img`
    height: 280px;
    margin-top: 0.825em;
    ${props => props.theme.breakpoints.maxTablet}  {
        display:none !important;
    } 
`;

export const ImageColumn = styled(Col)`
    display: flex;
    justify-content: center;
`;

const FinalizeDetails = ({ event }) => {
	if (!event) return <Container><Skeleton active /></Container>
	return (
		<Row style={{ height: '100%' }}>
			<Col xs={24}>
				<Container >
					<Col sm={12} md={12}>
						<TextContainer>
							<h1>ALL THINGS BACH
								Search Our Merch</h1>
						</TextContainer>
						<Paragraph>
							Keep your bride blushing and guests posting!
						</Paragraph>
						<BtnWrapper
							onClick={() => null}
							text=''
							htmlType='button'
							primary
						>
							<a href='https://www.goinvigo.com/city-guides/' target='_blank'>
								Go to City Guides <BudgetIcon />
							</a>
						</BtnWrapper>
					</Col>
					<ImageColumn sm={12} md={12}>
						<Image src={shopImage} alt="" />
					</ImageColumn>
				</Container>
			</Col>
		</Row>
	)
}

export default FinalizeDetails