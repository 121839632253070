import React from "react";
import styled from "styled-components";
import { Row, Col, Button, Skeleton } from "antd";
import { Tabs } from "antd";
import { Link } from "react-router-dom";
import RightArrowIcon from "../../../assets/icons/rightArrow";
import { TaskListCard } from "../../";
import { formatStart } from "../../../helpers/normalizeDate";
function callback(key) {
  // console.log(key);
}

export const Loader = styled.div`
  padding: 1.25em;
`;

export const Container = styled(Col)`
  padding: 0 1.25em;
`;

export const Header = styled(Col)`
  height: 60px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25em;

  h5 {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 100%;
    color: #101b43;
    padding: 0;
    margin: 0;
  }

  a {
    svg {
      fill: #494d5b;
    }
  }
`;

export const Bottom = styled(Col)`
  padding: 0 1.25em;
`;

function generateTableData(data, eventId) {
  return data.map((task, index) => {
    return {
      key: index,
      id: task.id,
      listName: task.listName ? task.listName : "-",
      name: task.name ? task.name : "-",
      description: task.description,
      due: task.due, //&& task.due.seconds ? formatStart(task.due.seconds) : task.due,
      assignTo: task.assignTo ? task.assignTo : "-",
      taskListId: task.taskListId,
      eventId: eventId,
      completed: task.completed ? task.completed : false,
    };
  });
}

const TaskList = ({ myTasks, eventId, pathUrl }) => {
  if (!myTasks)
    return (
      <Loader>
        <Skeleton active />
      </Loader>
    );
  return (
    <Row style={{ height: "100%", position: "relative" }}>
      <Header xs={24}>
        <h5>MY TASKS</h5>
        <Link to={`${pathUrl}/task-lists`}>
          <RightArrowIcon />
        </Link>
      </Header>
      <Container xs={24}>
        <TaskListCard items={generateTableData(myTasks, eventId)} />
      </Container>
    </Row>
  );
};

export default TaskList;
