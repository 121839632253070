import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Field, initialize, reduxForm } from "redux-form";
import { connect } from "react-redux";
import styled from "styled-components";
import { Button, Row, Col } from "antd";
import { InButton } from "../..";
import { FormComponents } from "../..";
import GoogleIcon from "../../../assets/icons/google";
import FacebookIcon from "../../../assets/icons/facebook";
import { useHistory } from "react-router-dom";
const FormWrapper = styled.form`
  max-width: 670px;
  width: 100%;
  margin: 27px 0 0 0;

  .ant-form-item-label {
    text-align: left;
  }

  .ant-form-item-label label::after {
    content: "";
  }
  @media all and (max-width: 767px) {
    max-width: 100%;
  }
`;

export const ForgotPassword = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  a {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 100%;
    /* identical to box height, or 13px */

    display: flex;
    align-items: center;
    text-align: center;
    color: #8696ae;
  }
`;
export const SocialMedia = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;

  span {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 13px */

    display: flex;
    align-items: center;
    text-align: center;
    color: #8696ae;
  }
`;

export const ButtonContainer = styled.div`
  /* display: flex; */
  flex-direction: row;
  justify-content: space-between;
  ${(props) => props.theme.breakpoints.maxPhone} {
    flex-direction: column;
  }
`;

const InSMButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 197px; */
  width: 100%;
  height: 44px;
  background: #ffffff;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05);
  border-radius: 8px;

  font-family: "Proxima Nova Semibold";
  font-size: 15px;
  line-height: 80%;

  color: #101b43;

  margin: 8px 0;

  &:hover {
    background: ${(props) => (props.primary ? "#2E2C9A" : "#ffffff")};
    border-color: #2e2c9a;
  }

  &:focus {
    background: ${(props) => (props.primary ? "#2E2C9A" : "#ffffff")};
    border-color: #2e2c9a;
  }

  svg {
    margin-right: 6px;
  }

  ${(props) => props.theme.breakpoints.maxPhone} {
    width: 100%;
  }
`;
export const Bottom = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
  text-transform: uppercase;
  color: #8696ae;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;

  span {
    display: flex;
    align-items: center;
    text-align: center;
    color: #101b43;
    margin: 0 0.45em;
    cursor: pointer;
  }
`;

const RsvpForm = ({
  handleSubmit,
  isAuthenticated,
  formInit,
  loginWithEmailAndPassword,
  googleLogin,
  facebookLogin,
  eventParams,
  dispatch,
}) => {
  const [passwordSet, setNewPassword] = useState(false);
  let history = useHistory();
  React.useEffect(() => {
    if (eventParams && eventParams.email) {
      const data = {
        email: eventParams.email,
        password: eventParams.key,
      };
      formInit({ ...data });
    }

    if (isAuthenticated && eventParams && eventParams.email) {
      setNewPassword(true);
    }
  }, [isAuthenticated]);

  return (
    <FormWrapper
      onSubmit={handleSubmit((values) => loginWithEmailAndPassword(values.email, values.password, dispatch))}
    >
      <Field
        label="Email"
        name="email"
        type="email"
        component={FormComponents.InInput}
        placeholder="Email"
        hasFeedback
      />

      {eventParams && eventParams.email ? (
        <Field label="" name="password" type="hidden" component={FormComponents.InInput} placeholder="Password" />
      ) : (
        <Field
          label=""
          name="password"
          type="password"
          component={FormComponents.InInputPassword}
          placeholder="Password"
        />
      )}

      <InButton
        type="primary"
        // disabled={pristine || submitting}
        htmlType="submit"
        text={eventParams.email ? "SIGN UP" : "SIGN IN"}
        primary
        width="100%"
        disabled={false}
      />
      {!eventParams.email && (
        <ForgotPassword>
          <a href="#">FORGOT PASSWORD</a>
        </ForgotPassword>
      )}

      <SocialMedia>
        <span>or {eventParams.email ? "Sign Up" : "Sign In"} via social media</span>
      </SocialMedia>
      <ButtonContainer>
        <Row gutter={16}>
          <Col xs={24} md={24}>
            <InSMButton htmlType="button" onClick={() => googleLogin(dispatch)} icon={<GoogleIcon />}>
              GOOGLE
            </InSMButton>
          </Col>
          {/* <Col xs={24} md={12}>
						<InSMButton htmlType="button" onClick={() => facebookLogin(dispatch)} icon={<FacebookIcon />}>
							FACEBOOK
						</InSMButton>
					</Col> */}
        </Row>
      </ButtonContainer>
      {eventParams.email ? (
        <Bottom>
          Have an account? <span onClick={() => history.push("/login")}>Log in</span>
        </Bottom>
      ) : (
        <Bottom>
          Don’t have account? <span onClick={() => history.push("/register")}>Sign up</span>
        </Bottom>
      )}
    </FormWrapper>
  );
};

RsvpForm.defaultProps = {};

RsvpForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "Required";
  }

  if (values && values.password && values.password.length < 8) {
    errors.password = "Your password is too short";
  }

  return errors;
};

const RsvpLoginForm = reduxForm({
  form: "login",
  validate,
})(RsvpForm);

function mapDispatchToProps(dispatch) {
  return {
    formInit: (data) => {
      dispatch(initialize("login", data));
    },
  };
}

export default connect(null, mapDispatchToProps)(RsvpLoginForm);
