import datesImage from "../../../assets/quiz/dates.png";
import cityImage from "../../../assets/quiz/cities.png";

const mockGraphqlData = (cities, dates) => ({
  type: "bride-unset-details",
  steps: [
    {
      name: "intro",
      title: "Intro",
      sections: [
        {
          name: "intro",
          caption: "Identifying Information",
          justify: "start",
          fields: [],
        },
      ],
    },
    {
      name: "city",
      title: "City",
      sections: [
        {
          name: "city",
          caption: null,
          image: cityImage,
          layout: {
            sub: 24,
            main: 24,
          },
          fields: [
            {
              name: "city",
              image: cityImage,
              component: "radioImagesGroup",
              caption: "Which city should we party in?",
              subCaption: `Select up to ${cities.length} destinations where the event should be`,
              options: [
                ...cities,
                {
                  value: `I am unable to attend.`,
                  label: `I am unable to attend.`,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: "dates",
      title: "Dates",
      sections: [
        {
          name: "dates",
          caption: null,
          image: datesImage,
          layout: {
            sub: 24,
            main: 24,
          },
          fields: [
            {
              name: "dates",
              component: "radioImagesGroup",
              caption: "What dates are you available to celebrate?",
              subCaption: "Select ALL available dates you are able to attend",
              options: [
                ...dates,
                {
                  value: `I am unable to attend.`,
                  label: `I am unable to attend.`,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
});

export default mockGraphqlData;
