import React from 'react'
import styled from "styled-components";
import { Row, Col } from 'antd';
import { InTypography } from "../..";

export const FieldWrapper = styled.div`

`;

export const Label = styled.label`
    cursor: pointer;
`;

export const Text = styled.div`
   background: #FAFAFC;
   max-width: 27.5em;
   width: 100%;
   height: 3.375em;
   font-weight: 700;
   font-size: 16px;
   padding: 1em;
   margin: 0.375em 0;
   display: flex;
   align-items: center;
   
    background: ${props => props.checked ? '#4F4DB8' : ' #FFFFFF'};
    border: 1px solid ${props => props.checked ? '#4F4DB8' : ' #EDEDED'};
    border-radius: 4px;
   
   p {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;    
    color: ${props => props.checked ? '#FFFFFF' : ' #101B43'};
    padding: 0;
    margin: 0;
   }

`;

export const Wrapper = styled.div`
    width: 100%;
    height: 150px;
    background: transparent url(${props => props.src}) no-repeat center;
    background-size: cover;
    border-radius: 8px 8px 0 0;
`;

export const Input = styled.input`
    width: 0;
    height: 0;
    visibility: hidden;
    position: absolute;
`;

export const Content = styled.div`
    margin: 0 0 2.5em 0;
    max-width: 555px;
    position: relative;
	${props => props.theme.breakpoints.maxPhone} {
		h1 {
			text-align: center;
		}
	}
`;

export const Circle = styled.div`
    margin: 0 0.5em;
    border: 1px solid ${props => props.checked ? '#FFFFFF' : '#4F4DB8'};
    background: ${props => props.checked ? '#4F4DB8' : '#FFFFFF'};
    width: 20.57px;
    height: 20.57px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    
    span {
        background: white;
        width: 13.33px;
        height: 13.33px;
        border-radius: 50%;
    }
`;

const SubText = styled.h3`
  max-width: 670px;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 120%;
  color: #101B43;
  margin: 0;
  padding: 32px 0;
    
`


const Container = styled.div`
    width: 100%;
    height: 150px;
    background: #FAFAFC;
border-radius: 8px;

    
`

const DatesContainer = styled.div`
    width: 100%;
    height: 150px;
    background: #ffffff;
    border-radius: 8px;    
    padding: 0;
`

export const Image = styled.div`
    width: 100%;
    height: 100px;
    background: transparent url(${props => props.src}) no-repeat center;
    background-size: cover;
    border-radius: 8px 8px 0 0;
    margin-bottom: 1em;
`;

const Caption = styled.h4`
  font-family: 'Proxima Nova Semibold';
font-size: 18px;
line-height: 100%;
  color: #101B43;
  margin: 0;
  padding: 0 20px;
    
`
const Bottom = styled.div`
    width: 100%;
    height: 100px;
    background: #FAFAFC;
    border-radius: 8px 8px 0 0;  
    padding: 1em 0;  
`

export default props => {
	console.log('props.options: ', props.altOptions)
	return (
		<Content>
			<InTypography.InH1>
				{props.label}
			</InTypography.InH1>
			<SubText>{props.subLabel && props.subLabel} {props.altOptions && props.altOptions.brideList && props.altOptions.brideList}</SubText>
			<Row gutter={16} style={{ marginTop: 32 }}>
				<Col xs={24} sm={12} md={{ order: 2 }}>
					<DatesContainer>
						<Bottom>
							<Caption>{props.altOptions.dates.label}</Caption>
							<Caption>{props.altOptions.dates.days} days</Caption>
						</Bottom>
					</DatesContainer>
				</Col>
				<Col xs={24} sm={12} md={{ order: 1 }}>
					<Container>
						<Image src={props.altOptions.city.image} />
						<Caption>{props.altOptions.city.label}</Caption>
					</Container>
				</Col>
			</Row>
		</Content>
	)
}
