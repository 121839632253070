import React from 'react';
import styled from "styled-components";
import {Menu} from "antd";

const Wrapper = styled.svg`
    fill: #494D5B;
     stroke: #494D5B;
    
    &:hover {
      fill: #2E2C9A;
     stroke: #2E2C9A;
    }
`

export default ({ style }) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.9264 0.657421L1.03614 9.00622C0.76308 9.13364 0.785827 9.52922 1.07169 9.62451L7.91194 11.9046C8.00338 11.9351 8.07715 12.0037 8.11423 12.0926L10.9949 19.0063C11.108 19.2778 11.4915 19.2805 11.6084 19.0107L19.3732 1.09202C19.4952 0.810573 19.2043 0.527709 18.9264 0.657421Z" fill="#4F4DB8" stroke="#4F4DB8"/>
    </svg>

);
