import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Field, initialize, reduxForm } from "redux-form";
import { connect } from "react-redux";
import styled from "styled-components";
import { Button, Space } from "antd";
import { InButton } from "../..";
import { FormComponents } from "../..";
import GoogleIcon from "../../../assets/icons/google";
import FacebookIcon from "../../../assets/icons/facebook";

const FormWrapper = styled.form`
  max-width: 670px;
  width: 100%;
  margin: 27px 0 0 0;

  .ant-form-item-label {
    text-align: left;
  }

  .ant-form-item-label label::after {
    content: "";
  }
`;

export const ForgotPassword = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  a {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 100%;
    /* identical to box height, or 13px */

    display: flex;
    align-items: center;
    text-align: center;
    color: #8696ae;
  }
`;
export const SocialMedia = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;

  span {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 13px */

    display: flex;
    align-items: center;
    text-align: center;
    color: #8696ae;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${(props) => props.theme.breakpoints.maxPhone} {
    flex-direction: column;
  }
`;

const InSMButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 197px;
  height: 44px;
  background: #ffffff;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05);
  border-radius: 8px;

  font-family: "Proxima Nova Semibold";
  font-size: 15px;
  line-height: 80%;

  color: #101b43;

  margin: 8px 0;

  &:hover {
    background: ${(props) => (props.primary ? "#2E2C9A" : "#ffffff")};
    border-color: #2e2c9a;
  }

  &:focus {
    background: ${(props) => (props.primary ? "#2E2C9A" : "#ffffff")};
    border-color: #2e2c9a;
  }

  svg {
    margin-right: 6px;
  }

  ${(props) => props.theme.breakpoints.maxPhone} {
    width: 100%;
  }
`;

const RsvpForm = ({ handleSubmit, isAuthenticated, formInit, handleSubmitPassword, eventParams }) => {
  const [passwordSet, setNewPassword] = useState(false);

  React.useEffect(() => {
    if (eventParams && eventParams.email) {
      const data = {
        email: eventParams.email,
        // password: eventParams.key,
      };
      formInit({ ...data });
    }

    if (isAuthenticated && eventParams && eventParams.email) {
      setNewPassword(true);
    }
  }, [isAuthenticated]);
  return (
    <FormWrapper onSubmit={handleSubmit((values) => handleSubmitPassword(eventParams.email, values.password))}>
      <Field
        label="Please set your password"
        addonAfter="Minimum 8 characters"
        name="password"
        type="password"
        component={FormComponents.InInputPassword}
        placeholder="Password"
      />

      <InButton
        type="primary"
        // disabled={pristine || submitting}
        htmlType="submit"
        text="UPDATE"
        primary
        width="100%"
        disabled={false}
      />
    </FormWrapper>
  );
};

RsvpForm.defaultProps = {};

RsvpForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const validate = (values) => {
  const errors = {};

  if (values && values.password && values.password.length < 8) {
    errors.password = "Your password is too short";
  }

  return errors;
};

const RsvpLoginForm = reduxForm({
  form: "password",
  validate,
})(RsvpForm);

function mapDispatchToProps(dispatch) {
  return {
    formInit: (data) => {
      dispatch(initialize("password", data));
    },
  };
}

export default connect(null, mapDispatchToProps)(RsvpLoginForm);
