import React from 'react';

import {Tabs, Timeline} from 'antd';
import styled from "styled-components";
import Done from '../icons/timelineDone'
import Incomplete from '../icons/incomplete'

const Wrapper = styled(Timeline)`
  margin: 12px;
`

const Paragraph = styled.p`
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: ${props => props.done ? '#101B43' : '#8696AE'};
    margin: 0 1em;
`

const TimelineWrapper = styled.div`
        position: absolute;
        width: 100%;
        //height: 90vh;
        overflow-y: auto;
        //padding-bottom: 60px;
        @media only screen and (min-device-width: 320px) and (max-device-width: 500px) {
            position: relative;
        }
`

export const TimelineActivity = () => (
    <TimelineWrapper>
            <Wrapper>
                    <Timeline.Item dot={<Done />}>
                        <Paragraph done={true}>Invite Guests</Paragraph>
                    </Timeline.Item>
                    <Timeline.Item dot={<Done />} >
                        <Paragraph done={true}>View RSVPs</Paragraph>
                    </Timeline.Item>
                    <Timeline.Item dot={<Incomplete />} >
                        <Paragraph>Finalize Destination and Date</Paragraph>
                    </Timeline.Item>
                    <Timeline.Item dot={<Incomplete />} >
                        <Paragraph>Review Total Budget</Paragraph>
                    </Timeline.Item>
                    <Timeline.Item dot={<Incomplete />} >
                        <Paragraph>Shop Party Merch</Paragraph>
                    </Timeline.Item>
                    <Timeline.Item dot={<Incomplete />} >
                        <Paragraph>View Personalized Recommendations</Paragraph>
                    </Timeline.Item>
                    <Timeline.Item dot={<Incomplete />} >
                        <Paragraph>Create a Poll</Paragraph>
                    </Timeline.Item>
                    <Timeline.Item dot={<Incomplete />} >
                        <Paragraph>Delegate the To-Dos</Paragraph>
                    </Timeline.Item>
                    <Timeline.Item dot={<Incomplete />} >
                        <Paragraph>Complete the Schedule</Paragraph>
                    </Timeline.Item>
                    <Timeline.Item dot={<Incomplete />} >
                        <Paragraph>Cue the Confetti!</Paragraph>
                    </Timeline.Item>
            </Wrapper>
    </TimelineWrapper>
);