import moment from "moment";
import { db } from "../../config/firebase";
import { AWAITING_RESPONSE, CANCELED, DECLINED } from "../constants";
import { toast } from "../../redux/actions";

function sortDates(dateItem) {
  return moment(dateItem).toDate();
}

const updateTimeline = (eventId, timelineItem) => {
  const activityRef = db.collection(`events`).doc(eventId);

  return activityRef
    .update(timelineItem)
    .then(function () {
      return true;
    })
    .catch((error) => {
      console.error("Error updating document: ", error);
      return false;
    });
};

const createEvent = (uid, displayName, data, setLoader, callback, resetForm) => {
  const timestamp = moment().unix();
  const countdown = moment().add(7, "d").format("YYYY-MM-DD");
  let optionOne, optionTwo, optionThree;
  if (data.dates && data.dates.datesOptionOne) {
    const resOne = data.dates.datesOptionOne.map((dateItem, index) => {
      return sortDates(dateItem);
    });

    optionOne = {
      start: resOne[0],
      end: resOne[1],
    };
  }

  if (data.dates && data.dates.datesOptionTwo) {
    const resTwo = data.dates.datesOptionTwo.map((dateItem, index) => {
      return sortDates(dateItem);
    });

    optionTwo = {
      start: resTwo[0],
      end: resTwo[1],
    };
  }

  if (data.dates && data.dates.datesOptionThree) {
    const resThree = data.dates.datesOptionThree.map((dateItem, index) => {
      return sortDates(dateItem);
    });

    optionThree = {
      start: resThree[0],
      end: resThree[1],
    };
  }

  const dates =
    optionOne && optionTwo && optionThree
      ? [optionOne, optionTwo, optionThree]
      : optionOne && optionTwo
      ? [optionOne, optionTwo]
      : optionOne
      ? [optionOne]
      : [];

  const docRef = db.collection("events").doc(`${uid}${timestamp}`);

  const isSetCity = data.cities.length === 1;
  const isSetDates = dates.length === 1;

  docRef
    .set({
      id: docRef.id,
      userId: uid,
      organizer: {
        displayName,
        uid: uid,
      },
      name: data.eventName,
      bride: data.brides,
      dates: dates,
      cities: data.cities,
      setCity: isSetCity,
      setDates: isSetDates,
      guestData: data.guestList,
      guestList: [],
      confirmedGuests: [],
      status: AWAITING_RESPONSE,
      timestamp,
      timeline: {
        inviteGuests: false,
        viewRsvp: false,
        finalize: false,
        reviewBudget: false,
        shopMerch: false,
        viewRecommendations: false,
        createPoll: false,
        finalizePolls: false,
        delegateToDo: false,
        completeSchedule: false,
      },
      itinerary: {
        start: false,
        eventId: docRef.id,
        eventName: data.eventName,
        details: null,
        city: {
          countdown,
          name: isSetCity && isSetDates ? data.cities[0].name : null,
          imageUrl: isSetCity && isSetDates ? data.cities[0].imageUrl : null,
        },
        dates: {
          countdown,
          start: isSetCity && isSetDates ? dates[0].start : null,
          end: isSetCity && isSetDates ? dates[0].end : null,
        },
      },
    })
    .then(() => {
      // onCreate event check cloud function
      setTimeout(() => {
        setLoader(false);
      }, 2000);

      setTimeout(() => {
        resetForm();
        callback();
      }, 3000);
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
    });
};

const getEvent = (eventId) => {
  const docRef = db.collection("events").doc(eventId);

  return docRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data();
      } else {
        return null;
      }
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
    });
};

const plannedEvents = (userId) => async (setData) => {
  db.collection(`events`)
    .where("userId", "==", userId)
    .where("status", "==", AWAITING_RESPONSE)
    .orderBy("timestamp", "desc")
    .onSnapshot((querySnapshot) => {
      let events = [];
      querySnapshot.forEach((doc) => {
        events.push(doc.data());
      });
      setData(events);
    });
};

const invitedTo = (userId) => async (setData) => {
  db.collection(`events`)
    .where("guestList", "array-contains", userId)
    .where("status", "==", AWAITING_RESPONSE)
    .orderBy("timestamp", "desc")
    .onSnapshot((querySnapshot) => {
      let events = [];
      querySnapshot.forEach((doc) => {
        events.push(doc.data());
      });
      setData(events, userId);
    });
};

const getEventsCanceled = (userId) => async (setData) => {
  db.collection(`events`)
    .where("userId", "==", userId)
    .where("status", "==", CANCELED)
    .orderBy("timestamp", "desc")
    .onSnapshot((querySnapshot) => {
      let events = [];
      querySnapshot.forEach((doc) => {
        events.push(doc.data());
      });
      setData(events);
    });
};

const getEventsPast = (userId) => async (setData) => {
  db.collection(`events`)
    .where("userId", "==", userId)
    .where("status", "==", DECLINED)
    .orderBy("timestamp", "desc")
    .onSnapshot((querySnapshot) => {
      let events = [];
      querySnapshot.forEach((doc) => {
        events.push(doc.data());
      });
      setData(events);
    });
};

const getEvents = async (setData) => {
  db.collection(`events`).onSnapshot((querySnapshot) => {
    let events = [];
    querySnapshot.forEach((doc) => {
      events.push(doc.data());
    });
    setData(events);
  });
};
const updateEventStatus = (eventId, status, reason) => () => {
  const eventRef = db.collection(`events`).doc(eventId);
  return eventRef
    .update({
      status,
      reason,
    })
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

const updateItinerary = (eventId, itinerary, finalSubmit) => {
  const eventRef = db.collection(`events`).doc(eventId);

  return eventRef
    .update({
      "itinerary.details": itinerary,
      "timeline.completeSchedule": finalSubmit,
    })
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

const reopenItinerary = (eventId, finalSubmit) => {
  const eventRef = db.collection(`events`).doc(eventId);

  return eventRef
    .update({
      "timeline.completeSchedule": finalSubmit,
    })
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

const sendEventReminder = async (eventId, onClose, dispatch, request = fetch) => {
  const requestObject = {
    data: {
      eventId,
    },
  };

  const postBody = JSON.stringify(requestObject);

  let url;
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    url = `${process.env.REACT_APP_SEND_EVENT_REMINDER_DEV}`;
  } else {
    url = `${process.env.REACT_APP_SEND_EVENT_REMINDER_PROD}`;
  }

  try {
    const response = await request(url, { method: "POST", body: postBody });
    if (response.status === 200) {
      const body = await response;
      dispatch(toast("Reminder sent", "success"));
      onClose();
      return body.body;
    }
  } catch (error) {
    onClose();
    console.log("errors: ", error);
  }
};

export default {
  getEvent: getEvent,
  planning: plannedEvents,
  create: createEvent,
  invitedTo: invitedTo,
  canceled: getEventsCanceled,
  past: getEventsPast,
  updateEventStatus: updateEventStatus,
  sendEventReminder: sendEventReminder,
  updateItinerary: updateItinerary,
  updateTimeline: updateTimeline,
  reopenItinerary: reopenItinerary,
  getEvents: getEvents,
};
