import React, { Component } from 'react';
import styled from "styled-components"
import founders from './meet-the-founders.svg'
import ladies from './hey-ladies.svg'
import withLove from './with-loe.svg'
import longline from '../../../../../assets/home/long-line.png'
import shortline from '../../../../../assets/home/short-line.png'
import decor from '../../../../../assets/home/decor.svg'

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.8);
  margin: 0;

  ${props => props.theme.breakpoints.desktop} {
    margin: 2em;
  }
`;

const TitleWrapper = styled.div`
  margin: 16px 0;
  text-align: left;
`;

export const Title = styled.h2`
    color: ${props => props.theme.colors.tarawera};
    font-family: 'Proxima Nova', sans-serif;
    font-style: normal;
    font-weight: bold;
    line-height: 62px;
    margin: 0;
    padding: 0;
    position: relative;
    text-align: left;
    font-size: 39px;
    display: flex;
  flex-direction: row;
  
  span {
    position: relative;
  }

   @media (min-width: 1655px) {
      font-size: 48px;
      line-height: 62px;
    }

   
`;

const ParagraphWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
  z-index: 3;

  ${props => props.theme.breakpoints.desktop} {
    width: 80%;
  }
  
  ul {
    list-style: none;
    margin: 16px 0 0 0;
    padding: 0;
    
    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      
      h3 {
        color: ${props => props.theme.colors.tarawera};
        font-family: 'Proxima Nova Semibold';
        font-size: 26px;
        line-height: 130%;
        text-align: left;
        margin: 0;
      }
      
      img {
        margin-top: 40px;
        width: 100%;
      }
    }
  }
  
  p {
    color: ${props => props.theme.colors.tarawera};
    font-family: 'Proxima Nova';
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 150%;
    text-align: left;
    margin: 8px 0;
    padding: 0;
  }
`;

const Image = styled.img`
  width: 100%;
`;

const Underline = styled.span`
color: ${props => props.theme.colors.tarawera};
font-family: 'Proxima Nova';
font-style: normal;
font-weight: 600;
font-size: 26px;
line-height: 130%;
text-align: left;
margin: 0;
padding: 16px 6px;
background-image: ${ props => props.line ? `url(${props.line })` : `url(${props.line })` };
background-repeat: no-repeat;
background-position: -5px 44px;

`;

const Space = styled.div`
  margin: ${props => props.margin};
`;

const BottomSpace = styled.div`
  margin: 0;
  ${props => props.theme.breakpoints.tablet} {
    margin: ${props => props.margin};
  }
`;

const DecorElement = styled.img`
  width: 40px !important;
  position: absolute;
  right: 1em;
  bottom: 150px;
  transform: rotate(145deg);

  ${props => props.theme.breakpoints.maxPhone} {
    bottom: 10vh;
  }
  
`;

class CarouselComponent extends Component {
  render() {
    return (
        <Container>
          <ParagraphWrapper>
            <TitleWrapper>
              <Image src={founders} />
            </TitleWrapper>
            <TitleWrapper>
              <img src={ladies} alt='' />
            </TitleWrapper>
            <ul>
              <li>
                <h3>We’re Laurelle & Rijon, and we’re <Underline line={shortline}>sisters.</Underline></h3>
              </li>
              <li>
                <p>
                  Laurelle loves travel and margaritas, and Rijon is pretty crazy about puppies and lattés... But one thing we both love, is partying with the girls!
                 
                </p>
              </li>
              <li>
                <p>
                  Man, we’ve seen some pretty crazy nights, and some really bad booty grinding too! We’ve seen what a real MOH breakdown looks like - Not fun! 
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <h3>We get it! We know how stressful planning a bachelorette party can be!</h3>
              </li>
              <li>
                <p>
                We’ve planned a dozen bachelorette parties too! Those group texts and email threads, excel docs, head counts, awkward budget convos and secret bathroom cries… Count us out!
                </p>
              </li>
              <li>
                <p>
                We had a revelation! It was time to take a stand for stressed Maids, Matrons and Brides everywhere! 
                </p>
              </li>
            </ul>
            
            <ul>
              <li>
                <h3>So, we created a dynamic & savvy <Underline line={longline}>party planning tool</Underline> that does it all.</h3>
              </li>
              <li>
                <p>
                  Organize, plan, look and book in half the time and with zero hassle! <br />
                  For the love of making life easy, no more maybe this, maybe that.
                </p>
              </li>
              <li>
                <Space margin='35px 0 0 0'>
                  <h3>So, here you go… <br />
                  Take it. It’s yours babe.</h3>

                  <DecorElement src={decor} />
                </Space>
                
              </li>
              <li>
                <BottomSpace margin='65px 0 0 0'>
                  <img src={withLove} alt=""/>
                </BottomSpace>
              </li>
            </ul>
          </ParagraphWrapper>
        </Container>
    );
  }
}

export default CarouselComponent
