import React from 'react';
import styled from "styled-components";
import {Menu} from "antd";

const Wrapper = styled.svg`
    fill: #494D5B;
     stroke: #494D5B;
    
    &:hover {
      fill: #2E2C9A;
     stroke: #2E2C9A;
    }
`

export default ({ style }) => (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.9409 6.71265V10.93L11.7703 12.0842C12.0311 12.4469 12.0733 12.9326 11.8791 13.3384C11.6847 13.7442 11.2872 14.0003 10.8527 14H1.14698C0.712504 14 0.315366 13.7438 0.121088 13.3382C-0.0731899 12.9325 -0.0312556 12.447 0.229374 12.0842L1.05868 10.93V6.71265C1.04966 4.72553 2.27133 2.96014 4.08123 2.34462L3.21344 0.533148C3.15885 0.418817 3.1646 0.283337 3.22892 0.17464C3.29341 0.0659423 3.40692 3.47467e-06 3.52927 3.47467e-06H8.47036C8.59306 -0.000550645 8.70719 0.0652035 8.77195 0.173901C8.83662 0.282506 8.84272 0.418632 8.78796 0.533148L7.9184 2.34462C9.7283 2.96014 10.9501 4.72553 10.9409 6.71265Z" fill="#47B0B7"/>
        <path d="M6.60009 7.62504H5.40011C5.06873 7.62504 4.80012 7.37312 4.80012 7.06254C4.80012 6.75197 5.06873 6.50005 5.40011 6.50005H7.60004V5.75012H6.40006V5H5.60004V5.75012H5.40011C4.62686 5.75012 4 6.3378 4 7.06254C4 7.78737 4.62686 8.37497 5.40011 8.37497H6.60009C6.93157 8.37497 7.20008 8.62688 7.20008 8.93746C7.20008 9.24822 6.93157 9.49995 6.60009 9.49995H4.40006V10.2501H5.60004V11H6.40006V10.2501H6.60009C7.37334 10.2501 8 9.66248 8 8.93746C8 8.21282 7.37334 7.62504 6.60009 7.62504Z" fill="white"/>
    </svg>
);
