import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from "styled-components"
import { scroller } from "react-scroll/modules";
import { Image } from '../../components'
import DecorDivider from "./Sections/DecorDivider";
import Header from "./Sections/Header";
import Info from "./Sections/Info";
import Dashboard from "./Sections/Dashboard";
import DitchTheGroupThreads from "./Sections/JoinNow";
import SignUpNow from "./Sections/SignUpNow";
import PersonalizeTheParty from "./Sections/PlanNow";
import Guides from "./Sections/Guides";
import PlanningStar from "./Sections/PlanningStar";
import bgLineImage from "./bg_hero_line.png";
import { Element } from 'react-scroll'

const Wrapper = styled.div`
  padding-bottom: 1em;
  overflow: hidden;
  min-height: 100vh;
`;

const HeaderContent = styled.div`
    padding-top: 120px;
    background: #F7F7F7;
`

function scrollTo(name) {
  scroller.scrollTo(name, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
  })
}

class HomePage extends Component{
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount () {
        const { location } = this.props;
        if(location.state) scrollTo(location.state.scrollToSection)
    }

    componentWillUnmount () {
      const { location } = this.props;
      location.state = null
    }

  render(){
        return (
            <Wrapper>
              <header>
                <HeaderContent>
                    <Header />
                </HeaderContent>
                <Image src={bgLineImage} width='100%' />
              </header>
                <Element name='info' />
              <DecorDivider decorAdd />
              <div>
                <Info />
                <DecorDivider />
                <Dashboard />
                <DecorDivider />
                <DitchTheGroupThreads />
                <DecorDivider />
                <SignUpNow />
                <DecorDivider />
                <PersonalizeTheParty />
                <DecorDivider />
                <Guides />
                <DecorDivider top={`50px`} />
                <PlanningStar />
              </div>
            </Wrapper>
        );
    }
}

export default connect(null, null)(HomePage)
