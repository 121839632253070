import { createGlobalStyle } from 'styled-components';

// ClearfaceBold
import ClearfaceBold from './ClearfaceBold/ClearfaceBold.woff';
import ClearfaceBold2 from './ClearfaceBold/ClearfaceBold.woff2';

// Proxima Nova
import ProximaNova from './ProximaNova/ProximaNova.woff';
import ProximaNova2 from './ProximaNova/ProximaNova.woff2';

// ProximaNovaRegular
import ProximaNovaRegular from './ProximaNovaRegular/ProximaNovaRegular.woff';
import ProximaNovaRegular2 from './ProximaNovaRegular/ProximaNovaRegular.woff2';

// ProximaNovaRegular
import ProximaNovaSemibold from './ProximaNovaSemibold/ProximaNovaSemibold.woff';
import ProximaNovaSemibold2 from './ProximaNovaSemibold/ProximaNovaSemibold.woff2';

// Proxima Nova Bold
import ProximaNovaBold from './ProximaNovaBold/ProximaNovaBold.woff';
import ProximaNovaBold2 from './ProximaNovaBold/ProximaNovaBold.woff2';

export default createGlobalStyle`
    @font-face {
        font-family: 'Clearface Bold';
        src: local('Clearface Bold'), local('ClearfaceBold'),
        url(${ClearfaceBold}) format('woff'),
        url(${ClearfaceBold2}) format('woff2');
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Proxima Nova';
        src: local('Proxima Nova'), local('ProximaNova'),
        url(${ProximaNova}) format('woff'),
        url(${ProximaNova2}) format('woff2');
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Proxima Nova Regular';
        src: local('Proxima Nova Regular'), local('ProximaNovaRegular'),
        url(${ProximaNovaRegular}) format('woff'),
        url(${ProximaNovaRegular2}) format('woff2');
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Proxima Nova Semibold';
        src: local('Proxima Nova Semibold'), local('ProximaNovaSemibold'),
        url(${ProximaNovaSemibold}) format('woff'),
        url(${ProximaNovaSemibold2}) format('woff2');
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Proxima Nova Bold';
        src: local('Proxima Nova Bold'), local('ProximaNovaBold'),
        url(${ProximaNovaBold}) format('woff'),
        url(${ProximaNovaBold2}) format('woff2');
        font-style: normal;
    }
`;