import React from 'react';
import PropTypes from 'prop-types';
import {useFirestoreConnect} from "react-redux-firebase";
import {useSelector} from "react-redux";
import EventGuestViewProvider, { EventGuestViewContext } from './EventGuestViewProvider'
import Dashboard from './Dashboard'
import styled from "styled-components";

const Container = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  //max-height: 90vh;
`

const EventGuestView = props => {
    useFirestoreConnect(() => [
        { collection: 'events', doc: props.match.params.eventId }
    ])
    const event = useSelector(
        ({ firestore: { data } }) => data.events && data.events[props.match.params.eventId]
    )

  return (
    <EventGuestViewProvider event={event} pathUrl={props.url}>
      <EventGuestViewContext.Consumer>
          {({ data }) => {
              return <Container><Dashboard /></Container>
          }}
      </EventGuestViewContext.Consumer>
    </EventGuestViewProvider>
  );
};

EventGuestView.defaultProps = {

};

EventGuestView.propTypes = {

};

export default EventGuestView