import React from "react";
import { Modal, Button, Row, Col } from "antd";
import { connect } from "react-redux";
import { LoginForm, ForgotPassword, RegisterForm, SocialPasswordForm } from "../../components/Forms";
import { loginUser, loginWithGoogle, loginWithFacebook, logoutUser } from "../../helpers/auth";
import styled, { createGlobalStyle } from "styled-components";
import { withRouter } from "react-router-dom";
import landing from "../../assets/login/login.png";
import invigoLogo from "../../assets/logo/logo.svg";

const GlobalStyle = createGlobalStyle`
		.login-modal .ant-modal-close {
			left: 15px;
			right: auto;
		}

		.loginform.login-modal .ant-modal,
    .loginform.login-modal .ant-modal-content {
			height: 100%;
		}
    .login-modal .ant-modal,
    .login-modal .ant-modal-content {
			height: auto;
      width: 100vw !important;
			max-width: 100vw;
      margin: 0;
      top: 0 !important;
			margin: 0;
			border-radius: 0;
			top: 0;
    }
	

		
		.loginform.login-modal .ant-modal-body {
			height: 100%;
		}
    
		.login-modal .ant-modal-body {
			height: auto;
			padding: 0;
    }
		@media all and (max-width: 767px) {
			.login-modal .ant-modal-body {
			height: auto;
			min-height: 100vh;
			padding: 15px;
    }
		}
    .login-modal .modal-dialog {
      max-width: 100%;
      margin: 0;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100vh;
      display: flex;
    }

		@media all and (min-width: 768px) {
			.login-modal .ant-modal,
    .login-modal .ant-modal-content,.login-modal .ant-modal-body {
			height: 100%;
		}
		}
`;

const ModalWrapper = styled(Modal)`
  padding: 0;
  .ant-modal-content {
    height: 100%;
    border-radius: 8px;
  }

  .ant-modal-close {
    top: 20px;
    right: 20px;
    display: none;
  }
  .ant-modal-close-x {
    display: none;
    border: 1px solid #eeeeef;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: #494d5b;
  }
`;

const Wrapper = styled(Button)`
  background: ${(props) => (props.primary ? "#EC6E5B" : "#ffffff")};
  border-color: #ec6e5b;
  width: ${(props) => (props.width ? props.width : "172px")};
  height: 44px;
  border-radius: 4px;
  color: ${(props) => (props.primary ? "#ffffff" : "#EC6E5B")};
  text-transform: uppercase;

  &:hover {
    background: ${(props) => (props.primary ? "#EC6E5B" : "#ffffff")};
    border-color: #ec6e5b;
    color: ${(props) => (props.primary ? "#ffffff" : "#EC6E5B")};
  }

  &:focus {
    background: ${(props) => (props.primary ? "#EC6E5B" : "#ffffff")};
    border-color: #ec6e5b;
    color: ${(props) => (props.primary ? "#ffffff" : "#EC6E5B")};
  }
`;

export const Image = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: column;
  background: transparent url(${(props) => props.src}) no-repeat 70% center;
  background-size: cover;
  //margin-top: 5vh;
`;

export const RowLogin = styled(Row)`
  height: 100%;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
`;

var promiseResolve;
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      activeForm: "login",
    };
  }

  componentDidMount() {
    this.setComponent(this.props.name);
    if (this.props.visible) {
      this.showModal();
    }
  }

  setComponent = (name) => {
    this.setState({
      activeForm: name,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleActiveForm = (activeForm) => {
    this.setState({ activeForm });
  };

  handleCancel = (e) => {
    if (this.props.returnBack) {
      this.props.history.goBack();
    }
    this.setState({
      visible: false,
    });
  };

  handleClose = (e) => {
    this.setState({
      visible: false,
    });
  };

  loginWithEmailAndPassword = (email, password) => {
    loginUser(email, password)(this.props.dispatch, this.handleClose);
  };

  logout = () => {
    logoutUser()(this.props.dispatch);
  };

  googleLogin = () => {
    loginWithGoogle(this.props.dispatch, this.handleClose);
  };

  socialPassword = () => {
    this.setState({ activeForm: "socialPassword" });
    return new Promise((resolve, reject) => {
      promiseResolve = resolve;
    });
  };

  facebookLogin = () => {
    loginWithFacebook({
      dispatch: this.props.dispatch,
      onClose: this.handleClose,
      socialPassword: this.socialPassword,
    });
  };

  handleSubmitPassword = (password) => {
    promiseResolve(password);
  };

  render() {
    const { activeForm } = this.state;
    const { submitUserDetails, primary } = this.props;
    return (
      <div>
        <GlobalStyle />
        {this.props.title && (
          <Wrapper
            type="default"
            primary={primary ? primary.toString() : null}
            width={this.props.width ? this.props.width : "126px"}
            onClick={() => {
              this.showModal();
              this.props.onClose && this.props.onClose();
            }}
          >
            {this.props.title}
          </Wrapper>
        )}
        <ModalWrapper
          transitionName=""
          title=""
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
          wrapClassName={`${activeForm === "login" ? "loginform" : "regform"} login-modal`}
          style={{ top: 16, borderRadius: 8 }}
          maskStyle={{ backgroundColor: this.props.visible ? "rgba(0, 0, 0, 0.88)" : "rgba(0, 0, 0, 0.45)" }}
        >
          <RowLogin>
            <Col xs={0} sm={12}>
              <Image src={landing} />
            </Col>
            <Col xs={24} sm={{ span: 8, offset: 2 }}>
              <FormWrapper>
                <a href="https://goinvigo.com/" target="_blank" rel="noopener noreferrer">
                  <img src={invigoLogo} alt="" />
                </a>
                {activeForm === "login" ? (
                  <LoginForm
                    googleLogin={this.googleLogin}
                    facebookLogin={this.facebookLogin}
                    loginWithEmailAndPassword={this.loginWithEmailAndPassword}
                    handleActiveForm={this.handleActiveForm}
                  />
                ) : activeForm === "register" ? (
                  <RegisterForm
                    googleLogin={this.googleLogin}
                    facebookLogin={this.facebookLogin}
                    handleActiveForm={this.handleActiveForm}
                    submitUserDetails={submitUserDetails}
                  />
                ) : activeForm === "socialPassword" ? (
                  <SocialPasswordForm handleSubmitPassword={this.handleSubmitPassword} />
                ) : (
                  <ForgotPassword handleActiveForm={this.handleActiveForm} />
                )}
              </FormWrapper>
            </Col>
          </RowLogin>
        </ModalWrapper>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
}

export default withRouter(connect(mapStateToProps, null)(Login));
