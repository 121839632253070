export const DISPLAY_TOAST = "DISPLAY_TOAST";

export const toast = (message, type) => {
  return {
    type: DISPLAY_TOAST,
    message: {
      type,
      message
    }
  };
};
