import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Avatar, Space, Dropdown, Menu } from "antd";
import { UserOutlined } from "@ant-design/icons";

const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
  h5 {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #101b43;
    padding: 0;
    margin: 0;
  }
`;

const DivWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h5 {
    margin: 0 12px;
  }
`;

const DropdownWrapper = styled(Dropdown)``;

const DropdownBtn = styled.button`
  border: none;
  background: none;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  width: 100%;
  height: 100%;
  text-align: left;

  color: #101b43;
  &:hover {
    color: #2e2c9a;
  }
`;

const menu = ({ logout, email }) => {
  const adminEmails = process.env.REACT_APP_ADMIN.split(",");
  const isShowUsersLink = adminEmails.includes(email);
  return (
    <Menu>
      {isShowUsersLink && (
        <Menu.Item>
          <Link to="/users">
            <DropdownBtn type="button">Users</DropdownBtn>
          </Link>
        </Menu.Item>
      )}

      <Menu.Item>
        <DropdownBtn type="button" onClick={() => logout()}>
          Log Out
        </DropdownBtn>
      </Menu.Item>
    </Menu>
  );
};

const ProfilePhoto = ({ logout, user: { displayName, photoURL, email } }) => {
  return (
    <Wrapper>
      <Space>
        <DropdownWrapper overlay={menu({ logout, email })} placement="bottomRight">
          <DivWrapper>
            <h5>{displayName}</h5>
            {photoURL ? <Avatar size="large" src={photoURL} /> : <Avatar size="large" icon={<UserOutlined />} />}
          </DivWrapper>
        </DropdownWrapper>
      </Space>
    </Wrapper>
  );
};

ProfilePhoto.defaultProps = {};

ProfilePhoto.propTypes = {};

export default ProfilePhoto;
