import React, { Component } from 'react';
import styled from "styled-components"
import banner from './about-banner.png'
import bannerBg1 from './Oval.svg'
import bannerBg2 from './Oval-2.svg'
import element1 from '../../../../../assets/website/about/element-1.svg'

const HeaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  position: relative;
  margin-top: 70px;
`;

const ImageWrapper = styled.div`
  margin-top: 28px;
  width: 100%;
  height: 512px;
  left: 0;
  z-index: 2;
  position: relative;
`;

const Image = styled.div`
    width: 100%;
    height: 100%;
    background: transparent url(${props => props.src}) no-repeat center;
    background-size: cover;
    transform-style: preserve-3d;
    transition: all 0.5s ease;
    border-radius: 80px 0;
`;

const Image1 = styled(Image)`
  position: absolute;
  top: -115px;
  left: -90px;
  width: 274px;
  height: 298px;
  z-index: -1;
`;

const Image2 = styled(Image)`
  position: absolute;
  bottom: 80px;
    right: -40px;
  width: 68.5px;
  height: 59.63px;
  z-index: -1;

  ${props => props.theme.breakpoints.maxPhone} {
    right: 0;
  }
`;

class CarouselComponent extends Component {
  render() {
    return (
      <HeaderWrapper>
            <ImageWrapper>
              <Image src={banner} />
              <Image1 src={bannerBg1} />
              <Image2 src={element1} />
            </ImageWrapper>
      </HeaderWrapper>
    );
  }
}

export default CarouselComponent
