import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, initialize, reduxForm } from 'redux-form'
import styled from "styled-components";
import { Form } from "antd";
import { InInput, InTextarea } from "./FormComponents";
import PollList from './PollList';
import { connect } from "react-redux";
import { polls } from "../../../helpers/firestore";

const FormItem = Form.Item;

const FormWrapper = styled.form`
  .ant-form-item-label {
    text-align: left;
  }
  
  .ant-form-item-label label::after {
    content: '';
}
`

export const NameContainer = styled.div`
  width: 100%;
  background: #ffffff;
    padding: 1.5em;
    
    .ant-form-item {
      margin: 1em 0;
    }
  .ant-row {
    width: 100%;
    
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
`

export const BottomContainer = styled.div`
  display: ${props => props.display ? 'flex' : 'none'};
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 76px;
  padding: 0 1.5em;
  
    background: #FFFFFF;
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
`



const FieldItem = styled(FormItem)`
     
    label {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      color: #101B43;
    }
    
    &:focus {
       border: 1px solid #4F4DB8;
    }
      
    input, textarea {
      height: 44px;
      outline: none;
      
      &:hover {
        border: 1px solid #4F4DB8;
      }
      
      &:focus {
        border: 1px solid #4F4DB8;
      }
    }

    textarea {
        max-width: 51.875em;
    }
`
const TextWrapper = styled.div`
     display: flex;
     align-items: baseline;
     max-width: 51.875em;
     height: auto;
`

const FieldParagrapgh = styled.p`
     
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      color: #101B43;
      padding: 0;
      margin: 0 1em;
`



const sortOptions = (recommendations) => {
	return recommendations.map((option, index) => {

		return {
			label: option.name,
			value: option.name,
			id: index,
			details: option
		}
	});
}

const CustomPollForm = ({ handleSubmit, openPollOption, formState, formInit, eventId, onClose, customPollData, pollOptions }) => {
	const [count, setPollCount] = useState(0);
	const [editNote, setEditNote] = React.useState(false);
	const [renderOnce, setRenderOnce] = React.useState(false);
	// const [pollOptions, setPollOptions] = React.useState([]);

	const initForm = (data) => {
		formInit({ ...formState.values, options: data });
	};



	const addCount = (count) => {
		setPollCount(count);
	};

	const toggleEdit = () => {
		setEditNote(!editNote)
	};

	const subtractCount = () => {
		setPollCount(count - 1);
	};



	const submitData = (eventId, pollData, values, onClose) => {

		polls.addPollWithOptions(eventId, pollData, values, onClose);
	};

	React.useEffect(() => {
		if (customPollData && !renderOnce) {
			const data = {
				question: customPollData.question,
				note: customPollData.note
			}

			const values = formState && formState.values ? { ...formState.values } : {}
			formInit({ ...values, question: data.question, note: data.note })
			// setRenderOnce(true)

		}
	}, [customPollData, formInit, renderOnce, formState]);

	return (
		<FormWrapper onSubmit={handleSubmit(values => submitData(eventId, customPollData, values, onClose))}>
			<NameContainer>
				{editNote
					? <FieldItem>
						<Field label="Notes" name="note" type='textarea' component={InTextarea} />
					</FieldItem>
					: <TextWrapper>
						<FieldParagrapgh>{customPollData.note}</FieldParagrapgh>
					</TextWrapper>}
				<FieldItem>
					<Field label="Question" name="question" type='text' component={InInput} />
				</FieldItem>
			</NameContainer>
			<PollList
				options={pollOptions}
				openPollOption={openPollOption}
				addCount={addCount}
				subtractCount={subtractCount}
				customPollData={customPollData}
				initForm={initForm}
			/>

		</FormWrapper>
	);
};

CustomPollForm.defaultProps = {

};

CustomPollForm.propTypes = {
	handleSubmit: PropTypes.func.isRequired
};

const validate = values => {
	const errors = {};
	if (!values.name) {
		errors.name = "Required";
	}

	return errors;
};

const CustomPoll = reduxForm({
	form: `customPoll`,
	validate,
	// onSubmit: submitData,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true
})(CustomPollForm)

export function mapStateToProps(state) {
	return {
		formState: state.form.customPoll,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		formInit: (data) => {
			dispatch(initialize('customPoll', data))
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomPoll)