import React, { useState } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd';
import { PlusOutlined } from "@ant-design/icons";
import { CustomModal, Cities, InButton } from "../";
import editButton from "../../assets/icons/editBtn.svg";

const Wrapper = styled.div`

`

const TextWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const Text = styled.h3`
  /* Desktop/Paragraph/16R */

font-family: 'Proxima Nova';
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 150%;
/* identical to box height, or 24px */


/* Text/Midnight Blue */

color: #101B43;
`

const CityWrapper = styled.div`
margin: 1em 0 2em 0;
width: 210px;
height: 10.5em;
background: #FAFAFC;
border-radius: 8px;
    
    h4 {
        margin: 12px 16px;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #101B43;
    }
`

export const SelectedCities = styled.div`
    display: flex;
`;

export const Image = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    flex-direction: column;
    background: transparent url(${props => props.src}) no-repeat center;
    background-size: cover;
    border-radius: 8px 8px 0 0;
    padding: 12px;
    
    button {
      border: none;
      outline: none;
      background: none;
    }
`;


const AddCities = ({ initForm, cities, destinations, setDestinations }) => {

	const [visible, setVisibility] = useState(false);
	const [checkValue, setCheck] = useState(true);

	const showModal = () => {
		setVisibility(true)
	};

	const handleDestinations = (results) => {
		setDestinations(results)

	};

	const handleCancel = () => {
		setVisibility(false)
	};

	const toggleCheck = () => {
		setCheck(!checkValue)
	};

	const handleAdd = () => {
		setVisibility(false)

		initForm({ cities: destinations })
		setDestinations([])
	};

	return (
		<Wrapper>
			<TextWrapper>
				<Text>Destination of Event</Text>
				{cities && cities.length ?
					<button
						type='button'
						onClick={() => showModal()}
						style={{ background: '#FFFFFF' }}
					>
						<img src={editButton} alt="" />
					</button>
					: null}
			</TextWrapper>
			<SelectedCities>
				{cities && cities.length
					? <Row gutter={16} style={{ width: '100%' }}>
						{cities.map((city, index) => {
							return (
								<Col xs={24} md={8} key={index}>
									<CityWrapper>
										<Image src={city.imageUrl} />
										<h4>{city.name}</h4>
									</CityWrapper>
								</Col>
							)
						})}
					</Row>
					: <InButton
						icon={<PlusOutlined />}
						type='button'
						onClick={() => showModal()}
						text='ADD CITY'
					/>}
				<CustomModal
					visible={visible}
					onClose={handleCancel}
				>
					<Cities
						handleCancel={handleCancel}
						handleDestinations={handleDestinations}
						toggleCheck={toggleCheck}
						handleAdd={handleAdd}
						disabled={!destinations.length}
						destinations={destinations}
						checkValue={checkValue}
						resetCities={!visible}
						cities={cities}
					/>
				</CustomModal>
			</SelectedCities>
		</Wrapper>
	);
};

AddCities.defaultProps = {

};

AddCities.propTypes = {

};

export default AddCities;
