import React from 'react'
import { Drawer } from 'antd';
import { Link } from "react-router-dom";
import styled from "styled-components";
import { scroller } from "react-scroll";
import { EventMobileMenu } from '../../../components'
const Dialog = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
  }
`

const Menu = styled.div`
  margin: 56px 0 0 0;
  padding: 0;

`

const scrollOnPage = (name, e, onClose) => {
	onClose()
	scroller.scrollTo(name, {
		duration: 800,
		delay: 0,
		smooth: 'easeInOutQuart'
	})

	e.preventDefault()
}

const MobileMenu = ({ location, visible, onClose, handleLogout, isGuest }) => {
	return (
		<Dialog
			title=""
			placement={`top`}
			closable={true}
			onClose={() => onClose()}
			visible={visible}
			key={`top`}
			height='100%'
			style={{ zIndex: 10, padding: 0 }}
		>
			<Menu>
				<EventMobileMenu handleLogout={handleLogout} onClose={onClose} isGuest={isGuest} />
			</Menu>
		</Dialog>
	);
}

export default MobileMenu;