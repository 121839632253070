import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import StarSVG from "../../assets/menuicons/star";
import DashSVG from "../../assets/menuicons/dash";
import PollsSVG from "../../assets/menuicons/polls";
import ScheduleSvg from "../../assets/menuicons/schedule";
import GuestsSVG from "../../assets/menuicons/guestlist";
import TaskSVG from "../../assets/menuicons/tasklist";
import ChatSVG from "../../assets/menuicons/chat";
import BudgetSVG from "../../assets/menuicons/budget";
import ShopSVG from "../../assets/menuicons/shop";
import SettingsSVG from "../../assets/menuicons/settings";
import HelpSVG from "../../assets/menuicons/help";

const MenuContainer = styled(Menu)`
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #fff;
  }

  li {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;

    color: #101b43;
    &:hover {
      color: #2e2c9a;
    }

    .ant-menu-submenu-title {
      &:hover {
        color: #2e2c9a;
      }
    }

    .ant-menu-submenu-arrow {
      &:hover {
        color: #2e2c9a;
      }
    }

    span {
      display: ${(props) => (props.collapsed ? "none" : "flex")};
    }
  }

  li a {
    &:hover {
      color: #2e2c9a;
    }

    .ant-menu-submenu-title {
      &:hover {
        color: #2e2c9a;
      }
    }

    .ant-menu-submenu-arrow {
      &:hover {
        color: #2e2c9a;
      }
    }
  }

  .ant-menu-item-selected {
    color: #2e2c9a;
  }

  .ant-menu-item-selected a {
    color: #2e2c9a;
  }

  .ant-menu-submenu-selected {
    color: #2e2c9a;
  }

  li {
    ::selection {
      color: #fff;
    }
  }
`;

const IconContainer = styled.div`
  width: ${(props) => (props.collapsed ? "auto" : "32px")};
  height: ${(props) => (props.collapsed ? "auto" : "32px")};
  background: ${(props) => (props.selected ? "#4F4DB8" : "#FAFAFC")};
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  transition: All 0.3s ease;
`;

const MenuItem = styled(Menu.Item)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(192, 192, 192, 0.3);

  svg {
    rect {
      stroke: #4f4db8;
      fill: white;
    }
  }
`;

const ItemMenu = styled(Menu.Item)`
  display: flex;
  align-items: center;

  svg {
    ${(props) => (props.selected ? "fill: #4F4DB8" : "")};
    rect {
      ${(props) => (props.selected ? "stroke: #4F4DB8; fill: #ffffff;" : "stroke: #4F4DB8;")};
    }
  }
`;

const Item = styled(Menu.Item)`
  display: flex;
  align-items: center;

  svg {
    ${(props) => (props.selected ? "fill: #ffffff;" : "fill: #4F4DB8;")};
    rect {
      ${(props) => (props.selected ? "stroke: #4F4DB8; fill: #ffffff;" : "stroke: #4F4DB8; fill: #4F4DB8;")};
    }
  }
`;

const TasksMenu = styled(Menu.Item)`
  display: flex;
  align-items: center;

  svg {
    ${(props) => (props.selected ? "fill: #ffffff; stroke: #4F4DB8;" : "stroke: #4F4DB8;")};
    rect {
      ${(props) => (props.selected ? "stroke: #4F4DB8; fill: #ffffff;" : "stroke: #4F4DB8;")};
    }
  }
`;

const Extra = styled(Menu)`
  margin-top: 2em !important;
  height: 100px !important;
`;

const SettingsItem = styled(Menu.Item)`
  display: flex;
  align-items: center;
  margin: 1em 0;

  svg {
    ${(props) => (props.selected ? "fill: #ffffff;" : "fill: #4F4DB8;")};
    rect {
      ${(props) => (props.selected ? "stroke: #4F4DB8; fill: #ffffff;" : "stroke: #4F4DB8; fill: #4F4DB8;")};
    }
  }
`;

const HelpItem = styled(Menu.Item)`
  display: flex;
  align-items: center;

  svg {
    ${(props) => (props.selected ? "stroke: #4F4DB8; fill: #ffffff;" : "stroke: #4F4DB8; fill: #fff;")};
  }
`;

const EventMenu = ({ url, collapsed, routeId }) => {
  return (
    <MenuContainer mode="inline" defaultSelectedKeys={["dashboard"]} collapsed={collapsed}>
      <MenuItem
        key="0"
        icon={
          <IconContainer selected={routeId === 0}>
            <StarSVG />
          </IconContainer>
        }
      >
        <Link to={`/events`}>Events</Link>
      </MenuItem>
      <ItemMenu
        key="dashboard"
        selected={routeId === "dashboard"}
        icon={
          <IconContainer collapsed={collapsed} selected={routeId === "dashboard"}>
            <DashSVG />
          </IconContainer>
        }
      >
        <Link to={`${url}/dashboard`}>Dashboard</Link>
      </ItemMenu>
      <ItemMenu
        key="polls"
        selected={routeId === "polls"}
        icon={
          <IconContainer collapsed={collapsed} selected={routeId === "polls"}>
            <PollsSVG />
          </IconContainer>
        }
      >
        <Link to={`${url}/polls`}>Polls</Link>
      </ItemMenu>
      <ItemMenu
        key="schedule"
        selected={routeId === "schedule"}
        icon={
          <IconContainer collapsed={collapsed} selected={routeId === "schedule"}>
            <ScheduleSvg />
          </IconContainer>
        }
      >
        <Link to={`${url}/schedule`}>Itinerary</Link>
      </ItemMenu>
      <Item
        key="guest-lists"
        selected={routeId === "guest-lists"}
        icon={
          <IconContainer collapsed={collapsed} selected={routeId === "guest-lists"}>
            <GuestsSVG />
          </IconContainer>
        }
      >
        <Link to={`${url}/guest-lists`}>Guestlist</Link>
      </Item>
      <TasksMenu
        key="task-lists"
        selected={routeId === "task-lists"}
        icon={
          <IconContainer collapsed={collapsed} selected={routeId === "task-lists"}>
            <TaskSVG />
          </IconContainer>
        }
      >
        <Link to={`${url}/task-lists`}>Tasklists</Link>
      </TasksMenu>
      <Item
        key="budget"
        selected={routeId === "budget"}
        icon={
          <IconContainer collapsed={collapsed} selected={routeId === "budget"}>
            <BudgetSVG />
          </IconContainer>
        }
      >
        <Link to={`${url}/budget`}>Budget</Link>
      </Item>
      <Item
        key="shop"
        selected={routeId === "shop"}
        icon={
          <IconContainer collapsed={collapsed} selected={routeId === "shop"}>
            <ShopSVG />
          </IconContainer>
        }
      >
        <a href="https://www.goinvigo.com/city-guides/" target="_blank" rel="noopener noreferrer">
        City Guides
        </a>
      </Item>
      <Extra>
        <SettingsItem
          key="settings"
          selected={routeId === "settings"}
          icon={
            <IconContainer collapsed={collapsed} selected={routeId === "settings"}>
              <SettingsSVG />
            </IconContainer>
          }
        >
          <Link to={`${url}/settings`}>Settings</Link>
        </SettingsItem>
        {/*<HelpItem key="help" selected={routeId === 'help'} icon={<IconContainer selected={routeId === 'help'} ><HelpSVG /></IconContainer>}>*/}
        {/*    <Link to={`${url}/help`}>Help</Link>*/}
        {/*</HelpItem>*/}
      </Extra>
    </MenuContainer>
  );
};

export default EventMenu;
