import React from "react";
import styled from "styled-components";
import { Field } from "redux-form";
import { Space } from "antd";
import { InputPhone } from "./MobileNumberInput";
import { InButton } from "../../..";
import { InSelect, Option, InInput, InMultiSelect } from "./FormComponents";
import { COMPLETED_RSVP, DECLINED, PENDING, PENDING_MAYBE } from "../../../../helpers/constants";
const Wrapper = styled.div`
  margin: 18px 0;
  max-width: 670px;
`;

const BoxWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 1.125em 0 5em;
  display: flex;
  flex-direction: column;
`;

const InputWrapper = styled.div`
  margin: 1em 0;
  label {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #101b43;
  }
`;

const Input = styled.input`
  width: 100%;
  height: 44px;
  padding: 10px 12px;
  margin: 0;
  border: 1px solid #eeeeef;
  border-radius: 4px;
  outline: none;

  &:hover {
    border: 1px solid #eeeeef;
  }
`;

const rsvpList = [
  { label: "Going", value: COMPLETED_RSVP },
  { label: "Not going", value: DECLINED },
  { label: "No response", value: PENDING },
  { label: "Maybe", value: PENDING_MAYBE },
];

const sortGuests = (guests = []) => {
  return guests.map((guest) => {
    return {
      label: guest.name,
      value: guest.id,
      id: guest.id,
    };
  });
};

const GuestEditForm = (props) => {
  const guestList = sortGuests(props.guests);
  return (
    <Wrapper>
      <BoxWrapper>
        <InputWrapper>
          <label htmlFor="name">Name</label>
          <Field name="name" component={InInput} placeholder="Full Name" bordered hasFeedback />
        </InputWrapper>
        <InputWrapper>
          <label htmlFor="email">Email</label>
          <Field name="email" component={InInput} placeholder="Email" bordered hasFeedback />
        </InputWrapper>
        <InputWrapper>
          <label htmlFor="mobileNumber">Mobile Number</label>
          <Field
            name="mobileNumber"
            component={InputPhone}
            defaultCountry="US"
            countryCode="US"
            onBlur={(e) => {
              console.log(e);
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <label htmlFor="rsvp">RSVP</label>
          <Field name="rsvp" ref={props.rsvp} component={InSelect}>
            {rsvpList.map((rsvp, index) => {
              return (
                <Option key={index} value={rsvp.value} label={rsvp.label}>
                  {rsvp.label}
                </Option>
              );
            })}
          </Field>
        </InputWrapper>
        <InputWrapper>
          <label htmlFor="roommates">Roommates</label>
          <Field
            name="roommates"
            component={InMultiSelect}
            bordered
            placeholder="roommates"
            hasFeedback
            mode="multiple"
          >
            {guestList.map((guest, index) => {
              return (
                <Option key={index} value={guest.id} label={guest.label}>
                  {guest.label}
                </Option>
              );
            })}
          </Field>
        </InputWrapper>
      </BoxWrapper>
      <Space size="large">
        <InButton
          type="button"
          text="save"
          disabled={props.pristine || props.submitting}
          loading={props.loading}
          htmlType="submit"
          primary
        >
          SAVE
        </InButton>
      </Space>
    </Wrapper>
  );
};

export default GuestEditForm;
