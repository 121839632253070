import firebase from "firebase";
import moment from "moment";
import { db, storage } from "../../config/firebase";
import { toast } from "../../redux/actions";

const addPoll = async (eventId, poll, recommendationName) => {
	const countdown = moment().add(7, "d").format("YYYY-MM-DD");
	const timestamp = moment().unix();
	const pollRef = db
		.collection("events")
		.doc(eventId)
		.collection("polls")
		.doc(eventId + timestamp);

	const guests = poll.invitedGuests.filter(guest => guest && guest.state !== "declined").map((guest) => {
		guest.isBride = !!guest.isBride;
		return guest;
	});

	const toSnakeCase = (str) =>
		str &&
		str
			.match(
				/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
			)
			.map((x) => x.toLowerCase())
			.join("_");

	const colors = [
		"https://res.cloudinary.com/pcgwa/image/upload/f_auto/w_640/v1610578555/reveleaze/colors/light-blue-image",
		"https://res.cloudinary.com/pcgwa/image/upload/f_auto/w_640/v1610578555/reveleaze/colors/pink-image",
		"https://res.cloudinary.com/pcgwa/image/upload/f_auto/w_640/v1610578555/reveleaze/colors/blue-image.jpg",
	];

	const processOptions = async (options) => {
		if (!poll.options) return [];

		return Promise.all(
			options.map(async (option, index) => {
				const imageUrl = option.image
					? await uploadImageAndGetUrl(
						eventId,
						option.image,
						toSnakeCase(option.name)
					)
					: colors[index % 3];
				return {
					...option,
					image: imageUrl,
				};
			})
		);
	};

	const pollOptions = await processOptions(poll.options);

	return pollRef
		.set({
			id: pollRef.id,
			name: poll.question,
			question: poll.question,
			note: poll.note || null,
			invitedGuests: guests,
			recommendationName,
			options: [],
			poll: firebase.firestore.FieldValue.arrayUnion(...pollOptions),
			multiple: poll.multipleAnswers || false,
			countdown,
			timestamp,
		})
		.then(async () => {
			const doc = await pollRef.get();
			sendPollInvites(doc.data(), eventId);
			return doc.data();
		})
		.catch((error) => {
			console.log(error.message, "error");
		});
};

async function sendPollInvites(pollData, eventId, request = fetch) {
	const requestObject = {
		data: {
			pollData,
			eventId,
		},
	};

	const postBody = JSON.stringify(requestObject);

	let url;
	if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
		url = `${process.env.REACT_APP_SEND_POLL_INVITES_DEV}`;
	} else {
		url = `${process.env.REACT_APP_SEND_POLL_INVITES_PROD}`;
	}

	try {
		const response = await request(url, { method: "POST", body: postBody });
		if (response.status === 200) {
			return await response.json();
		}
	} catch (error) {
		console.log("errors: ", error);
	}
}

async function sendPollReminder(pollData, eventId, dispatch, request = fetch) {
	const requestObject = {
		data: {
			pollData,
			eventId,
		},
	};

	const postBody = JSON.stringify(requestObject);

	let url;
	if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
		url = `${process.env.REACT_APP_SEND_POLL_REMINDER_DEV}`;
	} else {
		url = `${process.env.REACT_APP_SEND_POLL_REMINDER_PROD}`;
	}

	try {
		const response = await request(url, { method: "POST", body: postBody });
		if (response.status === 200) {
			const body = await response;
			dispatch(toast("Reminder sent", "success"));
			return body.body;
		}
	} catch (error) {
		console.log("errors: ", error);
	}
}

const addPollWithOptions = async (eventId, pollData, poll, onClose) => {
	const countdown = moment().add(7, "d").format("YYYY-MM-DD");
	const pollRef = db
		.collection(`events`)
		.doc(eventId)
		.collection(`polls`)
		.doc(pollData.id);

	await pollRef.update({
		question: poll.question,
		note: poll.note || null,
		invitedGuests: pollData.invitedGuests,
		poll: firebase.firestore.FieldValue.arrayUnion(...poll.options),
		countdown,
	});
	sendPollInvites(pollData, eventId);

	onClose();

	const doc = await pollRef.get();
	return doc.data();
};

const getPolls = (eventId, setPollList) => {
	const eventRef = db.collection("events").doc(eventId);
	const sfDocRef = eventRef.collection(`polls`);

	sfDocRef.onSnapshot(async (querySnapshot) => {
		let pollList = [];
		querySnapshot.forEach((doc) => {
			pollList.push(doc.data());
		});
		return setPollList(pollList);
	});
};

export async function uploadImageAndGetUrl(eventId, imageData, imageName) {
	const imagesRef = storage.child(
		`images/event/${eventId}/poll_options/${imageName}.jpg`
	);
	const uploadTask = imagesRef.putString(imageData, "data_url");

	return await uploadTask
		.then((snapshot) => {
			return snapshot;
		})
		.then((snapshot) => {
			return snapshot.ref.getDownloadURL().then(async (imageURL) => {
				return imageURL;
			});
		});
}

async function addPollOptionToArray(eventId, values, onClose, pollData) {
	const toSnakeCase = (str) =>
		str &&
		str
			.match(
				/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
			)
			.map((x) => x.toLowerCase())
			.join("_");

	const colors = [
		"https://res.cloudinary.com/pcgwa/image/upload/f_auto/w_640/v1610578555/reveleaze/colors/light-blue-image",
		"https://res.cloudinary.com/pcgwa/image/upload/f_auto/w_640/v1610578555/reveleaze/colors/pink-image",
		"https://res.cloudinary.com/pcgwa/image/upload/f_auto/w_640/v1610578555/reveleaze/colors/blue-image.jpg",
	];
	const randomItem = colors[Math.floor(Math.random() * colors.length)];

	const imageUrl = values.image
		? await uploadImageAndGetUrl(
			eventId,
			values.image,
			toSnakeCase(values.name)
		)
		: randomItem;

	const eventRef = db
		.collection(`events`)
		.doc(eventId)
		.collection("polls")
		.doc(pollData.id);
	values.image = imageUrl;
	await eventRef.update({
		options: firebase.firestore.FieldValue.arrayUnion(values),
	});

	const doc = await eventRef.get();
	onClose();
	return doc.data();
}

const updateVote = (user, eventId, vote, poll) => {
	// Set the guest preferences
	const pollRef = db
		.collection(`events`)
		.doc(eventId)
		.collection(`polls`)
		.doc(poll.id);

	const isMultiple = poll.multiple || false;

	// / Create a reference to the SF doc.
	return db
		.runTransaction((transaction) => {
			return transaction.get(pollRef).then((pollDoc) => {
				if (!pollDoc.exists) {
					throw "Document does not exist!";
				}


				const oldDoc = pollDoc.data();
				const removeOldVotesFromDoc = oldDoc.poll.map((pollItem) => {
					if (
						pollItem &&
						pollItem.votes &&
						pollItem.votes.some((voter) => voter.uid === user.uid) && !isMultiple
					) {
						pollItem.votes = pollItem.votes.filter((voter) => voter.uid !== user.uid);
						return pollItem;
					}

					if (pollItem && pollItem.name === vote.name && pollItem.votes &&
						pollItem.votes.some((voter) => voter.uid === user.uid) && isMultiple) {
						pollItem.votes = pollItem.votes.filter((voter) => voter.uid !== user.uid);
						return pollItem;
					}
					return pollItem;
				});


				const poll = removeOldVotesFromDoc.filter(
					(poll) => poll.name === vote.name
				);
				const oldPoll = poll[0];


				const isVoted = vote && vote.votes && vote.votes.some(el => el.uid === user.uid);


				if (oldPoll && oldPoll.votes && isVoted) {
					oldPoll.votes = [...oldPoll.votes];
				} else if (oldPoll && oldPoll.votes && !isVoted) {
					oldPoll.votes = [user, ...oldPoll.votes];
				}
				else {
					oldPoll.votes = [user];
				}



				transaction.update(pollRef, {
					poll: removeOldVotesFromDoc,
				});
			});
		})
		.then(function () {
			return true;
		})
		.catch(function (err) {
			// This will be an "population is too big" error.
			console.error(err);
		});
};

const closeVoting = async (eventId, pollId, data) => {
	const eventRef = db
		.collection(`events`)
		.doc(eventId)
		.collection("polls")
		.doc(pollId);
	await eventRef.update({
		finalizedPoll: firebase.firestore.FieldValue.arrayUnion(data)
	});

	const doc = await eventRef.get();
	return doc.data();
};

const reopenVoting = async (eventId, pollId) => {
	const eventRef = db
		.collection(`events`)
		.doc(eventId)
		.collection("polls")
		.doc(pollId);
	await eventRef.update({
		finalizedPoll: []
	});

	const doc = await eventRef.get();
	return doc.data();
};

const deletePoll = async (eventId, pollId) => {
	const eventRef = db
		.collection(`events`)
		.doc(eventId)
		.collection("polls")
		.doc(pollId);
	await eventRef.delete();

	return true;
};


const polls = {
	addPoll: addPoll,
	getPolls: getPolls,
	addPollOptionToArray: addPollOptionToArray,
	updateVote: updateVote,
	addPollWithOptions: addPollWithOptions,
	sendPollInvites: sendPollInvites,
	sendPollReminder: sendPollReminder,
	closeVoting: closeVoting,
	reopenVoting: reopenVoting,
	deletePoll: deletePoll
};

export default polls;
