import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const Wrapper = styled.div`

`

const InputWrapper = styled(Input)`

    background: #FFFFFF;
    width: ${props => props.width ? props.width : '325px'};
    height: 44px;
    border: 1px solid #EEEEEF;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 1.2em;
    color: #4F4DB8;
    ${props => props.theme.breakpoints.maxTablet}  {
      width: 100%;  
    }
    
    &:hover {
      border-color: #4F4DB8;
    }
    
    .ant-input {
        font-size: 1em;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: normal;
        line-height: 150%;
    }

`

const SearchInput = ({ width }) => {
	return (
		<Wrapper>
			<InputWrapper width={width} placeholder="Search" prefix={<SearchOutlined />} />
		</Wrapper>
	);
};

SearchInput.defaultProps = {

};

SearchInput.propTypes = {

};

export default SearchInput;
