import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const InH1 = styled.h1`
    color: #101B43;
    font-family: 'Clearface Bold';
    font-style: normal;
    font-size: 2em;
    line-height: 140%;
    padding: 0;
    margin: 0;
    span {
      color: #2E2C9A;
    }
`
const InH2 = styled.h2`
    color: #101B43;
    font-family: 'Clearface Bold';
    font-style: normal;
    font-size: 1.625em;
    line-height: 34px;
    padding: 0;
    margin: 0;
`

const InH3 = styled.h3`
    color: #101B43;
    font-family: 'Proxima Nova Regular';
    font-style: normal;
    font-size: 1.5em;
    line-height: 120%;
    padding: 0;
    margin: 0;
`

const InH4 = styled.h4`
    color: #101B43;
    font-family: 'Proxima Nova Semibold';
    font-style: normal;
    font-weight: 600;
    font-size: 1.125em;
    line-height: 100%;
    padding: 0;
    margin: 0;
`

const InH5 = styled.h5`
    color: #101B43;
    font-family: 'Proxima Nova Semibold';
    font-style: normal;
    font-size: 0.9375em;
    line-height: 100%;
    padding: 0;
    margin: 0;
`
const InParagraph = styled.p`
    color: #101B43;
    font-family: 'Proxima Nova Regular';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    padding: 0;
    margin: 0;
`

const InParagraphSt = styled.p`
    color: #101B43;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    padding: 0;
    margin: 0;
`

export default {
    InH1,
    InH2,
    InH3,
    InH4,
    InH5,
    InParagraph,
    InParagraphSt
};
