import React from 'react';
import styled from "styled-components";

export default ({ style }) => (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none">
        <g filter="url(#filter0_d)">
            <circle cx="18" cy="17" r="14" fill="white"/>
        </g>
        <path d="M20.4057 12.2914C20.7551 12.6384 20.7561 13.2032 20.4079 13.5514L16.9591 17.0003L20.4079 20.4492C20.7561 20.7974 20.7551 21.3622 20.4057 21.7092C20.058 22.0544 19.4966 22.0534 19.1502 21.707L14.5849 17.1417C14.5068 17.0636 14.5068 16.937 14.5849 16.8589L19.1502 12.2937C19.4966 11.9472 20.058 11.9462 20.4057 12.2914Z" fill="#4F4DB8"/>
        <defs>
            <filter id="filter0_d" x="0" y="0" width="36" height="36" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset dy="1"/>
                <feGaussianBlur stdDeviation="2"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
            </filter>
        </defs>
    </svg>
);
