import React from "react";
import styled from "styled-components";
import { Header, FinalizeDetails, Destination, GuestList, TaskList, Shop } from "./ConsoleComponents";
import { EventGuestViewContext } from "../../containers/EventGuestView/EventGuestViewProvider";
import { Row, Col, Skeleton } from "antd";
import { itinerary } from "../../helpers/firestore";
import funkyImage from "../../assets/console/task-list-is-empty.png";
import { Link } from "react-router-dom";
import RightArrowIcon from "../../assets/icons/rightArrow";

const Wrapper = styled.div`
  margin-top: 25px;
`;

const Cards = styled.div`
  height: 292px;
  background: ${(props) => (props.bg ? props.bg : "#ffffff")};
  border-radius: 8px;
  margin: 1.25em 0;
  ${(props) => props.theme.breakpoints.maxTablet} {
    height: fit-content;
    img {
      padding-bottom: 20px;
      width: 200px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export const HeaderElement = styled(Col)`
  height: 60px;
  width: 100%;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25em;
  text-transform: uppercase;

  h5 {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 100%;
    color: #ffffff;
    padding: 0;
    margin: 0;
  }

  a {
    svg {
      fill: white;
    }
  }
`;

const ShowMoreCard = styled(Cards)`
  min-height: 292px;
  height: auto;
  overflow: auto;
`;

const Title = styled.div`
  display: flex;
  align-items: center;

  h2 {
    font-family: "Clearface Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 140%;
    color: #ffffff;
    text-align: left;
    padding-left: 16px;
    ${(props) => props.theme.breakpoints.maxTablet} {
      font-size: 25px;
      padding-top: 20px;
    }
  }
`;

const EventConsole = () => {
  return (
    <EventGuestViewContext.Consumer>
      {({ event, guests, pathUrl, displayName, itineraryData, tasksLists, myTasks }) => {
        const reopenDestination = () => {
          const finalizeDetails = itineraryData && itineraryData.dates.start;
          itinerary
            .addDestination(event, { name: null, imageUrl: null }, finalizeDetails, [])
            .then((result) => console.log(result));
        };

        return (
          <Wrapper>
            <>
              {/* <Fade> */}
              <Cards>
                <Header
                  event={event}
                  displayName={displayName}
                  itineraryData={event && event.itinerary ? event.itinerary : {}}
                />
              </Cards>
              {/* </Fade> */}
            </>
            <>
              {/* <Fade> */}
              {event && !event.itinerary ? (
                <div>
                  <Skeleton active />
                </div>
              ) : event && event.itinerary && event.itinerary.city && event.itinerary.city.name ? (
                <Cards>
                  <Destination
                    name={event.itinerary.city.name}
                    reopenDestination={reopenDestination}
                    imageUrl={event.itinerary.city.imageUrl}
                    pathUrl={pathUrl}
                  />
                </Cards>
              ) : (
                <Cards bg="#4F4DB8">
                  <FinalizeDetails pathUrl={pathUrl} event={event} guests={guests} />
                </Cards>
              )}

              {/* </Fade> */}
            </>
            <>
              {/* <Fade> */}
              <ShowMoreCard>
                <GuestList guests={guests} pathUrl={pathUrl} />
              </ShowMoreCard>
              {/* </Fade> */}
            </>

            <>
              {/* <Fade> */}
              {!event ? (
                <div>
                  <Skeleton active />
                </div>
              ) : myTasks.length ? (
                <ShowMoreCard>
                  <TaskList guests={guests} myTasks={myTasks} eventId={event.id} pathUrl={pathUrl} />
                </ShowMoreCard>
              ) : (
                <Cards bg="#A9C5F4">
                  <HeaderElement xs={24}>
                    <h5>My Tasks</h5>
                    <Link to={`${pathUrl}/task-lists`}>
                      <RightArrowIcon />
                    </Link>
                  </HeaderElement>
                  <Row align="middle" justify="center">
                    <Col sm={12} align="middle" justify="center">
                      <Title>
                        <h2>YAY! You have no tasks to complete. Cheers to you, Darling!</h2>
                      </Title>
                    </Col>
                    <Col sm={12}>
                      <img style={{ marginTop: 25 }} src={funkyImage} alt="" />
                    </Col>
                  </Row>
                </Cards>
              )}
              {/* </Fade> */}
            </>
            <>
              {/* <Fade> */}
              <Cards bg="#FF8E95">
                <Shop event={event} />
              </Cards>
              {/* </Fade> */}
            </>
          </Wrapper>
        );
      }}
    </EventGuestViewContext.Consumer>
  );
};

EventConsole.defaultProps = {};

EventConsole.propTypes = {};

export default EventConsole;
