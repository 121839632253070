import React from "react";
import styled from "styled-components";
import { Layout, Row, Col, Space, Skeleton } from "antd";
import { InTypography } from "../";
import budgetImage from "../../assets/console/budget.png";
import { Shop } from "../EventConsole/ConsoleComponents";

const { Content } = Layout;

const Main = styled(Layout)`
  background: #fff;
  transition: all 0.2s linear;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

const Wrapper = styled.div`
  margin-top: 25px;
  margin-right: 1em;
	margin-left: 1em;
`;

const Cards = styled.div`
  /* height: 320px; */
  background: ${(props) => (props.bg ? props.bg : "#ffffff")};
  border-radius: 8px;
  margin: 1.25em 0 3.75em 0;
  width: 100%;
`;

const ShowMoreCard = styled(Cards)`
  //min-height: 292px;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  background: #fff;
`;

const Image = styled.img`
${props => props.theme.breakpoints.tablet} {
	height: 265px;
  }
	
`;

const TotalContainer = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
	${props => props.theme.breakpoints.maxTablet} {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
  /* flex-direction: column; */
	div {
    margin-right: 1em;
	}

  h1 {
    color: #ffffff;
    font-family: "Clearface Bold";
    font-style: normal;
    font-size: 2em;
    line-height: 140%;
    padding: 0;
    margin: 0;
  }

  p {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 10px;
    color: #ffffff;
  }
`;

const BudgetCards = styled.div`
  height: 126px;
  background: #ffffff;
  border-radius: 8px;
  margin: 1em 0;
  padding: 20px;
  //displa
  //width: 100%;

  h2 {
    font-family: "Clearface Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 34px;
    padding: 0;
    margin: 0 0 20px 0;
    color: #101b43;
  }
`;

function getTotalBudget(guests) {
	let total = 0;
	guests.forEach((guest) => {
		total +=
			guest.preferences && guest.preferences.totalBudget
				? guest.preferences.totalBudget
				: 0;
	});

	return total;
}

const Budget = ({ guests, event }) => {
	return (
		<Main>
			<Content>
				<Wrapper>
					<InTypography.InH1>Group Budget</InTypography.InH1>
					<Cards bg="#47B0B7">
						<Row>
							<TotalContainer xs={{ span: 24 }} sm={{ span: 8 }}>
								<div>
									<h1>{guests.length ? `$${getTotalBudget(guests)}` : "$0"}</h1>
									<p>group budget</p>
								</div>
								{event && (<div>
									<h1>${event.confirmedGuests && event.confirmedGuests.length && guests.length ? (getTotalBudget(guests) / event.confirmedGuests.length).toFixed(2) : 0}</h1>
									<p>per person</p>
								</div>)}
							</TotalContainer>
							<TotalContainer sm={16}>
								<Image
									style={{ marginTop: "1.8em" }}
									src={budgetImage}
									alt=""
								/>
							</TotalContainer>
						</Row>
					</Cards>
					<InTypography.InH1>Budget Per Person</InTypography.InH1>
					<ShowMoreCard>
						<Row gutter={16}>
							{guests
								? guests.map((guest, index) => {
									return (
										<Col sm={6} key={`BudgetCards_${index}`}>
											<BudgetCards>
												<h2>
													{guest && guest.preferences
														? `$${guest.preferences.totalBudget}`
														: "$0"}
												</h2>
												<Space>
													<Space>{guest.name}</Space>
												</Space>
											</BudgetCards>
										</Col>
									);
								})
								: [1, 2, 3, 4].map((el, ind) => {
									return (
										<Col sm={6} key={ind}>
											<BudgetCards>
												<Skeleton active />
											</BudgetCards>
										</Col>
									);
								})}
						</Row>
					</ShowMoreCard>
					<Cards bg="#FF8E95">
						<Shop event={[]} />
					</Cards>
				</Wrapper>
			</Content>
		</Main>
	);
};

Budget.defaultProps = {};

Budget.propTypes = {};

export default Budget;
