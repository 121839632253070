import React from 'react';
import styled from "styled-components";
import {Menu} from "antd";

const Wrapper = styled.svg`
    fill: #494D5B;
     stroke: #494D5B;
    
    &:hover {
      fill: #2E2C9A;
     stroke: #2E2C9A;
    }
`

export default ({ style }) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.1304 8.04347C13.3464 8.04347 13.5218 7.86816 13.5218 7.65216V2.95651C13.5217 1.87809 12.6444 1 11.5652 1H2.95651C1.87732 1 1 1.87809 1 2.95651V17.0435C1 18.1219 1.87732 19 2.95651 19H11.5652C12.6444 19 13.5217 18.1219 13.5217 17.0435V12.3478C13.5217 12.1318 13.3464 11.9565 13.1304 11.9565C12.9144 11.9565 12.7391 12.1318 12.7391 12.3478V17.0435C12.7391 17.6907 12.2124 18.2174 11.5652 18.2174H2.95651C2.30928 18.2174 1.7826 17.6907 1.7826 17.0435V2.95651C1.7826 2.30928 2.30928 1.7826 2.95651 1.7826H11.5652C12.2124 1.7826 12.7391 2.30928 12.7391 2.95651V7.65216C12.7391 7.86816 12.9144 8.04347 13.1304 8.04347Z" fill="#4F4DB8" stroke="#4F4DB8" stroke-width="0.2"/>
        <path d="M18.6083 9.6084H6.08663C5.87063 9.6084 5.69531 9.78371 5.69531 9.99971C5.69531 10.2157 5.87063 10.391 6.08663 10.391H18.6083C18.8243 10.391 18.9997 10.2157 18.9997 9.99968C18.9997 9.78368 18.8243 9.6084 18.6083 9.6084Z" fill="#4F4DB8" stroke="#4F4DB8" stroke-width="0.2"/>
        <path d="M18.8847 9.724L16.5369 7.37618C16.3843 7.22357 16.1362 7.22357 15.9836 7.37618C15.831 7.52878 15.831 7.77688 15.9836 7.92949L18.0544 10.0003L15.9836 12.0711C15.831 12.2237 15.831 12.4718 15.9836 12.6244C16.0603 12.7003 16.1605 12.7386 16.2606 12.7386C16.3608 12.7386 16.461 12.7003 16.5369 12.6252L18.8847 10.2774C19.0373 10.1247 19.0373 9.87661 18.8847 9.724Z" fill="#4F4DB8" stroke="#4F4DB8" stroke-width="0.2"/>
    </svg>


);
