import { combineReducers } from "redux";
import { reducer as reduxFormReducer } from 'redux-form'

import { firebaseReducer as firebase } from 'react-redux-firebase'
import { firestoreReducer } from "redux-firestore"
import { persistReducer } from 'redux-persist'
import localStorage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import authReducer from "./auth"
import toastReducer from "./toast"
import location from "../../publicIp/reducer"

export default function makeRootReducer() {
  return combineReducers({
    // Add sync reducers here
    firebase: persistReducer(
      { key: 'firebaseState', storage: localStorage, stateReconciler: hardSet },
      firebase
    ),
    firestore: firestoreReducer,
    form: reduxFormReducer,
    auth: authReducer,
    toast: toastReducer,
    location
  })
}