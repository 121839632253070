import React, { Component } from 'react';
import styled from "styled-components"
import banner from './plan-now.png'


const HeaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  position: relative;
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 596px;
  left: 0;
  z-index: 2;
  position: relative;
`;

const Image = styled.div`
    width: 100%;
    height: 100%;
    background: transparent url(${props => props.src}) no-repeat center;
    background-size: cover;
    transform-style: preserve-3d;
    transition: all 0.5s ease;
    border-radius: ${props => props.reverse ? '80px 0;' : '0 80px;'};
    position: absolute;
`;

class CarouselComponent extends Component {
  render() {
    return (
      <HeaderWrapper>
            <ImageWrapper>
              <Image src={banner} />
            </ImageWrapper>
      </HeaderWrapper>
    );
  }
}

export default CarouselComponent
