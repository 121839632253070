import React from 'react';
import styled from "styled-components";
import {Menu} from "antd";

const Wrapper = styled.svg`
    fill: #494D5B;
     stroke: #494D5B;
    
    &:hover {
      fill: #2E2C9A;
     stroke: #2E2C9A;
    }
`

export default ({ style }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M15.5 4.19922V13.1992C15.5 14.0276 14.8284 14.6992 14 14.6992H2C1.17157 14.6992 0.5 14.0276 0.5 13.1992V4.19922C0.5 3.37079 1.17157 2.69922 2 2.69922H4.44444H13.7778H14C14.8284 2.69922 15.5 3.37079 15.5 4.19922Z" />
        <path d="M11.6206 7.38096C11.5022 7.27701 11.3251 7.277 11.2068 7.38095L7.40147 10.7235C7.38682 10.7363 7.3649 10.7363 7.35025 10.7235L5.803 9.36436C5.68465 9.26041 5.50755 9.26041 5.38921 9.36437C5.24708 9.48923 5.24708 9.71057 5.38922 9.83542L7.32618 11.5369C7.3546 11.5618 7.39712 11.5618 7.42554 11.5369L11.6205 7.85201C11.7627 7.72717 11.7627 7.50582 11.6206 7.38096Z" strokeWidth="0.3"/>
        <path d="M9.01488 1.46182L9.01487 1.46178C8.88625 1.01446 8.4678 0.699219 8.00019 0.699219C7.53265 0.699219 7.11415 1.01444 6.98545 1.46184L9.01488 1.46182ZM9.01488 1.46182C9.23998 2.2445 9.84379 2.84833 10.6265 3.0734L9.01488 1.46182ZM11.3891 4.36589V4.08809C11.3891 3.62063 11.0738 3.20209 10.6265 3.07341L11.3891 4.36589ZM11.3891 4.36589H4.61133V4.0778C4.61573 3.61569 4.9308 3.20116 5.37328 3.07363L11.3891 4.36589Z" />
        <path d="M7.95546 3.22146C8.25285 3.22146 8.49393 2.98038 8.49393 2.683C8.49393 2.38561 8.25285 2.14453 7.95546 2.14453C7.65807 2.14453 7.41699 2.38561 7.41699 2.683C7.41699 2.98038 7.65807 3.22146 7.95546 3.22146Z" />
    </svg>
);