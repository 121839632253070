import React from 'react';
import styled from "styled-components";
import {Menu} from "antd";

const Wrapper = styled.svg`
    fill: #494D5B;
     stroke: #494D5B;
    
    &:hover {
      fill: #2E2C9A;
     stroke: #2E2C9A;
    }
`

export default ({ style }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M13.3412 11.7307V7.51343C13.3504 5.52631 12.1287 3.76092 10.3188 3.1454L11.1883 1.33393C11.2431 1.21941 11.237 1.08329 11.1723 0.974682C11.1076 0.865985 10.9935 0.800231 10.8707 0.800785H5.92966C5.80731 0.800785 5.6938 0.866724 5.62931 0.975421C5.56499 1.08412 5.55924 1.2196 5.61383 1.33393L6.48162 3.1454C4.67172 3.76092 3.45005 5.52631 3.45907 7.51343V11.7307L2.62976 12.885C2.36914 13.2478 2.3272 13.7333 2.52148 14.139C2.71576 14.5446 3.11289 14.8008 3.54737 14.8008H13.2531C13.6876 14.8011 14.0851 14.545 14.2795 14.1392C14.4736 13.7334 14.4315 13.2477 14.1706 12.885L13.3412 11.7307ZM10.2997 1.53756L9.59387 3.01131H7.20671L6.50082 1.53756H10.2997ZM13.2531 14.0639H3.54737C3.38025 14.0639 3.22755 13.9655 3.15288 13.8094C3.07821 13.6534 3.09414 13.4666 3.19455 13.3271L4.09446 12.0744C4.1402 12.0108 4.16497 11.9332 4.16497 11.8534V7.51343C4.15656 5.71323 5.34647 4.14824 7.028 3.74808H9.77249C11.4539 4.14824 12.6438 5.71323 12.6354 7.51343V11.8534C12.6354 11.9332 12.6602 12.0108 12.706 12.0744L13.6059 13.3271C13.7064 13.4666 13.7229 13.6535 13.6481 13.8096C13.5732 13.9656 13.4202 14.0643 13.2531 14.0639Z" strokeWidth="0.2"/>
        <path d="M9.00048 8.42582H7.8005C7.46912 8.42582 7.20051 8.1739 7.20051 7.86332C7.20051 7.55275 7.46912 7.30083 7.8005 7.30083H10.0004V6.5509H8.80045V5.80078H8.00043V6.5509H7.8005C7.02725 6.5509 6.40039 7.13858 6.40039 7.86332C6.40039 8.58815 7.02725 9.17575 7.8005 9.17575H9.00048C9.33196 9.17575 9.60047 9.42767 9.60047 9.73824C9.60047 10.049 9.33196 10.3007 9.00048 10.3007H6.80045V11.0509H8.00043V11.8008H8.80045V11.0509H9.00048C9.77373 11.0509 10.4004 10.4633 10.4004 9.73824C10.4004 9.0136 9.77373 8.42582 9.00048 8.42582Z"/>
    </svg>
);
