import React, { Component } from "react";
import { connect } from "react-redux";
import { events } from "../../helpers/firestore";
import { formatStart, formatEnd } from "../../helpers/normalizeDate";

function sortDates(dates) {
  return dates.map((option) => {
    return `${formatStart(option.start.seconds)} - ${formatEnd(option.end.seconds)}`;
  });
}

function sortDestinations(cities) {
  return cities.map((city) => {
    return city.name;
  });
}

function generateTableData(data) {
  return data.map((event, index) => {
    return {
      key: index,
      name: event.name,
      dates: sortDates(event.dates),
      destination: sortDestinations(event.cities),
      confirmedGuests: `${event.confirmedGuests.length}\\${event.guestList.length}`,
      rsvpStarted: event.confirmedGuests.length > 0,
      setDetails: (event.setCity && event.setDates) || (event.itinerary.city.name && event.itinerary.dates.start),
      status: event.status,
      id: event.id,
    };
  });
}

function generateTableDataForInvited(data, userId) {
  return data.map((event, index) => {
    return {
      key: index,
      name: event.name,
      dates: sortDates(event.dates),
      destination: sortDestinations(event.cities),
      rsvp: event.confirmedGuests.includes(userId) ? "GOING" : "NOT GOING",
      status: event.status,
      id: event.id,
    };
  });
}

export const EventsContext = React.createContext();

class EventsProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      drawerVisible: false,
      planned: null,
      canceled: [],
      invited: [],
      past: [],
      showCancel: false,
      cancelEventId: null,
      showReactivate: false,
      showReminder: false,
      reminderEventId: null,
      reactivateEventId: null,
    };
  }

  componentDidMount() {
    const { uid } = this.props;
    this.getData(uid);
  }

  setPlannedEvents = (planned) => {
    const data = generateTableData(planned);
    this.setState({ planned: data });
  };

  setInvitedEvents = (invited, userId) => {
    const data = generateTableDataForInvited(invited, userId);

    this.setState({ invited: data });
  };

  setPastEvents = (past) => {
    const data = generateTableData(past);
    this.setState({ past: data });
  };

  setCanceledEvents = (canceled) => {
    const data = generateTableData(canceled);
    this.setState({ canceled: data });
  };

  getData = (uid) => {
    events.planning(uid)(this.setPlannedEvents);
    events.invitedTo(uid)(this.setInvitedEvents);
    events.canceled(uid)(this.setCanceledEvents);
    events.past(uid)(this.setPastEvents);
  };

  showDrawer = () => {
    this.setState({ drawerVisible: true });
  };

  onClose = () => {
    this.setState({ drawerVisible: false });
  };

  showCancelModal = (id) => {
    this.setState({
      showCancel: true,
      cancelEventId: id,
    });
  };

  hideCancelModal = (id) => {
    this.setState({
      showCancel: false,
    });
  };

  showReactivateModal = (id) => {
    this.setState({
      showReactivate: true,
      reactivateEventId: id,
    });
  };

  hideReactivateModal = (id) => {
    this.setState({
      showReactivate: false,
    });
  };

  showReminderModal = (id) => {
    this.setState({
      showReminder: true,
      reminderEventId: id,
    });
  };

  hideReminderModal = (id) => {
    this.setState({
      showReminder: false,
    });
  };

  render() {
    const {
      drawerVisible,
      planned,
      invited,
      past,
      canceled,
      showCancel,
      cancelEventId,
      showReactivate,
      reactivateEventId,
      reminderEventId,
      showReminder,
    } = this.state;
    const { children, displayName } = this.props;

    const providerData = {
      displayName,
      drawerVisible,
      planned,
      invited,
      past,
      canceled,
      showCancel,
      cancelEventId,
      showReactivate,
      reactivateEventId,
      reminderEventId,
      showReminder,
      showDrawer: this.showDrawer,
      onClose: this.onClose,
      showCancelModal: this.showCancelModal,
      hideCancelModal: this.hideCancelModal,
      showReactivateModal: this.showReactivateModal,
      hideReactivateModal: this.hideReactivateModal,
      showReminderModal: this.showReminderModal,
      hideReminderModal: this.hideReminderModal,
    };

    return <EventsContext.Provider value={providerData}>{children}</EventsContext.Provider>;
  }
}

EventsProvider.defaultProps = {
  // children: PropTypes.object
};

EventsProvider.propTypes = {
  // children: {}
};

function mapStateToProps(state) {
  return {
    uid: state.auth.user.user.uid,
    displayName: state.auth.user.user.displayName,
  };
}

export default connect(mapStateToProps, null)(EventsProvider);
