import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Modal, Space, Button } from "antd";
import { InTypography } from "../../index";
import CancelBtn from "../../../assets/icons/cancelBtn";
import CancelIcon from "../../../assets/icons/cancel";
import { guests } from "../../../helpers/firestore";

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 2em;
`;

const CircleButton = styled.button`
  background: #fafafc;
  border-radius: 50%;
  width: 98px;
  height: 98px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
`;

export const Text = styled.div`
  margin: 32px 0 8px;
`;

export const Label = styled.label`
  color: #101b43;
  font-family: "Proxima Nova Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
`;

export const Buttons = styled.div`
  display: flex;
  margin: 115px 0 16px 0;
`;

export const InButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 210px;
  height: 44px;
  background: ${(props) => props.bg};
  border-radius: 4px;
  font-family: "Proxima Nova Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 80%;
  color: ${(props) => props.color};
`;

export const SubText = styled(InTypography.InParagraph)`
  text-align: center !important;
`;

const GuestReminder = ({ visible, onClose, eventId, guest }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const handleSubmit = () => {
    setLoading(true);
    guests
      .sendGuestReminder(eventId, guest.id, onClose, dispatch)
      .then((response) => (response ? console.log(response) : null)).then(() => setLoading(false));
  };
  return (
    <Modal
      title=""
      visible={visible}
      onCancel={() => {onClose(); setLoading(false)}}
      closeIcon={<CancelBtn style={{ margin: 12 }} />}
      destroyOnClose={true}
      footer={null}
    >
      <Content>
        <CircleButton type="button" onClick={() => onClose()}>
          <CancelIcon />
        </CircleButton>
        <Text>
          <InTypography.InH2>Attending?</InTypography.InH2>
        </Text>
        <SubText>
          Send a reminder to {guest?.name} <br />
        </SubText>
        <Buttons>
          <Space size="large">
            <InButton type="button" bg="#EDEDED" color="#8696AE" onClick={() => onClose()}>
              NO
            </InButton>
            <InButton onClick={() => handleSubmit()} danger type="primary" loading={loading}>
              YES
            </InButton>
          </Space>
        </Buttons>
      </Content>
    </Modal>
  );
};

GuestReminder.defaultProps = {};

GuestReminder.propTypes = {};

export default GuestReminder;
