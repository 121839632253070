import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Switch, Row, Col } from 'antd';
import backIcon from '../../assets/icons/backArrow.svg'
import MySwitch from '../Switch'

const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  
  h1 {
    margin: 0 20px;
    font-family: 'Clearface Bold';
    font-style: normal;
    font-size: 32px;
    line-height: 140%;
    
    display: flex;
    align-items: center;
    
    color: #101B43;
      }
      
  p {
    padding: 0;
    margin: 0 0 0 16px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #101B43;
  }
`

const IconButton = styled.button`
    padding: 0;
    margin: 0;
    outline: none;
    width: 44px;
    height: 44px;
    color: #494D5B;
    background: #FFFFFF;
    border: 1px solid #EEEEEF;
    box-sizing: border-box;
    border-radius: 4px;
`

const Content = styled.div`
    padding: 0;
    margin: 1em 0 0 0;
`
const ModalLayout = ({ children, title, checkboxLabel, handleCancel, toggleCheck, checkValue }) => {
	const desktop = {
		span: 18,
		offset: 6
	}

	const mobile = {
		span: 24,
		offset: 0
	}
	return (
		<Row>
			<Col sm={mobile} md={desktop}>
				<NavWrapper>
					<IconButton type='button' onClick={() => handleCancel()}><img src={backIcon} alt='' /></IconButton>
					<h1>{title}</h1>
					{toggleCheck && <MySwitch size="small" toggleCheck={toggleCheck} checkValue={checkValue} />}
					{checkboxLabel && <p>{checkboxLabel}</p>}
				</NavWrapper>
				<Content>
					{children}
				</Content>
			</Col>
		</Row>
	);
};

ModalLayout.defaultProps = {

};

ModalLayout.propTypes = {

};

export default ModalLayout;

export const ModalSidebarLayout = ({ children, title, checkboxLabel, handleCancel, toggleCheck, checkValue, sidebar, id }) => {
	return (
		<Row>
			<Col xs={24} md={6}>
				{sidebar}
			</Col>
			<Col xs={24} md={18}>
				<NavWrapper>
					<IconButton type='button' onClick={() => handleCancel()}><img src={backIcon} alt='' /></IconButton>
					<h1>{title}</h1>
					{toggleCheck && <MySwitch id={id} toggleCheck={toggleCheck} checkValue={checkValue} />}
					<p>{checkboxLabel}</p>
				</NavWrapper>
				<Content>
					{children}
				</Content>
			</Col>
		</Row>
	);
};