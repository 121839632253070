import React from 'react';
import styled from "styled-components"
import { MaxWidthContainer } from '../../components'
import Dashboard from "./Sections/Dashboard";
import bgLineImage from "./banner.png";
import faqLeft from '../../assets/faq/faq-left-splash.png'
import faqRight from '../../assets/faq/faq-right-splash.png'

export const HeadingOne = styled.h1`
  color: ${props => props.theme.colors.white};
  font-family: 'Clearface Bold';
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 140%;
    margin: 0 1em;
    
    ${props => props.theme.breakpoints.tablet}{
        font-size: 44px;
        line-height: 150%;
        margin: 0 22vw;
    } 
`;

export const ImageBG = styled.div`
    width: 100%;
    height: 539px;
    background: transparent url(${props => props.src}) no-repeat center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    ${props => props.theme.breakpoints.largerDesktop}{
        height: 640px;
    } 
`;

export const LeftSplash = styled.img`
    position: absolute;
    left: 0;
    bottom: -35px;
    width: 125px;
    
    ${props => props.theme.breakpoints.tablet}{
        bottom: -150px;
        width: auto;
    }
`;

export const RightSplash = styled.img`
    position: absolute;
    right: -70px;
    bottom: -275px;
    display: none;
    
    ${props => props.theme.breakpoints.tablet}{
        bottom: -270px;
        right: 0;
        width: auto;
        display: block;
    }
    
`;
const Faq = props => {
  return (
      <div>
          <ImageBG src={bgLineImage}>
              <HeadingOne>
                  Life changing. Champagne Poppin’.
                  The Smartest Bachelorette Suite
                  From Start to Vegas.
              </HeadingOne>
              <LeftSplash src={faqLeft} />
              <RightSplash src={faqRight} />
          </ImageBG>
          <MaxWidthContainer>
              <Dashboard />
          </MaxWidthContainer>
      </div>
  );
};

Faq.defaultProps = {

};

Faq.propTypes = {

};

export default Faq;
