import React from 'react'
import styled from "styled-components";
import { Row, Col } from 'antd';
import { HeartOutlined } from '@ant-design/icons';
import { InTypography } from '../../'

export const FieldWrapper = styled.div`
    //overflow-y: scroll;
    //height: 100vh;
`;

export const Label = styled.label`
    cursor: pointer;
    background: #FAFAFC;
	margin-bottom: 15px;
`;

export const Column = styled(Col)`
	margin-bottom: 15px;
`;

export const Text = styled.div`
   background: #FAFAFC;
   width: 100%;
   height: 75px;
   font-weight: 700;
   font-size: 16px;
   text-transform: capitalize;
   padding: 1em;
   border-radius: 8px;
   
   h4 {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: #101B43;
    display: flex;
    justify-content: space-between;
    
    span {
      color: #2E2C9A;
    }
   }
   
   p {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */
    
    display: flex;
    align-items: center;
    
    /* Text/Midnight Blue */
    
    color: #101B43;
   }
   
   svg {
    color: ${props => props.checked ? '#FF8E95' : ' #8696AE'};
    fill: ${props => props.checked ? '#FF8E95' : ' #8696AE'};
    font-size: 28px;
   }
`;
export const Image = styled.img`
    width: 100%;
`;

export const Wrapper = styled.div`
    width: 100%;
    height: 150px;
    background: transparent url(${props => props.src}) no-repeat center;
    background-size: cover;
    border-radius: 8px 8px 0 0;
`;

export const Input = styled.input`
    width: 0;
    height: 0;
    visibility: hidden;
    position: absolute;
`;

export const Content = styled.div`
    margin: 40px 1em;
	${props => props.theme.breakpoints.maxPhone} {
		margin: 0;
  }
`;

export const TextWrap = styled.div`
    margin: 16px 0;
`;

const HeartSvg = () => (
	<svg width="1.05em" height="1.05em" fill="#FF8E95" viewBox="0 0 1024 1024">
		<path d="M923 283.6c-13.4-31.1-32.6-58.9-56.9-82.8-24.3-23.8-52.5-42.4-84-55.5-32.5-13.5-66.9-20.3-102.4-20.3-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5-24.4 23.9-43.5 51.7-56.9 82.8-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3 0.1-35.3-7-69.6-20.9-101.9z" />
	</svg>
);

export default props => {
	const { input, options } = props
	const onChange = value => () => {
		return input.onChange(value)
	}
	return (
		<Content>
			<TextWrap>
				<InTypography.InH1>{props.label}</InTypography.InH1>
			</TextWrap>
			<TextWrap>
				<InTypography.InParagraph>{props.subLabel}</InTypography.InParagraph>
			</TextWrap>
			<Row gutter={16} style={{ marginTop: 16 }}>
				{options.map((option, index) => {
					const { value, label, image, days } = option;
					return (
						<Column xs={24} md={6} key={index}>
							<Label>
								{image && <Wrapper src={image} />}
								<Text checked={value === input.value.value}>
									<h4>{label} <span>{value}</span></h4>
									{days && <p>{`${days} days`}</p>}
									{value === input.value.value ? <HeartSvg /> : <HeartOutlined />}
								</Text>
								<Input
									type="radio"
									name={input.name}
									checked={value === input.value}
									onChange={onChange(option)} />
							</Label>
						</Column>
					)
				})}
			</Row>
		</Content>
	)
}

