import React from "react";
import styled from "styled-components";
import { Row, Col } from "antd";
import moment from "moment";

const DateBar = styled.div`
  margin: 32px 0;
  height: 53px;
  background: #e9e8f0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 20px;

  h5 {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 120%;
    color: #2e2c9a;
    margin: 0;
    padding: 0;
  }
`;

const TimelineSection = styled.div`
  min-height: 100px;
  background: #ffffff;
  padding: 0.5em 1em;
`;

const Title = styled.h5`
  font-family: "Proxima Nova Semibold";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;
  text-transform: uppercase;
  color: #101b43;
`;

const ActivityTitle = styled.h4`
  font-family: "Proxima Nova Semibold";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;
  text-transform: uppercase;
  color: #101b43;
  margin: 0;
  padding: 0;
`;

const ActivityTime = styled.p`
  font-family: "Proxima Nova";
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #2e2c9a;
`;

const ActivityRow = styled(Row)`
  height: 84px;
  background: #fafafc;
  border-radius: 4px;
  padding: 0.5em;
`;

export const Thumbnail = styled.div`
  width: 52px;
  height: 52px;
  border-radius: 50%;

  background: #ccc url(${(props) => props.src}) no-repeat center;
  background-size: cover;
`;

const Header = styled.h1`
  font-family: "Clearface Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 34px;
  color: #101b43;
`;

export const formatDate = (value) => {
  return moment(value).format("MMM DD YYYY");
};

const TimelinePageTwo = ({ dateList, registeredValues }) => {
  if (Object.keys(registeredValues).length === 0) {
    return (
      <>
        <Header>Need select ACCOMMODATION</Header>
      </>
    );
  }
  return (
    <>
      {Object.entries(registeredValues).map(([day, value], i) => {
        return (
          <div key={i}>
            <DateBar>
              <h5>
                {`Day ${i + 1}`}: {formatDate(dateList[i])}
              </h5>
            </DateBar>
            <Row>
              <Col xs={12}>
                <TimelineSection>
                  <Title>Morning</Title>
                  <ActivityRow align="middle">
                    {value &&
                      value.mornings &&
                      value.mornings.map((item) => {
                        return (
                          <Col xs={24}>
                            <Row align="middle">
                              <Col xs={6}>
                                <Thumbnail />
                              </Col>
                              <Col xs={12}>
                                <ActivityTitle>{item.place}</ActivityTitle>
                                <ActivityTime>
                                  {item.event} {item.time}
                                </ActivityTime>
                              </Col>
                            </Row>
                          </Col>
                        );
                      })}
                  </ActivityRow>
                </TimelineSection>
              </Col>
              <Col xs={12}>
                <TimelineSection>
                  <Title>Afternoon</Title>
                  <ActivityRow align="middle">
                    {value &&
                      value.afternoon &&
                      value.afternoon.map((item) => {
                        return (
                          <Col xs={24}>
                            <Row align="middle">
                              <Col xs={6}>
                                <Thumbnail />
                              </Col>
                              <Col xs={12}>
                                <ActivityTitle>{item.place}</ActivityTitle>
                                <ActivityTime>
                                  {item.event} {item.time}
                                </ActivityTime>
                              </Col>
                            </Row>
                          </Col>
                        );
                      })}
                  </ActivityRow>
                </TimelineSection>
              </Col>
              <Col xs={12}>
                <TimelineSection>
                  <Title>Evening</Title>
                  <ActivityRow align="middle">
                    {value &&
                      value.evening &&
                      value.evening.map((item) => {
                        return (
                          <Col xs={24}>
                            <Row align="middle">
                              <Col xs={6}>
                                <Thumbnail />
                              </Col>
                              <Col xs={12}>
                                <ActivityTitle>{item.place}</ActivityTitle>
                                <ActivityTime>
                                  {item.event} {item.time}
                                </ActivityTime>
                              </Col>
                            </Row>
                          </Col>
                        );
                      })}
                  </ActivityRow>
                </TimelineSection>
              </Col>
            </Row>
          </div>
        );
      })}
      <div></div>
    </>
  );
};

TimelinePageTwo.defaultProps = {};

TimelinePageTwo.propTypes = {};

export default TimelinePageTwo;
