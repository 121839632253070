import React from 'react';
import styled from "styled-components";
import {Menu} from "antd";

const Wrapper = styled.svg`
    fill: #494D5B;
     stroke: #494D5B;
    
    &:hover {
      fill: #2E2C9A;
     stroke: #2E2C9A;
    }
`

export default ({ style }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M8.31339 1.94258C8.35197 1.87636 8.44764 1.87636 8.48622 1.94258L10.4181 5.25935C10.5735 5.52622 10.8339 5.71545 11.1358 5.78081L14.8872 6.59316C14.9621 6.60938 14.9917 6.70037 14.9406 6.75752L12.3831 9.61975C12.1774 9.85004 12.0779 10.1562 12.109 10.4635L12.4956 14.2823C12.5034 14.3586 12.426 14.4148 12.3558 14.3839L8.84338 12.8361C8.56077 12.7115 8.23884 12.7115 7.95623 12.8361L4.44379 14.3839C4.37366 14.4148 4.29625 14.3586 4.30397 14.2823L4.69063 10.4635C4.72174 10.1562 4.62225 9.85004 4.41648 9.61975L4.04364 9.9529L4.41648 9.61975L1.85902 6.75752C1.80796 6.70037 1.83752 6.60938 1.91243 6.59316L5.66383 5.78081C5.96566 5.71545 6.22612 5.52621 6.38155 5.25935L8.31339 1.94258Z" stroke="#4F4DB8"/>
    </svg>
);
