const getUsers = async (setData, request = fetch) => {
  let url;
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    url = `${process.env.REACT_APP_GET_ALL_USERS_DEV}`;
  } else {
    url = `${process.env.REACT_APP_GET_ALL_USERS_PROD}`;
  }

  try {
    const response = await request(url);
    if (response.status === 200) {
      const body = await response;
      const users = await body.json();
      setData(users);
    }
  } catch (error) {
    console.log("errors: ", error);
  }
};

export default {
  getUsers,
};
