const cloudinaryQuizUrl =
  "https://res.cloudinary.com/invigo/image/upload/f_auto/w_640/v1592171931/quiz/";

export const landing = cloudinaryQuizUrl + "landing_sqbaan.png";
export const airbnb =
  "https://res.cloudinary.com/invigo/image/upload/f_auto/w_640/v1592171931/quiz/accomodations/airbnb.jpg";
export const standardhotel =
  "https://res.cloudinary.com/invigo/image/upload/f_auto/w_640/v1592171932/quiz/accomodations/standardhotel.jpg";
export const luxehotel =
  "https://res.cloudinary.com/invigo/image/upload/f_auto/w_640/v1592171936/quiz/accomodations/luxehotel";
export const lowkeyspot =
  "https://res.cloudinary.com/invigo/image/upload/f_auto/w_640/v1592171931/quiz/accomodations/lowkeyspot.jpg";

export const basicBundle =
  "https://res.cloudinary.com/invigo/image/upload/f_auto/w_640/v1592806386/quiz/decor/basic-bundle_xgi9qg";

export const beyondBasics =
  "https://res.cloudinary.com/invigo/image/upload/f_auto/w_640/v1592806386/quiz/decor/beyond-the-basics_x9cjvc";
export const decorGalore =
  "https://res.cloudinary.com/invigo/image/upload/f_auto/w_640/v1592806386/quiz/decor/decor-galore_ghxlgg";
export const aLittleExtra =
  "https://res.cloudinary.com/invigo/image/upload/f_auto/w_640/v1592806386/quiz/decor/add-a-little_xkki5b";

export const winetasting =
  "https://res.cloudinary.com/invigo/image/upload/f_auto/w_640/v1592171934/quiz/activities/winetasting_cqdvxq";
export const tourthecity =
  "https://res.cloudinary.com/invigo/image/upload/f_auto/w_640/v1592171937/quiz/activities/tourthecity_ca3fwj";
export const poolbeach =
  "https://res.cloudinary.com/invigo/image/upload/f_auto/w_640/v1592171931/quiz/activities/poolbeach_akynng";
export const spaday =
  "https://res.cloudinary.com/invigo/image/upload/f_auto/w_640/v1592171930/quiz/activities/spaday_vjmln6";

// transport
export const transportHeader =
  "https://res.cloudinary.com/invigo/image/upload/v1592809706/quiz/transport/transport_ygcfct";
export const publicTransport =
  "https://res.cloudinary.com/invigo/image/upload/f_auto/w_640/v1592809706/quiz/transport/public-transport_tysqsm";
export const rideShare =
  "https://res.cloudinary.com/invigo/image/upload/f_auto/w_640/v1592809706/quiz/transport/ride-share_ad2s1d";
export const limo =
  "https://res.cloudinary.com/invigo/image/upload/f_auto/w_640/v1592809706/quiz/transport/limo_ngq1py";
export const partyBus =
  "https://res.cloudinary.com/invigo/image/upload/f_auto/w_640/v1592809706/quiz/transport/party-bus_ivhhsv";

// alcohol
export const alcoholHeader =
  "https://res.cloudinary.com/invigo/image/upload/v1592811257/quiz/alcohol/header_jkaqxb.png";
export const twoBuckChuck =
  "https://res.cloudinary.com/invigo/image/upload/f_auto/w_640/v1592811256/quiz/alcohol/twoBuckChuck_keyjnm.png";
export const beer =
  "https://res.cloudinary.com/invigo/image/upload/f_auto/w_640/v1592811256/quiz/alcohol/beer_klgfh6.png";
export const champagne =
  "https://res.cloudinary.com/invigo/image/upload/f_auto/w_640/v1592811256/quiz/alcohol/champagne_u8b5nr.png";
export const hardliquor =
  "https://res.cloudinary.com/invigo/image/upload/f_auto/w_640/v1592811256/quiz/alcohol/hard-liquor_kgsgpl.png";

// night activities
export const night_activities =
  "https://res.cloudinary.com/invigo/image/upload/f_auto/w_640/v1592811259/quiz/night_activities/";
export const nightActivityheader = `https://res.cloudinary.com/invigo/image/upload/v1592811259/quiz/night_activities/header_wfd9o6`;
export const gameNight = `${night_activities}game-night_ko7nst`;
export const dinner = `${night_activities}dinner_rrkdim`;
export const bottleService = `${night_activities}bottle-service_ycs7y2`;
export const allAboveNightActivities = `${night_activities}all-of-the-above_ecrqff`;

// dining
export const diningActivities =
  "https://res.cloudinary.com/invigo/image/upload/f_auto/w_640/v1592811257/quiz/dining/";
export const diningHeader = `https://res.cloudinary.com/invigo/image/upload/v1592811257/quiz/dining/header_botpwb.png`;
export const fastfood = `${diningActivities}fastfood_msljvp`;
export const hipcasual = `${diningActivities}hip-casual_dyvn7b`;
export const privateChef = `${diningActivities}private-chef_bnta0w`;
export const finedining = `${diningActivities}fine-dining_vy1brq`;

// paid activities
export const paidActivities =
  "https://res.cloudinary.com/invigo/image/upload/f_auto/w_640/v1592811260/quiz/paid_activities/";
export const paidActivitiesHeader = `https://res.cloudinary.com/invigo/image/upload/v1592811260/quiz/paid_activities/header_c5dt5v.png`;
export const poledancing = `${paidActivities}pole-dancing_ncqljt`;
export const soulcycle = `${paidActivities}cycle_ep6gsw`;
export const rentaboat = `${paidActivities}boat_vsbnad`;
export const vipAccess = `${paidActivities}vip-access_uynff4`;

// groceries
export const groceries =
  "https://res.cloudinary.com/invigo/image/upload/f_auto/w_640/v1592811258/quiz/groceries/";
export const groceriesHeader = `https://res.cloudinary.com/invigo/image/upload/v1592811259/quiz/groceries/header_lrjs9e.png`;
export const coffeeOnly = `${groceries}only-coffee_fwvy3e`;
export const coffeeSnacks = `${groceries}coffee-snacks_cgh9yd`;
export const coffeeSnacks2 = `${groceries}coffee-snacks-breakfast_hgtvxy`;
export const allSnacks = `${groceries}all-above_r5sjkc`;

// City images
export const cancun =
  "https://res.cloudinary.com/pcgwa/image/upload/f_auto/w_640/v1605071300/reveleaze/cities/cancun";
export const palmSprings =
  "https://res.cloudinary.com/pcgwa/image/upload/f_auto/w_640/v1571310462/reveleaze/cities/palm-springs";
export const newYork =
  "https://res.cloudinary.com/pcgwa/image/upload/f_auto/w_640/v1571310461/reveleaze/cities/new-york";
export const sanFran =
  "https://res.cloudinary.com/pcgwa/image/upload/f_auto/w_640/v1571310461/reveleaze/cities/san-fran";
export const nashville =
  "https://res.cloudinary.com/pcgwa/image/upload/f_auto/w_640/v1571310461/reveleaze/cities/nashville";
export const vegas =
  "https://res.cloudinary.com/pcgwa/image/upload/f_auto/w_640/v1571310461/reveleaze/cities/las-vegas";
export const newOrleans =
  "https://res.cloudinary.com/pcgwa/image/upload/f_auto/w_640/v1571310460/reveleaze/cities/new-orleans";
export const miami =
  "https://res.cloudinary.com/pcgwa/image/upload/f_auto/w_640/v1571310460/reveleaze/cities/miami";
export const tulum =
  "https://res.cloudinary.com/pcgwa/image/upload/f_auto/w_640/v1605071655/reveleaze/cities/tulum";
export const austin =
  "https://res.cloudinary.com/pcgwa/image/upload/f_auto/w_640/v1571310460/reveleaze/cities/austin";
export const losAngeles =
  "https://res.cloudinary.com/pcgwa/image/upload/f_auto/w_640/v1571310460/reveleaze/cities/los-angeles-california";
export const charleston =
  "https://res.cloudinary.com/pcgwa/image/upload/f_auto/w_640/v1571310459/reveleaze/cities/charleston";
export const detroit =
  "https://res.cloudinary.com/pcgwa/image/upload/f_auto/w_640/v1571310459/reveleaze/cities/detoit";
export const colorado =
  "https://res.cloudinary.com/pcgwa/image/upload/f_auto/w_640/v1571310459/reveleaze/cities/colorado";
export const scottsdale =
  "https://res.cloudinary.com/pcgwa/image/upload/f_auto/w_640/v1605070615/reveleaze/cities/scottsdale";
export const montreal =
  "https://res.cloudinary.com/pcgwa/image/upload/f_auto/w_640/v1605070814/reveleaze/cities/montreal";
export const caboLucas =
  "https://res.cloudinary.com/pcgwa/image/upload/f_auto/w_640/v1605071044/reveleaze/cities/cabo-lucas";
export const napa =
  "https://res.cloudinary.com/pcgwa/image/upload/f_auto/w_640/v1605071464/reveleaze/cities/napa";
export const lakeTahoe =
  "https://res.cloudinary.com/pcgwa/image/upload/f_auto/w_640/v1605071795/reveleaze/cities/lake-tahoe";
export const portland =
  "https://res.cloudinary.com/pcgwa/image/upload/f_auto/w_640/v1605071953/reveleaze/cities/portland";
