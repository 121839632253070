import React from 'react';
import styled from "styled-components";
import {Menu} from "antd";

const Wrapper = styled.svg`
    fill: #494D5B;
     stroke: #494D5B;
    
    &:hover {
      fill: #2E2C9A;
     stroke: #2E2C9A;
    }
`

export default ({ style }) => (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
        <circle cx="14" cy="14" r="14" fill="#FF8E95"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M21.0069 9.63655C21.3869 10.0058 21.3869 10.6159 21.0069 10.9852L13.455 18.3228C13.0669 18.6999 12.4493 18.6999 12.0613 18.3228L8.9615 15.311C8.58149 14.9417 8.58149 14.3316 8.9615 13.9623C9.32636 13.6078 9.90701 13.6078 10.2719 13.9623L12.7581 16.3781L19.6965 9.63655C20.0613 9.28204 20.642 9.28204 21.0069 9.63655Z" fill="white"/>
    </svg>
);
