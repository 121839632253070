import React, { Component } from 'react';
import { Link } from "react-router-dom";
import styled from "styled-components"
import { HeadingThree } from '../../../../../components/Text'
import OvalDot from '../../../../../assets/faq/OvalDot.svg'

const HeaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 1em;
  
  ${props => props.theme.breakpoints.tablet}{
       margin: 3em 0;
    }
`;

const ImageWrapper = styled.div`
  width: 100%;
  min-height: 100px;
  text-align: left;
  background: #FAFAFC;
  border-radius: 40px 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin: 1em 0;
  padding: 1em 2.25em;
  
  a {
    text-decoration: none;
  }

  @media (min-width: 768px) {
    width: 80%;
    padding: 1em 2.25em;
  }
`;

const Para = styled.h3`
  display: flex;
  align-items: center;
  
  font-family: 'Proxima Nova';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 130%;
color: #386174;

`;

const Text = styled.p`
  display: flex;
  align-items: center;
  
  font-family: 'Proxima Nova';
font-style: normal;
font-size: 16px;
line-height: 130%;
color: #386174;

`;

const Box = styled.img`
  width: 10px;
    height: 10px;
    background: #EC6E5B;
    border-radius: 50%;
    margin-right: 12px;
`;

class CarouselComponent extends Component {
  render() {
    return (
      <HeaderWrapper>
        {this.props.cities.map((city, index) => {
          return (
            <ImageWrapper key={index}>
              <Para>
                <Box src={OvalDot} /> {city.text}
              </Para>
              <Text>
                {city.answer}
              </Text>
            </ImageWrapper>
          )
        })}

      </HeaderWrapper>
    );
  }
}

export default CarouselComponent
