import React from "react";
import styled from "styled-components";
import { Row, Col, Skeleton } from "antd";
import { Link } from "react-router-dom";
import { Tabs } from "antd";
import { GuestListCard } from "../../";
import RightArrowIcon from "../../../assets/icons/rightArrow";
import { COMPLETED_RSVP, PENDING, DECLINED, PENDING_MAYBE } from "../../../helpers/constants";

const { TabPane } = Tabs;

function callback(key) {
  // console.log(key);
}

export const Loader = styled.div`
  padding: 1.25em;
`;

export const Container = styled(Col)`
  padding: 0 1.25em;
  ${(props) => props.theme.breakpoints.maxPhone} {
    padding: 0;
  }
`;

export const Header = styled(Col)`
  height: 60px;
  width: 100%;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25em;

  ${(props) => props.theme.breakpoints.maxPhone} {
    padding: 1.25em 0;
  }

  h5 {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 100%;
    color: #101b43;
    padding: 0;
    margin: 0;
  }

  a {
    svg {
      fill: #494d5b;
    }
  }
`;

export const Bottom = styled(Col)`
  padding: 0 1.25em;
`;

const Wrapper = styled(Tabs)`
  .ant-tabs-nav {
    margin: 0 0 1.5625em 0;
  }
  .ant-tabs-tab {
    color: #8696ae;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 100%;
    margin-bottom: 12px;
    margin-top: 6px;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    color: #2e2c9a;
    font-weight: 600;
  }

  .ant-tabs-ink-bar {
    background: #2e2c9a;
  }

  .ant-tabs-tab:hover {
    color: #2e2c9a;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    ${(props) => props.theme.breakpoints.maxPhone} {
      margin: 0 0 0 23px;
    }
  }
`;

function generateGuests(guests) {
  let allGuests = [];
  let goingGuests = [];
  let pendingGuests = [];
  let notGoingGuests = [];

  if (guests.length) {
    guests.map((guest, index) => {
      if (guest.state === COMPLETED_RSVP) {
        goingGuests.push({
          id: index + 1,
          label: guest.name,
          status: "GOING",
          color: "green",
        });
      }

      if (guest.state === PENDING) {
        pendingGuests.push({
          id: index + 1,
          label: guest.name,
          status: "PENDING",
          color: "orange",
        });
      }

      if (guest.state === PENDING_MAYBE) {
        pendingGuests.push({
          id: index + 1,
          label: guest.name,
          status: "MAYBE",
          color: "orange",
        });
      }

      if (guest.state === DECLINED) {
        notGoingGuests.push({
          id: index + 1,
          label: guest.name,
          status: "NOT GOING",
          color: "red",
        });
      }

      allGuests.push({
        id: index + 1,
        label: guest.name,
        status:
          guest.state === COMPLETED_RSVP
            ? "GOING"
            : guest.state === DECLINED
            ? "NOT GOING"
            : guest.state === PENDING_MAYBE
            ? "MAYBE"
            : "PENDING",
        color: guest.state === COMPLETED_RSVP ? "green" : guest.state === DECLINED ? "red" : "orange",
      });
    });
  }

  return {
    allGuests,
    goingGuests,
    pendingGuests,
    notGoingGuests,
  };
}

const GuestList = ({ guests, pathUrl }) => {
  if (!guests)
    return (
      <Loader>
        <Skeleton active />
      </Loader>
    );
  const allGuests = generateGuests(guests);

  return (
    <Row style={{ height: "100%", position: "relative" }}>
      <Header xs={24}>
        <h5>GUEST LIST</h5>
        <Link to={`${pathUrl}/guest-lists`}>
          <RightArrowIcon />
        </Link>
      </Header>
      <Container xs={24}>
        <Wrapper defaultActiveKey="1" onChange={callback}>
          <TabPane tab="ALL" key="1">
            <GuestListCard items={allGuests.allGuests} />
          </TabPane>
          <TabPane tab="GOING" key="2">
            <GuestListCard items={allGuests.goingGuests} />
          </TabPane>
          <TabPane tab="PENDING" key="3">
            <GuestListCard items={allGuests.pendingGuests} />
          </TabPane>
          <TabPane tab="NOT GOING" key="4">
            <GuestListCard items={allGuests.notGoingGuests} />
          </TabPane>
        </Wrapper>
      </Container>
    </Row>
  );
};

export default GuestList;
