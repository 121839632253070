import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Form } from "antd";
import { CustomModal, CustomModalLayout } from "../../";
import { initialize, reduxForm } from "redux-form";
import { connect } from "react-redux";
import GuestEditFrom from "./GuestList/GuestEditForm";
import { guests as guestsApi } from "../../../helpers/firestore";
const FormItem = Form.Item;

const FormWrapper = styled.form`
  max-width: 670px;
  margin: 6.75em auto 0 auto;

  .ant-form-item-label {
    text-align: left;
  }

  .ant-form-item-label label::after {
    content: "";
  }
`;

const EditGuest = ({
  handleSubmit,
  visible,
  handleOk,
  handleCancel,
  eventId,
  guest,
  formInit,
  guests,
  submitting,
  pristine,
}) => {
  const [loading, setLoader] = useState(false);

  const toggleLoader = () => {
    setLoader(!loading);
  };

  useEffect(() => {
    if (!guest) return;
    formInit({
      name: guest.name,
      email: guest.email,
      rsvp: guest.rsvp,
      mobileNumber: guest.mobileNumber,
      roommates: guest.roommates.map((guest) => guest.id),
    });
  }, [guest, formInit]);

  return (
    <FormWrapper
      onSubmit={handleSubmit((values) => {
        guestsApi.updateGuest(eventId, guest.id, values);
        handleCancel();
      })}
    >
      <FormItem>
        <CustomModal
          visible={visible}
          handleOk={handleOk}
          handleCancel={handleCancel}
        >
          <CustomModalLayout
            handleCancel={handleCancel}
            title="Edit your Guest"
          >
            <GuestEditFrom
              loading={loading}
              guests={guests}
              pristine={pristine}
              submitting={submitting}
              editedGuest={guest}
            />
          </CustomModalLayout>
        </CustomModal>
      </FormItem>
    </FormWrapper>
  );
};

EditGuest.defaultProps = {};

EditGuest.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = "Required";
  }

  if (!values.mobileNumber) {
    errors.mobileNumber = "Required";
  }
  if (!values.email) {
    errors.email = "Required";
  }
  // if (!values.roommates?.length) {
  //   errors.roommates = "Required";
  // }

  return errors;
};

const EditGuestForm = reduxForm({
  form: `editGuest`,
  validate,
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(EditGuest);

export function mapStateToProps(state) {
  return {
    formState: state.form.createEvent,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    formInit: (data) => {
      dispatch(initialize("editGuest", data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditGuestForm);
