import React, {useState} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import GuestAddForm from "./GuestAddForm";

const Wrapper = styled.div`
  max-width: 670px;
`

const GuestList = ({ countryCode, initForm, handleCancel, guestList }) => {
    const inputElement = React.createRef()
    const [guests, setGuests ] = useState([]);
    const [currentItem, setCurrentItem ] = useState({
        name: '',
        description: '',
        key: '',
    });

    const deleteItem = key => {
        const filteredItems = guests.filter((item, index) => {
            return index !== key
        })
        setGuests(filteredItems)
    }

    const handleName = e => {
        const name = e.target.value
        setCurrentItem({
            ...currentItem,
            name: name
        })
    }

    const handleMobileNumber = number => {
        if(number) {
            setCurrentItem({
                ...currentItem,
                mobileNumber: number
            })
        }
    }

    const handleDescription = e => {
        const description = e.target.value
        setCurrentItem({
            ...currentItem,
            description: description
        })
    }

    const addGuest = (newGuest) => {
        if (newGuest !== '') {
            const guestList = [...guests, newGuest]

            initForm({ guestList: guestList });
            setGuests(guestList)
            setCurrentItem({
                name: '',
                description: '',
                key: '',
            })
        }


    }

    const addGuestAndComplete = (newGuest) => {
        if (newGuest !== '') {
            const guestList = [...guests, newGuest]

            initForm({ taskList: newGuest });
            // setGuests(guestList)
            setCurrentItem({
                name: '',
                description: '',
                key: '',
            })
            handleCancel()
        }


    }

  return (
    <Wrapper>
        <GuestAddForm
            addItem={addGuest}
            done={addGuestAndComplete}
            inputElement={inputElement}
            handleName={handleName}
            handleDescription={handleDescription}
            handleMobileNumber={handleMobileNumber}
            currentItem={currentItem}
            countryCode={countryCode}
            disabled={setCurrentItem.name === ''}
            guestList={guestList}
        />
    </Wrapper>
  );
};

GuestList.defaultProps = {

};

GuestList.propTypes = {

};

export default GuestList;
