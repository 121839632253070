import React from 'react';
import styled from "styled-components";
import {Menu} from "antd";

const Wrapper = styled.svg`
    fill: #494D5B;
     stroke: #494D5B;
    
    &:hover {
      fill: #2E2C9A;
     stroke: #2E2C9A;
    }
`

export default ({ style }) => (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="43" height="43" rx="3.5" />
        <path d="M22 31.4613L20.55 30.1413C15.4 25.4713 12 22.3813 12 18.6113C12 15.5213 14.42 13.1113 17.5 13.1113C19.24 13.1113 20.91 13.9213 22 15.1913C23.09 13.9213 24.76 13.1113 26.5 13.1113C29.58 13.1113 32 15.5213 32 18.6113C32 22.3813 28.6 25.4713 23.45 30.1413L22 31.4613Z"/>
    </svg>
);
