import React from 'react'
import styled from 'styled-components'
import { Drawer, Row, Col, Skeleton } from 'antd';
import backIcon from "../../../assets/icons/cancel.svg";

export const Container = styled(Row)`
    padding:  0 1.25em;
`;

export const Header = styled.div`
max-width: 670px;
margin: 0 auto;
`;

export const ColContent = styled(Col)`
  margin: 1.5em 2em;
  
  p {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */
    
    display: flex;
    align-items: center;
    
    /* Text/Midnight Blue */
    
    color: #101B43;
  }
`;

export const Content = styled.div`
          h5 {
            margin: 20px 0;
            padding: 0;
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 100%;
            color: #101B43;
        }
`;


export const HeaderImage = styled.div`
    width: 100%;
    height: 150px;
    background: transparent url(${props => props.src}) no-repeat center;
    background-size: cover;
    border-radius: 8px 8px 0 0;
`;

const IconButton = styled.button`
    padding: 0;
    margin: 0;
    outline: none;
    width: 44px;
    height: 44px;
    color: #494D5B;
    background: #FFFFFF;
    border: 1px solid #EEEEEF;
    box-sizing: border-box;
    border-radius: 4px;
`

const DrawerTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 670px;
    margin: 44px auto 27px auto;
    
    h2 {        
        font-family: 'Clearface Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 34px;
        color: #101B43;
        margin: 0;
        text-transform: capitalize;
    }
`

const PopUpDrawer = styled(Drawer)`
  overflow: hidden;
  .ant-drawer-header {
    border: none;
    padding: 0;
  }
  
  .ant-drawer-body {
    padding: 0;
  }
  .ant-drawer-content {
    background: #ffffff;
        
    margin: 0;
  }
  
  .ant-drawer-content-wrapper {
    background: #ffffff;
  }
`

const PollDrawer = ({ children, event, visible, closeDrawer, title }) => {

	if (!event) return <Container><Skeleton active /></Container>
	return (
		<PopUpDrawer
			title={<Header>
				<DrawerTitle>
					<h2>{title}</h2>
					<IconButton type='button' onClick={() => closeDrawer(null)}>
						<img src={backIcon} alt='' />
					</IconButton>
				</DrawerTitle>
				{/*<p>Choose up to 3 activites per day and let the guests vote</p>*/}
			</Header>}
			placement="bottom"
			closable={false}
			onClose={() => closeDrawer(null)}
			visible={visible}
			getContainer={false}
			height='92%'
			headerStyle={{ background: '#ffffff' }}
			destroyOnClose
		>
			{children}
		</PopUpDrawer>

	);
};

PollDrawer.defaultProps = {

};

PollDrawer.propTypes = {

};

export default PollDrawer;
