import React from 'react';
import { Timeline } from 'antd';
import styled from "styled-components";
import Done from '../icons/timelineDone'
import Incomplete from '../icons/incomplete'
import { EventContext } from '../../EventProvider'

const Wrapper = styled(Timeline)`
  margin: 12px;
`

const Paragraph = styled.p`
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: ${props => props.done ? '#101B43' : '#8696AE'};
    margin: 0 1em;
`

const TimelineWrapper = styled.div`
        position: absolute;
        width: 100%;
        overflow-y: auto;
				${props => props.theme.breakpoints.maxPhone} {
					position: relative;
				}
`

const TimelineItem = styled(Timeline.Item)`
    cursor: pointer;
`

export const TimelineActivity = () => (
	<EventContext.Consumer>
		{({ event, setActiviteTimeline }) => {
			if (!event) return null;
			return (
				<TimelineWrapper>
					<Wrapper>
						<TimelineItem dot={event.timeline.inviteGuests ? <Done /> : <Incomplete />} onClick={() => setActiviteTimeline({ "timeline.inviteGuests": !event.timeline.inviteGuests })}>
							<Paragraph done={event.timeline.inviteGuests}>Invite Guests</Paragraph>
						</TimelineItem>
						<TimelineItem dot={event.timeline.viewRsvp ? <Done /> : <Incomplete />} onClick={() => setActiviteTimeline({ "timeline.viewRsvp": !event.timeline.viewRsvp })} >
							<Paragraph done={event.timeline.viewRsvp}>View RSVPs</Paragraph>
						</TimelineItem>
						<TimelineItem dot={event.timeline.finalize ? <Done /> : <Incomplete />} onClick={() => setActiviteTimeline({ "timeline.finalize": !event.timeline.finalize })}>
							<Paragraph done={event.timeline.finalize}>Finalize Destination and Date</Paragraph>
						</TimelineItem>
						<TimelineItem dot={event.timeline.reviewBudget ? <Done /> : <Incomplete />} onClick={() => setActiviteTimeline({ "timeline.reviewBudget": !event.timeline.reviewBudget })}>
							<Paragraph done={event.timeline.reviewBudget}>Review Total Budget</Paragraph>
						</TimelineItem>
						<TimelineItem dot={event.timeline.createPoll ? <Done /> : <Incomplete />} onClick={() => setActiviteTimeline({ "timeline.createPoll": !event.timeline.createPoll })}>
							<Paragraph done={event.timeline.createPoll}>Create a Poll</Paragraph>
						</TimelineItem>
						<TimelineItem dot={event.timeline.finalizePolls ? <Done /> : <Incomplete />} onClick={() => setActiviteTimeline({ "timeline.finalizePolls": !event.timeline.finalizePolls })}>
							<Paragraph done={event.timeline.finalizePolls}>Finalize Polls</Paragraph>
						</TimelineItem>
						<TimelineItem dot={event.timeline.shopMerch ? <Done /> : <Incomplete />} onClick={() => setActiviteTimeline({ "timeline.shopMerch": !event.timeline.shopMerch })}>
							<Paragraph done={event.timeline.shopMerch}>Shop Party Merch</Paragraph>
						</TimelineItem>
						<TimelineItem dot={event.timeline.viewRecommendations ? <Done /> : <Incomplete />} onClick={() => setActiviteTimeline({ "timeline.viewRecommendations": !event.timeline.viewRecommendations })}>
							<Paragraph done={event.timeline.viewRecommendations}>View Personalized Recommendations</Paragraph>
						</TimelineItem>

						<TimelineItem dot={event.timeline.delegateToDo ? <Done /> : <Incomplete />} onClick={() => setActiviteTimeline({ "timeline.delegateToDo": !event.timeline.delegateToDo })}>
							<Paragraph done={event.timeline.delegateToDo}>Delegate the To-Dos</Paragraph>
						</TimelineItem>
						<TimelineItem dot={event.timeline.completeSchedule ? <Done /> : <Incomplete />} onClick={() => setActiviteTimeline({ "timeline.completeSchedule": !event.timeline.completeSchedule })}>
							<Paragraph done={event.timeline.completeSchedule}>Complete the Schedule</Paragraph>
						</TimelineItem>
						<TimelineItem dot={event.timeline.completeSchedule ? <Done /> : <Incomplete />} onClick={() => setActiviteTimeline({ "timeline.completeSchedule": !event.timeline.completeSchedule })}>
							<Paragraph done={event.timeline.completeSchedule}>Cue the Confetti!</Paragraph>
						</TimelineItem>
					</Wrapper>
				</TimelineWrapper>
			)
		}}
	</EventContext.Consumer>

);