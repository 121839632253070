import React from 'react'
import styled from 'styled-components'
import ShowMore from "@vinspee/react-show-more";
import { Button, Space } from "antd";
import DownArrowIcon from "../../assets/icons/downArrow";

const Wrapper = styled(ShowMore)`

`

const BtnWrapper = styled(Button)`
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 80%;
    margin: 0 0 1em 0;
    border: none;
    
  background: #FAFAFC;
  height: 44px;
  width: 100%;
  border-radius: 8px;
  color: #2E2C9A;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  
  svg {
    margin: 0 1em;
  }
  
  &:hover {
    background: #e1e1e2;
    color: #2E2C9A;
  }
  
  &:focus {
    background: #FAFAFC;
    color: #2E2C9A;
  }
  
  
  
`

const List = styled.ul`
  list-style: none;
  padding: 0;
  
  li {
    height: 64px;
    width: 100%;
    padding: 16px 20px;
    margin: 4px 0;
    background: #FAFAFC;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #101B43; 
  }
`
const State = styled.span`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;
  color: ${props => props.color ? props.color : '#8696AE'}; 
`

const ShowMoreCard = ({ items }) => {
	return (
		<Wrapper
			items={items}
			by={
				3
			}
		>
			{({ current, onMore }) => (
				<div>
					<List>{current.map(item => <li key={item.id}><Space><Space>{item.label}</Space></Space> <State color={item.color}>{item.status}</State></li>)}</List>
					{onMore
						&& <BtnWrapper
							disabled={!onMore}
							onClick={() => {
								if (!!onMore) onMore();
							}}
							text=''
							htmlType='button'
							primary
						>
							SHOW MORE <DownArrowIcon />
						</BtnWrapper>}
				</div>
			)}
		</Wrapper>
	);
};

ShowMoreCard.defaultProps = {

};

ShowMoreCard.propTypes = {

};

export default ShowMoreCard;
