import React from 'react'
import styled from 'styled-components'
import { Col, Row } from "antd";
import { ScheduleSidebar } from "../../components";
import { TimelineForm } from "../../components/Forms";
import { ScheduleContext } from "../../containers/Schedule/ScheduleProvider";
import { timestampToDate } from "../../helpers/normalizeDate";
import moment from "moment";
import { initialize } from "redux-form";
import { connect } from "react-redux";

const Main = styled.div`
  margin: 1.25em 1em 0 0;
	${props => props.theme.breakpoints.maxPhone} {
		margin: 1.25em 1em 1em 1em;
	}

`

const SiderActivityContainer = styled(Col)`
    //margin-top: 25px;
    height: 85vh;
    position: absolute;
    right: 1em;
    overflow-x: hidden;
    overflow-y: auto;
    max-width: 376px;
    ::-webkit-scrollbar {
      display: none;
    }
		${props => props.theme.breakpoints.maxPhone} {
			position: relative;
			height: auto;
			max-width: 100%;
			right: auto;
		}

`
const getDates = (startDate, endDate) => {
	let dates = [],
		currentDate = startDate,
		addDays = function (days) {
			const date = new Date(this.valueOf());
			date.setDate(date.getDate() + days);
			return date;
		};
	while (currentDate <= endDate) {
		dates.push(currentDate);
		currentDate = addDays.call(currentDate, 1);
	}
	return dates;
};

function formatDates(date) {
	const start = date.start.seconds;
	const end = date.end.seconds;

	const startTimestamp = timestampToDate(start);
	const startDateString = moment(startTimestamp);

	const endTimestamp = timestampToDate(end);
	const endDateString = moment(endTimestamp);

	return getDates(startDateString, endDateString);
}

const ScheduleTimeline = ({ formInit, formState, itineraryData }) => {
	const dateList = formatDates(itineraryData.dates);

	function initForm(data) {
		let value = { ...formState.values };
		// if (Object.keys(data)[0] === 'day_1') {
		// 	for (let index = 1; index < dateList.length; index++) {
		// 		value = { ...value, [`day_${index + 1}`]: { ...value[`day_${index + 1}`], accommodation: data.day_1.accommodation } }
		// 	}
		// }
		const key = Object.keys(data)[0];
		for (let index = 0; index < dateList.length; index++) {
			value = { ...value, [`day_${index + 1}`]: { ...value[`day_${index + 1}`], accommodation: data[key].accommodation } }
		}

		formInit({ ...value, ...data, });
	}
	return (
		<ScheduleContext.Consumer>
			{({ event, brides, activeMenu, toggleMenu, polls }) => {

				return (
					<Main>
						<Row gutter={16}>
							<Col xs={{ order: 2, span: 24 }} md={{ order: 1, span: 16 }}>
								<TimelineForm
									formInit={formInit}
									toggleMenu={toggleMenu}
									itineraryData={event.itinerary}
									timeline={event.timeline}
									eventId={event.id}
								/>
							</Col>
							<SiderActivityContainer xs={{ order: 1, span: 24 }} md={{ order: 2, span: 8 }}>
								<ScheduleSidebar
									completeSchedule={event.timeline.completeSchedule}
									itineraryData={event.itinerary}
									brides={brides}
									activeMenu={activeMenu}
									toggleMenu={toggleMenu}
									polls={polls}
									initForm={initForm}
									registeredValues={formState && formState.values ? formState.values : null}
								/>
							</SiderActivityContainer>
						</Row>
					</Main>
				)
			}}
		</ScheduleContext.Consumer>
	);
};

ScheduleTimeline.defaultProps = {

};

ScheduleTimeline.propTypes = {

};

export function mapStateToProps(state) {
	return {
		formState: state.form.itinerary,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		formInit: (data) => {
			dispatch(initialize('itinerary', data))
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleTimeline)