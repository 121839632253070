import React from 'react'
import styled from 'styled-components'
import { Row, Col, Button, Skeleton } from 'antd';
import { Link } from "react-router-dom"
import RatingIcon from '../../../assets/icons/rating'
import { formatStart, timestampToDate } from "../../../helpers/normalizeDate";
import moment from "moment";

export const Container = styled(Row)`
    padding: 1.25em;
`;

export const Header = styled(Col)`
    height: 60px;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05);
    border-radius: 8px 8px 0 0;
    display: flex;
    align-items: center;
    padding: 1.25em;
    
    h5 {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 100%;    
        color: #FFFFFF;
    }
`;

const BtnWrapper = styled(Button)`
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 80%;
    position: absolute;
    bottom: 0;
    margin: 8px 0;
    ${props => props.theme.breakpoints.maxTablet}  {
        position: relative;
      } 
    
  background: ${props => props.primary ? '#4F4DB8' : '#ffffff'};
  border-color: #ffffff;
  height: 44px;
  border-radius: 4px;
  color: ${props => props.primary ? '#ffffff' : '#2E2C9A'};
  text-transform: uppercase;
  
  &:hover {
    background: ${props => props.primary ? '#2E2C9A' : '#ffffff'};
    border-color: #2E2C9A;
    color: ${props => props.primary ? '#ffffff' : '#2E2C9A'};
  }
  
  &:focus {
    background: ${props => props.primary ? '#2E2C9A' : '#ffffff'};
    border-color: #2E2C9A;
    color: ${props => props.primary ? '#ffffff' : '#2E2C9A'};
  }
  
  
`

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
    p {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #FFFFFF;
        margin: 6px 12px 6px 0;
        padding: 0;
        
        svg {
          margin-left: 6px;
        }
    }
`;

export const Bottom = styled(Col)`
    padding: 0 1.25em;
`;

export const LeftContainer = styled(Col)`
    border-left: 1px solid rgba(255, 255, 255, 0.05);
    padding-left: 1em;
    ${props => props.theme.breakpoints.maxTablet}  {
        border-left: none;
        padding-left: 0em;
      } 
`;

function calculateCityVotes(cities) {

	const highestValue = Math.max.apply(Math, cities.map(function (o) {
		return o.votes;
	}))
	return cities.map((city, index) => {
		return (
			<TextContainer key={index}>
				<p>{city.name} &nbsp; - &nbsp; {city.votes === 1 ? `${city.votes} vote` : city.votes > 1 ? `${city.votes} votes` : `${city.votes || 0} votes`} {highestValue === city.votes && highestValue > 0 ? <RatingIcon /> : ''}</p>
			</TextContainer>
		)
	})
}


function formatDates(date) {
	const start = date.start.seconds
	const end = date.end.seconds

	const startTimestamp = timestampToDate(start);
	const startDateString = moment(startTimestamp);

	const endTimestamp = timestampToDate(end);
	const endDateString = moment(endTimestamp);
	const days = endDateString.diff(startDateString, 'days')

	return `${formatStart(start)} - ${formatStart(end)}`

}


function sortDates(dates) {
	if (!dates.length) return

	const votedDates = dates.map((date) => {
		return {
			dates: formatDates(date),
			votes: date && date.voterIds ? date.voterIds.length : 0
		}
	})

	const highestValue = Math.max.apply(Math, votedDates.map(function (o) {
		return o.votes;
	}))

	return votedDates.map((option, index) => {

		return (
			<TextContainer key={index}>
				<p>{option.dates} &nbsp; - &nbsp; {option.votes === 1 ? `${option.votes} vote` : `${option.votes} votes`} {highestValue === option.votes && highestValue > 0 ? <RatingIcon /> : ''}</p>
			</TextContainer>
		)
	})
}

const FinalizeDetails = ({ event, pathUrl }) => {
	if (!event) return <Container><Skeleton active /></Container>
	return (
		<Row style={{ height: '100%' }}>
			<Header xs={24}>
				<h5>FINALIZE EVENT DETAILS</h5>
			</Header>
			<Col xs={24}>
				<Container >
					<Col sm={12} md={12}>
						{calculateCityVotes(event.cities)}
					</Col>
					<LeftContainer sm={12} md={12}>
						{sortDates(event.dates)}
					</LeftContainer>
				</Container>
			</Col>
			<Bottom xs={24}>
				<BtnWrapper
					onClick={() => null}
					text=''
					htmlType='button'
					primary
				>
					<Link to={`${pathUrl}/polls`}>Make a final decision</Link>
				</BtnWrapper>
			</Bottom>
		</Row>
	)
}

export default FinalizeDetails