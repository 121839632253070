import React, { useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { Row, Col, Skeleton } from "antd";
import { formatEnd, formatStart, timestampToDate } from "../../../helpers/normalizeDate";
import HeartSvg, { HeartOutlinedSvg } from "../../../assets/icons/heart";
import RatingIcon from "../../../assets/icons/rating";
import { InButton, PollTimer } from "../../../components";
import { itinerary } from "../../../helpers/firestore";
import Modal from "./datesModal";
import { ActionContainer } from "./destinations";
import { ActionGridContainer } from "./destinations";

export const Container = styled(Row)`
  padding: 0 1.25em;
`;

export const Header = styled(Col)`
  height: 60px;
  @media (max-width: 500px) {
		height: max-content;
	}
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 0 0;
  display: flex;
  @media (max-width: 500px) {
		display: grid;
	}
  align-items: center;
  justify-content: space-between;

  h2 {
    font-family: "Clearface Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 34px;
    color: #101b43;
    padding: 0 16px;
    margin: 0;
    @media (max-width: 500px) {
      margin: 1rem 0;
    }
  }

  button {
    margin-right: 1.25em;
  }
`;

export const CardContent = styled(Row)`
  padding: 1.25em;
`;

export const CityCard = styled.div`
  height: 182px;
  background: #fafafc;
  border-radius: 8px;
  padding: 0 20px;
  h5 {
    padding: 20px 0 0 0;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: #101b43;
  }

  p {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    padding: 0;
    margin: 0;
    color: #101b43;
  }

  span {
    margin: 0;

    svg {
      font-size: 1.5em;
      margin: 0;
      padding: 0;
    }
  }
`;

export const VotesContainer = styled.div`
  margin: 0;
  position: relative;

  svg {
    font-size: 1.8em;
    margin: 0;
    padding: 0;
  }

  span {
    position: absolute;
    width: 28px;
    height: 25px;
    left: 0;
    top: 3px;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    text-align: center;
    color: #ffffff;
  }
`;

export const IconContainer = styled.div`
  margin-top: 1em;
  svg {
    font-size: 1em;
    margin: 0;
    padding: 0;
    width: 20px;
  }
`;

export const SvgContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;

  svg {
    fill: #ff8e95;
    font-size: 1.5em;
  }

  span {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 100%;
    text-transform: uppercase;
    color: #000000;
    margin-right: 6px;
  }
`;

function formatDates(date) {
	const start = date.start.seconds;
	const end = date.end.seconds;

	const startTimestamp = timestampToDate(start);
	const startDateString = moment(startTimestamp);

	const endTimestamp = timestampToDate(end);
	const endDateString = moment(endTimestamp);
	const days = endDateString.diff(startDateString, "days");

	return days > 1 ? `${days} days` : `${days} day`;
}

const PollsPage = ({ event, itineraryData, guests }) => {
	const [visible, setVisibility] = useState(false);

	const toggleModal = () => {
		setVisibility(!visible);
	};

	const finalize = (dates) => {
		const finalizeDetails = itineraryData && itineraryData.city.name;
		itinerary.addDates(event, dates, finalizeDetails, guests, true).then((result) => setVisibility(false));
	};
	if (!event || !itineraryData)
		return (
			<Container>
				<Skeleton active />
			</Container>
		);
	const highestValue = Math.max.apply(
		Math,
		event.dates.map(function (o) {
			return o && o.votes ? o.votes : 0;
		})
	);
	return (
		<div>
			<Row>
				<Header xs={24}>
					<h2>Dates</h2>
          <ActionContainer>
            <h5>POLL CLOSES:</h5>
            <ActionGridContainer>
              <PollTimer closeDate={itineraryData.dates.countdown} />
              <InButton width="170px" type="button" onClick={() => toggleModal()} text="CLOSE VOTING" primary />
            </ActionGridContainer>
          </ActionContainer>
				</Header>
			</Row>
			<CardContent gutter={16}>
				{event.dates.map((eventDate, index) => {
					return (
						<Col sm={8} key={index}>
							<CityCard>
								<h5>
									{`${formatStart(eventDate.start.seconds)} - ${formatStart(eventDate.end.seconds)}`}
									<span style={{ marginLeft: 12 }}>
										{highestValue === eventDate.votes && highestValue > 0 ? <RatingIcon /> : ""}
									</span>
								</h5>
								{/* <span>{highestValue === city.votes &&  highestValue > 0 ? <RatingIcon /> : ''}</span> */}
								<p>{formatDates(eventDate)}</p>
								<SvgContainer>
									<HeartSvg />
									<span>
										{eventDate && eventDate.votes ? eventDate.votes : 0}{" "}
										{eventDate && eventDate.votes && eventDate.votes === 1 ? "vote" : "votes"}
									</span>
								</SvgContainer>
							</CityCard>
						</Col>
					);
				})}
			</CardContent>
			<Modal visible={visible} toggleModal={toggleModal} event={event} finalize={finalize} />
		</div>
	);
};

PollsPage.defaultProps = {};

PollsPage.propTypes = {};

export default PollsPage;
