import React, { useState } from "react";
import styled from "styled-components";
import Fade from "react-reveal/Zoom";
import { Avatar, Button, Space, Checkbox, Menu, Dropdown } from "antd";
import {
  DeleteOutlined,
  EllipsisOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import CreateTaskForm from "../../Forms/CreateTask";
import { TaskListsContext } from "../../../containers/TaskLists/TaskListsProvider";
import EditIcon from "../../../assets/icons/edit";
import { tasks } from "../../../helpers/firestore";
import EditSingleTask from "../EditSingleTask";
import { formatStart } from "../../../helpers/normalizeDate";
import useGuests from "../../../hooks/useGuests";

const TableWrapper = styled.div``;

const Table = styled.div`
  background: ${(props) => (props.header ? "none" : "#ffffff")};
  border-radius: 8px;
  margin: 0.5em 0;
  padding: 0;

  ${(props) =>
    props.header ? "" : "box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);"};

  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 64px;
  }

  @media all and (max-width: 1023px) {
    display: ${(props) => (props.header ? "none" : "block")};
  }
  .table-cell {
    text-decoration: ${(props) => (props.completed ? "line-through" : "none")};
  }
`;

const TableCell = styled.div`
  width: ${(props) => (props.width ? props.width : "11.42%")};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  padding: 0 1em;

  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #101b43;
  text-align: left;

  @media all and (max-width: 1023px) {
    width: 100%;
    padding: 0.5em 1em;
  }
`;

const RowHead = styled.div`
  width: 3%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-grow: 1;
  height: 100%;
  padding: 0.5em 1em;

  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  /* Text/Endeavour */

  color: #2e2c9a;

  @media all and (max-width: 1023px) {
    width: 100%;
    border-radius: 8px 8px 0 0;
  }

  @media (min-width: 1024px) {
    border-radius: 0 8px 8px 0;
  }
`;

const RowFooter = styled.div`
  width: 5%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-grow: 1;
  height: 100%;
  padding: 0.5em 1em;

  @media all and (max-width: 1023px) {
    width: 100%;
  }

  @media (min-width: 1024px) {
    border-radius: 0 8px 8px 0;
  }
`;

const ActionMenu = styled(Menu)`
  width: 160px;
`;

const ActionItem = styled(Menu.Item)`
  width: 160px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #101b43;

  &:hover {
    color: ${(props) => props.color};
  }
`;

const deleteItem = (item) => {
  tasks.deleteTask(item);
};

const MenuAction = ({ item, showModalEdit, handleMenuClick }) => {
  return (
    <ActionMenu onClick={handleMenuClick}>
      <ActionItem
        color="#2E2C9A"
        icon={<EditIcon style={{ marginRight: 6 }} />}
        onClick={() => showModalEdit(item)}
      >
        Edit
      </ActionItem>
      <ActionItem
        color="#EE4238"
        icon={<DeleteOutlined />}
        onClick={() => deleteItem(item)}
      >
        Delete
      </ActionItem>
    </ActionMenu>
  );
};

const DropMenu = styled(Dropdown)`
  cursor: pointer;
`;

const CreateActions = ({ data, showModalEdit }) => {
  const isGuest = useGuests();
  const [visible, setVisible] = useState(false);
  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };
  const handleMenuClick = () => setVisible(false);
  if (isGuest) {
    return null;
  }
  return (
    <Space size="middle">
      <DropMenu
        overlay={
          <MenuAction
            item={data}
            showModalEdit={showModalEdit}
            handleMenuClick={handleMenuClick}
          />
        }
        placement="bottomRight"
        onVisibleChange={handleVisibleChange}
        visible={visible}
      >
        <EllipsisOutlined style={{ fontSize: 28 }} />
      </DropMenu>
    </Space>
  );
};

const HeaderCell = styled.div`
  width: ${(props) => (props.width ? props.width : "11.42%")};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  padding: 0 1em;

  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;

  color: #8696ae;

  @media all and (max-width: 1023px) {
    width: 100%;
  }
`;

const RowStart = styled.div`
  width: 3%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-grow: 1;
  height: 100%;
  padding: 0.8em 1.2em;

  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;

  color: #2e2c9a;

  @media all and (max-width: 1023px) {
    width: 100%;
    border-radius: 8px 8px 0 0;
  }

  @media (min-width: 1024px) {
    border-radius: 0 8px 8px 0;
  }
`;

const HeaderFooter = styled.div`
  width: 5%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-grow: 1;
  height: 100%;
  padding: 0.8em 1.2em;

  @media all and (max-width: 1023px) {
    width: 100%;
  }

  @media (min-width: 1024px) {
    border-radius: 0 8px 8px 0;
  }
`;

const IconButton = styled(Button)`
  background: ${(props) => (props.primary ? "#4F4DB8" : "#ffffff")};
  border-color: #4f4db8;
  height: 44px;
  border-radius: 4px;
  color: ${(props) => (props.primary ? "#ffffff" : "#2E2C9A")};
  text-transform: uppercase;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 80%;
  display: flex;
  align-items: center;

  &:hover {
    background: ${(props) => (props.primary ? "#2E2C9A" : "#ffffff")};
    border-color: #2e2c9a;
    color: ${(props) => (props.primary ? "#ffffff" : "#2E2C9A")};
  }

  &:focus {
    background: ${(props) => (props.primary ? "#2E2C9A" : "#ffffff")};
    border-color: #2e2c9a;
    color: ${(props) => (props.primary ? "#ffffff" : "#2E2C9A")};
  }
`;

const CheckboxButton = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #4f4db8;
    border-color: #4f4db8;
  }
`;

const CheckItem = ({ item }) => {
  const newItem = {
    ...item,
    assignTo: item.assignTo.map((guest) => ({ id: guest.id })),
  };
  const handleChange = (input) => {
    if (item.completed) {
      tasks.updateTaskChecked(newItem, false);
    } else {
      tasks.updateTaskChecked(newItem, true);
    }
  };

  return (
    <CheckboxButton
      name={item.name}
      size="large"
      onChange={(e) => handleChange(e)}
      checked={item.completed}
    />
  );
};

const renderRows = (tableData, showModalEdit) => {
  return tableData.reverse().map((data, index) => {
    return (
      <Table key={index} completed={data.completed}>
        <RowHead>
          <CheckItem item={data} />
        </RowHead>
        <TableCell width="25%" className="table-cell">
          {data.name}
        </TableCell>
        <TableCell width="15%" className="table-cell">
          {data.due === "" ? "" : formatStart(data.due)}
        </TableCell>
        <TableCell width="22%" className="table-cell">
          <Space>
            <Space>
              {data.assignTo.map((person, index) => (
                <span key={index}>{person.name}</span>
              ))}
            </Space>
          </Space>
        </TableCell>
        <TableCell width="30%" className="table-cell">
          {data.description}
        </TableCell>
        <RowFooter>
          <CreateActions data={data} showModalEdit={showModalEdit} />
        </RowFooter>
      </Table>
    );
  });
};

const SingleTaskList = ({ tableData, activeList, showAdd, handleCancel }) => {
  const [showEdit, setEditTask] = useState(false);
  const [singleTask, setSingleTask] = useState(null);

  const handleCancelEdit = () => {
    setEditTask(false);
  };

  const showModalEdit = (item) => {
    setSingleTask(item);
    setEditTask(true);
  };

  return (
    <TaskListsContext.Consumer>
      {({ guests, createTask }) => {
        return (
          <TableWrapper>
            <Table header>
              <RowStart />
              <HeaderCell width="25%">TASK</HeaderCell>
              <HeaderCell width="15%">DUE</HeaderCell>
              <HeaderCell width="22%">ASSIGNEE</HeaderCell>
              <HeaderCell width="30%">DESCRIPTION</HeaderCell>
              <HeaderFooter />
            </Table>
            {showAdd && (
              <CreateTaskForm
                activeList={activeList}
                guests={guests}
                createTask={createTask}
                handleCancel={handleCancel}
              />
            )}
            {renderRows(tableData, showModalEdit)}

            <EditSingleTask
              visible={showEdit}
              onClose={handleCancelEdit}
              guests={guests}
              activeList={activeList}
              singleTask={singleTask}
            />
          </TableWrapper>
        );
      }}
    </TaskListsContext.Consumer>
  );
};

SingleTaskList.defaultProps = {};

SingleTaskList.propTypes = {};

export default SingleTaskList;
