import React, { Component } from 'react';
import styled from "styled-components"
import dashboard from '../../../../../assets/home/Budget-Dashboard.png'

const HeaderWrapper = styled.div`
  width: 100%;
  height: auto;
  border: 8px solid #FAFAFC;
  box-sizing: border-box;
  border-radius: 12px;

    ${props => props.theme.breakpoints.tablet}{
      width: 100%;
      height: 356px;
    }

`;

const Image = styled.img`
    width: auto;

    ${props => props.theme.breakpoints.maxTablet}{
      width: 100%;
    }
`;

class CarouselComponent extends Component {
  render() {
    return (
      <HeaderWrapper>
          <Image src={dashboard} alt=""/>
      </HeaderWrapper>
    );
  }
}

export default CarouselComponent
