import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { LoginRsvp } from "../../components";
import { logoutUser } from "../../helpers/auth";
import { Redirect } from "react-router-dom";

import { guests, events } from "../../helpers/firestore";
import cityImage from "../../assets/quiz/cities.png";
import datesImage from "../../assets/quiz/dates.png";
import guestListImage from "../../assets/quiz/guests.png";
import { timestampToDate, formatStart } from "../../helpers/normalizeDate";

import rsvpSteps from "./Steps";

export const RsvpContext = React.createContext();

function formatValues(data) {
	return data.map((data) => {
		return {
			value: data.name,
			label: data.name,
			image: data.imageUrl,
		};
	});
}

function formatCity(data) {
	return {
		value: data.name,
		label: data.name,
		image: data.imageUrl,
	};
}

function formatCities(cities) {
	return cities.map((city) => {
		return { value: city.name, label: city.name, image: city.imageUrl };
	});
}

function formatDates(dates) {
	return dates.map((date) => {
		const start = date.start.seconds;
		const end = date.end.seconds;

		const startTimestamp = timestampToDate(start);
		const startDateString = moment(startTimestamp);

		const endTimestamp = timestampToDate(end);
		const endDateString = moment(endTimestamp);
		const days = endDateString.diff(startDateString, "days");

		return {
			value: `${formatStart(start)} - ${formatStart(end)}`,
			label: `${formatStart(start)} - ${formatStart(end)}`,
			days: days,
		};
	});
}

function formatDateRange(dates) {
	const start = dates.start.seconds;
	const end = dates.end.seconds;

	const startTimestamp = timestampToDate(start);
	const startDateString = moment(startTimestamp);

	const endTimestamp = timestampToDate(end);
	const endDateString = moment(endTimestamp);
	const days = endDateString.diff(startDateString, "days");

	return {
		value: `${formatStart(start)} - ${formatStart(end)}`,
		label: `${formatStart(start)} - ${formatStart(end)}`,
		days: days,
	};
}

function sortEventData(steps, eventData, guestList, userId) {
	console.log(userId);
	return steps.map((step) => {
		// if(step.name === 'city'){
		//   const cities = formatValues(eventData.cities)
		//   step = {
		//     name: 'city',
		//     title: 'City',
		//     sections: [
		//       {
		//         name: 'city',
		//         caption: null,
		//         image: cityImage,
		//         layout: {
		//           sub: 24,
		//           main: 24
		//         },
		//         fields: [
		//           {
		//             name: 'city',
		//             image: cityImage,
		//             component: 'radioImagesGroup',
		//             caption: 'Which city should we party in?',
		//             subCaption: `Select up to ${cities.length} destinations where the event should be`,
		//             options: [ ...cities, {
		//               value: `I am unable to attend.`,
		//               label: `I am unable to attend.`,
		//             } ],
		//           },
		//         ]
		//       }
		//     ]
		//   }
		// }

		// if(step.name === 'dates'){
		//   const dates = formatDates(eventData.dates)
		//   step = {
		//     name: 'dates',
		//     title: 'Dates',
		//     sections: [
		//       {
		//         name: 'dates',
		//         caption: null,
		//         image: datesImage,
		//         layout: {
		//           sub: 24,
		//           main: 24
		//         },
		//         fields: [
		//           {
		//             name: 'dates',
		//             component: 'radioImagesGroup',
		//             caption: 'What dates are you available to celebrate?',
		//             subCaption: 'Select ALL available dates you are able to attend',
		//             options: [ ...dates, {
		//               value: `I am unable to attend.`,
		//               label: `I am unable to attend.`,
		//             }],
		//           },
		//         ]
		//       }
		//     ]
		//   }
		// }

		if (step.name === "submit") {
			step = {
				name: "submit",
				title: "Submit",
				birdieList: eventData.bride.map((details) => details.fullname),
				sections: [
					{
						name: "submit",
						caption: "Submit Information",
						justify: "start",
						fields: [],
					},
				],
			};
		}

		if (step.name === "destination") {
			const filteredGuests = guestList.filter((guest) => userId !== guest.id);

			const guestsArray = filteredGuests.filter(guest => guest && guest.state !== "declined").map((guest) => ({
				value: guest.id,
				label: guest.name,
				id: guest.id
			}));
			console.log(guestsArray);
			step = {
				name: "destination",
				title: "Alcohol & Age",
				sections: [
					{
						name: "destination",
						caption: "Destination",
						image: guestListImage,
						justify: "start",
						layout: {
							sub: 8,
							main: 16,
						},
						fields: [
							{
								name: "travellingFrom",
								placeholder: "Search City",

								component: "inputSearch",
								caption: "Where will you be traveling from?",
								validationRule: "^(?!\\s*$).+",
							},
							{
								name: "roommate",
								placeholder: "Select",
								component: "select",
								caption: "Who do you want to room with?",
								subCaption: "Select all that apply",
								options: guestsArray,
								validationRule: "^(?!\\s*$).+",
							},
							{
								name: "allergies",
								placeholder: "gluten-free, allergic to dairy, vegan etc.",
								component: "textarea",
								caption: "Specify any food allergies?",
								rows: 2,
								// validationRule: "^(?!\\s*$).+",
							},
							{
								name: "paymentMethod",
								placeholder: "Select",
								component: "selectAlt",
								caption: "Payment",
								subCaption: "What is your prefered payment method?",
								options: [
									{ label: "Venmo", value: "Venmo" },
									{ label: "Zelle", value: "Zelle" },
									{ label: "Cash App", value: "Cash App" },
									{ label: "Paypal", value: "Paypal" },
								],
								validationRule: "^(?!\\s*$).+",
							},
							{
								name: "paymentUsername",
								placeholder: "Username",
								component: "input",
								caption: "What is your username?",
								validationRule: "^(?!\\s*$).+",
							},
						],
					},
				],
			};
		}

		return step;
	});
}
class RsvpProvider extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: null,
			userId: null,
			eventId: null,
			page: 0,
			loading: true,
			isBride: false,
			currentStepIndex: 0,
			done: false,
			guests: [],
			eventSteps: [],
			event: null,
			progress: 5,
			isSetDetails: false,
			guestDetails: null,
			redirect: false
		};

		this.getData = this.getData.bind(this);
	}

	componentDidMount() {
		if (this.props.isAuthenticated && this.props.userId !== this.props.userUid) {
			logoutUser(this.props.dispatch);
		}

		if (!this.state.event && this.props.isAuthenticated && this.props.userId === this.props.userUid) {
			this.getData();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.isAuthenticated && this.props.userId !== this.props.userUid) {
			console.log('redirect')
			this.setState({ redirect: true })
		}
		if (!this.state.event && this.props.isAuthenticated && this.props.userId === this.props.userUid) {
			this.getData();
		}
	}

	setEventData = (data) => {
		this.setState({ data });
	};

	setGuests = (guests) => {
		this.setState({ guests });
	};

	checkBride = (brides, userId, isSet, event, guestDetails) => {
		let isBride = false;
		const guestDetailsIsSet = guestDetails && guestDetails.detailsSet;
		brides.forEach((bride) => {
			if (bride.uid === userId) {
				isBride = true;
			}
		});

		const cities = event.itinerary.city.name ? formatCity(event.itinerary.city) : formatCities(event.cities);
		// const dates = formatDates(event.dates)
		console.log(event)
		const dates =
			event.itinerary.dates && event.itinerary.dates.start
				? formatDateRange(event.itinerary.dates)
				: formatDates(event.dates);
		// console.log("dates:: ", isBride && (isSet || guestDetailsIsSet));
		// console.log(cities);
		return {
			isBride,
			data:
				isBride && (isSet || guestDetailsIsSet)
					? rsvpSteps.brideStepsSetDetails(cities, dates, event)
					: isBride && !isSet
						? rsvpSteps.brideStepsUnsetDetails(cities, dates)
						: !isBride && (isSet || guestDetailsIsSet)
							? rsvpSteps.guestStepsSetDetails(cities, dates, event)
							: rsvpSteps.guestStepsUnsetDetails(cities, dates),
		};
	};

	getData = async () => {
		const { eventId, userId, history } = this.props;

		const event = await events.getEvent(eventId);
		const isOnGuestList = event.guestList.includes(userId);

		this.setState({ event });

		if (isOnGuestList) {
			const guestList = await guests.get(eventId, this.setGuests);
			const guestDetails = guestList.filter((guest) => guest.id === userId);
			const isSet = event.setDates && event.setCity;
			const eventData = this.checkBride(event.brides, userId, isSet, event, guestDetails[0]);

			// console.log("eventData:: ", eventData);
			if (eventData && this.state.loading) {
				const sortedData = sortEventData(eventData.data.steps, event, guestList, userId);
				this.setState({
					isSetDetails: isSet || (guestDetails.length && guestDetails[0].detailsSet),
					isBride: eventData.isBride,
					data: event,
					userId: userId,
					eventId: eventId,
					eventSteps: sortedData,
					loading: false,
					guestDetails: guestDetails[0],
				});
			}
		}
	};

	goToStep = (stepIndex) => {
		const { steps } = this.props;
		if (stepIndex < steps.length) {
			this.setState({ currentStepIndex: stepIndex });
		} else {
		}
	};

	calcProgress = (progress) => {
		this.setState({ progress });
	};

	render() {
		const {
			loading,
			data,
			userId,
			eventId,
			isBride,
			currentStepIndex,
			done,
			guests,
			eventSteps,
			progress,
			isSetDetails,
			guestDetails,
			redirect
		} = this.state;
		const { isAuthenticated, children, history, eventParams } = this.props;



		const providerData = {
			isAuthenticated,
			history,
			loading,
			data,
			userId,
			eventId,
			isBride,
			currentStepIndex,
			done,
			guests,
			progress,
			isSetDetails,
			steps: eventSteps,
			guestDetails,
			goToStep: this.goToStep,
			calcProgress: this.calcProgress,
		};



		if (redirect) {
			return <Redirect to={{ pathname: '/events' }} />
		}
		return (
			<RsvpContext.Provider value={providerData}>
				{children}
				<LoginRsvp eventParams={eventParams} isAuthenticated={isAuthenticated} />
			</RsvpContext.Provider>
		);
	}
}

RsvpProvider.defaultProps = {
	// <!--  children: PropTypes.object-->
};

RsvpProvider.propTypes = {
	// <!--  children: {}-->
};

function mapStateToProps(state) {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		userUid: state?.auth?.user?.user?.uid,
	};
}

export default connect(mapStateToProps, null)(RsvpProvider);
