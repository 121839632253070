import React, { Component } from 'react';
import styled from "styled-components"
import checkMark from './Check.svg'
import { SectionHeading, SectionHeadingUnderLine, HeadingFour, SectionLongUnderLine } from '../../../../../components/Text'
import {Button} from "antd";
import longline from '../../../../../assets/home/blue-vector.png'
import longLine from '../../../../../assets/home/long-line.png'

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  margin-top: 2em;
  
  ${props => props.theme.breakpoints.tablet}{
       margin-top: 1em;
  }
`;


const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  text-align: left;
`;

const ParagraphWrapper = styled.div`
    width: 100%;
  margin-bottom: 16px;
  text-align: left;
  
  ul {
    list-style: none;
    margin: 16px 0 0 0;
    padding: 0;
    
    li {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 1em 0;
      
      h4 {
        margin: 0;
      }
      
      p {
        margin: 0;
      }
      img {
        margin-right: 10px;
      }
    }
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 14px;
`;

const NoBorderBtn = styled(Button)`
  background: ${props => props.primary ? '#EC6E5B' : '#ffffff'};
  border-color: #EC6E5B;
  width: ${props => props.width ? props.width : '172px'};
  height: 44px;
  border-radius: 4px;
  color: ${props => props.primary ? '#ffffff' : '#EC6E5B'};
  text-transform: uppercase;
  
  &:hover {
    background: ${props => props.primary ? '#EC6E5B' : '#ffffff'};
    border-color: #EC6E5B;
    color: ${props => props.primary ? '#ffffff' : '#EC6E5B'};
  }
  
  &:focus {
    background: ${props => props.primary ? '#EC6E5B' : '#ffffff'};
    border-color: #EC6E5B;
    color: ${props => props.primary ? '#ffffff' : '#EC6E5B'};
  }

`
const Underline = styled.span`
  color: #101B43;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 130%;
  text-align: left;
  margin: 0;
  padding: 20px 0;
  background-image: ${ props => props.line ? `url(${props.line })` : `url(${props.line })` };
  background-repeat: repeat-x;
  background-position: -5px 44px;
`;

class CarouselComponent extends Component {
  render() {
    return (
        <Container>
          <TitleWrapper>
            <SectionHeading align='left' color='#fff' >
              No More <Underline line={longline}>Awkward Budget Conversations</Underline>
            </SectionHeading>
          </TitleWrapper>
          <ParagraphWrapper>
            <HeadingFour align='left'>It’s all sparklers and confetti pops until someone won’t pay their share.
            </HeadingFour>
            <ul>
              <li>
                <img src={checkMark} alt=""/>
                <HeadingFour align='left'>
                  Collect guests’ budgets anonymously via a quick quiz
                </HeadingFour>
              </li>
              <li>
                <img src={checkMark} alt=""/>
                <HeadingFour align='left'>
                  Tally up on the go, with Invigo's Splitwise integration
                </HeadingFour>
              </li>
              <li>
                <img src={checkMark} alt=""/>
                <HeadingFour align='left'>
                  Don’t worry about penny pinching, focus on the fun
                </HeadingFour>
              </li>
              <li>
                <ButtonContainer>
                  <NoBorderBtn primary>Sign Up Now</NoBorderBtn>
                </ButtonContainer>
              </li>
            </ul>
          </ParagraphWrapper>
        </Container>
    );
  }
}

export default CarouselComponent
