// /* global google */
import React, { useState, useRef } from "react";
import styled from "styled-components";
import Geosuggest from "react-geosuggest";
import { getProxyUrl } from "../../../helpers/proxy";

export const GeoSuggestion = styled(Geosuggest)`
  width: 100%;
  input {
    border: 1px solid #eeeeef;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    outline: none;
    padding: 10px 12px;
    width: 100%;
    -webkit-appearance: none;
    :focus {
      border: 1px solid #eeeeef;
    }
  }

  .geosuggest__suggests-wrapper {
    ${(props) => (props.dropDownOpen ? "display: block;" : "display: none;")}
    ul {
      margin: 0;
      padding: 0;
      position: absolute;
      background: #ffffff;

      border: 1px solid #eeeeef;
      box-sizing: border-box;
      border-radius: 4px;

      max-width: 670px;
      width: 100%;
      list-style: none;
      z-index: 1;
    }
  }

  li {
    padding: 10px 12px;
    margin: 0;
    border-bottom: 1px solid #ededed;
  }

  li:hover {
    cursor: pointer;
    background: #4f4db8;
    color: white;
  }
`;

function handleOnChange(value, open, close) {
  if (value) return open(true);
  close(false);
}

export const SelectLocation = (props) => {
  const textInput = useRef();
  const [open, setOpen] = useState(false);
  const { onSelect, placeholder } = props;

  const getUrl = async (placeId) => {
    const proxyUrl = getProxyUrl();

    const placesRequestUrl = `${proxyUrl}https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&fields=photo&key=${process.env.REACT_APP_PLACE_API_KEY}`;

    const initialPlacesRequest = await fetch(proxyUrl + placesRequestUrl)
      .then((res) => res.json())
      .catch(console.error);

    const photoRef = initialPlacesRequest?.result?.photos?.[0]?.photo_reference;

    const url = `https://maps.googleapis.com/maps/api/place/photo?photoreference=${photoRef}&key=${process.env.REACT_APP_PLACE_API_KEY}&maxwidth=1200&maxheight=1200`;

    return url;
  };
  return (
    <GeoSuggestion
      ref={textInput}
      placeholder={placeholder}
      types={["(cities)"]}
      onSuggestSelect={async (suggest) => {
        if (suggest === undefined) return;
        if (suggest && suggest.description) {
          setOpen(false);

          onSelect(suggest.description, await getUrl(suggest.placeId));
        }
        textInput.current.clear();
      }}
      autoComplete="on"
      dropDownOpen={open}
      onChange={(value) => handleOnChange(value, setOpen, setOpen)}
    />
  );
};
