import React from 'react'
import styled from 'styled-components'
import { Row, Col, Button, Skeleton } from 'antd';
import RightArrowIcon from "../../../assets/icons/rightArrow";
import BudgetIcon from "../../../assets/icons/budget";
import budgetImage from "../../../assets/console/budget.png";
import { Link } from "react-router-dom";

export const Container = styled(Row)`
    padding: 1.25em;
`;


export const Header = styled(Col)`
    height: 60px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
    border-radius: 8px 8px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.25em;
    text-transform: uppercase;
    
    h5 {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 100%;    
        color: #ffffff;
        padding: 0;
        margin: 0;
    }
    
    button {
      background: none;
      
      svg {
        fill: white;
      }
    }
`;


const BtnWrapper = styled(Button)`
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 80%;
    position: absolute;
    bottom: 0;
    margin: 8px 0 1.5em 0;
    display: flex;
    align-items: center;
    
    background: #ffffff;
    height: 44px;
    border-radius: 4px;
    color: #101B43;
    text-transform: uppercase;
  
  &:hover {
    background: #ffffff;
    color: #101B43;
  }
  
  &:focus {
    background: #ffffff;
    color: #101B43;
  }
  
  svg {
   margin: 0 1em;
  }
`

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  
  div {
    margin-right: 1em;
    
    h1 {
        font-family: 'Clearface Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 140%;
        color: #FFFFFF;
        margin: 0;
        padding: 0;
    }
    
    p {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #FFFFFF;
        margin: 0;
        padding: 0;
    }
  }
`;

export const Paragraph = styled.p`
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
    margin: 20px 0 0 0;
    padding: 0;
`;

function getTotalBudget(guests) {
	let total = 0;
	guests.forEach((guest) => {
		total += guest.preferences && guest.preferences.totalBudget ? guest.preferences.totalBudget : 0
	})

	return total
}

const BudgetDetails = ({ event, pathUrl, guests }) => {
	if (!event) return <Container><Skeleton active /></Container>
	return (
		<Row style={{ height: '100%' }}>
			<Header xs={24}>
				<h5>Bachelorette Budget</h5>
				<button type='button' ><RightArrowIcon /></button>
			</Header>
			<Col xs={24}>
				<Container >
					<Col sm={12} md={12}>
						<TextContainer>
							<div>
								<h1>${getTotalBudget(guests)}</h1>
								<p>group budget</p>
							</div>
							<div>
								<h1>${event.confirmedGuests && event.confirmedGuests.length ? (getTotalBudget(guests) / event.confirmedGuests.length).toFixed(2) : 0}</h1>
								<p>per person</p>
							</div>
						</TextContainer>
						<Paragraph>
							Budget will be finalized once all guests RSVP
						</Paragraph>
						<BtnWrapper
							onClick={() => null}
							text=''
							htmlType='button'
							primary={+true}
						>

							<Link to={`${pathUrl}/budget`}>Go to Budget <BudgetIcon /></Link>
						</BtnWrapper>
					</Col>
					<Col sm={12} md={12}>
						<img style={{ marginTop: '1.8em' }} src={budgetImage} alt="" />
					</Col>
				</Container>
			</Col>
		</Row>
	)
}

export default BudgetDetails