import React, { Component } from "react";
import { connect } from "react-redux";
import {
  itinerary,
  recommendations,
  polls,
  guests,
} from "../../helpers/firestore";
export const PollsContext = React.createContext();

class PollsProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itineraryData: null,
      recommendations: [],
      polls: [],
      guests: [],
      isGuest: false,
    };
  }

  componentDidMount() {
    this.getData(this.props.eventId);
  }

  setItineraryData = (itineraryData) => {
    this.setState({ itineraryData });
  };

  setRecommendationsData = (recommendations) => {
    this.setState({ recommendations });
  };

  setPollList = (polls) => {
    this.setState({ polls });
  };

  setGuests = (guests) => {
    this.setState({ guests });
    const isGuest = guests.some((guest) => guest.id === this.props.uid);
    this.setState({ isGuest });
  };

  getData = (eventId) => {
    guests.get(eventId, this.setGuests);
    itinerary.getItineraryData(eventId, this.setItineraryData);
    recommendations.get(eventId, this.setRecommendationsData);
    polls.getPolls(eventId, this.setPollList);
  };

  render() {
    const { itineraryData, recommendations, polls, guests, isGuest } =
      this.state;
    const { children, event, eventId } = this.props;

    const providerData = {
      eventId,
      event,
      itineraryData,
      recommendations,
      polls,
      guests,
      isGuest,
    };
    return (
      <PollsContext.Provider value={providerData}>
        {children}
      </PollsContext.Provider>
    );
  }
}

PollsProvider.defaultProps = {
  // <!--  children: PropTypes.object-->
};

PollsProvider.propTypes = {
  // <!--  children: {}-->
};

function mapStateToProps(state) {
  return {
    uid: state.auth.user.user.uid,
  };
}

export default connect(mapStateToProps, null)(PollsProvider);
