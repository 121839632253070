import React from 'react';
import styled from "styled-components";
import {Menu} from "antd";

const Wrapper = styled.svg`
    fill: #494D5B;
     stroke: #494D5B;
    
    &:hover {
      fill: #2E2C9A;
     stroke: #2E2C9A;
    }
`

export default ({ style }) => (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" fill="white" stroke="#EEEEEF"/>
        <rect width="20" height="2.5" rx="1.25" transform="matrix(-1 0 0 1 30 12.9998)" fill="#494D5B"/>
        <rect width="14" height="2.5" rx="1.25" transform="matrix(-1 0 0 1 30 19.2913)" fill="#494D5B"/>
        <rect width="16.6667" height="2.5" rx="1.25" transform="matrix(-1 0 0 1 30 25.2915)" fill="#494D5B"/>
    </svg>

);
