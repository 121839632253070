import React, { useEffect, useState} from 'react';
import styled from 'styled-components';
import ReactFileReader from 'react-file-reader';
import uploadImage from '../../assets/icons/uploadImage.svg';
import { auth, storage } from '../../config/firebase'

export const Container = styled.div`
    width: 180px;
    height: 180px;
    border: 1px solid #ccc;
    border-radius: 50%;
    background: transparent url(${props => props.src}) no-repeat center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    cursor: pointer;

    input {
      &:hover {
        border: 1px solid #eee;
      }

      border: #eee;
      outline: #eee;

      &:focus {
        border: #eee;
        outline: #eee;
      }
    }

    h5 {
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 80%;
      color: #2E2C9A;
      margin: 1em 0;
    }

    button {
      border: none;
      background: none;
      outline: none;
    }
`;

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export async function uploadImageAndGetUrl (userId, imageData) {
  const imagesRef = storage.child(`images/profile/${userId}.jpg`);
  const uploadTask = imagesRef.putString(imageData, 'data_url')

  return await uploadTask.then((snapshot) => {
                      return snapshot
                  }).then((snapshot) => {
                      
                      return snapshot.ref.getDownloadURL()
                      .then(async (imageURL) => {
                      return imageURL
                      })
                  })
}

function Previews({ input, image, userId }) {
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    if(image){
      setPreview(image)
    }
  }, [ image ])

  const handleFiles = async (files) => {
    setPreview(files.base64)
    
    const imageUrl = await uploadImageAndGetUrl(userId, files.base64)
    const updateUser = auth.currentUser;

    updateUser.updateProfile({
      photoURL: imageUrl
    }).then((res) => {
      // console.log('user update response:: ', res)
    }).catch((error) => {
      // An error happened.
    });
  }

  return (
    
        <ReactFileReader fileTypes={[".jpg",".jpeg", ".png"]} base64={true} handleFiles={handleFiles}>
          <Container src={preview}>
            <button type='button'>
            {preview ? '' : 
            <Wrapper>
              <img src={uploadImage} alt='' />
            </Wrapper>}
            </button>
            <input {...input} type='hidden' name='image' value={preview} />
          </Container>
        </ReactFileReader>
        
      
  );
}

export default Previews;