import React from "react";
import { GuestListForGuests } from "../../components";
import GuestListsProvider, { GuestListsContext } from "./GuestListsProvider";
import { Row, Skeleton } from "antd";
import styled from "styled-components";
import moment from "moment";

export const Container = styled(Row)`
  padding: 1.25em;
  height: 90vh;
`;

const colData = [
	{
		title: "NAME",
		dataIndex: "name",
		key: "name",
		type: "link-text",
	},
	{
		title: "RSVP",
		dataIndex: "rsvp",
		key: "rsvp",
		type: "text",
	},
	{
		title: "BUDGET",
		dataIndex: "budget",
		key: "budget",
		type: "text",
	},
	{
		title: "DESTINATION(S)",
		dataIndex: "destination",
		key: "destination",
		type: "text",
	},
	{
		title: "DATES",
		key: "dates",
		dataIndex: "dates",
		type: "text",
	},
	{
		title: "ROOMMATE(S)",
		key: "roommates",
		dataIndex: "roommates",
		type: "text",
	},
	{
		title: "EMAIL & MOBILE",
		key: "emailMobile",
		dataIndex: "emailMobile",
		type: "text",
	},
	{
		title: "Payment",
		key: "venmo",
		dataIndex: "venmo",
		type: "text",
	},
	{
		title: "",
		key: "action",
		type: "action",
	},
];
function createMarkup(guests) {
	return {
		__html:
			guests.length > 1 ? `${guests.length} Guests` : `${guests.length} Guest`,
	};
}

export const normalizeMomentDate = (value) => {
	moment.defaultFormat = "ddd MMM d";
	const newDate = moment(value, moment.defaultFormat).toDate();
	const tmp = moment(newDate).format("llll");
	const newstr = tmp.split(",");

	return `${newstr[0]} ${newstr[1]}`;
};

function formatDates(dateArray) {
	const newDateArray = dateArray.map((dateItem) => {
		return dateItem;
	});

	return newDateArray;
}

function sortDates(dates) {
	return dates.map((date) => {
		return formatDates(date.label.split("-"));
	});
}

const sortGuests = (guest, guests) =>
	guests
		.filter((item) => item.id === guest.id)
		.map((el) => ({
			label: el.name,
			id: el.id,
			value: el.id,
		}))[0];

function generateTableData(data) {
	return data.map((guest, index) => {
		return {
			key: index,
			name: guest.name,
			rsvp: guest.state,
			budget: `$${guest.preferences && guest.preferences.totalBudget
				? guest.preferences.totalBudget
				: 0
				}`,
			allergies:
				guest.preferences &&
					guest.preferences.destination &&
					guest.preferences.destination.allergies
					? guest.preferences.destination.allergies
					: null,
			dates:
				// guest.preferences && !Array.isArray(guest.preferences.dates) && guest.preferences.dates.length
				// ? sortDates(guest.preferences.dates)
				guest.preferences &&
					Array.isArray(guest.preferences.dates) &&
					guest.preferences.dates.length
					? guest.preferences.dates
					: null,
			roommates:
				guest.preferences &&
					guest.preferences.destination &&
					guest.preferences.destination.roommate
					? guest.preferences.destination.roommate.map((roommate) =>
						sortGuests(roommate, data)
					)
					: [],
			emailMobile: [guest.email, guest.mobileNumber],
			email: guest.email,
			mobileNumber: guest.mobileNumber,
			paymentMethod:
				guest.preferences &&
					guest.preferences.destination &&
					guest.preferences.destination.paymentMethod
					? guest.preferences.destination.paymentMethod
					: null,
			paymentUsername:
				guest.preferences &&
					guest.preferences.destination &&
					guest.preferences.destination.paymentUsername
					? guest.preferences.destination.paymentUsername
					: null,
			id: guest.id,
		};
	});
}

const GuestLists = (props) => {
	return (
		<GuestListsProvider eventId={props.match.params.eventId}>
			<GuestListsContext.Consumer>
				{({ guests, eventId }) => {
					return (
						<div>
							{guests ? (
								<GuestListForGuests
									title={createMarkup(guests)}
									tableData={generateTableData(guests)}
									colData={colData}
									guests={guests}
									eventId={eventId}
								/>
							) : (
								<Container>
									<Skeleton active />
									<Skeleton active />
								</Container>
							)}
						</div>
					);
				}}
			</GuestListsContext.Consumer>
		</GuestListsProvider>
	);
};

GuestLists.defaultProps = {};

GuestLists.propTypes = {};

export default GuestLists;
