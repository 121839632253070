import React from "react";
import { Modal } from "antd";
import styled, { createGlobalStyle } from "styled-components";
import backIcon from "../../assets/icons/backArrow.svg";

const IconButton = styled.button`
  padding: 0;
  margin: 0;
  outline: none;
  width: 44px;
  height: 44px;
  color: #494d5b;
  background: #ffffff;
  border: 1px solid #eeeeef;
  box-sizing: border-box;
  border-radius: 4px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  h2 {
    font-family: "Clearface Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 34px;
    color: #101b43;
    margin: 0 1em;
  }
`;

const GlobalStyle = createGlobalStyle`
.custommodal.ant-modal,
.custommodal .ant-modal-content {
    height: 100vh;
    width: 100vw;
    margin: 0;
    top: 0;
    max-width: 100vw;
  }
 .custommodal .ant-modal-body {
    height: calc(100vh - 110px);
  }

`;

const InModal = ({ visible, onClose, title, children }) => {
  return (
    <>
      <GlobalStyle />
      <Modal
        title=""
        closable={false}
        onClose={onClose}
        visible={visible}
        footer={null}
        mask={false}
        width={"100vw"}
        className="custommodal"
      >
        {children}
      </Modal>
    </>
  );
};

export default InModal;
