import React from 'react';
import { Row, Col } from "antd";
import styled from "styled-components";
import InfoGrid from './InfoGrid'
import { SectionHeading } from '../../../../components/Text'
import { MaxWidthContainer } from '../../../../components';

export const Title = styled(SectionHeading)`
    margin: 1em;
    
    ${props => props.theme.breakpoints.tablet}{
    
    }
`;

export const Container = styled.div`
    display: flex;
    justify-content: center;
`;



function Benefits() {
    return (
        <MaxWidthContainer>
            <Row justify='center'>
                <Col xs={24}>
                    <Container
                        width='100%'
                        justifyContent='center'
                    >
                        <Title align='center'>
                            Everything You Need In One Place
                        </Title>
                    </Container>
                </Col>
                <InfoGrid />
            </Row>
        </MaxWidthContainer>
    );
}

export default Benefits


