import React from "react";
import { useRouteMatch } from "react-router-dom";
import Unsubscribe from "./Unsubscribe";

const UnsubscribePage = () => {
  const { params } = useRouteMatch();
  return <Unsubscribe userId={params.userId} />;
};

export default UnsubscribePage;
