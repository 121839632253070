import React from 'react'
import styled from "styled-components";
import {PlusOutlined} from "@ant-design/icons";
import {InButton, InTypography} from "../index";

const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    flex-grow: 1;
`

const Image = styled.img`
  margin: 0 0 16px 0;
  max-width: 21vw;
`

const ContainerAlt = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    flex-grow: 1;
`

const Text = styled(InTypography.InH3)`
  margin: 16px 0;
`
export const EmptyTableAlt = ({ image, text, action }) => {
    return (
        <ContainerAlt>
            <Image src={image} alt=""/>
            <Text>
                {text}
            </Text>
        </ContainerAlt>
    )
}

const EmptyTable = ({ image, text, action }) => {
    return (
        <Container>
            <Image src={image} alt=""/>
            <Text>
                {text}
            </Text>
            {action && <InButton primary='true' text='NEW EVENT' icon={<PlusOutlined />} onClick={() => action()}/>}
        </Container>
    )
}

export default  EmptyTable;