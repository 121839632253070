import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col, Modal } from "antd";
import { InButton } from "../../../components";
import { HeartSvg, HeartOutlinedSvg } from "../../../assets/icons/heart";

export const Header = styled(Col)`
  height: 60px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    font-family: "Clearface Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 34px;
    color: #101b43;
    padding: 0 1em;
    margin: 0;
  }

  button {
    margin-right: 1.25em;
  }
`;

export const CardContent = styled(Row)`
  padding: 1.25em;
`;

export const CityCard = styled.div`
  /* height: 254px; */
  //background: #FAFAFC;
  background: #fafafc;
  border-radius: 8px;
`;

export const Content = styled.div`
  padding: 10px;
  h5 {
    margin: 20px 0;

    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: #101b43;
  }
`;

export const Container = styled.div``;

export const HeaderImage = styled.div`
  width: 100%;
  height: 150px;
  background: transparent url(${(props) => props.src}) no-repeat center;
  background-size: cover;
  border-radius: 8px 8px 0 0;
`;

export const Title = styled.div`
  width: 100%;

  h1 {
    font-family: "Clearface Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 140%;
    text-align: center;
    color: #101b43;
  }
`;

export const SvgContainer = styled.div`
  margin-top: 1em;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  svg {
    font-size: 1em;
    margin: 0;
    padding: 0;
    width: 28px;
  }
  span {
    font-weight: 600;
    margin-left: 5px;
    text-transform: uppercase;
    color: #000;
  }
`;

const ModalPage = ({ poll, visible, toggleModal, finalize }) => {
  const [loading, setLoader] = useState(false);
  return (
    <div>
      <Modal
        title={
          <Title>
            <h1>{poll.name}</h1>
          </Title>
        }
        visible={visible}
        onOk={() => toggleModal()}
        onCancel={() => toggleModal()}
        width="90%"
        footer={null}
      >
        <Row gutter={16}>
          {poll &&
            poll.poll &&
            poll.poll.map((item, index) => {
              return (
                <Col xs={24} sm={8} key={index}>
                  <CityCard>
                    <HeaderImage src={item.image} />
                    <Content>
                      <h5>{item.name}</h5>
                      <SvgContainer>
                        <HeartSvg />
                        <span>
                          {item && item.votes ? item.votes.length : 0} votes
                        </span>
                      </SvgContainer>
                      <Container>
                        <InButton
                          width="127px"
                          type="button"
                          onClick={() => {
                            setLoader(true);
                            finalize(item, poll);
                          }}
                          text="FINALIZE"
                          primary
                          loading={loading}
                        />
                      </Container>
                    </Content>
                  </CityCard>
                </Col>
              );
            })}
        </Row>
      </Modal>
    </div>
  );
};

ModalPage.defaultProps = {};

ModalPage.propTypes = {};

export default ModalPage;
