import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col, Skeleton } from "antd";
import { PollsContext } from "../PollsProvider";
import DisplayAddedPoll from "./displayAddedPoll";

export const Container = styled(Row)`
  padding: 0 1.25em;
`;

const Cards = styled.div`
  min-height: 184px;
  background: ${(props) => (props.bg ? props.bg : "#ffffff")};
  border-radius: 8px;
  margin: 1.25em 0 3.75em 0;
  width: 100%;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.05);
`;

export const Header = styled(Col)`
  height: 60px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    font-family: "Clearface Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 34px;
    color: #101b43;
    padding: 0 1em;
    margin: 0;
    text-transform: capitalize;
  }
`;

export const ColContent = styled(Col)`
  margin: 1.5em 2em;

  p {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */

    display: flex;
    align-items: center;

    /* Text/Midnight Blue */

    color: #101b43;
  }
`;

export const Content = styled.div`
  h5 {
    margin: 20px 0;
    padding: 0;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: #101b43;
  }
`;

export const HeaderImage = styled.div`
  width: 100%;
  height: 150px;
  background: transparent url(${(props) => props.src}) no-repeat center;
  background-size: cover;
  border-radius: 8px 8px 0 0;
`;

const AllPolls = () => {
  const findPolls = (poll, name) => {
    return poll.recommendationName === name;
  };

  return (
    <PollsContext.Consumer>
      {({ event, recommendations, polls, eventId }) => {
        if (!recommendations && !event)
          return (
            <Container>
              <Skeleton active />
            </Container>
          );

        return recommendations.map((recommendation) => {
          const recommendationPolls = polls.filter((poll) =>
            findPolls(poll, recommendation.name)
          );
          return (
            <Cards>
              {recommendationPolls.length ? (
                <DisplayAddedPoll
                  eventId={eventId}
                  name={recommendation.name}
                  event={event}
                  recommendationPolls={recommendationPolls}
                />
              ) : (
                <Row>
                  <Header xs={24}>
                    <h2>
                      {recommendation.name === "custom"
                        ? "Custom Poll"
                        : recommendation.name}
                    </h2>
                  </Header>
                  <ColContent xs={24}>
                    <p>Polls not set</p>
                  </ColContent>
                </Row>
              )}
            </Cards>
          );
        });
      }}
    </PollsContext.Consumer>
  );
};

AllPolls.defaultProps = {};

AllPolls.propTypes = {};

export default AllPolls;
