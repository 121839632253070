import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { events, users as usersGet } from "../../helpers/firestore";
import { useSelector } from "react-redux";

const Users = () => {
  const email = useSelector(({ auth: { user } }) => user?.user?.email);
  const [eventsData, setEventsData] = useState([]);
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  useEffect(() => {
    events.getEvents(setEventsData);
    usersGet.getUsers(setAllUsers);
  }, []);

  useEffect(() => {
    if (eventsData.length === 0) return;
    let usersData = [];
    const convertGuests = (data) => {
      return [...data].map((el) => ({
        mobile: el.mobileNumber,
        email: el.email,
        name: el.name,
        type: "guest",
      }));
    };

    const convertBride = (data) => {
      return [...data].map((el) => ({
        mobile: el.mobile,
        email: el.email,
        name: el.fullname,
        type: "bride",
      }));
    };

    
    const convertOrganizer = (organizer) => {
      const org = allUsers.find((user) => user.uid === organizer.uid);
      if (org) {
        return {
          mobile: "",
          email: org.email,
          name: organizer.displayName,
          type: "organizer",
          registered: org.metadata.creationTime
        };
      }
    };
    eventsData.forEach((event) => {
      const organizer = convertOrganizer(event.organizer);
      if (organizer) {
        usersData.push(organizer);
      }
      usersData.push(...convertBride(event.bride), ...convertGuests(event.guestData));
    });

    const userDataGroup = usersData
      .filter((user) => !!user.email)
      .reduce((acc, val, i) => {
        const index = acc.findIndex((item) => item.email === val.email);
        if (index === -1) {
          acc.push({ ...val, types: [val.type] });
        } else {
          acc[index] = { ...acc[index], types: [...new Set(acc[index].types.concat(val.type))] };
        }
        return acc;
      }, []);
    setUsers(userDataGroup.map((user, index) => ({ key: index, ...user, types: user.types.join(", ") })));
  }, [eventsData, allUsers]);

  const columns = [
    {
      title: "name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "mobile",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "types",
      dataIndex: "types",
      key: "types",
    },
    {
      title: "registered",
      dataIndex: "registered",
      key: "registered",
    },
  ];

  if (!process.env.REACT_APP_ADMIN.split(",").includes(email)) {
    return null;
  }

  return (
    <div style={{ marginTop: 50 }}>
      <Table dataSource={users} columns={columns} pagination={false} />
    </div>
  );
};

export default Users;
