import React, {useState, useEffect} from 'react';

import { Modal, Timeline } from 'antd';
import styled from "styled-components";
import CreateChatIcon from "../../../assets/icons/chatEdit";
import {SearchInput} from "../../";
import CreateChatGroup from "./createChatGroup";

const Wrapper = styled(Timeline)`
  margin: 12px 0;
  
  ul {
    list-style: none;
        padding: 0;
        margin: 0;
  }
`

const ListItem = styled.li`
padding: 0 20px;
      margin: 0;
      height: 82px;
      background: ${props => props.active ? '#E9E8F0' : '#ffffff'};
      display: flex;
      align-items: center;
      border-bottom: 1px solid #E5E5E5;
      cursor: pointer;
      
      &:first-child {
          border-top: 1px solid #E5E5E5;
        }
`

const Paragraph = styled.p`
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #101B43;
    margin: 0;
    padding: 0;
`

const TimelineWrapper = styled.div`
        width: 100%;
        overflow-y: auto;
        padding-bottom: 60px;
`


const TopContent = styled.div`
display: flex;
justify-content: space-between;
        margin: 0 1em 1em;
        
        button {
          background: none;
          cursor: pointer;
        }
`

const Content = styled.div`
        margin: 0 1em;
`

export const AllChats = ({ guests, eventId, allGroupChats, setActiveGroupChat, activeGroup }) => {
    const [visibility, setVisibility] = useState(false);

    const handleOpen = () => {
        setVisibility(true)
    };

    const handleClose = () => {
        setVisibility(false)
    };

    useEffect(() => {
        if(allGroupChats.length){
            setActiveGroupChat(allGroupChats[0])
        }
    }, []);
    
    return (
        <TimelineWrapper>
            <Wrapper>
                <TopContent>
                    <SearchInput width='275px' />
                    <button onClick={() => handleOpen()}>
                        <CreateChatIcon />
                    </button>
                </TopContent>
                <ul>
                    {allGroupChats.map((groupChat, index) => {
                        return (
                            <ListItem key={index} onClick={() => setActiveGroupChat(groupChat)} active={activeGroup && activeGroup.name === groupChat.name}>
                                <Content>
                                    <Paragraph>
                                        {groupChat.name}
                                    </Paragraph>
                                </Content>
                            </ListItem>
                        )
                    })}
                </ul>
            </Wrapper>
            <Modal
                title=''
                visible={visibility}
                onCancel={() => handleClose()}
                destroyOnClose={true}
                footer={null}
            >
                <CreateChatGroup eventId={eventId} handleClose={handleClose} guests={guests} />
            </Modal>
        </TimelineWrapper>
    )
};