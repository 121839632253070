export default {
	minPhone: '@media only screen and (max-width : 360px)',
	phone: '@media only screen and (min-width : 480px)',
	maxPhone: '@media only screen and (max-width : 767px)',
	tablet: '@media only screen and (min-width : 768px)',
	maxTablet: '@media only screen and (max-width : 1023px)',
	desktop: '@media only screen and (min-width : 1024px)',
	largerDesktop: '@media only screen and (min-width : 1231px)',
}

// export default {
//   minPhone: '@media only screen and (max-width : 360px)',
//   phone: '@media only screen and (min-width : 480px)',
//   maxPhone: '@media only screen and (max-width : 768px)',
//   tablet: '@media only screen and (min-width : 768px)',
//   desktop: '@media only screen and (min-width : 1025px)',
//   minDesktop: '@media only screen and (min-width : 1024px)',
//   maxTablet: '@media only screen and (max-width : 1024px)',
//   mediumDesktop: '@media only screen and (max-width : 1230px)',
//   largerDesktop: '@media only screen and (min-width : 1231px)',
//   maxLaptop: '@media only screen and (max-width : 1366)',
//   maxLargerDesktop: '@media only screen and (max-width : 1919px)',
//   XLargeDesktop: '@media only screen and (min-width : 1920px)'
// }maxPhone