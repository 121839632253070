import { fbApp, googleProvider, facebookProvider, emailProvider, db, auth } from "../../config/firebase";
import {
  requestLogin,
  receiveLogin,
  loginError,
  requestLogout,
  receiveLogout,
  logoutError,
  verifyRequest,
  verifySuccess,
  toast,
} from "../../redux/actions";

const supportedPopupSignInMethods = [
  fbApp.auth.GoogleAuthProvider.PROVIDER_ID,
  fbApp.auth.FacebookAuthProvider.PROVIDER_ID,
  fbApp.auth.EmailAuthProvider.PROVIDER_ID,
];

const loginUser = (email, password) => (dispatch, onClose) => {
  dispatch(requestLogin());
  fbApp
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then((user) => {
      dispatch(receiveLogin(user));
      onClose();
    })
    .catch((error) => {
      dispatch(toast(error.message, "error"));
      dispatch(loginError());
    });
};

const logoutUser = (dispatch) => {
  dispatch(requestLogout());
  fbApp
    .auth()
    .signOut()
    .then(() => {
      dispatch(receiveLogout());
      window.location.pathname = "/login";
    })
    .catch((error) => {
      dispatch(toast(error.message, "error"));
      dispatch(logoutError());
    });
};

const verifyAuth = () => (dispatch) => {
  dispatch(verifyRequest());
  fbApp.auth().onAuthStateChanged((user) => {
    if (user !== null) {
      dispatch(receiveLogin(user));
    }
    dispatch(verifySuccess());
  });
};

async function setProfile(userData) {
  const user = fbApp.auth().currentUser;
  if (user.uid) {
    const docRef = db.collection("users").doc(userData.uid);
    const { exists } = await docRef.get();
    if (exists) return;
    docRef
      .set({
        id: userData.uid,
        fullName: userData.displayName,
        type: "member",
        // settings: {
        //   notifications: [],
        // },
        sendEmail: true,
        registered: new Date()
      })
      .then(() => {
        console.log("User profile updated");
      })
      .catch((error) => {
        toast(error.message, "error");
      });
  }
}

const loginWithGoogle = (dispatch, onClose) => {
  dispatch(requestLogin());
  return new Promise((resolve, reject) => {
    fbApp
      .auth()
      .signInWithPopup(googleProvider)
      .then((user) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // const token = result.credential.accessToken;
        // // The signed-in user info.
        // const user = result.user;

        dispatch(receiveLogin(user));
        setProfile(user.user);
        resolve(user);

        setTimeout(() => {
          onClose();
        }, 1000);

        // ...
      })
      .catch(function (error) {
        // Handle Errors here.
        const errorCode = error.code;
        dispatch(toast(error.message, "error"));
        // const errorMessage = error.message;
        // // The email of the user's account used.
        // const email = error.email;
        // // The firebase.auth.AuthCredential type that was used.
        // const credential = error.credential;
        dispatch(loginError());
        reject(errorCode);
      });
  });
};

const getProvider = (providerId) => {
  switch (providerId) {
    case fbApp.auth.GoogleAuthProvider.PROVIDER_ID:
      return googleProvider;
    case fbApp.auth.FacebookAuthProvider.PROVIDER_ID:
      return facebookProvider;
    case fbApp.auth.EmailAuthProvider.PROVIDER_ID:
      return emailProvider;
    default:
      throw new Error(`No provider implemented for ${providerId}`);
  }
};

/*
 * Returns providers for currently signed in user.
 */
const providersForEmail = async (email) => {
  return auth.fetchSignInMethodsForEmail(email);
};
/*
 * Returns whether the user is only logged in using password provider.
 */
const userHasOnlyEmailProvider = async (email) => {
  let providers;
  if (email) {
    providers = await providersForEmail(email);
  } else {
    const user = auth.currentUser;
    if (!user) {
      return false;
    }
    providers = await providersForEmail(user.email);
  }
  return providers.length === 1 && providers[0] === fbApp.auth.EmailAuthProvider.PROVIDER_ID;
};

const linkProviders = async (email, credential) => {
  const providers = await auth.fetchSignInMethodsForEmail(email);
  const firstPopupProviderMethod = providers.find((p) => supportedPopupSignInMethods.includes(p));
  if (!firstPopupProviderMethod) {
    throw new Error(`Your account is linked to a provider that isn't supported.`);
  }
  const linkedProvider = getProvider(firstPopupProviderMethod);
  linkedProvider.setCustomParameters({ login_hint: email });
  try {
    const result = await fbApp.auth().signInWithPopup(linkedProvider);
    return await result.user.linkWithCredential(credential);
  } catch (error) {
    console.log("linkWithCredentials failed: ", error);
  }
};

const loginWithFacebook = ({ dispatch, onClose, eventParams, socialPassword }) => {
  dispatch(requestLogin());
  return new Promise(async (resolve, reject) => {
    try {
      const user = await fbApp.auth().signInWithPopup(facebookProvider);
      dispatch(receiveLogin(user));
      // setProfile(user.user);
      resolve(user);
      setTimeout(() => onClose(), 1000);
    } catch (error) {
      if (error.code === "auth/account-exists-with-different-credential") {
        const userHasOnlyEmail = await userHasOnlyEmailProvider(error.email);
        let user;
        if (userHasOnlyEmail) {
          if (eventParams?.email && eventParams?.key) {
            await fbApp.auth().signInWithEmailAndPassword(eventParams.email, eventParams.key);
          } else {
            const password = await socialPassword();
            await fbApp.auth().signInWithEmailAndPassword(error.email, password);
          }
          user = await auth.currentUser.linkWithCredential(error.credential);
        } else {
          user = await linkProviders(error.email, error.credential);
        }
        dispatch(receiveLogin(user));
        // setProfile(user.user);
        resolve(user);
        setTimeout(() => onClose(), 500);
      } else {
        const errorCode = error.code;
        dispatch(toast(error.message, "error"));
        dispatch(loginError());
        reject(errorCode);
      }
    }
  });
};

async function setUserProfile(values) {
  const user = fbApp.auth().currentUser;

  if (user.uid) {
    user
      .updateProfile({
        displayName: `${values.fullName}`,
      })
      .then(() => {
        console.log(" successful profile update");
      })
      .catch((error) => {
        console.log(" profile update error", error);
      });

    user
      .sendEmailVerification()
      .then(() => {
        console.log("Please check your email to verify your email address");
      })
      .catch((error) => {
        console.log("Error message: ", error);
      });

    const docRef = db.collection("users").doc(user.uid);
    docRef
      .set({
        id: docRef.id,
        fullName: values.fullName,
        type: "member",
        sendEmail: true,
      })
      .then(() => {
        console.log("User profile updated");
      })
      .catch((error) => {
        console.log("Error message: ", error.message);
      });
  }
}

const registerUser = async (values, dispatch, onClose) => {
  dispatch(requestLogin());
  return new Promise((resolve, reject) => {
    fbApp
      .auth()
      .createUserWithEmailAndPassword(values.email, values.password)
      .then((user) => {
        dispatch(receiveLogin(user));
        setUserProfile(values);
        setTimeout(() => {
          onClose();
        }, 1000);
        resolve(user);
      })
      .catch((error) => {
        dispatch(toast(error.message, "error"));
        dispatch(loginError());
        reject(error.message);
      });
  });
};

const updateGuestPassword = (newPassword, onClose) => {
  const user = fbApp.auth().currentUser;

  user
    .updatePassword(newPassword)
    .then(() => {
      onClose();
    })
    .catch((error) => {
      // dispatch(toast(error.message, 'error'))
    });
};

const resetPassword = async (email, request = fetch) => {
  const requestObject = {
    data: {
      email,
    },
  };

  const postBody = JSON.stringify(requestObject);

  let url;
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    url = `${process.env.REACT_APP_SEND_RESET_PASS_DEV}`;
  } else {
    url = `${process.env.REACT_APP_SEND_RESET_PASS_PROD}`;
  }

  try {
    const response = await request(url, { method: "POST", body: postBody });
    if (response.status === 200) {
      return await response.json();
    }
  } catch (error) {
    console.log("errors: ", error);
  }
};

export {
  loginUser,
  logoutUser,
  verifyAuth,
  loginWithGoogle,
  loginWithFacebook,
  registerUser,
  updateGuestPassword,
  resetPassword,
};
