import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

const config = {
  apiKey: `${process.env.REACT_APP_API_KEY}`,
  authDomain: `${process.env.REACT_APP_DOMAIN}`,
  databaseURL: `${process.env.REACT_APP_DATABASE_URL}`,
  projectId: `${process.env.REACT_APP_PROJECT_ID}`,
  storageBucket: `${process.env.REACT_APP_STORAGE_BUCKET}`,
  messagingSenderId: `${process.env.REACT_APP_MESSAGING_SENDER_ID}`,
  appId: `${process.env.REACT_APP_ID}`,
};

firebase.initializeApp(config);
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const facebookProvider = new firebase.auth.FacebookAuthProvider();
facebookProvider.addScope("public_profile, email");
export const emailProvider = firebase.auth.EmailAuthProvider();

export const auth = firebase.auth();
export const storageMain = firebase.storage();
export const storage = firebase.storage().ref();
// const fbFunctions = fbApp.functions();
export const fbApp = firebase;
export const db = firebase.firestore();
export const functions = firebase.functions();

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // dev code
  console.log("development: ", process.env.NODE_ENV);
  console.log(window.location.hostname);
  // if()
  // if (window.location.hostname.includes("localhost")) {
  db.useEmulator(window.location.hostname, 8080);
  auth.useEmulator(`http://${window.location.hostname}:9099`);
  functions.useEmulator(window.location.hostname, 5001);
  storageMain.useEmulator(window.location.hostname, 9199);
  // }
} else {
  console.log("production: ", process.env.NODE_ENV);
}

// const settings = {};
// db.settings(settings);
db.enablePersistence()
  .then(() => {
    // Initialize Cloud Firestore through firebase
    firebase.firestore();
  })
  .catch((err) => {
    if (err.code === "failed-precondition") {
      console.log("Multiple tabs open, persistence can only be enabled in one tab at a a time.");
    } else if (err.code === "unimplemented") {
      console.log("The current browser does not support all of the features required to enable persistence");
    }
  });
