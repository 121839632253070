import React, { Component } from "react";
import { Field } from "redux-form";

import AddCity from "./AddCity";
import styled from "styled-components";
import cancelIcon from "./Cancel.svg";
import { InButton } from "../../";

export const hiddenInputField = (props) => {
	const { input } = props;
	return (
		<div>
			<input {...input} type="hidden" />
		</div>
	);
};

const Wrapper = styled.div`
  width: 325px;
  height: 200px;
  margin-right: 16px;
  background: #fafafc;
  border-radius: 8px;
  ${props => props.theme.breakpoints.maxTablet}  {
    width: fill-available;
    margin-right: 0px;
  } 
  h4 {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    margin: 16px 20px;
    /* identical to box height, or 18px */

    /* Text/Midnight Blue */

    color: #101b43;
  }
`;

const SelectedCitiesWrapper = styled.div`
  margin: 1em 0 5em;
  h2 {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 100%;
    color: #8696ae;
  }

  p {
    padding: 0;
    margin: 0 0 20px 0;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #8696ae;
  }
`;

export const SelectedCities = styled.div`
  display: flex;
`;

export const Image = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: column;
  background: transparent url(${(props) => props.src}) no-repeat center;
  background-size: cover;
  border-radius: 8px 8px 0 0;
  padding: 12px;

  button {
    border: none;
    outline: none;
    background: none;
  }
`;

class CitySuggest extends Component {
	inputElement = React.createRef();
	render() {
		return (
			<div>
				<AddCity
					addItem={this.props.addItem}
					inputElement={this.inputElement}
					onSelect={this.props.onSelect}
					currentItem={this.props.currentItem}
				/>
				<Field name="cities" component={hiddenInputField} />

				<SelectedCitiesWrapper>
					<p>
						{this.props.checkValue
							? "Please add a city option for the event"
							: "Please add a maximum of 3 city options for the event"}
					</p>
					<SelectedCities>
						{this.props.items.map((city, index) => {
							return (
								<Wrapper key={index}>
									<Image src={city.imageUrl}>
										<button
											type="button"
											onClick={(data) => this.props.deleteItem(city.key)}
										>
											<img src={cancelIcon} alt="" />
										</button>
									</Image>
									<h4>{city.name}</h4>
								</Wrapper>
							);
						})}
					</SelectedCities>
				</SelectedCitiesWrapper>
				<InButton
					type="button"
					onClick={() => this.props.handleAdd()}
					text="ADD CITY"
					disabled={this.props.disabled}
					primary
				/>
			</div>
		);
	}
}

export default CitySuggest;
