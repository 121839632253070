import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
.onoffswitch {
  position: relative;
  width: 40px;

  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select: none;
}
.onoffswitch-checkbox {
  display: none;
}
.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
  height: 20px;
}
.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner:before, .onoffswitch-inner:after {
  display: block;
   float: left;
    width: 50%;
     height: 20px;
     padding: 0; 
     line-height: 20px;
  font-size: 14px;
   color: white; 

  box-sizing: border-box;
}
.onoffswitch-inner:before {
  content: "";
  padding-left: 10px;
  background-color: #EDEDED;
   color: #FFFFFF;
}
.onoffswitch-inner:after {
  content: "";
  padding-right: 10px;
  background-color: #EDEDED;
   color: #FFFFFF;
  text-align: right;
}
.onoffswitch-switch {
  display: block;
   width: 20px;
   height: 20px;
    margin: 2px;
  background: ${props => props.checkValue ? '#2E2C9A' : '#8696AE' };
  position: absolute;
   top: -2px;
    bottom: 0;
  right: 17px;
   border-radius: 20px;
  transition: all 0.3s ease-in 0s; 
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: -2px; 
}
`


const ModalLayout = ({ toggleCheck, checkValue, id }) => {

    return (
        <Wrapper checkValue={checkValue}>
            <div className="onoffswitch">
              <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id={id} onChange={() => toggleCheck()} checked={checkValue} />
              <label className="onoffswitch-label" htmlFor={id}>
                  <span className="onoffswitch-inner"></span>
                  <span className="onoffswitch-switch"></span>
              </label>
          </div>
        </Wrapper>
    );
};

ModalLayout.defaultProps = {

};

ModalLayout.propTypes = {

};

export default ModalLayout;
