import React from 'react'
import styled from 'styled-components'
import { Row, Col, Skeleton, Button } from 'antd';
import { Link } from "react-router-dom";
import { InTypography } from "../../";
import funkyImage from '../../../assets/console/console_header.svg'
import clipImage from '../../../assets/itinerary/clip-1.png'
import Timer from './CountdownTimer'

export const Container = styled(Row)`
    padding: 1.25em;
	${props => props.theme.breakpoints.maxPhone} {
		padding: 0;
	}
`;

export const Column = styled(Col)`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

export const TextWrapper = styled.div`
    margin: 8px 0;
`;

export const TimerWrapper = styled.div`
    /* position: absolute;
    bottom: 0; */

    border: 1px solid #A9C5F4;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 20px;
    height: 102px;
	display: flex;
	align-items: center;

	${props => props.theme.breakpoints.maxPhone} {
		padding: 10px;
		height: auto;
	}

  p { 
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 10px;
        color: #101B43;
    }
`;

export const H1 = styled(InTypography.InH1)`
    color: #ffffff;
`;

export const Image = styled.div`
    width: 30vw;
    max-width: 598px;
    height: 100%;
    img {
        width: 100%;
        height: 100%;
    }
`;

const InButton = styled(Button)`
  background: ${props => props.primary ? '#ffffff' : 'transparent'};
  border-color: ${props => props.primary ? '#ffffff' : '#ffffff'};
  width: ${props => props.width ? props.width : '172px'};
  height: 44px;
  border-radius: 4px;
  color: #2E2C9A;
  text-transform: uppercase;
  margin-top: 1em;
  
  &:hover {
    background: ${props => props.primary ? '#ffffff' : '#ffffff'};
    border-color: #2E2C9A;
    color: ${props => props.primary ? '#2E2C9A' : '#2E2C9A'};
  }
  
  &:focus {
    background: ${props => props.primary ? '#ffffff' : '#ffffff'};
    border-color: #2E2C9A;
    color: ${props => props.primary ? '#2E2C9A' : '#2E2C9A'};
  }

`

const RenderItinerary = ({ pathUrl }) => {
	return (
		<Container>
			<Col sm={12}>
				<H1>Great news! <br />
					Now you can start building your itinerary
				</H1>
				<InButton
					onClick={() => null}
					text=''
					htmlType='button'
					primary
				>
					<Link to={`${pathUrl}/schedule`}>START BUILDING</Link>
				</InButton>
			</Col>
			<Col sm={12}>
				<Image>
					<img src={clipImage} alt="" />
				</Image>
			</Col>
		</Container>
	)
}

const Header = ({ event, displayName, itineraryData, pathUrl }) => {
	if (!event) return <Container><Skeleton active /></Container>
	const isSet = itineraryData && itineraryData.city.name && itineraryData.dates.start && event.timeline.finalizePolls
	const brides = event.bride.map(bride => bride.fullname)
	// if (isSet) return <RenderItinerary pathUrl={pathUrl} />

	return (
		<Container>
			<Column xs={24} lg={16} xl={12}>
				{isSet
					? <InTypography.InH1>Pack Your Bags!</InTypography.InH1>
					: <InTypography.InH1>Hey {displayName},</InTypography.InH1>}

				<TextWrapper>
					{itineraryData.dates.start
						? <InTypography.InParagraph>
							It's time to celebrate. <br />
							Let's make {brides.join()}'s bachelorette amazing!
						</InTypography.InParagraph>
						: <InTypography.InParagraph>
							Let's get ready to celebrate!
						</InTypography.InParagraph>}

				</TextWrapper>
				<TimerWrapper>
					{!itineraryData.dates.start && <p>Waiting on dates to be finalized to start countdown</p>}
					<Timer itineraryData={itineraryData} />
				</TimerWrapper>
			</Column>
			<Col xs={0} lg={8} xl={12}>
				<img src={funkyImage} alt="" />
			</Col>
		</Container>
	)
}

export default Header