import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col } from "antd";
import { CustomModal, CustomModalLayout, InButton } from "../";
import { PlusOutlined } from "@ant-design/icons";
import GuestList from "./GuestList";
import EditGuest from "./EditGuest";
import editIcon from "../../assets/icons/edit.svg";
import cancelIcon from "../../assets/icons/cancel.svg";

const Wrapper = styled.div`
  width: 100%;
  margin-top: 20px;
`;
const TextWrapper = styled.h3`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #101b43;
`;

const ListRow = styled(Row)`
  width: 100%;
  padding: 12px 16px;
  margin: 1em 0;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #101b43;
  border: 1px solid #e9e8f0;
  box-sizing: border-box;
  border-radius: 4px;

  span {
    display: flex;
  }
`;

const Icon = styled.button`
  width: 1em;
  margin: 0 0.5em;
  background: none;
`;

const AddGuests = ({ countryCode, initForm, guestList }) => {
  const [visible, setVisibility] = useState(false);
  const [editVisible, setEditVisibility] = useState(false);
  const [editGuest, setEditGuest] = useState(null);
  const [renderOnce, setRender] = useState(false);

  const showModal = () => {
    setVisibility(true);
  };

  const handleOk = () => {
    setVisibility(false);
  };

  const handleCancel = () => {
    setVisibility(false);
  };

  const showEditModal = (guest) => {
    setEditGuest(guest);
    setEditVisibility(true);
  };

  const handleEditCancel = () => {
    setEditVisibility(false);
    setEditGuest(null);
    setRender(false);
  };

  const deleteItem = (key) => {
    const filteredItems = guestList.filter((guest) => {
      return guest.key !== key;
    });
    initForm({ guestList: filteredItems });
  };

  useEffect(() => {
    if (editGuest && !renderOnce) {
      initForm({
        editGuestName: editGuest.name,
        editGuestEmail: editGuest.email,
        editGuestMobileNumber: editGuest.mobileNumber,
        editGuestKey: editGuest.key,
      });

      setRender(!renderOnce);
    }
  }, [editGuest, initForm, renderOnce]);
  return (
    <Wrapper>
      <TextWrapper>Guests</TextWrapper>
      {guestList && guestList.length ? (
        <div>
          {guestList.map((guest, index) => {
            return (
              <ListRow key={index} gutter={8}>
                <Col xs={6}>{guest.name}</Col>
                <Col xs={10}>{guest.email}</Col>
                <Col xs={5}>{guest.mobileNumber}</Col>
                <Col xs={3}>
                  <Icon type="button" onClick={() => showEditModal(guest)}>
                    <img src={editIcon} alt="" />
                  </Icon>
                  <Icon type="button" onClick={() => deleteItem(guest.key)}>
                    <img src={cancelIcon} alt="" />
                  </Icon>
                </Col>
              </ListRow>
            );
          })}
        </div>
      ) : (
        ""
      )}
      <InButton
        icon={<PlusOutlined />}
        type="button"
        onClick={() => showModal()}
        text="ADD GUESTS"
      />
      <CustomModal
        visible={visible}
        handleOk={handleOk}
        handleCancel={handleCancel}
      >
        <CustomModalLayout
          handleCancel={handleCancel}
          title="Start your Guestlist"
          subTitle="Don't forget to add yourself as a guest!"
          subTitleStyle={{ color: "red" }}
        >
          <GuestList
            countryCode={countryCode}
            initForm={initForm}
            handleCancel={handleCancel}
            guestList={guestList}
          />
        </CustomModalLayout>
      </CustomModal>
      <CustomModal
        visible={editVisible}
        handleOk={handleEditCancel}
        handleCancel={handleEditCancel}
      >
        <CustomModalLayout handleCancel={handleEditCancel} title="Edit Guest">
          {editGuest && (
            <EditGuest
              countryCode={countryCode}
              initForm={initForm}
              handleCancel={handleEditCancel}
              editGuest={editGuest}
              guestList={guestList}
            />
          )}
        </CustomModalLayout>
      </CustomModal>
    </Wrapper>
  );
};

AddGuests.defaultProps = {};

AddGuests.propTypes = {};

export default AddGuests;
