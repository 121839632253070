import React from "react";
import styled from "styled-components";
import { Row, Col, Button } from "antd";
import { Link } from "react-router-dom";
import { formatStart } from "../../../helpers/normalizeDate";

export const Header = styled(Col)`
  height: 60px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;

  h2 {
    font-family: "Clearface Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 34px;
    color: #101b43;
    padding: 0 1.25em;
    margin: 0;
  }
`;

export const CardContent = styled(Row)`
  padding: 1.25em;
`;

export const CityCard = styled.div`
  height: 254px;
  background: #fafafc;
  border-radius: 8px;
  padding: 20px;
`;

export const HeaderImage = styled.div`
  width: 100%;
  height: 150px;
  background: transparent url(${(props) => props.src}) no-repeat center;
  background-size: cover;
`;

export const Image = styled.div`
  width: 100%;
  height: 292px;
  background: transparent url(${(props) => props.src}) no-repeat center;
  background-size: cover;
  border-radius: 8px;
  padding: 1em 1.25em 40px 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${(props) => props.theme.breakpoints.maxPhone} {
    padding: 50px 1em 1em 1em;
    height: auto;
  }

  &:after {
    background-color: #000000;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.25;
    border-radius: 8px;
  }

  span {
    z-index: 2;
  }

  h4 {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: #ffffff;
    z-index: 2;
    margin: 0;
  }

  p {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #ffffff;
    z-index: 2;
    margin: 0;
  }

  h1 {
    font-family: "Clearface Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 1;
    color: #ffffff;
    z-index: 2;
    margin: 10px 0;
  }
`;

export const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  margin: 16px 0;

  ${(props) => props.theme.breakpoints.tablet} {
    width: 370px;
  }

  ${(props) => props.theme.breakpoints.maxPhone} {
    flex-direction: column;
    margin-bottom: 0;
    button + button {
      margin-top: 15px;
    }
  }
`;

const InButton = styled(Button)`
  background: ${(props) => (props.primary ? "#2E2C9A" : "transparent")};
  border-color: ${(props) => (props.primary ? "#2E2C9A" : "#ffffff")};
  width: ${(props) => (props.width ? props.width : "172px")};
  height: 44px;
  border-radius: 4px;
  color: #ffffff;
  text-transform: uppercase;

  &:hover {
    background: ${(props) => (props.primary ? "#2E2C9A" : "#ffffff")};
    border-color: #2e2c9a;
    color: ${(props) => (props.primary ? "#ffffff" : "#2E2C9A")};
  }

  &:focus {
    background: ${(props) => (props.primary ? "#2E2C9A" : "#ffffff")};
    border-color: #2e2c9a;
    color: ${(props) => (props.primary ? "#ffffff" : "#2E2C9A")};
  }
`;

const ReopenDestinations = ({ name, reopenDestination, imageUrl, pathUrl, dates }) => {
  return (
    <Row>
      <Col xs={24}>
        <Image src={imageUrl} alt="">
          <span>
            <p>You are going to</p>
            <h1>{name}</h1>
            <h4>{dates ? `${formatStart(dates.start.seconds)} - ${formatStart(dates.end.seconds)}` : ""}</h4>
          </span>
          <BtnContainer>
            <InButton onClick={() => null} text="" htmlType="button" primary={1}>
              <Link to={`${pathUrl}/polls`}>START PLANNING</Link>
            </InButton>
            {/* <InButton
							width='170px'
							type='button'
							onClick={() => reopenDestination()}
						>
							Reopen polls
						</InButton> */}
          </BtnContainer>
        </Image>
      </Col>
    </Row>
  );
};

ReopenDestinations.defaultProps = {};

ReopenDestinations.propTypes = {};

export default ReopenDestinations;
