import React from 'react';
import { Row, Col } from 'antd';
import styled from "styled-components";
import ImageBanner from './ImageSection'
import TextSection from './TextSection'

export const Sticky = styled.div`
    margin: 0;
    position: fixed;
    height: 100vh;
    // width: 100vw;
    
    ${props => props.theme.breakpoints.maxPhone}{
        position: relative;
      }
    
    ${props => props.theme.breakpoints.tablet}{
      width: 50vw;
    }

  ${props => props.theme.breakpoints.desktop} {
    width: 40vw;
  }
`;

function DashboardSection() {
    return (
        <Row>
            <Col item xs={24} md={12}>
                <Sticky >
                    <ImageBanner />
                </Sticky>
            </Col>
            <Col item xs={24} md={12}>
                <TextSection />
            </Col>
        </Row>
    );
}

export default DashboardSection


