import React from "react";

export default () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.33398 5.33398H42.6673" stroke="#494D5B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18.6675 2.66699H29.3341" stroke="#494D5B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M29.334 2.66699V5.33366" stroke="#494D5B" strokeWidth="2" />
    <path d="M18.6675 2.66699V5.33366" stroke="#494D5B" strokeWidth="2" />
    <path
      d="M8.00098 5.33398V25.334V44.334C8.00098 44.8863 8.44869 45.334 9.00098 45.334H39.001C39.5533 45.334 40.001 44.8863 40.001 44.334V5.33398"
      stroke="#494D5B"
      strokeWidth="2"
    />
  </svg>
);
