import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import styled from "styled-components";
import { Button, Row, Col } from "antd";
import { FormComponents, InTypography, InButton } from "../../index";
import GoogleIcon from "../../../assets/icons/google";
import FacebookIcon from "../../../assets/icons/facebook";

const FormWrapper = styled.form`
  max-width: 670px;
  width: 100%;
  margin: 47px 0 0 0;
  text-align: center;

  @media all and (min-width: 1024px) {
    margin: 27px 0 0 0;
  }

  p {
    margin: 1em 0;
  }

  .ant-form-item-label {
    text-align: left;
  }

  .ant-form-item-label label::after {
    content: "";
  }
`;

export const ForgotPassword = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  button {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 100%;
    /* identical to box height, or 13px */
    border: none;
    outline: none;
    background: none;

    display: flex;
    align-items: center;
    text-align: center;
    color: #8696ae;
  }
`;
export const SocialMedia = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;

  span {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 13px */

    display: flex;
    align-items: center;
    text-align: center;
    color: #8696ae;
  }
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
  text-transform: uppercase;
  color: #8696ae;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;

  span {
    display: flex;
    align-items: center;
    text-align: center;
    color: #101b43;
    margin: 0 0.45em;
    cursor: pointer;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
`;

const InSMButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 44px;
  background: #ffffff;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05);
  border-radius: 8px;

  font-family: "Proxima Nova Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 80%;
  color: #101b43;
  margin: 8px 0;

  &:hover {
    background: #ffffff;
    border-color: rgba(0, 0, 0, 0.3);
    color: #101b43;
  }

  &:focus {
    background: #ffffff;
    border-color: rgba(0, 0, 0, 0.3);
    color: #101b43;
  }

  svg {
    margin-right: 6px;
  }
`;
export const Disclaimer = styled.div`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #8696ae;
  border-top: 1px solid #eeeeef;
  padding: 1em 0;
`;

const RegisterForm = ({ handleSubmit, facebookLogin, handleActiveForm, googleLogin, submitUserDetails }) => {
  return (
    <FormWrapper onSubmit={handleSubmit((values) => submitUserDetails(values))}>
      <InTypography.InH1>Welcome to Invigo</InTypography.InH1>
      <InTypography.InParagraph>Get ready to invite, plan and go!</InTypography.InParagraph>
      <Row gutter={8}>
        <Col xs={24} sm={24} md={24}>
          <InSMButton type="button" onClick={googleLogin} icon={<GoogleIcon />}>
            GOOGLE
          </InSMButton>
        </Col>
        
      </Row>
      <SocialMedia>
        <span>or Sign Up with email</span>
      </SocialMedia>
      <Field
        label="Name"
        name="fullName"
        type="text"
        component={FormComponents.InInput}
        placeholder="Full Name"
        hasFeedback
      />
      <Field
        label="Email"
        name="email"
        type="email"
        component={FormComponents.InInput}
        placeholder="Your Email"
        hasFeedback
      />
      <Field
        label="Password"
        addonAfter="Minimum 8 characters"
        name="password"
        type="password"
        component={FormComponents.InInputPassword}
        placeholder="Password"
      />

      <InButton
        type="primary"
        // disabled={pristine || submitting}
        htmlType="submit"
        text="SIGN UP"
        primary
        width="100%"
        disabled={false}
      >
        Submit
      </InButton>
     
      
      <Bottom>
        Have an account? <span onClick={() => handleActiveForm("login")}>Log in</span>
      </Bottom>
     
    </FormWrapper>
  );
};

RegisterForm.defaultProps = {};

RegisterForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const validate = (values) => {
  const errors = {};
  if (!values.fullName) {
    errors.fullName = "Required";
  }

  if (!values.email) {
    errors.email = "Required";
  }

  if (!values.password) {
    errors.password = "Required";
  }

  if (values && values.password && values.password.length < 8) {
    errors.password = "Your password is too short";
  }

  return errors;
};

const Register = reduxForm({
  form: "registerUser",
  validate,
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(RegisterForm);

export default Register;
