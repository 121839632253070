import React, { Component } from 'react';
import styled from "styled-components"
import banner from './join-now-pimary.png'
import banner2 from './join-now-secondary.png'
import getStarted from './decor-join-bg.svg'
import elemnt from '../../../../../assets/home/ditch-the-group-threads-element.png'

const HeaderWrapper = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 16px;

  ${props => props.theme.breakpoints.tablet}{
    margin-bottom: 0;
}
`;

const ImageWrapper = styled.div`
  margin-top: 28px;
  width: 90vw;
      height: 460px;
  z-index: 2;
  position: relative;
  
  ${props => props.theme.breakpoints.tablet}{
      width: 470px;
      height: 460px;
  }
`;

const SmallImageWrapper = styled.div`
  width: 119px;
  height: 119px;
  right: -25px;
  z-index: 2;
  position: absolute;
  bottom: 1em;
  border: 8px solid #FFFFFF;
  border-radius: ${props => props.reverse ? '30px 0;' : '0 30px;'};
  
   ${props => props.theme.breakpoints.tablet}{
      width: 236px;
      height: 236px;
      right: -85px;
      bottom: 2em;
      border-radius: ${props => props.reverse ? '60px 0;' : '0 60px;'};
  }
`;

const Image = styled.div`
    width: 100%;
    height: 100%;
    background: transparent url(${props => props.src}) no-repeat center;
    background-size: cover;
    transform-style: preserve-3d;
    transition: all 0.5s ease;
    border-radius: ${props => props.reverse ? '30px 0;' : '0 30px;'};
    position: absolute;
    
    ${props => props.theme.breakpoints.tablet}{
      border-radius: ${props => props.reverse ? '60px 0;' : '0 60px;'};
  }
`;

const Element = styled.img`
    z-index: 0;
    position: absolute;
    top: -140px;
    right:  -160px;
`;

class CarouselComponent extends Component {
  render() {
    return (
      <HeaderWrapper>
          
            <ImageWrapper>
              <Element src={elemnt} />
              <Image src={banner} />
              <SmallImageWrapper reverse>
                <Image src={banner2} reverse />
              </SmallImageWrapper>
            </ImageWrapper>
      </HeaderWrapper>
    );
  }
}

export default CarouselComponent
