import guestStepsSetDetails from './guestStepsSetDetails'
import brideStepsSetDetails from './brideStepsSetDetails'
import guestStepsUnsetDetails from './guestStepsUnsetDetails'
import brideStepsUnsetDetails from './brideStepsUnsetDetails'

export default {
    guestStepsSetDetails,
brideStepsSetDetails,
guestStepsUnsetDetails,
brideStepsUnsetDetails,
}