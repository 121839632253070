import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { guests, chat } from "../../helpers/firestore";
export const ChatContext = React.createContext()

class ChatProvider extends Component {
  constructor(props) {
    super(props)

    this.state = {
      guests: [],
      allGroupChats: [],
      activeGroup: null,
      open: false,
      chatThread: null
    }
  }

  componentDidMount() {
    this.getData(this.props.eventId)
  }

  setChatThread = (chatThread) => {
    this.setState({ chatThread })
  };

  setActiveGroupChat = (activeGroup) => {
    this.setState({ activeGroup })
    chat.getChatThread(this.props.eventId, activeGroup.id, this.setChatThread)
  };

  setGuests = (guests) => {

    this.setState({ guests })
  };

  setChatList = (allGroupChats) => {
    if(allGroupChats.length)this.setActiveGroupChat(allGroupChats[0])
    this.setState({ allGroupChats })
  };

  getData = (eventId) => {
    guests.get(eventId, this.setGuests)
    chat.getAllChats(eventId, this.setChatList)
  }

  render() {
    const { guests, allGroupChats, activeGroup, chatThread } = this.state
    const { children, eventId, uid, pathUrl  } = this.props

    const providerData = {
      uid,
        pathUrl,
      guests,
      eventId,
      allGroupChats,
      chatThread,
      activeGroup,
      setActiveGroupChat: this.setActiveGroupChat
    }
    return (
      <ChatContext.Provider value={providerData}>
        {children}
      </ChatContext.Provider>
    )
  }
}

ChatProvider.defaultProps = {
//<!--  children: PropTypes.object-->
};

ChatProvider.propTypes = {
//<!--  children: {}-->
};

function mapStateToProps (state) {
  return {
    uid: state.auth.user.user.uid,
  }
}

export default connect(mapStateToProps, null)(ChatProvider)