import React from 'react'
import styled from 'styled-components'
import { Space, Menu, Dropdown } from "antd";
import {  Link } from "react-router-dom"
import { BellOutlined, DeleteOutlined, EllipsisOutlined } from "@ant-design/icons";
import { InTypography, SearchInput, TableImage } from '../../../../components'
import { EventsContext } from "../../EventsProvider";

const travelArt = 'https://res.cloudinary.com/pcgwa/image/upload/v1600697385/invigo/email/past-events.png'
const TableWrapper = styled.div`

`

const SearchBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    
    @media (max-width: 768px) {
      flex-direction: column;
    }
  
`

const Table = styled.div`
    background: ${props => props.header ? 'none' : '#ffffff'};
    border-radius: 8px;
    margin: 1em 0;
    padding: 1em 0;
    cursor: pointer;
    ${props => props.header ? '' : 'box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.2); border: 1px solid rgba(0, 0, 0, 0.1);'};
      
    @media (min-width: 1024px) {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        min-height: 64px;
    }

    @media all and (max-width: 1023px) {
      display: ${props => props.header ? 'none' : 'block'};
    }
`

const TableCell = styled.div`
    width: ${props => props.width ? props.width : '11.42%'};
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 64px;
    padding: 0 1em;
    
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #101B43;
    text-align: left;
    text-transform: capitalize;
    
    span {
      text-align: left;
      width: 100%;
    }
  
    @media all and (max-width: 1023px) {
        width: 100%;
        padding: 0.5em 1em;
    }
`

const RowFooter = styled.div`
    width: 5%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    flex-grow: 1;
    height: 100%;
    padding: 0.5em 1em;
    
    @media all and (max-width: 1023px) {
      width: 100%;
    }
    
    @media (min-width: 1024px) {
        border-radius: 0 8px 8px 0;
    }
`

const ActionMenu = styled(Menu)`
    width: 160px;
    
`

const ActionItem = styled(Menu.Item)`
    width: 160px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #101B43;
    
    &:hover {
      color: ${props => props.color};
    }

`

const MenuAction = ({ id }) => {
    return (
        <ActionMenu>
            <ActionItem color='#2E2C9A' icon={<BellOutlined />} >
                Remind
            </ActionItem>
            <ActionItem color='#EE4238' icon={<DeleteOutlined />}>
                Delete
            </ActionItem>
        </ActionMenu>
    )
};

const DropMenu = styled(Dropdown)`
  cursor: pointer;
`

const createActions = (data) => {
    return (
        <Space size="middle">
            <DropMenu
                overlay={<MenuAction id={data.id} />}
                placement="bottomRight"
            >
                <EllipsisOutlined style={{ fontSize: 28 }} />
            </DropMenu>
        </Space>
    )
}



const HeaderCell = styled.div`
    width: ${props => props.width ? props.width : '11.42%'};
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;
    padding: 0 1em;
    
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 100%;
    color: #8696AE;
  
    @media all and (max-width: 1023px) {
        width: 100%;
    }
`

const HeaderFooter = styled.div`
    width: 5%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    flex-grow: 1;
    height: 100%;
    padding: 0.8em 1.2em;
    
    @media all and (max-width: 1023px) {
      width: 100%;
    }
    
    @media (min-width: 1024px) {
        border-radius: 0 8px 8px 0;
    }
`

const Title = styled.p`
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #2E2C9A;
    margin: 0;
    padding: 0;
`

const LinkTo = styled(Link)`
  text-decoration: none;
 display: flex;
 width: 100%;
`

function createMarkup(displayName) {
    return {__html: `Hey <span>${displayName}</span>, you are invited to:`};
}

const RenderRows = ({ tableData }) => {
    return tableData.map((data, index) => {
        return (
            <Table key={data.key}>
                <TableCell width='20%' >
                    <LinkTo to={`/event-guest-view/${data.id}/dashboard`}>
                        <Title>{data.name}</Title>
                    </LinkTo>
                </TableCell>
                <TableCell width='20%'>
                    {data.dates ? data.dates.map((item, index) => <span key={index}>{item}</span>) : '-'}
                </TableCell>
                <TableCell width='20%'>
                    {data.destination ? data.destination.map((item, index) => <span key={index}>{item}</span>) : '-'}
                </TableCell>
                <TableCell width='15%'>
                    {data.rsvp}
                </TableCell>
                <TableCell width='15%'>
                    {data.status ? data.status : '-'}
                </TableCell>
                <RowFooter>
                    {/* {createActions({ id: data.key })} */}
                </RowFooter>
            </Table>
        );
    })
};

const ResponsiveTable = ({ action }) => {
    return (
        <EventsContext.Consumer>
            {({
                  planned,
                  invited,
                  displayName,
                  showCancel,
                  showCancelModal,
                  hideCancelModal,
                  cancelEventId,
                  showDrawer,
              }) => {
                return (
                    <div>
                        <TableWrapper>
                            <SearchBar>
                                <InTypography.InH1 dangerouslySetInnerHTML={createMarkup(displayName)} />
                                <SearchInput />
                            </SearchBar>
                            <Table header>
                                <HeaderCell width='20%'>EVENT NAME</HeaderCell>
                                <HeaderCell width='20%'>DATE</HeaderCell>
                                <HeaderCell width='20%'>DESTINATION(S)</HeaderCell>
                                <HeaderCell width='15%'>RSVP</HeaderCell>
                                <HeaderCell width='15%'>EVENT STATUS</HeaderCell>
                                <HeaderFooter />
                            </Table>
                            {invited.length 
                            ? <div>
                                <RenderRows tableData={invited} /> 
                                <TableImage image={travelArt} width="25vw" />
                            </div>
                            : <TableImage image={travelArt} width="25vw" />}
                        </TableWrapper>
                    </div>
                )
            }}
        </EventsContext.Consumer>
    );
};

ResponsiveTable.defaultProps = {

};

ResponsiveTable.propTypes = {

};

export default ResponsiveTable;