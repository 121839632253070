import React from 'react'
import styled from "styled-components";
import { InTypography } from '../../'

export const Image = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    flex-direction: column;
    background: transparent url(${props => props.src}) no-repeat center;
    background-size: cover;
    //margin-top: 5vh;
`;

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: ;
    text-align: center;
    
`;

export const Subtitle = styled(InTypography.InH3)`
    margin: 1em 0
`;

export const BudgetIntro = ({next}) => {
    return (
        <Container >
            <InTypography.InH1>
                Girls just wanna have fund$!
            </InTypography.InH1>
            <Subtitle>
                We’ll ask you a few questions to help set your budget.
            </Subtitle>
        </Container>
    )
}