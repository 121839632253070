import React from "react";
import { useHistory } from "react-router-dom";
import { RsvpWizard } from "../../components";
import RsvpProvider from "./RsvpProvider";

const Rsvp = (props) => {
  const history = useHistory();
  return (
    <RsvpProvider
      eventId={props.match.params.eventId}
      userId={props.match.params.userId}
      eventParams={props.match && props.match.params ? props.match.params : null}
      history={history}
    >
      <RsvpWizard />
    </RsvpProvider>
  );
};

Rsvp.defaultProps = {};

Rsvp.propTypes = {};

export default Rsvp;
