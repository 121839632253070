import React from 'react';
import styled from "styled-components";
import {Menu} from "antd";

const Wrapper = styled.svg`
    fill: #494D5B;
     stroke: #494D5B;
    
    &:hover {
      fill: #2E2C9A;
     stroke: #2E2C9A;
    }
`

export default ({ style }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
        <rect x="0.5" y="0.5" width="6" height="5" rx="0.5" fill="white" stroke="white"/>
        <rect x="8.5" y="11.5" width="7" height="4" rx="0.5" fill="white" stroke="white"/>
        <rect x="8.5" y="0.5" width="7" height="9" rx="0.5" fill="white" stroke="white"/>
        <rect x="0.5" y="7.5" width="6" height="8" rx="0.5" fill="white" stroke="white"/>
    </svg>
);
