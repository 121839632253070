import React from "react";
import PropTypes from "prop-types";
import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import EventProvider, { EventContext } from "./EventProvider";
import Dashboard from "./Dashboard";
import styled from "styled-components";

const Container = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  //max-height: 90vh;
`;

const Event = (props) => {
  useFirestoreConnect(() => [{ collection: "events", doc: props.match.params.eventId }]);
  const event = useSelector(({ firestore: { data } }) => data.events && data.events[props.match.params.eventId]);

  return (
    <EventProvider event={event} pathUrl={props.url}>
      <EventContext.Consumer>
        {() => {
          return (
            <Container>
              <Dashboard />
            </Container>
          );
        }}
      </EventContext.Consumer>
    </EventProvider>
  );
};

Event.defaultProps = {};

Event.propTypes = {};

export default Event;
