import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, initialize, formValueSelector } from 'redux-form'
import { connect } from "react-redux";
import styled from "styled-components";
import { Button, Radio, Form } from "antd";
import { InInput, InTextarea } from "./FormComponents";
import { SaveOutlined } from "@ant-design/icons";
import { polls } from '../../../helpers/firestore'
import FileUpload from "../../../components/FileUpload";

const FormItem = Form.Item;

const FormWrapper = styled.form`
max-width: 670px;
margin: 0 auto;
  .ant-form-item-label {
    text-align: left;
  }
  
  .ant-form-item-label label::after {
    content: '';
}
`

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
`

const IconButton = styled(Button)`
    background: ${props => props.disabled ? '#EDEDED' : '#2E2C9A'};
    border-color: #2E2C9A;
    height: 44px;
    border-radius: 4px;
    color: ${props => props.disabled ? '#ffffff' : '#ffffff'};
    text-transform: uppercase;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 80%;
    display: flex;
    align-items: center;
  
  &:hover {
    background: ${props => props.disabled ? '#2E2C9A' : '#2E2C9A'};
    // border-color: #2E2C9A;
    color: ${props => props.disabled ? '#ffffff' : '#ffffff'};
  }
  
  &:focus {
    background: ${props => props.disabled ? '#2E2C9A' : '#2E2C9A'};
    // border-color: #2E2C9A;
    color: ${props => props.disabled ? '#ffffff' : '#ffffff'};
  }

`

export const RadioItem = styled(FormItem)`
  .ant-radio-button {
    color: #2E2C9A;
    border-radius: 4px;
    border: 1px solid #EEEEEF;
  }
  
  
  
  .ant-radio-button-checked {
    color: #2E2C9A;
    border: 1px solid #EEEEEF;
  }
  
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #2E2C9A;
    border: 1px solid #2E2C9A;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #2E2C9A;
}

.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-right-color: #2E2C9A;
}
  
  label {
    margin-right: 1em;
    color: #2E2C9A;
    font-family: 'Proxima Nova Semibold';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 100%;
  }
  
  &:hover {
      label {
        color: #2E2C9A;
      }
  }
`

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 24 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 24 }
	}
};

const options = [
	{ label: '$', value: '$' },
	{ label: '$$', value: '$$' },
	{ label: '$$$', value: '$$$' },
	{ label: '$$$$', value: '$$$$' },
];

const DollarSign = ({ input, meta, children, hasFeedback, label, ...rest }) => {
	const [rating, setRating] = useState(0);

	const handleRating = (value) => {
		setRating(value)
	};

	const handleChange = (e, input) => {
		return input.onChange(e)
	}
	const hasError = meta.touched && meta.invalid;
	return (
		<RadioItem
			{...formItemLayout}
			label={label}
			validateStatus={hasError ? "error" : "success"}
			hasFeedback={hasFeedback && hasError}
		// help={hasError && meta.error}
		>
			<Radio.Group
				options={options}
				onChange={(e) => handleChange(e, input)}
				value={rating}
				optionType="button"
				{...input}
				{...rest}
				children={children}
				size="large"
			/>
		</RadioItem>
	)
}

const AddPollOption = ({
	handleSubmit,
	pristine,
	submitting,
	reset,
	setCustomPollData,
	closePollOption,
	eventId,
	formInit,
	formState,
	customPollData,
	url,
	name,
	price
}) => {


	function initForm(data) {
		const dataObj = formState && formState.values ? { ...formState.values, ...data } : { ...data }
		formInit(dataObj);
	}

	const submitValues = async (eventId, values, closePollOption, customPollData) => {
		const newOptions = await polls.addPollOptionToArray(eventId, values, closePollOption, customPollData)
		setCustomPollData(newOptions)
	}
	return (
		<FormWrapper onSubmit={handleSubmit(values => submitValues(eventId, values, closePollOption, customPollData))}>
			<FormItem>
				<Field label="Name of Option" name="name" type='text' component={InInput} placeholder="Name" />
			</FormItem>
			<FormItem>
				<Field label="URL" name="url" type='text' component={InInput} placeholder="Type URL link" />
			</FormItem>
			<FormItem>
				<Field label="Note" name="note" type='textarea' component={InTextarea} placeholder="Note" />
			</FormItem>
			<FormItem>
				<Field label="Image" name="image" type='file' component={FileUpload} placeholder="image" initForm={initForm} />
			</FormItem>
			<FormItem>
				<Field label="Price" name="price" component={DollarSign} placeholder="Price" />
			</FormItem>
			<IconButton
				primary
				icon={<SaveOutlined size='large' />}
				disabled={!url || !name || !price}
				htmlType="submit"
			>
				SAVE
			</IconButton>
		</FormWrapper>
	);
};

AddPollOption.defaultProps = {

};

AddPollOption.propTypes = {
	handleSubmit: PropTypes.func.isRequired
};

const validate = values => {
	const errors = {};
	if (!values.url) {
		errors.url = "Required";
	}

	if (!values.name) {
		errors.name = "Required";
	}

	if (!values.price) {
		errors.price = "Required";
	}
	return errors;
};

const CreateTaskForm = reduxForm({
	form: `addPollOption`,
	validate,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true
})(AddPollOption)


const selector = formValueSelector('addPollOption')

export function mapStateToProps(state) {
	return {
		uid: state.auth.user.user.uid,
		formState: state.form.addPollOption,
		url: selector(state, 'url'),
		name: selector(state, 'name'),
		price: selector(state, 'price'),
	}
}

function mapDispatchToProps(dispatch) {
	return {
		formInit: (data) => {
			dispatch(initialize('addPollOption', data))
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTaskForm)