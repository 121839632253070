import React from 'react';
import styled from "styled-components";
import {Menu} from "antd";

const Wrapper = styled.svg`
    fill: #494D5B;
     stroke: #494D5B;
    
    &:hover {
      fill: #2E2C9A;
     stroke: #2E2C9A;
    }
`

export default ({ style }) => (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="43" height="43" rx="3.5" fill="white" stroke="#EEEEEF"/>
        <path d="M30.6315 23.975C30.3687 23.975 30.1557 24.188 30.1557 24.4508V28.6753C30.1548 29.4631 29.5164 30.1017 28.7284 30.1026H15.3788C14.5908 30.1017 13.9525 29.4631 13.9515 28.6753V16.2773C13.9525 15.4893 14.5908 14.8509 15.3788 14.85H19.6031C19.8659 14.85 20.0789 14.637 20.0789 14.3742C20.0789 14.1114 19.8659 13.8984 19.6031 13.8984H15.3788C14.0656 13.8999 13.0015 14.9641 13 16.2773V28.6753C13.0015 29.9885 14.0656 31.0527 15.3788 31.0541H28.7284C30.0416 31.0527 31.1057 29.9885 31.1072 28.6753V24.451C31.1072 24.1882 30.8943 23.975 30.6315 23.975Z" fill="#4F4DB8"/>
        <path d="M20.4414 21.4723L27.3882 14.5254L29.6286 16.7658L22.6818 23.7127L20.4414 21.4723Z" fill="#4F4DB8"/>
        <path d="M19.399 24.5046C19.3575 24.6544 19.4953 24.7922 19.6451 24.7507L21.7805 24.1592L19.9905 22.3691L19.399 24.5046Z" fill="#4F4DB8"/>
        <path d="M30.2453 13.3478C29.7805 12.8841 29.028 12.8841 28.5632 13.3478L28.0586 13.8523L30.299 16.0927L30.8036 15.5881C31.2674 15.1233 31.2674 14.3709 30.8036 13.906L30.2453 13.3478Z" fill="#4F4DB8"/>
    </svg>
);
