import React, {useState} from 'react'
import styled from 'styled-components'
import GuestEditForm from "./GuestEditForm";


const Wrapper = styled.div`
  max-width: 670px;
`

const GuestList = ({ countryCode, initForm, handleCancel, editGuest, guestList }) => {
    const inputElement = React.createRef()

    const [currentItem, setCurrentItem ] = useState({
        name: '',
        email: '',
        mobileNumber: '',
        key: '',
    });

    const [errors, setErrors ] = useState({
        name: false,
        email: false,
        mobileNumber: false,
    });

    React.useEffect(() => {

        if(editGuest){    
            setCurrentItem({
                name: editGuest.name,
                email: editGuest.email,
                mobileNumber: editGuest.mobileNumber,
                key: editGuest.key,
               });
        }
    
    }, [ editGuest, setCurrentItem ]);

    const handleName = e => {
        const name = e.target.value
        setCurrentItem({
            ...currentItem,
            name: name
        })
    }

    const handleMobileNumber = number => {
        if(number) {
            setCurrentItem({
                ...currentItem,
                mobileNumber: number
            })
        }
    }

    const handleEmail = e => {
        const email = e.target.value
        setCurrentItem({
            ...currentItem,
            email: email
        })
    }

    const addGuestAndComplete = (newGuest) => {
        if (newGuest.name !== '' && newGuest.email !== '' && newGuest.mobileNumber !== '') {
            const filteredItems = guestList.map(item => {
                return item.key === newGuest.key ? newGuest : item
            })
            initForm({ guestList: filteredItems });
            setCurrentItem({
                name: '',
                email: '',
                mobileNumber: '',
                key: '',
            })
            handleCancel()
        } else {
            setErrors({
                name: newGuest.name === '',
                email: newGuest.email === '',
                mobileNumber: newGuest.mobileNumber === '',
            })
        }
    }

  return (
    <Wrapper>
        <GuestEditForm
            done={addGuestAndComplete}
            inputElement={inputElement}
            handleName={handleName}
            handleEmail={handleEmail}
            handleMobileNumber={handleMobileNumber}
            editGuest={editGuest}
            currentItem={currentItem}
            countryCode={countryCode}
            disabled={setCurrentItem.name === '' || setCurrentItem.email === '' || setCurrentItem.mobileNumber === ''}
            errors={errors}
        />
    </Wrapper>
  );
};

GuestList.defaultProps = {

};

GuestList.propTypes = {

};

export default GuestList;
