import React from "react";
import styled from "styled-components";

const Modal = styled.div`
  background: white;
  border: 1px solid #d0cccc;
  transition: all 0.3s;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  padding: 1em;
  overflow-y: scroll;
`;
const Content = styled.div`
  padding: 0;
  max-width: 1400px;
  height: 100%;
  margin-top: 100px;
  z-index: 11;
`;

const modal = ({ visible, handleCancel, children }) => {
  return (
    <Modal
      style={{
        transform: visible ? "translateY(0vh)" : "translateY(-100vh)",
        opacity: visible ? "1" : "0",
        display: visible ? "block" : "none",
      }}
    >
      <Content>{children}</Content>
    </Modal>
  );
};

export default modal;
