import React from 'react';
import styled from "styled-components";
import {Menu} from "antd";

const Wrapper = styled.svg`
    fill: #494D5B;
     stroke: #494D5B;
    
    &:hover {
      fill: #2E2C9A;
     stroke: #2E2C9A;
    }
`

export default ({ style }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
        <rect x="0.5" y="1.5" width="15" height="14" rx="1.5" stroke="#4F4DB8"/>
        <path d="M15.5 6.33398H0.5C0.223999 6.33398 0 6.10999 0 5.83398C0 5.55798 0.223999 5.33398 0.5 5.33398H15.5C15.776 5.33398 16 5.55798 16 5.83398C16 6.10999 15.776 6.33398 15.5 6.33398Z" fill="#4F4DB8"/>
        <path d="M3.83301 4C3.55701 4 3.33301 3.776 3.33301 3.5V0.5C3.33301 0.223999 3.55701 0 3.83301 0C4.10901 0 4.33301 0.223999 4.33301 0.5V3.5C4.33301 3.776 4.10901 4 3.83301 4Z" fill="#4F4DB8"/>
        <path d="M12.167 4C11.891 4 11.667 3.776 11.667 3.5V0.5C11.667 0.223999 11.891 0 12.167 0C12.443 0 12.667 0.223999 12.667 0.5V3.5C12.667 3.776 12.443 4 12.167 4Z" fill="#4F4DB8"/>
        <rect x="3" y="9" width="2" height="2" rx="1" fill="#4F4DB8"/>
        <rect x="7" y="9" width="2" height="2" rx="1" fill="#4F4DB8"/>
        <rect x="11" y="9" width="2" height="2" rx="1" fill="#4F4DB8"/>
    </svg>
);
