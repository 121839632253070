import React, { useEffect, useState } from "react";
import { Modal, Row, Col } from "antd";
import styled, { createGlobalStyle } from "styled-components";
import landing from "../../assets/login/login.png";
import { settings } from "../../helpers/firestore";
import { InTypography, InButton } from "../../components";

const GlobalStyle = createGlobalStyle`
		.login-modal .ant-modal-close {
			left: 15px;
			right: auto;
		}

		.loginform.login-modal .ant-modal,
    .loginform.login-modal .ant-modal-content {
			height: 100%;
		}
    .login-modal .ant-modal,
    .login-modal .ant-modal-content {
			height: auto;
      width: 100vw !important;
			max-width: 100vw;
      margin: 0;
      top: 0 !important;
			margin: 0;
			border-radius: 0;
			top: 0;
    }
	

		
		.loginform.login-modal .ant-modal-body {
			height: 100%;
		}
    
		.login-modal .ant-modal-body {
			height: auto;
			padding: 0;
    }
		@media all and (max-width: 767px) {
			.login-modal .ant-modal-body {
			height: auto;
			min-height: 100vh;
			padding: 0px;
    }
		}
    .login-modal .modal-dialog {
      max-width: 100%;
      margin: 0;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100vh;
      display: flex;
    }

		@media all and (min-width: 768px) {
			.login-modal .ant-modal,
    .login-modal .ant-modal-content,.login-modal .ant-modal-body {
			height: 100%;
		}
		}
`;

const ModalWrapper = styled(Modal)`
  padding: 0;
  .ant-modal-content {
    height: 100%;
    border-radius: 0px;
  }

  .ant-modal-close {
    top: 20px;
    right: 20px;
    display: none;
  }
  .ant-modal-close-x {
    display: none;
    border: 1px solid #eeeeef;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: #494d5b;
  }
`;

export const RowLogin = styled(Row)`
  height: 100%;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  color: #8696ae;
  cursor: pointer;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;

  span {
    color: #101b43;
    margin: 0 0.4em;
  }
`;

export const Image = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: column;
  background: transparent url(${(props) => props.src}) no-repeat 70% center;
  background-size: cover;
  //margin-top: 5vh;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h3 {
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
`;

const Unsubscribe = ({ userId }) => {
  const [unsubscribe, setUnsubscribe] = useState(false);
  useEffect(() => {
    async function getUserdata() {
      settings
        .get(userId)
        .then((userData) => {
          userData && userData.hasOwnProperty("sendEmail") && setUnsubscribe(!userData?.sendEmail);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (userId) {
      getUserdata();
    }
  }, [userId]);

  const handleUnsubcribe = async () => {
    if (!userId) return;
    settings
      .unsubscibe(userId)
      .then(() => {
        setUnsubscribe(true);
      })
      .catch((error) => {
        setUnsubscribe(true);
        console.log(error);
      });
  };

  return (
    <>
      <GlobalStyle />
      <ModalWrapper title="" transitionName="" visible={true} footer={null} wrapClassName={"loginform login-modal"}>
        <RowLogin>
          <Col xs={0} sm={12}>
            <Image src={landing} />
          </Col>
          <Col xs={24} sm={{ span: 8, offset: 2 }}>
            <Content>
              <InTypography.InH1>Unsubcribe</InTypography.InH1>

              {!unsubscribe ? (
                <Wrapper>
                  <InTypography.InH3>Do you really want to unsubcribe from Invigo newsletters?</InTypography.InH3>
                  <InButton type="button" onClick={() => handleUnsubcribe()} text="Unsubcribe"></InButton>
                </Wrapper>
              ) : (
                <Wrapper>
                  <InTypography.InH3>You're already unsubscribed</InTypography.InH3>
                  <InTypography.InParagraph>You can resubscribe in the settings</InTypography.InParagraph>
                </Wrapper>
              )}

              <Container
                onClick={() => {
                  window.location.href = "/";
                }}
              >
                BACK TO <span> MAIN</span>
              </Container>
            </Content>
          </Col>
        </RowLogin>
      </ModalWrapper>
    </>
  );
};

export default Unsubscribe;
