import {
  DISPLAY_TOAST,
} from "../actions";

export default (
  state = {
    message: null
  },
  action
) => {
  switch (action.type) {
    case DISPLAY_TOAST:
      return {
        ...state,
        message: action.message,
      };
    default:
      return state;
  }
};
