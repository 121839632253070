import React, { useRef, useState } from 'react';
import styled from "styled-components";
// import { Mentions, Form } from 'antd';
import {Link} from "react-router-dom";
import {ChatContext} from "../../../containers/Chat/ChatProvider";
import SendIcon from '../../../assets/icons/sendIcon'
// import { chat } from '../../../helpers/firestore'
// import TimeAgo from 'javascript-time-ago'

import firebase from 'firebase/app';

import { auth, db } from '../../../config/firebase';

import { useCollectionData } from 'react-firebase-hooks/firestore';

// Load locale-specific relative date/time formatting rules.
// import en from 'javascript-time-ago/locale/en'

// Add locale-specific relative date/time formatting rules.
// TimeAgo.addLocale(en)

// Create relative date/time formatter.
// const timeAgo = new TimeAgo('en-US')

const InputForm = styled.form`
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 64px;
    padding: 0;
    margin: 0;
    background: #FFFFFF;
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.05);
    border-radius: 0px 0px 8px 8px;

    button {
        width: 20%;
        background-color: #ffffff;
      }
      
      
      input {
        line-height: 1.5;
        width: 100%;
        height: 100%;
        background: #FFFFFF;
        outline: none;
        border: none;
        padding: 0 16px;
        border-radius: 0px 0px 8px 8px;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #101B43;
      }
      
      button {
        background-color: #ffffff; 
        border: none;
        color: white;
        padding: 15px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        cursor: pointer;
        font-size: 1.25rem;

        svg {
            fill: #4F4DB8;

        }
      }
      
      button:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
`

const Wrapper = styled.div`
    width: 100%;
    height: 80vh;
    background: white;
    padding: 0;
    border-radius: 8px 8px 0 0;
    position: relative;
`
const HeaderBar = styled.div`
    width: 100%;
    height: 64px;
    background: white;
    padding: 12px 20px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
    border-radius: 8px 8px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    h4 {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 140%;
        color: #101B43;
        margin: 0;
        padding: 0;
    }
    
    p {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        color: #8696AE;
        margin: 0;
        padding: 0;
    }
    
    a {
       font-family: 'Proxima Nova Semibold';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 80%;
        text-transform: uppercase;
        color: #2E2C9A;
    }
`
// **************************************************************


const MainThread = styled.div`
    padding: 0;
    margin: 0;
    
`


const ThreadContent = styled.div`
height: 70vh;
overflow-y: scroll;
padding: 10px;
display: flex;
flex-direction: column;
padding-bottom: 50px;
`
const Message = styled.div`

p {
    max-width: 500px;
    min-height: 44px;
    margin-bottom: 12px;
    line-height: 24px;
    padding: 10px 20px;
    border-radius: 25px;
    position: relative;
    color: white;
    text-align: left;
  }
  
  .message {
    display: flex;
    align-items: center;
  }
  
  
  .sent {
    flex-direction: row-reverse;
  }
  
  .sent p {
    background: #FF8E95;
    align-self: flex-end;
    box-shadow: 0 1px 2px #E5E5E5;
    border-radius: 8px 8px 0 8px;
  }
  .received p {
    background: #4F4DB8;
    box-shadow: 0 1px 2px #E5E5E5;
    border-radius: 8px 8px 8px 0;
  }
  
  span {
    height: 75px;
    display: flex;
    align-items: flex-start;
  }
  img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 2px 5px;
  }
`

function ChatRoom({ activeGroup }) {
    const dummy = useRef();
    const messageRef = db.collection(`events`).doc(activeGroup.eventId).collection('chat-group').doc(activeGroup.id).collection('messages');

    const query = messageRef.orderBy('createdAt').limit(25);
  
    const [messages] = useCollectionData(query, { idField: 'id' });
  
    const [formValue, setFormValue] = useState('');
  
  
    const sendMessage = async (e) => {
      e.preventDefault();
  
      const { uid, photoURL, displayName } = auth.currentUser;
  
      await messageRef.add({
        id: messageRef.id,
        groupId: activeGroup.id,
        text: formValue,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        uid,
        photoURL,
        displayName
      })
  
      setFormValue('');
      dummy.current.scrollIntoView({ behavior: 'smooth' });
    }
  
    return (
    <MainThread>
      <ThreadContent>
  
        {messages && messages.map(msg => <ChatMessage key={msg.id} message={msg} />)}
  
        <span ref={dummy}></span>
  
        <InputForm onSubmit={sendMessage}>
            <input value={formValue} onChange={(e) => setFormValue(e.target.value)} placeholder="Start typing..." />
            <button type="submit" disabled={!formValue}><SendIcon /></button>
        </InputForm>
      </ThreadContent>
  
     
    </MainThread>)
  }
  
  
  function ChatMessage(props) {
    const { text, uid, photoURL, displayName } = props.message;
  
    const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';
  
    return (
    <Message>
      <div className={`message ${messageClass}`}>
        <span><img src={photoURL} alt='' /></span>
        <div>
            <h5>{displayName ? displayName : 'Guest'}</h5>
            <p>{text}</p>
        </div>
      </div>
    </Message>)
  }

export const ChatThread = ({ activeGroup }) => {
    return (
        <ChatContext.Consumer>
            {({ pathUrl }) => {
    
                return (
                    <Wrapper>
                        <HeaderBar>
                            <div>
                                <h4>{activeGroup.name}</h4>
                                <p>{activeGroup.members.length} members</p>
                            </div>
                            <Link to={`${pathUrl}/task-lists`}>GO TO TASKS </Link>
                        </HeaderBar>
                        <ChatRoom activeGroup={activeGroup} />
                    </Wrapper>
                )
            }}
        </ChatContext.Consumer>
    
    )
};