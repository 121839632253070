import firebase from "firebase";
import { db } from "../../config/firebase";
import { generateRandomPassword } from "../generateRandomPassword";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

function getTaskListData(eventId, setTaskListData) {
	const eventRef = db.collection("events").doc(eventId);
	const sfDocRef = eventRef
		.collection(`task-lists`)
		.where("eventId", "==", eventId)
		.orderBy("timestamp", "desc");

	return sfDocRef.onSnapshot(async (querySnapshot) => {
		let taskList = [];
		await querySnapshot.forEach((doc) => {
			taskList.push(doc.data());
		});
		setTaskListData(taskList);
		return taskList;
	});
}

function sortDates(dateItem) {
	return moment(dateItem).unix();
}

const updateTaskList = (eventId, taskListId, task, activeList) => {
	const uid = uuidv4();
	const newTask = [
		{
			id: uid,
			taskListId: taskListId,
			listName: activeList.name,
			name: task.name,
			due: task.due === "" ? "" : sortDates(task.due),
			assignTo: task.assignTo,
			description: task.description,
			completed: false,
		},
	];

	const eventRef = db
		.collection(`events`)
		.doc(eventId)
		.collection("task-lists")
		.doc(taskListId);
	return eventRef
		.set(
			{
				tasks: activeList.tasks
					? [...activeList.tasks, ...newTask]
					: [...newTask],
			},
			{ merge: true }
		)
		.then(function () {
			return true;
		})
		.catch((error) => {
			// The document probably doesn't exist.
			console.error("Error updating document: ", error);
			return false;
		});
};

const updateTaskChecked = (task, checked) => {
	const { eventId, taskListId } = task;

	// Set the guest preferences
	const taskListRef = db
		.collection(`events`)
		.doc(eventId)
		.collection("task-lists")
		.doc(taskListId);

	// Update the confirmed guests
	// const eventRef = db.collection(`events`).doc(eventId);

	// / Create a reference to the SF doc.
	return db
		.runTransaction((transaction) => {
			return transaction.get(taskListRef).then((taskListDoc) => {
				if (!taskListDoc.exists) {
					throw "Document does not exist!";
				}

				const oldDoc = taskListDoc.data();

				const dbTask = oldDoc.tasks.filter((oldTask) => oldTask.id === task.id);

				const oldTask = dbTask[0];

				transaction.update(taskListRef, {
					tasks: firebase.firestore.FieldValue.arrayRemove(oldTask),
				});

				task.completed = checked;
				transaction.update(taskListRef, {
					tasks: firebase.firestore.FieldValue.arrayUnion(task),
				});
			});
		})
		.then(function () {
			return true;
		})
		.catch(function (err) {
			// This will be an "population is too big" error.
			console.error(err);
		});
};

const updateTask = (task) => {
	const { eventId, taskListId } = task;

	// Set the guest preferences
	const taskListRef = db
		.collection(`events`)
		.doc(eventId)
		.collection("task-lists")
		.doc(taskListId);

	// Update the confirmed guests
	// const eventRef = db.collection(`events`).doc(eventId);

	// / Create a reference to the SF doc.
	return db
		.runTransaction((transaction) => {
			return transaction.get(taskListRef).then((taskListDoc) => {
				if (!taskListDoc.exists) {
					throw "Document does not exist!";
				}

				const oldDoc = taskListDoc.data();

				const dbTask = oldDoc.tasks.filter((oldTask) => oldTask.id === task.id);
				const oldTask = dbTask[0];

				transaction.update(taskListRef, {
					tasks: firebase.firestore.FieldValue.arrayRemove(oldTask),
				});

				// task.completed = checked;

				transaction.update(taskListRef, {
					tasks: firebase.firestore.FieldValue.arrayUnion(task),
				});
			});
		})
		.then(function () {
			return true;
		})
		.catch(function (err) {
			// This will be an "population is too big" error.
			console.error(err);
		});
};

const addTask = async (eventId, task) => {
	const taskRf = generateRandomPassword();
	const timestamp = moment().unix();
	const taskListRef = db
		.collection("events")
		.doc(eventId)
		.collection("task-lists")
		.doc(`${eventId}-${taskRf}`);

	const newTaskList = {
		id: `${eventId}-${taskRf}`,
		eventId: eventId,
		name: task.name,
		description: task.description,
		timestamp,
	};

	return taskListRef
		.set(newTaskList)
		.then(async () => {
			const doc = await taskListRef.get();
			return doc.data();
		})
		.catch((error) => {
			console.log(error.message, "error");
		});
};

const deleteTask = (task) => {
	const { eventId, taskListId, key } = task;

	// Set the guest preferences
	const taskListRef = db
		.collection(`events`)
		.doc(eventId)
		.collection("task-lists")
		.doc(taskListId);

	// Create a reference to the SF doc.
	return db
		.runTransaction((transaction) => {
			return transaction.get(taskListRef).then((taskListDoc) => {
				if (!taskListDoc.exists) {
					throw "Document does not exist!";
				}

				const arraydata = taskListDoc.data().tasks;

				arraydata.splice(key, 1);
				console.log(arraydata);
				transaction.update(taskListRef, {
					tasks: arraydata,
				});
			});
		})
		.then(function () {
			return true;
		})
		.catch(function (err) {
			// This will be an "population is too big" error.
			console.error(err);
		});
};

const taskLists = {
	getTaskLists: getTaskListData,
	updateList: updateTaskList,
	updateTaskChecked: updateTaskChecked,
	updateTask: updateTask,
	addTask: addTask,
	deleteTask: deleteTask,
};

export default taskLists;
