import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Field, reduxForm, initialize } from "redux-form";
import { connect } from "react-redux";
import { Row } from "antd";
import { guests as guestsApi } from "../../helpers/firestore";
import { InButton, FormComponents } from "../../components";
import FileUpload from "./uploadPhoto";
import { InputPhone } from "./MobileNumberInput";
import { toast } from "../../redux/actions";
import {
  FormWrapper,
  Section,
  Header,
  ButtonWrapper,
  ProfileImageContainer,
  ProfileImage,
  FormItem,
} from "./Settings";

const BoxWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 1.125em 0 5em;
  display: flex;
  flex-direction: column;
`;

const InputWrapper = styled.div`
  margin: 1em 0;
  display: flex;
  flex-direction: column;

  label {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #101b43;
  }

  span {
    text-align: right;
    color: red;
    width: 100%;
  }
`;

const Wrapper = styled.div`
  margin: 18px 0;
  /* height: 100%; */
  max-width: 670px;
`;

const ProfileUser = ({
  handleSubmit,
  dispatch,
  formInit,
  pristine,
  submitting,
  user,
  photoURL,
  mobileNumber,
  eventId,
  guestName,
  isGuest,
}) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    formInit({
      name: isGuest ? guestName : user.displayName,
      email: user.email,
      mobileNumber: mobileNumber,
    });
  }, [user, formInit, mobileNumber, guestName, isGuest]);
  const submitFunction = (values) => {
    setLoading(true);
    guestsApi.updateGuestSettings(eventId, user.uid, values).then(() => {
      setLoading(false);
      dispatch(toast("User updated", "success"));
    });
  };

  return (
    <FormWrapper onSubmit={handleSubmit((values) => submitFunction(values))}>
      <Row>
        <Header xs={24}>
          <h5>{`PROFILE & ACCOUNT`}</h5>
        </Header>
        <Section xs={24} md={8}>
          <ProfileImageContainer>
            <ProfileImage>
              <FormItem>
                <Field
                  label="Image"
                  name="image"
                  type="file"
                  component={FileUpload}
                  placeholder="image"
                  image={photoURL}
                  userId={user.uid}
                  dispatch={dispatch}
                  disabled
                />
              </FormItem>
            </ProfileImage>
          </ProfileImageContainer>
        </Section>
        <Section xs={24} md={16}>
          <Wrapper>
            <BoxWrapper>
              <InputWrapper>
                <label htmlFor="email">Email</label>
                <Field
                  name="email"
                  placeholder="Email"
                  component={FormComponents.InInput}
                  disabled
                />
              </InputWrapper>
              <InputWrapper>
                <Field
                  label="Name"
                  name="name"
                  component={FormComponents.InInput}
                  placeholder="Full Name"
                  disabled={!isGuest}
                />
              </InputWrapper>

              <InputWrapper>
                <label htmlFor="mobileNumber">Mobile Number</label>
                <Field
                  name="mobileNumber"
                  component={InputPhone}
                  defaultCountry="US"
                  disabled={!isGuest}
                />
              </InputWrapper>
            </BoxWrapper>
          </Wrapper>
          <ButtonWrapper>
            <InButton
              disabled={pristine || submitting}
              loading={loading}
              htmlType="submit"
              width="100%"
              primary
              text="Save"
            />
          </ButtonWrapper>
        </Section>
      </Row>
    </FormWrapper>
  );
};

ProfileUser.defaultProps = {};

ProfileUser.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = "Required";
  }

  if (!values.email) {
    errors.email = "Required";
  }

  if (!values.mobileNumber) {
    errors.mobileNumber = "Required";
  }
  return errors;
};

const UserForm = reduxForm({
  form: `profileUser`,
  validate,
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(ProfileUser);

export function mapStateToProps(state) {
  return {
    formState: state.form.profileUser,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    formInit: (data) => {
      dispatch(initialize("profileUser", data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
