import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from "moment";
import { CustomModal, CustomModalLayout, InButton, InDatepicker } from '../'
import { normalizeDate, normalizeMomentDate } from '../../helpers/normalizeDate'
import editButton from '../../assets/icons/editBtn.svg'

const Wrapper = styled.div`
    margin-bottom: 20px;
`

const TextWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const Text = styled.h3`
font-family: 'Proxima Nova';
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 150%;
color: #101B43;
`

const DateCard = styled.div`
    background: #FAFAFC;
    border-radius: 8px;
    height: 5.375em;
    padding: 1em;
    
    span {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: normal;
        font-size: 1em;
        line-height: 150%;
        color: #101B43;
    }
`
const Dash = styled.div`
    display: flex;
    background: ${props => props.color};
    border-radius: 8px;
    width: 3.3125em;
    height: 0.1875em;
    margin: 0.4375em 0;

`

const SelectedDates = (dates) => {
	return (
		<Row gutter={16}>
			<Col className="gutter-row" xs={24} md={8}>
				{dates.dates.datesOptionOne
					&& <DateCard>
						<Dash color={`#FF8E95`} />
						{
							dates.dates.datesOptionOne.map((date, index) => {
								return (
									<span key={index}>
										{index ? ` - ` : ''}{normalizeMomentDate(date)}
									</span>
								)
							})
						}
					</DateCard>}
			</Col>
			<Col className="gutter-row" xs={24} md={8}>
				{dates.dates.datesOptionTwo
					&& <DateCard>
						<Dash color={`#A9C5F4`} />
						{
							dates.dates.datesOptionTwo.map((date, index) => {
								return (
									<span key={index}>
										{index ? ` - ` : ''}{normalizeMomentDate(date)}
									</span>
								)
							})
						}
					</DateCard>}
			</Col>
			<Col className="gutter-row" xs={24} md={8}>
				{dates.dates.datesOptionThree
					&& <DateCard>
						<Dash color={`#47B0B7`} />
						{
							dates.dates.datesOptionThree.map((date, index) => {
								return (
									<span key={index}>
										{index ? ` - ` : ''}{normalizeMomentDate(date)}
									</span>
								)
							})
						}
					</DateCard>}
			</Col>
		</Row>
	)

}

function getNumberOfDays(start, end) {
	const startDateString = moment(start);
	const endDateString = moment(end);
	return endDateString.diff(startDateString, 'days')

}

const AddDates = ({ dates, initForm }) => {
	const [visible, setVisibility] = useState(false);
	const [checkValue, setCheck] = useState(false);
	const [selectedDates, setSelectedDate] = useState([]);

	const showModal = () => {
		setVisibility(true)
	};

	const handleOk = () => {
		setVisibility(false)
	};

	const handleCancel = () => {
		setVisibility(false)
		setSelectedDate([])
	};

	const toggleCheck = () => {
		if (checkValue && dates) {
			let datesSelected = []
			Object.entries(dates).forEach(([key, value]) => {
				if (!(key === 'datesOptionTwo' || key === 'datesOptionThree')) {
					datesSelected.push({
						start: normalizeDate(value[0]),
						end: normalizeDate(value[1]),
						days: getNumberOfDays(value[0], value[1])
					})
				}
			});

			if (datesSelected.length === 1) initForm({ dates: { datesOptionOne: dates.datesOptionOne } });
			setSelectedDate(datesSelected)
		}

		setCheck(!checkValue)
	};



	useEffect(() => {
		if (dates) {
			let datesSelected = []
			Object.entries(dates).forEach(([key, value]) => {
				if (value) {
					datesSelected.push({
						start: normalizeMomentDate(value[0]),
						end: normalizeMomentDate(value[1]),
						days: getNumberOfDays(value[0], value[1])
					})
				}
			});
			setSelectedDate(datesSelected)

		}

	}, [dates]);

	return (
		<Wrapper>
			<TextWrapper>
				<Text>Date(s) of Event</Text>
				{dates ?
					<button
						type='button'
						onClick={() => showModal()}
					>
						<img src={editButton} alt="" />
					</button>
					: null}
			</TextWrapper>

			{dates
				? <SelectedDates dates={dates} />
				: <InButton
					icon={<PlusOutlined />}
					type='button'
					onClick={() => showModal()}
					text='ADD DATES'
				/>}
			<CustomModal
				visible={visible}
				handleOk={handleOk}
				handleCancel={handleCancel}
			>
				<CustomModalLayout
					handleCancel={handleCancel}
					title='Add Dates'
					id='add-dates'
					//   checkboxLabel='Do you have a set date?'
					//   toggleCheck={toggleCheck}
					//   checkValue={!checkValue}
					spaceBetween
				>
					<InDatepicker
						disabled={!dates}
						checkValue={checkValue}
						onClick={handleCancel}
						selectedDates={selectedDates}
					/>
				</CustomModalLayout>
			</CustomModal>
		</Wrapper>
	);
};

AddDates.defaultProps = {

};

AddDates.propTypes = {

};

export default AddDates;
