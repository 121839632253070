import React, { Component } from "react";
import { connect } from "react-redux";
import { logoutUser, registerUser } from "../../helpers/auth";
export const NavbarContext = React.createContext();

class NavbarProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLogin: true,
    };
  }

  logout = () => {
    logoutUser(this.props.dispatch);
  };

  onClose = () => {
    this.setState({ showLogin: false });
  };

  googleLogin = () => {
    // loginWithGoogle(this.props.dispatch, this.onClose)
  };

  submitUserDetails = (values) => {
    registerUser(values, this.props.dispatch, this.onClose).then((r) => console.log(r));
  };

  render() {
    const { showLogin } = this.state;
    const { children, user, isAuthenticated } = this.props;

    const providerData = {
      isAuthenticated,
      user,
      showLogin,
      googleLogin: this.googleLogin,
      submitUserDetails: this.submitUserDetails,
      logout: this.logout,
    };
    return <NavbarContext.Provider value={providerData}>{children}</NavbarContext.Provider>;
  }
}

NavbarProvider.defaultProps = {
  // children: PropTypes.object
};

NavbarProvider.propTypes = {
  // children: {}
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: {
      displayName: state.auth.user && state.auth.user.user ? state.auth.user.user.displayName : "User",
      photoURL: state.auth.user && state.auth.user.user ? state.auth.user.user.photoURL : null,
      email: state.auth.user && state.auth.user.user ? state.auth.user.user.email : null,
    },
  };
}

export default connect(mapStateToProps, null)(NavbarProvider);
