import React, {useState} from 'react'
import styled from 'styled-components'
import { Modal, Select, Space } from 'antd';
import { InTypography } from '../index'
import CancelBtn from "../../assets/icons/cancelBtn";
import CancelIcon from '../../assets/icons/cancel'
import { events } from '../../helpers/firestore'
import { CANCELED } from '../../helpers/constants'

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 2em;
`

const CircleButton = styled.button`
  background: #FAFAFC;
  border-radius: 50%;
  width: 98px;
  height: 98px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
`

export const Text = styled.div`
  margin: 32px 0 8px;
`

const DropdownWrapper = styled.div`
  width: 100%;
  margin: 27px 0 40px;
  
  .ant-select-selector {
    height: 100px;
  }
`

const Dropdown = styled(Select)`
  width: 100%;
  outline: black;
  
  input {
  border: black;
        outline: black;
      &:focus {
        border: black;
        outline: black;
      }
  }

`

export const Label = styled.label`
    color: #101B43;
    font-family: 'Proxima Nova Regular';
    font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 150%;
`

export const Buttons = styled.div`
  display: flex;
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 210px;
height: 44px;
background: ${props => props.bg};
border-radius: 4px;
font-family: 'Proxima Nova Regular';
font-style: normal;
font-weight: 600;
font-size: 15px;
line-height: 80%;


color: ${props => props.color};

`

const { Option } = Select;

const CancelPopup = ({ visible, onClose, cancelEventId }) => {
    const [selected, setSelected] = useState(null);

    const handleChange = (value) => {
        setSelected(value)
    };

    const handleSubmit = () => {
        events.updateEventStatus(cancelEventId, CANCELED, selected)().then((response) => response ? onClose() : null)
    }
  return (
      <Modal
          title=''
          visible={visible}
          onCancel={() => onClose()}
          closeIcon={<CancelBtn style={{ margin: 12 }} />}
          destroyOnClose={true}
          footer={null}
      >
          <Content>
              <CircleButton type='button' onClick={() => onClose()} >
                  <CancelIcon />
              </CircleButton>
              <Text>
                  <InTypography.InH2>
                      Woah!
                  </InTypography.InH2>
              </Text>
              <InTypography.InParagraph>Are you sure you want to cancel this event?</InTypography.InParagraph>
              <DropdownWrapper>
                  <Label>Select reason</Label>
                  <Dropdown placeholder='Select' size='large' style={{ width: '100%' }} onChange={handleChange}>
                      <Option value="budget">Budget</Option>
                      <Option value="date conflict">Date Conflict</Option>
                      <Option value="attendance">Attendance</Option>
                      <Option value="other">Other</Option>
                  </Dropdown>
              </DropdownWrapper>
              <Buttons>
                  <Space size='large'>
                      <Button
                          type='button'
                          bg='#EDEDED'
                          color='#8696AE'
                          onClick={() => onClose()}
                      >NO</Button>
                      <Button
                          disabled={!selected}
                          onClick={() => handleSubmit()}
                          bg='#EE4238' color='#FFFFFF'>
                          YES</Button>
                  </Space>

              </Buttons>
          </Content>
      </Modal>
  );
};

CancelPopup.defaultProps = {

};

CancelPopup.propTypes = {

};

export default CancelPopup;
