import { applyMiddleware, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { createFirestoreInstance } from "redux-firestore"; // <- needed if using firestore

import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import { persistStore, persistReducer } from "redux-persist";
import localStorage from "redux-persist/lib/storage";

import { composeWithDevTools } from "redux-devtools-extension";
import { fbApp } from "../config/firebase";
import makeRootReducer from "./reducers";

// react-redux-firebase config
const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
  enableClaims: true, // Get custom claims along with the profile
};

// persist state
const persistConfig = {
  key: "invigo-web-app",
  storage: localStorage,
  blacklist: ["form", "toast", "firestore", "firebase"],
};

export default (initialState = {}, history) => {
  const persistedReducer = persistReducer(persistConfig, makeRootReducer());
  // const reducer = makeRootReducer();
  const store = createStore(
    persistedReducer,
    // reducer,
    initialState,
    composeWithDevTools(applyMiddleware(thunkMiddleware))
  );

  const rrfProps = {
    firebase: fbApp,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance,
  };

  const persistor = persistStore(store);

  return {
    store,
    rrfProps,
    persistor,
  };
};
