import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Form } from "antd";
import { CustomModal, CustomModalLayout, InModal } from "../../";
import { initialize, reduxForm } from "redux-form";
import { connect } from "react-redux";
import GuestList from "./GuestList/GuestList";
import { tasks } from "../../../helpers/firestore";

const FormItem = Form.Item;

const FormWrapper = styled.form`
  max-width: 670px;
  margin: 6.75em auto 0 auto;

  .ant-form-item-label {
    text-align: left;
  }

  .ant-form-item-label label::after {
    content: "";
  }
`;

const AddGuests = ({
  handleSubmit,
  eventId,
  visible,
  handleOk,
  handleCancel,
  guests,
}) => {
  const initForm = (data) => {
    tasks.addTask(eventId, data.taskList);
  };

  return (
    <FormWrapper
      onSubmit={handleSubmit((values) =>
        console.log("submit values: ", values)
      )}
    >
      <FormItem>
        <InModal visible={visible}>
          <CustomModalLayout
            handleCancel={handleCancel}
            title="Create Tasklist"
          >
            <GuestList
              countryCode="ZA"
              initForm={initForm}
              handleCancel={handleCancel}
              guestList={guests}
            />
          </CustomModalLayout>
        </InModal>
      </FormItem>
    </FormWrapper>
  );
};

AddGuests.defaultProps = {};

AddGuests.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const AddGuestsForm = reduxForm({
  form: `guests`,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(AddGuests);

export function mapStateToProps(state) {
  return {
    uid: state.auth.user.user.uid,
    formState: state.form.createEvent,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    formInit: (data) => {
      dispatch(initialize("guests", data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddGuestsForm);
