import React, { Component } from 'react'
import { connect } from 'react-redux'
import {guests} from "../../helpers/firestore";
export const GuestListsContext = React.createContext()

class GuestListsProvider extends Component {
  constructor(props) {
    super(props)

    this.state = {
      guests: null
    }
  }

  componentDidMount() {
    this.getData(this.props.eventId)
  }

  setGuests = (guests) => {
    this.setState({ guests })
  };

  getData = (eventId) => {
    guests.get(eventId, this.setGuests)
  }

  render() {
    const { guests } = this.state
    const { children, eventId  } = this.props

    const providerData = { guests, eventId }
    return (
      <GuestListsContext.Provider value={providerData}>
        {children}
      </GuestListsContext.Provider>
    )
  }
}

GuestListsProvider.defaultProps = {
//<!--  children: PropTypes.object-->
};

GuestListsProvider.propTypes = {
//<!--  children: {}-->
};

function mapStateToProps (state) {
  return {
    //redux data
  }
}

export default connect(mapStateToProps, null)(GuestListsProvider)