import { db } from '../../config/firebase'
import moment from "moment";

function getActivitiesData(eventId, setActivities){
    const eventRef = db.collection('events').doc(eventId)
    const sfDocRef = eventRef.collection(`activities`)
        .orderBy('timestamp', 'desc');

    return sfDocRef.onSnapshot((querySnapshot) => {
        let activities = []
        querySnapshot.forEach((doc) => {
            activities.push(doc.data())
        })
        setActivities(activities)
        return activities
    })
}

const addActivity = (eventId, userId, activity) => {
    const timestamp = moment().unix()

    const activityRef = db.collection(`events`)
        .doc(eventId)
        .collection('activities')
        .doc(`${eventId}-${timestamp}`);

    return activityRef.set( {
        id: activityRef.id,
        activity: activity,
        userId: userId,
        timestamp
    })
        .then(function() {
            return true
        })
        .catch((error) => {
            console.error("Error updating document: ", error);
            return false
        });

};

const activities = {
    getActivitiesData: getActivitiesData,
    addActivity: addActivity
};

export default activities;