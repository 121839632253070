import React from "react";
import { MaxWidthContainer } from "../../components";
import DecorDivider from "./Sections/DecorDivider";
import Dashboard from "./Sections/Dashboard";

// const Content = styled.div`
//     width: 100%;
//     height: 100%;
// `

const Founders = (props) => {
  return (
    <MaxWidthContainer>
      <DecorDivider />
      <div>
        <Dashboard />
      </div>
    </MaxWidthContainer>
  );
};

Founders.defaultProps = {};

Founders.propTypes = {};

export default Founders;
