import React from "react";
import styled from "styled-components";
import { Space, Checkbox, Menu, Dropdown } from "antd";
import { DeleteOutlined, EllipsisOutlined } from "@ant-design/icons";
import EditIcon from "../../../assets/icons/edit";
import { formatStart } from "../../../helpers/normalizeDate";
import { TaskListsContext } from "../../../containers/TaskLists/TaskListsProvider";
import { tasks } from "../../../helpers/firestore";
import useGuests from "../../../hooks/useGuests";
const TableWrapper = styled.div``;

const Table = styled.div`
  background: ${(props) => (props.header ? "none" : "#ffffff")};
  border-radius: 8px;
  margin: 0.5em 0;
  padding: 0;

  ${(props) =>
    props.header ? "" : "box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);"};

  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 64px;
  }

  @media all and (max-width: 1023px) {
    display: ${(props) => (props.header ? "none" : "block")};
  }
  .table-cell {
    text-decoration: ${(props) => (props.completed ? "line-through" : "none")};
  }
`;

const TableCell = styled.div`
  width: ${(props) => (props.width ? props.width : "11.42%")};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  padding: 0 1em;

  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #101b43;
  text-align: left;

  @media all and (max-width: 1023px) {
    width: 100%;
    padding: 0.5em 1em;
    flex-wrap: nowrap;
  }
`;

const RowHead = styled.div`
  width: 3%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-grow: 1;
  height: 100%;
  padding: 0.5em 1em;

  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  /* Text/Endeavour */

  color: #2e2c9a;

  @media all and (max-width: 1023px) {
    width: 100%;
    border-radius: 8px 8px 0 0;
  }

  @media (min-width: 1024px) {
    border-radius: 0 8px 8px 0;
  }
`;

const RowFooter = styled.div`
  width: 5%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-grow: 1;
  height: 100%;
  padding: 0.5em 1em;

  @media all and (max-width: 1023px) {
    width: 100%;
  }

  @media (min-width: 1024px) {
    border-radius: 0 8px 8px 0;
  }
`;

const ActionMenu = styled(Menu)`
  width: 160px;
`;

const ActionItem = styled(Menu.Item)`
  width: 160px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #101b43;

  &:hover {
    color: ${(props) => props.color};
  }
`;

const MenuAction = ({ id }) => {
  return (
    <ActionMenu>
      <ActionItem
        color="#2E2C9A"
        icon={<EditIcon style={{ marginRight: 6 }} />}
      >
        Edit
      </ActionItem>
      <ActionItem color="#EE4238" icon={<DeleteOutlined />}>
        Delete
      </ActionItem>
    </ActionMenu>
  );
};

const DropMenu = styled(Dropdown)`
  cursor: pointer;
`;

const createActions = (data) => {
  return (
    <Space size="middle">
      <DropMenu overlay={<MenuAction id={data.id} />} placement="bottomRight">
        <EllipsisOutlined style={{ fontSize: 28 }} />
      </DropMenu>
    </Space>
  );
};

const HeaderCell = styled.div`
  width: ${(props) => (props.width ? props.width : "11.42%")};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  padding: 0 1em;

  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;

  color: #8696ae;

  @media all and (max-width: 1023px) {
    width: 100%;
  }
`;

const RowStart = styled.div`
  width: 3%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-grow: 1;
  height: 100%;
  padding: 0.8em 1.2em;

  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;

  color: #2e2c9a;

  @media all and (max-width: 1023px) {
    width: 100%;
    border-radius: 8px 8px 0 0;
  }

  @media (min-width: 1024px) {
    border-radius: 0 8px 8px 0;
  }
`;

const HeaderFooter = styled.div`
  width: 5%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-grow: 1;
  height: 100%;
  padding: 0.8em 1.2em;

  @media all and (max-width: 1023px) {
    width: 100%;
  }

  @media (min-width: 1024px) {
    border-radius: 0 8px 8px 0;
  }
`;
const MobTitle = styled.div`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #8696ae;
  padding-right: 1rem;
  width: 130px;
  @media (min-width: 1024px) {
    display: none;
  }
`;
const CheckboxButton = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #4f4db8;
    border-color: #4f4db8;
  }
`;

const Truncate = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const CheckItem = ({ item }) => {
  const handleChange = (input) => {
    if (item.completed) {
      tasks.updateTaskChecked(item, false);
    } else {
      tasks.updateTaskChecked(item, true);
    }
  };

  return (
    <CheckboxButton
      name={item.name}
      size="large"
      onChange={(e) => handleChange(e)}
      checked={item.completed}
    />
  );
};

const renderRows = (tableData) => {
  return tableData.map((data, index) => {
    return (
      <Table key={index} completed={data.completed}>
        <RowHead>
          <CheckItem item={data} />
        </RowHead>
        <TableCell width="25%" className="table-cell">
          <MobTitle>TASK</MobTitle>
          {data.name}
        </TableCell>
        <TableCell width="15%" className="table-cell">
          <MobTitle>DUE</MobTitle>
          {data.due === "" ? "" : formatStart(data.due)}
        </TableCell>
        <TableCell width="22%" className="table-cell">
          <MobTitle>ASSIGNEE</MobTitle>
          <Space>
            <Space>
              {data.assignTo.map((person, index) => (
                <span key={index}>{person.name}</span>
              ))}
            </Space>
          </Space>
        </TableCell>
        <TableCell width="30%" className="table-cell">
          <MobTitle>DESCRIPTION</MobTitle>
          <Truncate>{data.description}</Truncate>
        </TableCell>
        {/* <RowFooter>{createActions({ id: data.key })}</RowFooter> */}
      </Table>
    );
  });
};

const SingleTaskList = ({ tableData }) => {
  return (
    <TaskListsContext.Consumer>
      {({ guests, createTask }) => {
        return (
          <TableWrapper>
            <Table header>
              <RowStart />
              <HeaderCell width="25%">TASK</HeaderCell>
              <HeaderCell width="15%">DUE</HeaderCell>
              <HeaderCell width="22%">ASSIGNEE</HeaderCell>
              <HeaderCell width="30%">DESCRIPTION</HeaderCell>
              <HeaderFooter />
            </Table>
            {renderRows(tableData)}
          </TableWrapper>
        );
      }}
    </TaskListsContext.Consumer>
  );
};

SingleTaskList.defaultProps = {};

SingleTaskList.propTypes = {};

export default SingleTaskList;
