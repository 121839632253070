import React from 'react';
import PropTypes from 'prop-types';
import ChatProvider, { ChatContext } from './ChatProvider'
import { ChatComponent } from '../../components'
const Chat = props => {
  return (
    <ChatProvider eventId={props.match.params.eventId} pathUrl={props.url}>
      <ChatContext.Consumer>
          {({ guests, eventId, activeGroup, setActiveGroupChat, chatThread, pathUrl }) => {
              return (
                <div>
                  <ChatComponent
                      guests={guests}
                      eventId={eventId}
                      activeGroup={activeGroup}
                    setActiveGroupChat={setActiveGroupChat}
                      chatThread={chatThread}
                      pathUrl={pathUrl}
                  />
                </div>
              )
          }}
      </ChatContext.Consumer>
    </ChatProvider>
  );
};

Chat.defaultProps = {

};

Chat.propTypes = {

};

export default Chat