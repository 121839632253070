import React from 'react';
import styled from "styled-components";
import {Menu} from "antd";

const Wrapper = styled.svg`
  
`

export default ({ style }) => (
    <Wrapper width="14" height="13" viewBox="0 0 17 16" fill="none">
        <g clipPath="url(#clip0)">
            <path d="M4.25 8.00012C4.25 7.2074 4.46841 6.4648 4.84784 5.82896V3.13184H2.15072C1.08025 4.52209 0.5 6.21643 0.5 8.00012C0.5 9.7838 1.08025 11.4781 2.15072 12.8684H4.84784V10.1713C4.46841 9.53543 4.25 8.79284 4.25 8.00012Z" fill="#FBBD00"/>
            <path d="M8.5 12.2502L6.625 14.1252L8.5 16.0002C10.2837 16.0002 11.978 15.42 13.3683 14.3495V11.6553H10.674C10.0326 12.0361 9.28691 12.2502 8.5 12.2502Z" fill="#0F9D58"/>
            <path d="M4.84947 10.1719L2.15234 12.869C2.36428 13.1443 2.59506 13.4078 2.84478 13.6576C4.35578 15.1686 6.36475 16.0007 8.50163 16.0007V12.2507C6.95088 12.2507 5.59172 11.4157 4.84947 10.1719Z" fill="#31AA52"/>
            <path d="M16.5 7.99964C16.5 7.51292 16.4559 7.0253 16.369 6.55036L16.2987 6.16602H8.5V9.91602H12.2954C11.9268 10.6492 11.3601 11.2473 10.674 11.6547L13.3682 14.349C13.6435 14.137 13.9071 13.9062 14.1568 13.6565C15.6678 12.1455 16.5 10.1365 16.5 7.99964Z" fill="#3C79E6"/>
            <path d="M11.5052 4.99478L11.8367 5.32622L14.4883 2.67459L14.1569 2.34316C12.6459 0.832156 10.6369 0 8.5 0L6.625 1.875L8.5 3.75C9.63519 3.75 10.7025 4.19206 11.5052 4.99478Z" fill="#CF2D48"/>
            <path d="M8.50166 3.75V0C6.36478 0 4.35581 0.832156 2.84478 2.34313C2.59506 2.59284 2.36428 2.85644 2.15234 3.13172L4.84947 5.82884C5.59175 4.585 6.95091 3.75 8.50166 3.75Z" fill="#EB4132"/>
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="16" height="16" fill="white" transform="translate(0.5)"/>
            </clipPath>
        </defs>
    </Wrapper>
);
