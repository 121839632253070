import React from 'react';
import { Row, Col } from 'antd';
import styled from "styled-components";
import { MaxWidthContainer } from '../../../../components'
import ImageBanner from './ImageSection'
import TextSection from './TextSection'

function DashboardSection() {
    return (
      <MaxWidthContainer>
        <Row container spacing={16}>
          <Col xs={{ order: 2, span: 24 }}  md={{ order: 1, span: 12 }}>
            <TextSection />
          </Col>
          <Col xs={{ order: 1, span: 24 }}  md={{ order: 2, span: 12 }}>
            <ImageBanner />
          </Col>
        </Row>
      </MaxWidthContainer>
    );
}

export default DashboardSection


