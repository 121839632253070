import React, { PureComponent } from 'react';
import styled from 'styled-components';
import {Link, Redirect } from 'react-router-dom';

export const Wrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
height: 100%;
`;

export const Title = styled.div`
  font-size: 4.0625rem;
  font-weight: 800;
`;

export const SubTitle = styled.div`
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 2em;
`;
export const Content = styled.div`
  font-size: 0.875rem;
  
  a{
    color: inherit;
    text-decoration: underline;
  }
`;

class PageNotFound extends PureComponent {
    componentDidMount() {

    }

    render() {
        return (
            <div>
                <h1>404 Page Not Found</h1>
            </div>
        );
    }
}

export default PageNotFound;
