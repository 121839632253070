import React, { Component } from 'react';
import styled from "styled-components"

const ImageWrapper = styled.div`
  margin: 28px 0 38px 0;
  width: 100%;
  height: 320px;
  min-width: 300px;
`;

const Image = styled.div`
    width: 100%;
    height: 100%;
    background: transparent url(${props => props.src}) no-repeat center;
    background-size: cover;
    transform-style: preserve-3d;
    transition: all 0.5s ease;
    border-radius: ${props => props.reverse ? '80px 0;' : '0 80px;'};
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`;

export const DefaultBtn = styled.button`
  margin: 0;
  padding: 12px 14px;
  width: 125px;
  outline: none;
  ${props => {
    return props.primary
      ? `background: #EC6E5B; color: #FFFFFF;  border: none;`
      : `background: #FFFFFF; color: #EC6E5B; border: 1px solid #EC6E5B;`;
  }};
  border-radius: 6px;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;

a{
  ${props => {
    return props.primary
      ? `background: #EC6E5B; color: #FFFFFF;  border: none;`
      : `background: #FFFFFF; color: #EC6E5B; border: 1px solid #EC6E5B;`;
  }};
}
`;

const Container = styled.div`
  width: 243px;
  height: 132px;
  border-radius: 40px 0;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -70px;
`;

export const HeadingThree = styled.h3`
    color: ${props => props.theme.colors.tarawera};   
    font-family: 'Proxima Nova Semibold';
    font-size: 20px;
    line-height: 130%;
    text-align: ${props => props.align ? props.align : 'center'};
    margin: 12px 0;   
    
    ${props => props.theme.breakpoints.tablet}{
      font-size: 20px;
    } 
`;

class CarouselComponent extends Component {
  render() {
    return (
        <ImageWrapper>
          <Image src={this.props.src} reverse={this.props.reverse}>
            <Container>
              <HeadingThree>{this.props.title}</HeadingThree>
              {this.props.to ? (
                <DefaultBtn primary>
                  <a href='https://www.goinvigo.com/city-guides/' target='_blank'>
                      {this.props.button}
                  </a>
                  </DefaultBtn>
                
              ) : <DefaultBtn primary>
                    {this.props.button}
                  </DefaultBtn>}              
            </Container>
          </Image>
        </ImageWrapper>
    );
  }
}

export default CarouselComponent
