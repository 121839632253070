import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Drawer, Button, Skeleton } from "antd";
import backIcon from "../../../assets/icons/backArrow.svg";
import SingleTaskList from "../SingleTaskList";
import { PlusOutlined } from "@ant-design/icons";
import useGuests from "../../../hooks/useGuests";

const IconButton = styled.button`
  padding: 0;
  margin: 0;
  outline: none;
  width: 44px;
  height: 44px;
  color: #494d5b;
  background: #ffffff;
  border: 1px solid #eeeeef;
  box-sizing: border-box;
  border-radius: 4px;
`;

const AddButton = styled(Button)`
  background: ${(props) => (props.primary ? "#4F4DB8" : "#ffffff")};
  border-color: #4f4db8;
  height: 44px;
  border-radius: 4px;
  color: ${(props) => (props.primary ? "#ffffff" : "#2E2C9A")};
  text-transform: uppercase;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 80%;
  display: flex;
  align-items: center;

  &:hover {
    background: ${(props) => (props.primary ? "#2E2C9A" : "#ffffff")};
    border-color: #2e2c9a;
    color: ${(props) => (props.primary ? "#ffffff" : "#2E2C9A")};
  }

  &:focus {
    background: ${(props) => (props.primary ? "#2E2C9A" : "#ffffff")};
    border-color: #2e2c9a;
    color: ${(props) => (props.primary ? "#ffffff" : "#2E2C9A")};
  }
  ${(props) => props.theme.breakpoints.maxPhone} {
    width: 100%;
    justify-content: center;
  }
`;

const DrawerTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  h2 {
    font-family: "Clearface Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 34px;
    color: #101b43;
    margin: 0 1em;
  }
  ${(props) => props.theme.breakpoints.maxTablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const PopUpDrawer = styled(Drawer)`
  .ant-drawer-header {
    border: none;
    ${(props) => props.theme.breakpoints.maxTablet} {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .ant-drawer-content {
    background: #fff;
  }
  .ant-drawer-body {
    ${(props) => props.theme.breakpoints.maxTablet} {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

const SubHeading = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  p {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #101b43;
    max-width: 47.75em;
    //width: 100%;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  ${(props) => props.theme.breakpoints.maxTablet} {
    margin-bottom: 1em;
  }
`;
function generateTableData(data, guests) {
  return data.tasks
    ? data.tasks.map((task, index) => {
        return {
          id: task.id,
          key: index,
          name: task.name ? task.name : "-",
          description: task.description,
          due: task.due,
          assignTo: task.assignTo
            ? guests.filter((o1) => task.assignTo.some((o2) => o1.id === o2.id))
            : [],
          taskListId: task.taskListId,
          eventId: data.eventId,
          completed: task.completed ? task.completed : false,
          listName: task.listName ? task.listName : "-",
        };
      })
    : [];
}

const TaskList = ({ visible, onClose, activeList, guests }) => {
  const [showAdd, setAddNew] = useState(false);
  const isGuest = useGuests();

  const showModal = () => {
    setAddNew(true);
  };

  const handleCancel = () => {
    setAddNew(false);
  };

  return (
    <PopUpDrawer
      title={
        <DrawerTitle>
          <Title>
            <IconButton
              type="button"
              onClick={() => {
                onClose();
                handleCancel();
              }}
            >
              <img src={backIcon} alt="" />
            </IconButton>
            <h2>{activeList ? activeList.name : <Skeleton active />}</h2>
          </Title>
          {!isGuest && (
            <AddButton
              icon={<PlusOutlined />}
              onClick={() => showModal()}
              disabled={showAdd}
            >
              CREATE NEW TASK
            </AddButton>
          )}
        </DrawerTitle>
      }
      placement="bottom"
      closable={false}
      onClose={onClose}
      visible={visible}
      getContainer={false}
      style={{ position: "absolute" }}
      height="100%"
      headerStyle={{ background: "##ffffff" }}
    >
      <SubHeading>
        <p>{activeList ? activeList.description : <Skeleton active />}</p>
      </SubHeading>
      {activeList ? (
        <SingleTaskList
          activeList={activeList}
          tableData={generateTableData(activeList, guests)}
          showAdd={showAdd}
          handleCancel={handleCancel}
        />
      ) : (
        <Skeleton active />
      )}
    </PopUpDrawer>
  );
};

TaskList.defaultProps = {};

TaskList.propTypes = {};

export default TaskList;
