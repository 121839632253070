import React from 'react';
import { Row, Col } from 'antd';
import styled from "styled-components";
import { MaxWidthContainer } from '../../../../components';
import ImageBanner from './ImageSection'
import TextSection from './TextSection'

export const Wrapper = styled(Col)`
  margin-top: 35px;
`;

function Benefits() {
    return (
      <MaxWidthContainer>
        <Row gutter={1}>
          <Col item xs={24} md={16}>
            <TextSection />
          </Col>
          <Wrapper item xs={24}  md={8}>
            <ImageBanner />
          </Wrapper>
        </Row>
      </MaxWidthContainer>
    );
}

export default Benefits


