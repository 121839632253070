import React from "react";
import { Row, Col } from "antd";
import styled from "styled-components";
import ImageBanner from "./ImageSection";
import PartyShop from "./party-shop.png";
import IdeasAdvice from "./ideas-advice.png";
import { MaxWidthContainer, MotionSlider } from "../../../../components";
import CityGuides from "../../../../assets/home/city-guides.jpg";
import guidesBgTop from "../../../../assets/home/guides-bg-top.png";
import guidesBgBottom from "../../../../assets/home/guides-bg-bottom.png";

const Background = styled.div`
  background: #fafafc;
  height: 582px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 75px;

  @media (min-width: 768px) {
    margin-top: 0;
  }
`;

export const Top = styled.img`
  position: absolute;
  left: 0;
  width: 100%;
  height: 139px;
  z-index: 100;
  top: -100px;
`;

export const Bottom = styled.img`
  position: absolute;
  left: 0;
  width: 100%;
  height: 139px;
  z-index: 100;
  bottom: -100px;
`;

export const Desktop = styled.div`
  display: block;

  @media (max-width: 958px) {
    display: none;
  }
`;

export const Mobile = styled.div`
  @media (max-width: 957px) {
    display: block;
  }
  @media (min-width: 958px) {
    display: none;
  }
`;

export const GridItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const guides = [
  {
    title: "City Guides",
    image: CityGuides,
    button: "Explore",
    reverse: true,
    link: null,
  },
  {
    title: "City Guides",
    image: PartyShop,
    button: "Shop Now",
    reverse: false,
    link: "https://www.goinvigo.com/city-guides/",
  },
  {
    title: "Ideas & Advice",
    image: IdeasAdvice,
    button: "Read",
    reverse: false,
    link: null,
  },
];
function DashboardSection() {
  return (
    <Background>
      <Top src={guidesBgTop} />
      <MaxWidthContainer>
        <Mobile>
          <MotionSlider padding={0} gap={30}>
            {guides.map((guide, i) => (
              <ImageBanner
                key={i}
                title={guide.title}
                src={guide.image}
                button={guide.button}
                to={guide.link}
                reverse
              />
            ))}
          </MotionSlider>
        </Mobile>

        <Desktop>
          <Row gutter={16}>
            {guides.map((guide, i) => (
              <Col xs={24} md={8} key={guide.title}>
                <ImageBanner
                  key={i}
                  title={guide.title}
                  src={guide.image}
                  button={guide.button}
                  to={guide.link}
                  reverse
                />
              </Col>
            ))}
          </Row>
        </Desktop>
        <Bottom src={guidesBgBottom} />
      </MaxWidthContainer>
    </Background>
  );
}

export default DashboardSection;
