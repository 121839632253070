import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Drawer, Skeleton } from "antd";
import DownArrow from "../../../../assets/icons/downArrow";
import { HeartSvg } from '../../../../assets/icons/heart'

const Wrapper = styled.div`
  width: 100%;
  min-width: 332px;
  background: #FAFAFC;
	${(props) => props.theme.breakpoints.maxTablet} {
		background: #fff
	}
`

const MenuTitle = styled.div`
font-family: 'Clearface Bold';
font-style: normal;
font-weight: bold;
font-size: 26px;
line-height: 34px;
color: #101B43;
margin-bottom: 1em;
`

const PopUpDrawer = styled(Drawer)`

.ant-drawer-mask {
  border-radius: 8px;
}
  .ant-drawer-content-wrapper {
    border-radius: 8px;
    padding: 32px 20px;
  }
  
  .ant-drawer-header {
    border: none;
  }
  
  .ant-drawer-body {
    
  }
  .ant-drawer-content {
    background: #FAFAFC;
    max-width: 670px;
    margin: 0 auto;
    
  }
  
  .ant-drawer-content-wrapper {
    background: #FAFAFC;
  }
`
const Menu = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  
  li {
    margin: 4px 0;
  }
`

const Button = styled.button`
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    color: #101B43;
    padding: 11px 1em;
    z-index: 999;
    width: 100%;
    height: 44px;
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const Icon = styled.div`
  transform: rotateZ(-90deg);
`
const OptionTitle = styled.h1`

`

const Options = styled.ul`
    padding: 0;
    margin: 0;
    list-style: none;
    
    li {
        display: flex;
        align-items: center;
        margin: 0.5em 0;
        cursor: pointer;
    }
`

const Details = styled.div`
    padding-left: 1em;
    text-align: left;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`

export const Image = styled.div`
    width: 95px;
    height: 95px;
    background: transparent url(${props => props.src}) no-repeat center;
    background-size: cover;
    border-radius: 8px;
`;

export const SvgContainer = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	width: 32px;
  svg {
    font-size: 2em;
    margin: 0;
    padding: 0;
	width: 32px;
    
  }
	span {
		font-weight: 600;
		text-transform: uppercase;
		color: #fff;
		font-size: 11px;
		position: absolute;
		left: 0;
		right: 0;
		top: 2px;
		text-align: center;

	}
`;

const EventPlaceMenu = ({ polls, addEvent, addPlace }) => {
	const [drawer, setDrawer] = useState({
		visible: false,
		data: ''
	});
	// const [eventType, setEvent] = useState(false);

	const onClose = ({placeName, placeImage}) => {
		setDrawer({
			visible: false,
			name: '',
			data: null
		})

		addPlace({placeName,placeImage })
	}

	const onOpen = (name, data) => {
		setDrawer({
			visible: true,
			name: name,
			data: data
		})

		addEvent(name)
	}

	const dining = polls.filter((poll) => poll.recommendationName === 'dining')
	const activities = polls.filter((poll) => poll.recommendationName === 'activities')
	const customPolls = polls.filter((poll) => poll.recommendationName === 'custom')

	return (
		<Wrapper>
			<div>
				<MenuTitle>Dining</MenuTitle>
				<Menu>
					{dining.map(poll => {
						return (
							<li>
								<Button type='button' onClick={() => onOpen(poll.name, poll)}>
									<span>{poll.name}</span>
									<Icon><DownArrow /></Icon>
								</Button>
							</li>
						)
					})}
				</Menu>
			</div>
			<div>
				<MenuTitle>Activities</MenuTitle>
				<Menu>
					{activities.map(poll => {
						return (
							<li>
								<Button type='button' onClick={() => onOpen(poll.name, poll)}>
									<span>{poll.name}</span>
									<Icon><DownArrow /></Icon>
								</Button>
							</li>
						)
					})}
				</Menu>
			</div>
			<div>
				<MenuTitle>Custom Poll</MenuTitle>
				<Menu>
					{customPolls.map(poll => {
						return (
							<li>
								<Button type='button' onClick={() => onOpen(poll.name, poll)}>
									<span>{poll.name}</span>
									<Icon><DownArrow /></Icon>
								</Button>
							</li>
						)
					})}
				</Menu>
			</div>

			{drawer.visible
				&& <PopUpDrawer
					placement="right"
					closable={false}
					onClose={() => onClose()}
					visible={drawer.visible}
					getContainer={false}
					style={{ position: 'absolute', width: '100%' }}
					width='100%'
				>
					<OptionTitle>{drawer.name}</OptionTitle>

					<Options>
						{drawer.data.poll.map((poll, index) => {
							return (
								<li key={index} onClick={() => onClose({placeName: poll.name, placeImage: poll.image})}>
									<Image src={poll.image} />
									<Details>
										<h5>{poll.name}</h5>
										<p>{poll.note}</p>
										<SvgContainer>
											<HeartSvg />
											<span>
												{poll.votes ? poll.votes.length : 0}
											</span>
										</SvgContainer>
									</Details>
								</li>
							)
						})}
					</Options>
					<button type='button' onClick={() => onClose()}>close</button>
				</PopUpDrawer>}

		</Wrapper>
	);
};

EventPlaceMenu.defaultProps = {

};

EventPlaceMenu.propTypes = {

};

export default EventPlaceMenu;
