import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { guests, itinerary, polls } from "../../helpers/firestore";
export const ScheduleContext = React.createContext()

class ScheduleProvider extends Component {
	constructor(props) {
		super(props)

		this.state = {
			itineraryData: null,
			recommendations: [],
			polls: [],
			brides: [],
			activeMenu: {
				name: 'itinerary-plan',
				field: null

			}
		}
	}

	componentDidMount() {
		this.getData(this.props.eventId)
	}

	setItineraryData = (itineraryData) => {
		this.setState({ itineraryData })
	};

	setRecommendationsData = (recommendations) => {
		this.setState({ recommendations })
	};

	setPollList = (polls) => {
		this.setState({ polls })
	};

	setGuests = (guests) => {
		this.setState({ brides: guests })
	};

	getData = (eventId) => {
		itinerary.getItineraryData(eventId, this.setItineraryData)
		guests.getBride(eventId, this.setGuests)
		polls.getPolls(eventId, this.setPollList)
	}

	setAccommodationMenu = (field) => {
		this.setState({
			accommodationMenu: {
				show: true,
				field: field
			},
			activePlan: false
		})
	};

	toggleMenu = (name, field) => {
		this.setState({
			activeMenu: {
				name: name,
				field: field
			}
		})
	}

	render() {
		const { itineraryData, brides, activeMenu, polls } = this.state
		const { children, eventId, event, userUid } = this.props
		const providerData = {
			eventId,
			polls,
			itineraryData,
			event,
			brides,
			userUid,
			activeMenu,
			toggleMenu: this.toggleMenu,
		}
		return (
			<ScheduleContext.Provider value={providerData}>
				{children}
			</ScheduleContext.Provider>
		)
	}
}

ScheduleProvider.defaultProps = {
	//<!--  children: PropTypes.object-->
};

ScheduleProvider.propTypes = {
	//<!--  children: {}-->
};

function mapStateToProps(state) {
	return {
		//redux data
		userUid: state?.auth?.user?.user?.uid
	}
}

export default connect(mapStateToProps, null)(ScheduleProvider)