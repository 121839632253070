import React from "react";
import styled from "styled-components";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";

export const PhoneNumber = styled(PhoneInput)`
  border: 1px solid #eeeeef;
  border-radius: 4px;
  display: flex;
  outline: none;
  height: 44px;

  input {
    border: 1px solid #eeeeef;
    border-radius: 4px;
    display: flex;
    outline: none;
    height: 44px;
    padding: 10px 12px;

    &:focus {
      border: 1px solid #eeeeef;
    }
  }

  .PhoneInput {
    /* This is done to stretch the contents of this component. */
    display: flex;
    align-items: center;
  }

  .PhoneInputInput {
    /* The phone number input stretches to fill all empty space */
    flex: 1;
    /* The phone number input should shrink to make room for the extension input */
    min-width: 0;
  }

  .PhoneInputCountryIcon {
    width: 30px;
    height: 1em;
  }

  .PhoneInputCountryIcon--square {
    width: 100%;
  }

  .PhoneInputCountryIcon--border {
  }

  .PhoneInputCountryIconImg {
    display: block;
    width: 100%;
    height: 100%;
  }

  .PhoneInputInternationalIconPhone {
    opacity: 1;
  }

  .PhoneInputInternationalIconGlobe {
    opacity: 1;
  }

  /* Styling native country \`<select/>\`. */

  .PhoneInputCountry {
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
  }

  .PhoneInputCountrySelect {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
  }

  .PhoneInputCountrySelect[disabled] {
    cursor: default;
  }

  .PhoneInputCountrySelectArrow {
    display: block;
    content: "";
    width: 1em;
    height: 1em;
    margin-top: 0.5em;
    margin-left: 0;
    border-style: solid;
    border-color: black;
    border-top-width: 0;
    border-bottom-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    opacity: 1;
  }

  .PhoneInputCountrySelect:focus
    + .PhoneInputCountryIcon
    + .PhoneInputCountrySelectArrow {
    opacity: 1;
    color: #eeeeef;
  }

  .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
    box-shadow: 0 0 0 1px #eeeeef;
  }

  .PhoneInputCountrySelect:focus
    + .PhoneInputCountryIcon
    .PhoneInputInternationalIconGlobe {
    opacity: 1;
    color: #eeeeef;
  }
`;
export const InputPhone = ({
  input,
  custom,
  name,
  label,
  countryCode,
  onChange,
  value,
  valueInput,
}) => {
  return (
    <PhoneNumber
      label={label}
      name={name}
      value={valueInput}
      onChange={(e) => {
        if (onChange) onChange(e);
        input.onChange(e);
      }}
      international={false}
      withCountryCallingCode={true}
      country={countryCode}
      defaultCountry="US"
      flags={flags}
      // {...input}
      // {...custom}
    />
  );
};
