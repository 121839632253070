import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Field, reduxForm, SubmissionError } from "redux-form";
import styled from "styled-components";
import { FormComponents, InTypography, InButton } from "../../index";
import { fbApp } from "../../../config/firebase";
import { useHistory } from "react-router-dom";

const FormWrapper = styled.form`
  max-width: 670px;
  width: 100%;
  margin: 27px 0 0 0;
  text-align: center;

  p {
    margin: 1em 0;
  }

  .ant-form-item-label {
    text-align: left;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;

    color: #101b43;
  }

  .ant-form-item-label label::after {
    content: "";
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
`;

// export const Container = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-top: 20px;
//   color: #8696ae;
//   cursor: pointer;
//   font-family: "Proxima Nova";
//   font-style: normal;
//   font-weight: 600;
//   font-size: 13px;
//   line-height: 100%;

//   span {
//     color: #101b43;
//     margin: 0 0.4em;
//   }
// `;

export const Container = styled.div`
  margin: 27px 55px 40px 55px;
  text-align: center;
  p {
    margin: 1em 0;
  }
`;

const ResetPassword = ({ handleSubmit, handleActiveForm }) => {
	let history = useHistory();
	const [emailSent, setEmailSent] = useState(false);
	const [actionCode, setActionCode] = useState(null);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const [loading, setLoading] = useState(false);
	const [continueUrl, setContinueUrl] = useState(null);

	useEffect(() => {
		const windowData = Object.fromEntries(
			new URL(window.location).searchParams.entries()
		);
		setContinueUrl(windowData.continueUrl);
		if (!windowData.oobCode) {
			history.push(windowData.continueUrl);
		}
		setActionCode(windowData.oobCode);
	}, []);

	const resetPassword = async (values) => {
		fbApp
			.auth()
			.verifyPasswordResetCode(actionCode)
			.then((email) => {
				const newPassword = values.password;

				fbApp
					.auth()
					.confirmPasswordReset(actionCode, newPassword)
					.then((resp) => {
						setSuccess(true);
					})
					.catch((error) => {
						setError(error.code);
					});
			})
			.catch((error) => {
				setError(error.code);
			});
	};

	if (error) {

		return (
			<Container>
				<InTypography.InH1>Link Expired</InTypography.InH1>
				<InTypography.InParagraph>
					Your request to reset your password has expired or the link has
					already been used.
				</InTypography.InParagraph>
				<InButton
					type="primary"
					onClick={() => continueUrl ? window.location.href = continueUrl : history.push("/")}
					text="Continue"
					primary
					width="100%"
					disabled={false}
				>
					Continue
				</InButton>
			</Container>
		);
	}

	if (success) {
		return (
			<Container>
				<InTypography.InH1>Success</InTypography.InH1>
				<InTypography.InParagraph>
					Your password has been updated.
				</InTypography.InParagraph>
				<InButton
					type="primary"
					onClick={() => continueUrl ? window.location.href = continueUrl : history.push("/")}
					text="Continue"
					primary
					width="100%"
					disabled={false}
				>
					Continue
				</InButton>
			</Container>
		);
	}

	return (
		<FormWrapper onSubmit={handleSubmit((values) => { setLoading(true); resetPassword(values) })}>
			<InTypography.InH1>Reset password</InTypography.InH1>
			<InTypography.InParagraph>
				Please enter your new password
			</InTypography.InParagraph>
			<Field
				label="Password"
				addonAfter="Minimum 8 characters"
				name="password"
				type="password"
				component={FormComponents.InInputPassword}
				placeholder="Password"
			/>
			<Field
				label="Password"
				addonAfter="Minimum 8 characters"
				name="confirmPassword"
				type="password"
				component={FormComponents.InInputPassword}
				placeholder="Password"
			/>
			<InButton
				type="primary"
				htmlType="submit"
				text="RESET"
				primary
				width="100%"
				disabled={false}
				loading={loading}
			/>
		</FormWrapper>
	);
};

ResetPassword.defaultProps = {};

ResetPassword.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
};

const validate = (values) => {
	const errors = {};
	if (!values.password) {
		errors.password = "Required";
	}
	if (values && values.password && values.password.length < 8) {
		errors.password = "Your password is too short";
	}
	if (values && values.password !== values.confirmPassword) {
		errors.confirmPassword = "Password doesn't match";
		errors.password = "Password doesn't match";
	}

	return errors;
};

const ForgotPasswordForm = reduxForm({
	form: "ResetPassword",
	validate,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
})(ResetPassword);

export default ForgotPasswordForm;
