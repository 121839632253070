import React, { Component } from 'react';
import styled from "styled-components"
import getStarted from '../../../../../assets/home/dashboard.jpg'
import dashboardDecor from '../../../../../assets/home/dashboard-decor.svg'


const HeaderWrapper = styled.div`
  width: 100%;
  height: 60vw;;
  min-height: 186px;
  border: 8px solid #FAFAFC;
  box-sizing: border-box;
  border-radius: 12px;
  position: relative;
  background: #ffffff;

    ${props => props.theme.breakpoints.tablet}{
      width: 100%;
      height: 36vw;
      max-height: 456px;
    }

`;

const BgImage = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  
  z-index: 0;
  top: -82px;
  left: -137px;

  ${props => props.theme.breakpoints.tablet}{
    top: -135px;
    left: -182px;
  }

  
`;

const PrimaryContainer = styled.div`
  z-index: 1;
  background: #ffffff;
  position: absolute;
  width: 100%;
  height: 100%;

`;

const PrimaryImage = styled.img`
  width: 100%;
  height: 100%;
  background: #ffffff;

`;

class CarouselComponent extends Component {
  render() {
    return (
      <HeaderWrapper>
        <BgImage src={dashboardDecor} />
        <PrimaryContainer>
          <PrimaryImage src={getStarted} />
        </PrimaryContainer>
        
      </HeaderWrapper>
    );
  }
}

export default CarouselComponent
