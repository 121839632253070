import { db } from "../../config/firebase";

const getUserSettings = async (userId) => {
  console.log({ userId });
  const settingsRef = db.collection(`users`).doc(userId);
  return settingsRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data();
      } else {
        return null;
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });
};

const setUserSettings = async (userId, settings) => {
  const settingsRef = db.collection(`users`).doc(userId);
  const batch = db.batch();

  batch.update(settingsRef, settings);
  return batch
    .commit()
    .then(async (res) => {
      return res;
    })
    .catch((err) => console.log("err: ", err));
};

const unsubscibe = async (userId, request = fetch) => {
  const requestObject = {
    data: {
      userId,
    },
  };

  const postBody = JSON.stringify(requestObject);

  let url;
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    url = `${process.env.REACT_APP_UNSUBSCRIBE_DEV}`;
  } else {
    url = `${process.env.REACT_APP_UNSUBSCRIBE_PROD}`;
  }

  try {
    const response = await request(url, { method: "POST", body: postBody });
    if (response.status === 200) {
      return await response.json();
    }
  } catch (error) {
    console.log("errors: ", error);
  }
};

export default {
  get: getUserSettings,
  set: setUserSettings,
  unsubscibe,
};
