import React from 'react'
import styled from 'styled-components'
import { Tabs } from 'antd';
import { InButton } from '../'
import { PlusOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

const Wrapper = styled(Tabs)`
  .ant-tabs-nav {
    margin: 0 0 2.265em 0;
  }
  .ant-tabs-tab {
    color: #8696AE;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 100%;
    margin-bottom: 12px;
    margin-top: 6px;
}
  .ant-tabs-tab.ant-tabs-tab-active {
    color: #2E2C9A;
    font-weight: 600;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #2E2C9A;
    font-weight: 600;
}

.ant-tabs-ink-bar {
    background: #2E2C9A;
}

.ant-tabs-tab:hover {
    color: #2E2C9A;
}
`

const TabMobile = styled.div`
  display: block;
     @media (min-width: 768px) {
       display: none;
    }
`

const MobileButton = styled.div`
  display: block;
     @media (max-width: 768px) {
       display: none;
    }
`

const CustomTab = ({ data, action, defaultTab }) => {
  return (
      <Wrapper
        defaultActiveKey={defaultTab}
        tabBarExtraContent={
          <MobileButton>
            <InButton
              primary='true' 
              text='NEW EVENT' 
              icon={<PlusOutlined />}
              onClick={() => action()}
            />
          </MobileButton>}
      >
          {data.map((tab, index) => {
              const { name, component } = tab
              const Component = component
              return (
                  <TabPane tab={name} key={index}>
                      <TabMobile>
                          <InButton primary='true' text='NEW EVENT' icon={<PlusOutlined />} onClick={() => action()}/>
                      </TabMobile>
                      <Component action={action}/>
                  </TabPane>
              )
          })}
      </Wrapper>
  );
};

CustomTab.defaultProps = {

};

CustomTab.propTypes = {

};

export default CustomTab;
