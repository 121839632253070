import React, { useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import { HeartSvg, HeartOutlinedSvg } from "../../../assets/icons/heart";
import { PollTimer, InButton } from "../../../components";
import { polls } from "../../../helpers/firestore";

export const Container = styled(Row)`
  padding: 0 1.25em;
`;

export const Header = styled(Col)`
  height: 60px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
  ${(props) => props.theme.breakpoints.maxTablet} {
    display: block;
    height: auto;
  }

  h2 {
    font-family: "Clearface Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 34px;
    color: #101b43;
    padding: 0 15px;
    margin: 0;
    ${(props) => props.theme.breakpoints.maxTablet} {
      padding: 15px;
    }
  }

  h3 {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 120%;
    color: #2e2c9a;
    padding: 0;
    margin: 0;
  }
`;

export const CardContent = styled(Row)`
  /* padding: 1em; */
  flex-wrap: wrap;
`;

export const CardHeader = styled(Row)`
  padding: 1em;
  ${(props) => props.theme.breakpoints.maxPhone} {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;

  h5 {
    padding: 0;
    margin: 0;
    font-family: "Proxima Nova Semibold";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 100%;
    color: #101b43;
  }

  ${(props) => props.theme.breakpoints.maxTablet} {
    margin-top: 15px;
    margin-bottom: 15px;
    align-items: flex-start;
    h5 {
      padding-top: 15px;
    }
  }
`;

export const CityCard = styled.div`
  /* height: 254px; */
  background: #fafafc;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin-bottom: 1em;
`;

export const Content = styled.div`
  padding: 15px;
  h5 {
    margin-bottom: 15px;
    padding: 0;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: #101b43;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

export const SvgContainer = styled.div`
  margin-top: 1em;
  display: flex;
  align-items: center;
  svg {
    font-size: 1em;
    margin: 0;
    padding: 0;
    width: 28px;
  }
  span {
    font-weight: 600;
    text-transform: uppercase;
    color: #000;
    margin-left: 5px;
  }
`;

export const Price = styled.div`
  color: #4f4db8;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
`;

export const Note = styled.div`
  font-size: 16px;
`;

const Column = styled(Col)`
  display: flex;
  flex-direction: column;
`;

export const HeaderImage = styled.div`
  width: 100%;
  height: 150px;
  background: transparent url(${(props) => props.src}) no-repeat center;
  background-size: cover;
  border-radius: 8px 8px 0 0;
`;

export const ColContent = styled(Col)`
  margin: 1.5em 2em;

  p {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */

    display: flex;
    align-items: center;

    /* Text/Midnight Blue */

    color: #101b43;
  }
`;

const PollsPage = ({ name, recommendationPolls, eventId, user }) => {
  const [visible, setVisibility] = useState(false);

  const toggleModal = () => {
    setVisibility(!visible);
  };

  const voteCast = (vote, poll) => {
    const userDetails = {
      uid: user.uid,
      photoURL: user.photoURL,
      name: user.displayName,
    };
    polls.updateVote(userDetails, eventId, vote, poll);
  };

  return (
    <div>
      <Row>
        <Header xs={24}>
          <h2>{name}</h2>
        </Header>
      </Row>

      {recommendationPolls.every(
        (poll) => !poll.invitedGuests.some((guest) => guest.id === user.uid)
      ) && (
        <Row>
          <ColContent xs={24}>
            <p>Polls not set</p>
          </ColContent>
        </Row>
      )}

      {recommendationPolls.map((poll, index) => {
        if (!poll.invitedGuests.some((guest) => guest.id === user.uid)) {
          return <div></div>;
        }
        return (
          <>
            <CardHeader gutter={16} key={index}>
              <Header xs={24}>
                <h3>{poll.name}</h3>
                {poll.finalizedPoll && poll.finalizedPoll.length ? (
                  <ActionContainer>
                    <InButton
                      width="170px"
                      type="button"
                      onClick={() => {}}
                      text="POLL CLOSED"
                    />
                  </ActionContainer>
                ) : (
                  <ActionContainer>
                    <h5>POLL CLOSES:</h5>
                    <PollTimer closeDate={poll.countdown} />
                  </ActionContainer>
                )}
              </Header>
            </CardHeader>
            <CardContent gutter={16}>
              {poll && poll.finalizedPoll && poll.finalizedPoll.length
                ? poll.finalizedPoll.map((item, index) => {
                    return (
                      <Column xs={24} sm={8} key={index}>
                        <CityCard
                          onClick={() =>
                            item.url
                              ? window.open(
                                  item.url.startsWith("http://") ||
                                    item.url.startsWith("https://")
                                    ? item.url
                                    : `https://${item.url}`,
                                  "_blank"
                                )
                              : {}
                          }
                        >
                          <HeaderImage src={item.image} />
                          <Content>
                            <h5>{item.name}</h5>
                            <Note>{item.note}</Note>
                            <Price>{item.price}</Price>
                            <SvgContainer>
                              <HeartSvg />
                              <span>
                                {item && item.votes ? item.votes.length : 0}{" "}
                                votes
                              </span>
                            </SvgContainer>
                          </Content>
                        </CityCard>
                      </Column>
                    );
                  })
                : poll &&
                  poll.poll &&
                  poll.poll.map((item, index) => {
                    const didVote =
                      item && item.votes && item.votes.length
                        ? item.votes.some((voter) => voter.uid === user.uid)
                        : false;
                    return (
                      <Column xs={24} sm={8} key={index}>
                        <CityCard>
                          <HeaderImage
                            src={item.image}
                            onClick={() =>
                              item.url
                                ? window.open(
                                    item.url.startsWith("http://") ||
                                      item.url.startsWith("https://")
                                      ? item.url
                                      : `https://${item.url}`,
                                    "_blank"
                                  )
                                : {}
                            }
                          />
                          <Content>
                            <h5>{item.name}</h5>
                            <div>
                              <Note>{item.note}</Note>
                              <Price>{item.price}</Price>
                              <SvgContainer
                                onClick={() => voteCast(item, poll)}
                              >
                                {didVote ? <HeartSvg /> : <HeartOutlinedSvg />}
                                <span>
                                  {item && item.votes ? item.votes.length : 0}{" "}
                                  votes
                                </span>
                              </SvgContainer>
                            </div>
                          </Content>
                        </CityCard>
                      </Column>
                    );
                  })}
            </CardContent>
          </>
        );
      })}
    </div>
  );
};

PollsPage.defaultProps = {};

PollsPage.propTypes = {};

export function mapStateToProps(state) {
  return {
    user: state.auth.user.user,
  };
}

export default connect(mapStateToProps)(PollsPage);
